import {
	ADD_TO_SELECTED_BOOKLIST,
	FETCH_MY_BOOKLIST,
	FETCH_MY_CONTENT,
	START_MY_CONTENT_LIST_FETCH,
} from '../actions';

const initialState = {
	loading: false,
	data: null,
	included: null,
	totalContent: 0
};

export default (state = initialState, action) => {
	switch (action.type) {
	case START_MY_CONTENT_LIST_FETCH:
		return {
			...state,
			loading: true,
		};
	case ADD_TO_SELECTED_BOOKLIST:
		const contents = state.data;
		const nContent = action.payload;
		if (
			contents.filter(
				(content) =>
					content.attributes.slug && (nContent.attributes ? content.attributes.slug === nContent.attributes.slug : content.attributes.slug === nContent.included.attributes.slug) ||
          !content.attributes.slug && (content.id === nContent.id)
			).length === 0
		) {
			const attributes = nContent.included;
			nContent.newSlug = attributes.id.replace(new RegExp('/', 'g'), '');
			const newContent = {
				...nContent,
				attributes: {
					...attributes
				},
				type: `${action.payload.newType || attributes.type.toUpperCase() || action.payload.type}S`
			};
			return {
				...state,
				contents: [...contents, newContent],
			};
		}
		return state;
	case FETCH_MY_CONTENT:
		return {
			...state,
			data: action.payload.data,
			included: action.payload.included,
			totalContent: action.payload.total,
			loading: false,
		};
	case FETCH_MY_BOOKLIST:
		return {
			...state,
			data: action.payload.data,
			included: action.payload.included,
			loading: false,
		};
	default:
		return state;
	}
};
