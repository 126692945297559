/* eslint-disable no-undef */
import { setCache } from '../../helpers/cacheStore';

import axios from 'axios';
import {
	ARTICLE_TYPE,
	environment,
	EXTERNAL_BOOK_TYPE,
	OFFLINE_BOOK_TYPE,
	JOURNAL,
} from './index';
import { PUT_BOOKLIST_TAGS } from '../types/booklistTypes';
import Cognito from '../../helpers/cognito';
import { uploadFile } from './uploadFileActions';
import {
	PUT_CONTENT_TAGS,
	UPDATE_CONTENT_PREVIEW,
	PROFILE_CONTENTS,
	PROFILE_BOOKLIST_FOLLOWINGS,
	PROFILE_BOOKLIST_PERMISSIONS,
	PRIVATE_BOOKLISTS_PERMISSION,
	CLEAR_PROFILE_CONTENT,
	REMOVE_FROM_STATE,
	PROFILE_LIKED_CONTENTS,
	PROFILE_COLLABORATIVE_BOOKLISTS,
	CLEAR_PROFILE_LIKED_CONTENT,
	DELETE_CONTENT_FROM_PROFILE,
} from '../types/contentTypes';
import { PROFILE_CONTENTS_LIMIT } from '../../utils/constans';

export const FETCH_PREVIEW_CONTENT = 'fetch_preview_content';
export const START_FETCH_PREVIEW_CONTENT = 'start_fetch_preview_content';
export const fetchPreviewContent = (slug) => async (dispatch) => {
	dispatch({
		type: START_FETCH_PREVIEW_CONTENT,
	});
	return axios({
		method: 'GET',
		url: `${env.msContentNew}/api/contents/${slug}`,
		headers: {
			env: environment,
		},
	}).then((resp) => {
		dispatchAndCachePreviewContent(dispatch, resp.data, slug);
		return resp.data;
	});
};

export const INPUT_CHANGE_CONTENT = 'INPUT_CHANGE_CONTENT';
export const inputChangeContent = (type, value) => async (dispatch) => {
	dispatch({
		type: INPUT_CHANGE_CONTENT,
		payload: { type, value },
	});
};

export const START_SUBMIT_EDITING_CONTENT = 'START_SUBMIT_EDITING_CONTENT';
export const END_SUBMIT_EDITING_CONTENT = 'END_SUBMIT_EDITING_CONTENT';
export const submitEditingContent =
  (categoryId) => async (dispatch, getState) => {
  	const { contentPreview } = getState();

  	dispatch({
  		type: START_SUBMIT_EDITING_CONTENT,
  	});
  	return Cognito.retrieveToken()
  		.then((session) => {
  			if (contentPreview.editingCoverFile) {
  				return uploadFile(
  					contentPreview.editingCoverFile,
  					session,
  					null,
  					dispatch
  				).then((coverURL) =>
  					putContent(contentPreview, session, coverURL, categoryId)
  				);
  			} else {
  				return putContent(contentPreview, session, null, categoryId);
  			}
  		})
  		.then((response) => {
  			const data = {
  				...contentPreview.data,
  				attributes: {
  					...contentPreview.data.attributes,
  					description: response.data.data.description,
  					title: response.data.data.title,
  					image: response.data.data.image,
  					author: response.data.data.author,
  					categoryId: response.data.categoryId,
  				},
  			};
  			const included = {
  				...contentPreview.included,
  				attributes: {
  					...contentPreview.included.attributes,
  					isPublic: contentPreview.editingIsPublic,
  				},
  			};
  			return dispatch({
  				type: END_SUBMIT_EDITING_CONTENT,
  				payload: { data, included },
  			});
  		})
  		.catch((error) => {
  			console.error(error);
  		});
  };

const putContent = (booklist, session, coverURL, categoryId) => {

	const data = {
		title: booklist.editingTitle,
		description: booklist.editingDescription,
		slug: booklist.data.slug,
		isPublic: booklist.editingIsPublic,
		categoryId
	};
	if (coverURL) data['image'] = coverURL;
	return axios({
		method: 'PUT',
		url: `${env.msContentNew}/api/contents`,
		data,
		headers: {
			'Authorization': session.idToken.jwtToken,
			'env': environment
		}
	});
};

export const submitBooklistCover = (file) => async (dispatch) => {
	try {
		const session = await Cognito.retrieveToken();
		const imageUrl = await uploadFile(file, session, null, dispatch);
		return imageUrl;
	} catch (error) {
		console.error(error);
	}
};

export const LOADING_FETCH_PREVIEW_CONTENT = 'loading_fetch_preview_content';

export function dispatchAndCachePreviewContent(dispatch, data, slug) {

	dispatch({
		type: FETCH_PREVIEW_CONTENT,
		payload: data,
	});
	setCache(slug, data);
}

export const putArticle = (params, session) => {
	return axios({
		method: 'PUT',
		url: `${env.msContentNew}/api/contents`,
		data: {
			slug: params.id.replace(/\//g, ''),
			title: params.title,
			description: params.description || ' ',
			author: params.author || ' ',
			image: params.image,
			type: ARTICLE_TYPE,
			journal: params.journal,
			reader: params.slug,
		},
		headers: {
			'Authorization': session.idToken.jwtToken,
			'env': environment
		}
	});
};

export const putExternalBook = (params, session) => {
	return axios({
		method: 'PUT',
		url: `${env.msContentNew}/api/contents`,
		data: {
			slug: params.externalID,
			title: params.title,
			description: params.description || ' ',
			author: params.author || ' ',
			image: params.image,
			type: EXTERNAL_BOOK_TYPE,
			editorial: params.editorial,
			hostURL: params.slug,
			externalID: params.externalID,
			source: params.source,
		},
		headers: {
			'Authorization': session.idToken.jwtToken,
			'env': environment
		}
	});
};

export const putLink = (params, session) => {
	return axios({
		method: 'POST',
		url: `${env.msContentNew}/api/contents`,
		data: {
			title: params.title,
			description: params.description,
			author: params.author,
			image: null,
			type: 'LINKS',
			url: params.url,
			categories: params.categories,
		},
		headers: {
			'Authorization': session.idToken.jwtToken,
			'env': environment
		}
	});
};

export const putOfflineBook = async (params, session) => {
	let description = `
    Editorial: ${params.editorial},
    Año: ${params.year ? params.year : ' '},
    Tipo de Material: ${params.resourceType},
    Biblioteca: ${params.library},
    Ubicación: ${params.location}
  `;
	try {
		const request = await axios({
			method: 'PUT',
			url: `${env.msContentNew}/api/contents`,
			data: {
				slug: params.id,
				title: params.title,
				description,
				author: params.author || ' ',
				image: params.image,
				type: OFFLINE_BOOK_TYPE,
				editorial: params.editorial,
				holdings: params.location,
				externalID: params.id,
				source: params.type,
			},
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});

		return request;
	} catch (error) {
		throw Error(error);
	}
};

export const getLikeTotal = async (id) => {
	try {
		const request = await axios({
			method: 'GET',
			url: `${env.msContentNew}/api/likes/count`,
			params: {
				contentId: id,
			},
		});
		const data = request.data;

		return data;
	} catch (error) {
		return 0;
	}
};

export const getLike = async (id) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${env.msContentNew}/api/likes`,
			params: {
				contentId: id,
			},
			headers: {
				Authorization: session.idToken.jwtToken,
			},
		});

		if (request.data) {
			return true;
		}
		return false;
	} catch (error) {
		return error;
	}
};

export const putLike = (id) => async (dispatch, getState) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'PUT',
			url: `${env.msContentNew}/api/likes`,
			data: {
				contentId: `${id}`,
			},
			headers: {
				Authorization: session.idToken.jwtToken,
				env: environment,
			},
		});

		const data = request.data.data;

		const { profile, institution } = getState();

		return data;
	} catch (error) {
		return error;
	}
};

export const deleteLike = async (id) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'DELETE',
			url: `${env.msContentNew}/api/likes`,
			data: {
				contentId: `${id}`,
			},
			headers: {
				Authorization: session.idToken.jwtToken,
			},
		});

		const data = request.data.data;

		return data;
	} catch (error) {
		return error;
	}
};

export const putFollowBooklist = (id) => async (dispatch, getState) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'PUT',
			url: `${env.msContentNew}/api/booklist-followings`,
			data: {
				booklistId: id,
			},
			headers: {
				Authorization: session.idToken.jwtToken,
				env: environment,
			},
		});

		const data = request.data.data;

		return data;
	} catch (error) {
		return error;
	}
};

export const deleteFollowBooklist = async (id) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'DELETE',
			url: `${env.msContentNew}/api/booklist-followings`,
			data: {
				booklistId: id,
			},
			headers: {
				Authorization: session.idToken.jwtToken,
			},
		});

		const data = request.data.data;

		return data;
	} catch (error) {
		return error;
	}
};

export const addContent = async (content, included, type) => {
	const session = await Cognito.retrieveToken();
	switch (included.type || type) {
	case JOURNAL:
		try {
			const article = await addArticle(content, session, included);
			return article;
		} catch (error) {
			console.error(error);
			return null;
		}
	case 'book':
		try {
			const externalBook = await addExternalBook(content, session, included);
			return externalBook;
		} catch (error) {
			console.error(error);
			return null;
		}
	case OFFLINE_BOOK_TYPE:
		try {
			const offlineBook = await putOfflineBook(content.info, session);
			return offlineBook;
		} catch (error) {
			console.error(error);
			return null;
		}
	default:
		return null;
	}
};

const addExternalBook = async (content, session) => {
	let result;
	const { slug, title, description, author, image } = content.info;
	const { editorial } = content.attributes;
	// eslint-disable-next-line no-useless-escape
	const contentId = content.id.replace(new RegExp('/', 'g'), '');
	try {
		result = await putExternalBook(
			{
				slug,
				title,
				description,
				author,
				image,
				editorial,
				externalID: contentId,
				hostURL: slug,
				source: content.type,
			},
			session
		);
	} catch (error) {
		console.error(error);
		return error;
	}

	return result;
};

export const postContent = async(content,type)=>{
	const session = await Cognito.retrieveToken();
	try {
		const response = await axios({
			method: 'POST',
			url: `${env.msContentNew}/api/contents`,
			data: {
				title: content.title,
				description: content.description,
				author: content.author || null,
				image: content.image || null,
				type: type,
				url: content.url,
				provider: content.resource_origin ? content.resource_origin : content.provider ? content.provider : null,
				collection: content.collection || null,
				editorial: content.editorial,
				reader: content.reader || null,
				hostURL:content.hostURL || null,
				externalID:content.externalID || null,
				source:content.source || null,
				edition:content.edition || null,
				metadata:{
					publication: content.publication,
					isbn: content.isbn,
					keywords: content.keywords,
					publisher: content.publisher,
					author_corporative: content.author_corporative,
					pages: content.pages,
					language:content.language,
					holdings:content.holdings,
				}
			},
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});
	
		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
};

const addArticle = async (content, session, included) => {
	let result;
	const { slug, title, description, author, image } = content.info;
	const { editorial } = content.attributes;
	try {
		result = await putArticle(
			{
				id: content.id,
				slug: slug || included.attributes.reader,
				title,
				description,
				author,
				journal: editorial,
				image,
			},
			session
		);
	} catch (error) {
		console.error('Error adding Article', error);
		return error;
	}
	return result;
};

export const getContentExists = async (slug) => {
	try {
		const request = await axios({
			method: 'GET',
			url: `${env.msContent}/content/slug/${slug}`,
			headers: {
				env: environment,
			},
		});

		let data = request.data.data.id;

		if (data) {
			return data;
		} else {
			return 0;
		}
	} catch (error) {
		return 0;
	}
};

export const getContent = async (slug) => {
	try {
		const request = await axios({
			method: 'GET',
			url: `${env.msContentNew}/api/contents/${slug}`,
			headers: {
				env: environment,
			},
		});

		let data = request.data;
		return data;
	} catch (error) {
		throw Error(error);
	}
};

export const getFollowingCount = async (id) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${env.msContentNew}/api/booklist-followings/count`,
			params: {
				booklistId: id,
			},
			headers: {
				Authorization: session.idToken.jwtToken,
			},
		});

		let data = request.data;

		return data;
	} catch (error) {
		return 0;
	}
};

export const postContentBooklist =
  (contentSlug, booklistId) => async (dispatch, getState) => {
  	try {
  		const session = await Cognito.retrieveToken();
  		const request = await axios({
  			method: 'POST',
  			url: `${env.msContentNew}/api/booklist-contents`,
  			data: {
  				booklistId,
  				contentSlug,
  			},
  			headers: {
  				Authorization: session.idToken.jwtToken,
  			},
  		});

  		dispatch({
  			type: REMOVE_FROM_STATE,
  			payload: contentSlug,
  		});

  		return request.data.data;
  	} catch (error) {
  		return error;
  	}
  };

export const END_PUT_CONTENT_TAGS = 'END_PUT_CONTENT_TAGS';
export const putTags = (contentId, tags) => async (dispatch) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'PUT',
			url: `${env.msContentNew}/api/content-tags`,
			data: {
				contentId: contentId,
				tags: tags,
			},
			headers: {
				Authorization: session.idToken.jwtToken,
				env: environment,
			},
		});
		dispatch({
			type: PUT_BOOKLIST_TAGS,
			payload: request.data,
		});
		dispatch({
			type: PUT_CONTENT_TAGS,
			payload: request.data,
		});
		return request;
	} catch (error) {
		console.error(error);
	}
};

export const PUT_PRIVACY = 'PUT_PRIVACY';
export const END_PUT_PRIVACY = 'END_PUT_PRIVACY';
export const putPrivacy = (booklist) => async (dispatch) => {
	dispatch({
		type: PUT_PRIVACY,
	});
	const data = {
		title: booklist.title,
		description: booklist.description,
		slug: booklist.slug,
		isPublic: booklist.isPublic,
	};
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'PUT',
			url: `${env.msContentNew}/api/contents`,
			data,
			headers: {
				Authorization: session.idToken.jwtToken,
				env: environment,
			},
		});
		dispatch({
			type: END_PUT_PRIVACY,
		});
		return request.data;
	} catch (error) {
		console.error(error);
	}
};

export const START_SUBMIT_EDITING_CONTENT_FILE =
  'START_SUBMIT_EDITING_CONTENT_FILE';
export const END_SUBMIT_EDITING_CONTENT_FILE =
  'END_SUBMIT_EDITING_CONTENT_FILE';
export const submitEditingContentFile = (file) => async (dispatch) => {
	dispatch({
		type: START_SUBMIT_EDITING_CONTENT_FILE,
	});
	try {
		const session = await Cognito.retrieveToken();
		const imageUrl = await uploadFile(file.cover, session, null, dispatch);
		const data = {
			title: file.title,
			description: file.description,
			slug: file.slug,
			image: imageUrl,
			categoryId: file.categoryId,
			metadata: file.metadata
		};
		const content = await axios({
			method: 'PUT',
			url: `${env.msContentNew}/api/contents`,
			data,
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});
		dispatch({
			type: END_SUBMIT_EDITING_CONTENT_FILE,
			payload: content
		});
		return content;
	} catch (error) {
		console.error(error);
	}
};

export const getTags = async (contentId) => {
	try {
		const request = await axios({
			method: 'GET',
			url: `${env.msContentNew}/api/content-tags?contentId=${contentId}`,
		});

		return request.data;
	} catch (error) {
		console.error(error);
	}
};

export const getContentCategory = async (slug) => {
	try {
		const request = await axios({
			method: 'GET',
			url: `${env.msContentNew}/api/contents/${slug}`,
		});

		let data = request.data;

		return data.categoryId;
	} catch (error) {
		console.error(error);
	}
};

export const getFollowedBooklist = async (email) => {
	try {
		let emailEncoded = encodeURI(email);

		const request = await axios({
			method: 'GET',
			url: `${env.msContentNew}/api/booklist-followings?email=${emailEncoded}`,
		});

		let data = request.data;

		let response = await Promise.all(
			data.map(async (booklist) => {
				try {
					const info = await axios({
						method: 'GET',
						url: `${env.msContent}/content/${booklist.booklistId}`,
					});
					let responseData = info.data;
					return {
						id: responseData.data.id,
						type: responseData.data.type,
						attributes: {
							isPublic: responseData.relatedContent.attributes.isPublic,
						},
						info: {
							author: responseData.data.attributes.author,
							description: responseData.data.attributes.description,
							image: responseData.data.attributes.image,
							slug: responseData.data.attributes.slug,
							title: responseData.data.attributes.title,
						},
					};
				} catch (error) {
					return {};
				}
			})
		);

		return response.filter(
			(item) => typeof item === 'object' && Object.keys(item).length !== 0
		);
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const reportContent = async (contentId, reporter) => {
  try {
	const session = await Cognito.retrieveToken();
	let response = await fetch(
		`${env.msContentNew}/api/v2/reports?contentId=${contentId}&reportingUser=${reporter}`,
		{
		  method: "POST",
		  headers: {
			env: environment,
			Authorization: session.idToken.jwtToken,
		  },
		}
	  );
	  if (response.status >= 400) return response.status;

	  return await response.json()

  } catch (error) {
	return error;
  }
};

export const updateContentPreview = (content) => (dispatch) => {
	dispatch({
		type: UPDATE_CONTENT_PREVIEW,
		payload: content,
	});
};

export const getAllContentsOfProfile = (email, page) => async (dispatch) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${env.msContentNew}/api/v2/contents/all?email=${email}&type=&order=&page=${
				page ? page : 1
			}&limit=${PROFILE_CONTENTS_LIMIT}`,
			headers: {
				Authorization: session.idToken.jwtToken,
				env: environment,
			},
		});
		dispatch({
			type: PROFILE_CONTENTS,
			payload: {
				...request.data,
				page: page ? page : 1,
			},
		});
	} catch (error) {
		throw Error(error);
	}
};

export const getAllContentsOfProfileWithFilters = async (
	email,
	page,
	type,
	order,
	term
) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${
				env.msContentNew
			}/api/v2/contents/all?email=${email}&type=${type}&order=${order}&term=${
				term || ''
			}&page=${page ? page : 1}&limit=${PROFILE_CONTENTS_LIMIT}`,
			headers: {
				Authorization: session.idToken.jwtToken,
				env: environment,
			},
		});
		return {
			...request.data,
			page: page ? page : 1,
		};
	} catch (error) {
		throw Error(error);
	}
};

export const getAllLikedContent =  (email, page, type) => async (dispatch) => {
	try {
		const session = await Cognito.retrieveToken();
		const response = await axios({
			method: 'GET',
			url: `${
				env.msContentNew
			}/api/contents/like?email=${email}&page=${
				page ? page : 1
			}&limit=${PROFILE_CONTENTS_LIMIT}`,
			headers: {
				Authorization: session.idToken.jwtToken,
				env: environment,
			},
		});
		dispatch({
			type: PROFILE_LIKED_CONTENTS,
			payload: {
				...response.data,
				page: page ? page : 1,
			},
		});
	} catch (error) {
		throw Error(error);
	}
};

export const getAllLikedContentWithFilters =
	async(email, page, type) => {
		try {
			const session = await Cognito.retrieveToken();
			const response = await axios({
				method: 'GET',
				url: `${
					env.msContentNew
				}/api/contents/like?email=${email}&type=${type}&page=${
					page ? page : 1
				}&limit=${PROFILE_CONTENTS_LIMIT}`,
				headers: {
					Authorization: session.idToken.jwtToken,
					env: environment,
				},
			});
			return {
				...response.data,
				page: page ? page : 1,
			};
		} catch (error) {
			throw Error(error);
		}
	};

export const getBooklistFollowingsOfProfile =
	(email, page) => async (dispatch) => {
		try {
			const session = await Cognito.retrieveToken();
			const request = await axios({
				method: 'GET',
				url: `${
					env.msContentNew
				}/api/v2/booklist/followings?email=${email}&isPublic=${true}&page=${
					page ? page : 1
				}&limit=${PROFILE_CONTENTS_LIMIT}`,
				headers: {
					Authorization: session.idToken.jwtToken,
					env: environment,
				},
			});
			dispatch({
				type: PROFILE_BOOKLIST_FOLLOWINGS,
				payload: {
					...request.data,
					page: page ? page : 1,
				},
			});
		} catch (error) {
			throw Error(error);
		}
	};

export const getCollaborativeBooklistByEmail =
	(page) => async (dispatch) => {
		try {
			const session = await Cognito.retrieveToken();
			const response = await axios({
				method: 'GET',
				url: `${env.msContentNew}/api/v2/booklist-permissions/raw-object?page=${
					page ? page : 1
				}&limit=${PROFILE_CONTENTS_LIMIT}`,
				headers: {
					Authorization: session.idToken.jwtToken,
					env: environment,
				},
			});
			dispatch({
				type: PROFILE_COLLABORATIVE_BOOKLISTS,
				payload: {
					...response.data,
					page: page ? page : 1,
				},
			});
		} catch (error) {
			throw Error(error);
		}
	};

export const getBooklistPermissionsOfProfile =
	(email, isPublic, page) => async (dispatch) => {
		try {
			const session = await Cognito.retrieveToken();
			const request = await axios({
				method: 'GET',
				url: `${
					env.msContentNew
				}/api/v2/booklist/permissions?email=${email}&isPublic=${isPublic}&page=${
					page ? page : 1
				}&limit=${PROFILE_CONTENTS_LIMIT}`,
				headers: {
					Authorization: session.idToken.jwtToken,
					env: environment,
				},
			});
			if (isPublic) {
				dispatch({
					type: PROFILE_BOOKLIST_PERMISSIONS,
					payload: {
						...request.data,
						page: page ? page : 1,
					},
				});
			} else {
				dispatch({
					type: PRIVATE_BOOKLISTS_PERMISSION,
					payload: {
						...request.data,
						page: page ? page : 1,
					},
				});
			}
		} catch (error) {
			throw Error(error);
		}
	};

export const deleteContentFromProfilePage = (slug) => async (dispatch) => {
  try {
    const session = await Cognito.retrieveToken();
    await axios.delete(`${env.msContentNew}/api/contents/${slug}`, {
      headers: { Authorization: session.idToken.jwtToken, },
    });

    dispatch({
      type: DELETE_CONTENT_FROM_PROFILE,
    });
  } catch (error) {
    throw Error(error);
  }
};

export const clearProfileContent = () => async (dispatch) => {
	dispatch({
		type: CLEAR_PROFILE_CONTENT,
	});
};

export const clearProfileLikedContent = () => async (dispatch) => {
	dispatch({
		type: CLEAR_PROFILE_LIKED_CONTENT,
	});
};

export const postShareContent = async (contentId, targetEmail, externalContent) => {
	try {
		const session = await Cognito.retrieveToken();
		await axios({
			method: 'POST',
			url: `${env.msContentNew}/api/contents/share`,
			headers: {
				Authorization: session.idToken.jwtToken,
			},
			data: {
				contentId,
				targetEmail,
				externalContent,
			},
		});
	} catch (error) {
		throw Error(error);
	}
};
