export const LOADING = 'LOADING_BOOKLIST';
export const ERROR = 'ERROR';
export const BOOKLIST_INFO = 'BOOKLIST_INFO';
export const BOOKLIST_CONTENT = 'BOOKLIST_CONTENT';
export const LOADING_BOOKLIST_CONTENT = 'LOADING_BOOKLIST_CONTENT';
export const MORE_BOOKLIST_CONTENT = 'MORE_BOOKLIST_CONTENT';
export const PUT_BOOKLIST_INFO = 'PUT_BOOKLIST_INFO';
export const PUT_BOOKLIST_SECTION = 'PUT_BOOKLIST_SECTION';
export const LOADING_PUT_BOOKLIST = 'LOADING_PUT_BOOKLIST';
export const DELETE_CONTENT_BOOKLIST = 'DELETE_CONTENT_BOOKLIST';
export const GET_BOOKLIST_SECTIONS = 'GET_BOOKLIST_SECTIONS';
export const LOADING_BOOKLIST_SECTIONS = 'LOADING_BOOKLIST_SECTIONS';
export const POST_BOOKLIST_SECTION = 'POST_BOOKLIST_SECTION';
export const BOOKLIST_PERMISSIONS = 'BOOKLIST_PERMISSIONS';
export const ADD_COLABORATOR = 'ADD_COLABORATOR';
export const DELETE_COLABORATOR = 'DELETE_COLABORATOR';
export const DELETE_BOOKLIST_SECTION = 'DELETE_BOOKLIST_SECTION';
export const EDIT_COLABORATOR = 'EDIT_COLABORATOR';
