import {
	ADVANCE_SEARCH,
	AUTOCOMPLETE,
	CLEAN_INPUT_SEARCH,
	CLEAN_SEARCH_RESULTS,
	FETCH_ARTICLE_SEARCH,
	FETCH_BOOKLISTS_SEARCH,
	FETCH_BOOKS_SEARCH,
	FETCH_DOCUMENTS_SEARCH,
	FETCH_INSTITUTION_CATALOGUE_SEARCH,
	FETCH_JOURNALS_SEARCH,
	FETCH_MOVIES_SEARCH,
	FETCH_MUSIC_SEARCH,
	FETCH_PROFILE_SEARCH,
	FETCH_THESIS_SEARCH,
	LOADING_SEARCH,
	SEARCH_INPUT,
} from '../actions';

// Si el initial state de los sarchXXXXXX estan en null significa que estan aun buscando, cuando el state cambia a [] es porque no encontro nada
const initialState = {
	searchInput: '',
	searchPage: 0,
	searchBooks: null,
	searchBooksRelated: null,
	searchDocuments: null,
	searchDocumentsIncluded: null,
	searchBooklist: null,
	searchBooklistIncluded: null,
	searchArticles: null,
	searchArticlesRelated: null,
	searchProfile: null,
	searchInstitutionCatalogue: null,
	searchInstitutionCatalogueRelated: null,
	searchJournals: null,
	searchJournalsIncluded: null,
	searchMusic: null,
	searchMusicIncluded: null,
	searchMovies: null,
	searchMoviesIncluded: null,
	searchThesis: null,
	searchThesisIncluded: null,
	meta: null,
	total: 0,
	autocomplete: [],
	loading: false,
	advancedSearch: null
};

export default (state = initialState, action) => {
	switch (action.type) {
	case LOADING_SEARCH:
		return {
			...state,
			loading: true
		};
	case ADVANCE_SEARCH:
		let equation = '';
		Object.keys(action.payload).map(key => equation += ` ${action.payload[key].category}: ${action.payload[key].term} ${action.payload[key].operator || ''}`);
		return {
			...state,
			searchInput: `{${equation}}`,
			advancedSearch: action.payload,
		};
	case CLEAN_INPUT_SEARCH:
		return {
			...initialState
		};
	case SEARCH_INPUT:
		return {
			...state,
			searchInput: action.payload
		};
	case AUTOCOMPLETE:
		return {
			...state,
			autocomplete: action.payload ? action.payload.map(word => word.word) : []
		};
	case FETCH_BOOKS_SEARCH:
		let totalBooks = 0;
		action.payload.meta.map(result => totalBooks += result.results);
		return {
			...state,
			total: totalBooks,
			loading: false,
			meta: action.payload.meta,
			searchBooks: action.payload.page ?
				state.searchBooks.concat(action.payload.data) : action.payload.data,
			searchBooksRelated: action.payload.page ?
				state.searchBooksRelated.concat(action.payload.included) : action.payload.included,
			searchPage: action.payload.page
		};
	case FETCH_DOCUMENTS_SEARCH:
		let totalDocuments = 0;
		action.payload.meta.map(result => totalDocuments += result.results);
		return {
			...state,
			loading: false,
			total: totalDocuments,
			meta: action.payload.meta,
			searchDocuments: action.payload.page ?
				state.searchDocuments.concat(action.payload.data) : action.payload.data,
			searchDocumentsIncluded: action.payload.page ?
				state.searchDocumentsIncluded.concat(action.payload.included) : action.payload.included,
			searchPage: action.payload.page
		};
	case FETCH_BOOKLISTS_SEARCH:
		let totalBooklists = 0;
		action.payload.meta.map(result => totalBooklists += result.results);
		return {
			...state,
			total: totalBooklists,
			loading: false,
			meta: action.payload.meta,
			searchBooklist: action.payload.page ?
				state.searchBooklist.concat(action.payload.data) : action.payload.data,
			searchBooklistIncluded: action.payload.page ?
				state.searchBooklistIncluded.concat(action.payload.included) : action.payload.included,
			searchPage: action.payload.page
		};
	case FETCH_ARTICLE_SEARCH:
		let totalArticles = 0;
		action.payload.meta.map(result => totalArticles += result.results);
		return {
			...state,
			total: totalArticles,
			loading: false,
			meta: action.payload.meta,
			searchArticles: action.payload.page ?
				state.searchArticles.concat(action.payload.data) : action.payload.data,
			searchArticlesRelated: action.payload.page ?
				state.searchArticlesRelated.concat(action.payload.included) : action.payload.included,
			searchPage: action.payload.page
		};
	case FETCH_INSTITUTION_CATALOGUE_SEARCH:
		let totalInstitution = 0;
		action.payload.meta.map(result => totalInstitution += result.results);
		return {
			...state,
			total: totalInstitution,
			meta: action.payload.meta,
			loading: false,
			searchInstitutionCatalogue: action.payload.page ?
				state.searchInstitutionCatalogue.concat(action.payload.data) : action.payload.data,
			searchInstitutionCatalogueRelated: action.payload.page ?
				state.searchInstitutionCatalogueRelated.concat(action.payload.included) : action.payload.included,
			searchPage: action.payload.page
		};
	case FETCH_PROFILE_SEARCH:
		return {
			...state,
			searchProfile: action.payload,
		};
	case FETCH_JOURNALS_SEARCH:
		let totalJournal = 0;
		action.payload.meta.map(result => totalJournal += result.results);
		return {
			...state,
			total: totalJournal,
			loading: false,
			meta: action.payload.meta,
			searchJournals: action.payload.page ?
				state.searchJournals.concat(action.payload.data) : action.payload.data,
			searchJournalsIncluded: action.payload.page ?
				state.searchJournalsIncluded.concat(action.payload.included) : action.payload.included,
			searchPage: action.payload.page
		};
	case FETCH_MUSIC_SEARCH:
		let totalMusic = 0;
		action.payload.meta.map(result => totalMusic += result.results);
		return {
			...state,
			total: totalMusic,
			loading: false,
			meta: action.payload.meta,
			searchMusic: action.payload.page ?
				state.searchMusic.concat(action.payload.data) : action.payload.data,
			searchMusicIncluded: action.payload.page ?
				state.searchMusicIncluded.concat(action.payload.included) : action.payload.included,
			searchPage: action.payload.page
		};
	case FETCH_MOVIES_SEARCH:
		let totalMovies = 0;
		action.payload.meta.map(result => totalMovies += result.results);
		return {
			...state,
			total: totalMovies,
			loading: false,
			meta: action.payload.meta,
			searchMovies: action.payload.page ?
				state.searchMovies.concat(action.payload.data) : action.payload.data,
			searchMoviesIncluded: action.payload.page ?
				state.searchMoviesIncluded.concat(action.payload.included) : action.payload.included,
			searchPage: action.payload.page
		};
	case FETCH_THESIS_SEARCH:
		let totalThesis = 0;
		action.payload.meta.map(result => totalThesis += result.results);
		return {
			...state,
			total: totalThesis,
			loading: false,
			meta: action.payload.meta,
			searchThesis: action.payload.page ?
				state.searchThesis.concat(action.payload.data) : action.payload.data,
			searchThesisIncluded: action.payload.page ?
				state.searchThesisIncluded.concat(action.payload.included) : action.payload.included,
			searchPage: action.payload.page
		};
	case CLEAN_SEARCH_RESULTS:
		return {
			...state,
			page: 0,
			searchBooks: null,
			searchBooksRelated: null,
			searchDocuments: null,
			searchDocumentsIncluded: null,
			searchBooklist: null,
			searchBooklistIncluded: null,
			searchArticles: null,
			searchArticlesRelated:null,
			searchProfile: null,
			searchInstitutionCatalogue: null,
			searchInstitutionCatalogueRelated: null,
			meta: null,
			total: null,
			loading: false,
		};
	default:
		return state;
	}
};
