import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { deleteContentBooklist, deleteContentFromProfilePage } from '../../actions';
import PopUp  from '../shared/PopUp';
import { useTranslation } from 'react-i18next';
import { breakPoint } from '../../../utils/constans';


const PopUpDeleteContent = ({
	isMyContent,
	closePopUp,
	booklistId,
	slugContent,
	contentId,
	deleteContentBooklist,
	deleteContentFromProfilePage,
	loading
}) => {

	const [isMobile, setIsMobile] = useState(
		window.innerWidth < breakPoint.mobileWide,
	);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < breakPoint.mobileWide);
		};
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleDeleteContent = async () => {
		if (isMyContent) {
			deleteContentFromProfilePage(slugContent);
			closePopUp();
			return;
		}
		await deleteContentBooklist(booklistId,slugContent,contentId);
		closePopUp();
	};

	const {t} = useTranslation();

	return (
		<PopUp
			closePopUp={closePopUp}
			style={{
				popUp:{
					maxHeight:'',
					maxWidth:'487px'
				},
				popUpContent:{
					display:'grid',
					margin:'40px'
				},
				button:{
					marginTop: isMobile ? '20px' : '40px',
					justifyContent:'center',
				}
			}}
			
			buttons={{
				style:{
					width:'195px',
					height:'50px',
					borderRadius:'8px',
					marginRight:'20px',	
				},
				primary:{
					title:t('textContextMenuDelete'),
					type:'red',
					action:handleDeleteContent,
					loading:loading
				},
				secondary:{
					title:t('textContextMenuCancel'),
					type:'secondary',
					action:closePopUp
				}
			}}
		>
			<div style={{marginTop:'16px'}}>
				<p style={{fontSize:'18px', fontWeight:'500'}}>{t('titleDeleteEntryFromBooklist')}</p>
				<p style={{fontSize:'18px'}}>{t('infoDeleteEntryFromBooklist')}</p>
			</div>
		</PopUp>
	
	);
};

const mapStateToProps = ({ booklistV2 }) => {
	return {
		booklistInfo: booklistV2.booklistsInfo,
		loading: booklistV2.loadingPut
	};
};

export default withRouter(connect(mapStateToProps, {deleteContentBooklist, deleteContentFromProfilePage})(PopUpDeleteContent));