import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from './styles/forgotPassword.scss';
import { ImpulseSpinner } from 'react-spinners-kit';
import {
	iconLock,
	STEP_CREATE_PROFILE
} from '../../../utils/constans';

import Cognito, {COGNITO_LOGGED_IN} from '../../../helpers/cognito';
import { useTranslation } from 'react-i18next';

const tabChangePassword= 1;
const tabEmail= 0;

const errorCode = {
	code: 'CodeMismatchException',
	userNotFound: 'UserNotFoundException',
	password: 'InvalidParameterException',
	passwordMismatch: 'passwordMismatch',
	requestLimit: 'LimitExceededException'
};

const ForgotPassword = ({
	handleChangeStep,
	closePopUp,
}) => {
	const [tabPosition, setTabPosition] = useState(tabEmail);
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [confirmPassword, setConfirmPassword] = useState();
	const [code, setCode] = useState('');
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const { t, i18n } = useTranslation();



	useEffect(() => {
	}, []);


	const handleChangePassword= async () => {
		try {
			setError(false);
			setLoading(true);
			if(password !== confirmPassword) {
				setError(errorCode.passwordMismatch);
				setLoading(false);
			} else {
				await Cognito.changePassword(email, code.trim(), password);
				setLoading(false);
				handleChangeStep(STEP_CREATE_PROFILE);
			}
		} catch (error) {
			setError(error.code);
			setLoading(false);
		}
	};
	const handleSendEmail= async() => {
		try {
			setError(false);
			setLoading(true);
			await Cognito.recoverPassword(email);
			setTabPosition(tabChangePassword);
			setLoading(false);
		} catch (error) {
			setError(error.code);
			setLoading(false);
		}
	};


	return (
		<div className={styles.container}>
			{tabPosition === tabEmail ?
				<div className={`${styles.containerVerification} ${styles.containerEmail}`}>
					<div>
						<h2 className={styles.fontTitle}>{t('titleForgotPassword')}</h2>
						<div>
							<p className={`${styles.fontp} ${styles.paragraphMargin}`}>{t('textForgotPassword')}</p>
						</div>
						<div className={(error === errorCode.userNotFound || error === errorCode.requestLimit || error === errorCode.password) ? `${styles.input_Rectangle_Icon} ${styles.alertBorderNew}` : styles.input_Rectangle_Icon}>
							<input 
								name='email'
								type='input' 
								placeholder={t('emailPlaceholder')}
								onChange={(event) => setEmail(event.target.value)}
							/>
						</div>
						{(error === errorCode.userNotFound || error === errorCode.requestLimit || error === errorCode.password) &&
							<p className={styles.errorMessageParagraph}>{error === errorCode.userNotFound ? t('errorProfileNotExists') : error === errorCode.requestLimit? t('errorResquestLimit') : t('errorMessageForgotPassword')}</p>
						}
					</div>
					<div className={`${styles.button_blue} ${styles.buttonPosition}`} 
						onClick={handleSendEmail}
					>
						{t('buttonSend')}
					</div>
				</div>
				:
				<>
					<div className={error === errorCode.code ? `${styles.loginContainer} ${styles.gapNone}` : styles.loginContainer}>
						<div>
							<h2 className={styles.fontTitle}>{t('createNewPassword')}</h2>
							<p className={styles.fontp}>{t('textHelpCreatePassword')}</p>
						</div>
						<div className={error === errorCode.code ? styles.marginTop : null}>
							<p className={styles.fontp}>{t('textCodeNewPassword')}</p>
							<div className={error === errorCode.code ? `${styles.input_Rectangle_Icon} ${styles.alertBorderNew}` : styles.input_Rectangle_Icon}>
								<input 
									name='code'
									type='input' 
									placeholder={t('placeholderCode')}
									onChange={(event) => setCode(event.target.value)}
								/>
							</div>
							{error === errorCode.code  &&
							<p className={styles.errorMessageParagraph}>{t('errorCode')}</p>
							}
						</div>
						<div>
							<p className={styles.fontp}>{t('textWriteNewPassword')}</p>
							<div className={error === errorCode.password || error === errorCode.passwordMismatch ? `${styles.input_Rectangle_Icon} ${styles.alertBorderNew}` : styles.input_Rectangle_Icon}>
								<input 
									name='password'
									type='password' 
									placeholder={t('passwordPlaceholder')}
									onChange={(event) => setPassword(event.target.value)}
								/>
								<img src={iconLock} />
							</div>
						</div>
						<div>
							<p className={styles.fontp}>{t('textConfirmNewPassword')}</p>
							<div className={error === errorCode.password || error === errorCode.passwordMismatch  ? `${styles.input_Rectangle_Icon} ${styles.alertBorderNew}` : styles.input_Rectangle_Icon}>
								<input 
									name='password'
									type='password' 
									placeholder={t('passwordPlaceholder')}
									onChange={(event) => setConfirmPassword(event.target.value)}
								/>
								<img src={iconLock} />
							</div>
							{(error === errorCode.password || error === errorCode.passwordMismatch ) &&
								<p className={styles.errorMessageParagraph}>{error === errorCode.password ? t('errorCreatePassword') : t('errorMismatchPassword')}</p>
							}
							{error === errorCode.requestLimit  &&
								<p className={styles.errorMessageParagraph}>{t('errorResquestLimit')}</p>
							}
						</div>
					</div>

					<div className={`${styles.button_blue} ${styles.buttonPosition}`} 
						onClick={handleChangePassword}
					>
						{!loading ?  
							t('buttonEndForm')
							: 	
							<ImpulseSpinner
								size={40}
								color="white"
								frontColor="white"
								backColor='white'
								loading={loading}
							/>
						}
					</div>

				</>
			}

		</div>
	);
};

const mapStateToProps = ({   }) => {
	return {
		
	};
};

export default withRouter(connect(mapStateToProps, {
})(ForgotPassword));