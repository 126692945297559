import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import nativeMenu from "../components/hocs/nativeMenu";
import { connect } from "react-redux";
import styles from "./styles/homeV3Page.scss";
import PopUpShare from "../components/shared/PopUpShare";
import { useTranslation } from "react-i18next";
import ProfilesResult from "../components/search/ProfilesResult";
import LoadSearch from "../components/shared/LoadSearch";
import LoaderBooklistContent from "../components/booklists/LoaderBooklistContent";
import {
  setPopUpShare,
  loadNewHome,
  getPopularContentsPaginate,
  getPopularContentsWithFilters,
  openPopUp,
  loadHome,
} from "../actions";
import {
  breakPoint,
  iconFilterOff,
  iconFilterOn,
  adminId,
  transformUsersToPeople,
} from "../../utils/constans";
import {
	removeListenerScroll,
	endTrackHome,
	trackHome,
} from '../../helpers/tracking';
import LoaderSearchContent from "../components/search/LoaderSearchContent";
import ContentResult from "../components/search/ContentResult";
import FiltersComponent from "../components/search/FiltersComponent";
import People from "../components/home/People";
import CurrentCareer from "../components/home/CurrentCareer";
import Cognito from "../../helpers/cognito";
import NoResultSearch from "../components/search/NoResultSearch";
import Banner from "../components/shared/Banner";
import { getLibraryProfile } from "../actions/explorationActions";

const HomeV3Page = ({
  profile,
  exploration,
  setPopUpShare,
  loadNewHome,
  homeState,
  getPopularContentsPaginate,
  getPopularContentsWithFilters,
  openPopUp,
  loadHome,
}) => {
  const [results, setResults] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const intersectionObserverRef = useRef(null);
  const [filters, setFilters] = useState({
    editorial: "Todos",
    lang: "Todos",
    type: "",
    people: "",
  });
  const [careerString, setCareerString] = useState();
  const [isFilteredSearch, setIsFilteredSearch] = useState(false);

  const [loadingScroll, setLoadingScroll] = useState();
  const [page, setPage] = useState(1);
  const [profiles, setProfiles] = useState();
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < breakPoint.mobileWide
  );
  const [isFilterVisible, setIsFilterVisible] = useState(true);
  const [libraryProfile, setLibraryProfile] = useState();
  const [isLibraryLoading, setIsLibraryLoading] = useState(true);

  const toggleVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  const { t } = useTranslation();

  useEffect(() => {
    loadHome();
    fetchLibraryProfile();
    handleResize();
    handlePopUpLogin();
    document.body.style.backgroundColor = "#fcfcfc";
    window.addEventListener("resize", handleResize);
    return () => {
      setHasNextPage();
      window.removeEventListener("resize", handleResize);
      removeListenerScroll();
      endTrackHome();
    };
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setIsFilterVisible(true);
    }
  }, [isMobile]);

  useEffect(() => {
    setProfiles(transformUsersToPeople(homeState.profiles));
  }, [homeState.profiles]);

  useEffect(() => {
    setLoadingScroll(true);
    homeState.contents && setResults(homeState.contents);
  }, [homeState.contents]);

  useEffect(() => {
    if (results) {
      setLoadingScroll(false);
      setHasNextPage(results.hasNextPage);
    }
  }, [results]);

  const selectCareer = (selectedCareer) => {
    setLoadingScroll(true);
    loadNewHome(selectedCareer);
    setCareerString(selectedCareer);
  };

  useEffect(() => {
    if (!loadingScroll && hasNextPage) {
      let observerScroll = document.getElementById("observerScroll");
      intersectionObserverRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            handleMoreResults();
            setLoadingScroll(true);
          }
        },
        {
          rootMargin: "0px 0px 1px 0px",
        }
      );
      intersectionObserverRef.current.observe(observerScroll);
    }
  }, [loadingScroll]);

  useEffect(() => {
    if (profile && profile.profile) {
      const career = profile.profile.data.mayor || t("titleDefaultSearch");
      homeState.loadingPage && loadNewHome(career);
      homeState.contents && setResults(homeState.contents);
      setCareerString(career);
      trackHome(profile.profile.data.email);
    }
  }, [profile]);

  useEffect(() => {
    if (
      filters.editorial != "Todos" ||
      (filters.lang && filters.lang != "Todos") ||
      filters.type
    ) {
      setIsFilteredSearch(true);
      setPage(1);
      filteredSearch();
    } else {
      setIsFilteredSearch(false);
      setPage(homeState.page);
    }
  }, [filters]);

  useEffect(() => {
    if (!isFilteredSearch) {
      setResults(homeState.contents);
    }
  }, [isFilteredSearch]);

  const handlePopUpLogin = async () => {
    try {
      const session = await Cognito.retrieveToken();
    } catch (error) {
      if (env.proxy) {
        window.location = env.proxy;
      } else {
        openPopUp(true);
      }
    }
  };

  const filteredSearch = async () => {
    setLoadingScroll(true);
    setResults(
      await getPopularContentsWithFilters(
        homeState.topIds,
        filters,
        1,
        careerString
      )
    );
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < breakPoint.mobileWide);
  };

  const handleMoreResults = async () => {
    if (isFilteredSearch) {
      const filteredResults = await getPopularContentsWithFilters(
        homeState.topIds,
        filters,
        page + 1,
        careerString
      );
      setResults({
        ...results,
        content: [...results.content, ...filteredResults.content],
        hasNextPage: filteredResults.hasNextPage,
      });
    } else {
      await getPopularContentsPaginate(
        homeState.topIds,
        page + 1,
        careerString
      );
    }
    setPage(page + 1);
  };

  const fetchLibraryProfile = async () => {
		try {
			await getLibraryProfile().then((library) =>
				setLibraryProfile(library[0]),
			);
		} catch (error) {
			console.error('Error fetching library profile: ', error);
		} finally {
			setIsLibraryLoading(false);
		}
	};

  if (!homeState) {
    return (
      <div
        className={isMobile ? styles.mainMobileContainer : styles.mainContainer}
      >
        <LoadSearch />
      </div>
    );
  }
  return (
    <div
      className={isMobile ? styles.mainMobileContainer : styles.mainContainer}
    >
      <>
        <div className={isMobile ? "" : styles.gridContainer}>
          <div
            className={
              isMobile ? styles.filtersMobileContainer : styles.filtersContainer
            }
          >
            {isMobile && (
              <button
                className={styles.filterButton}
                onClick={toggleVisibility}
              >
                <img
                  src={isFilterVisible ? iconFilterOn : iconFilterOff}
                  alt="Filter"
                  width="20"
                  height="20"
                />
              </button>
            )}
            <div className={isFilterVisible ? "" : styles.invisibleContainer}>
              <FiltersComponent
                filtersFromSearch={homeState.filters}
                profilesFromSearch={transformUsersToPeople(homeState.profiles)}
                filtersFromComponent={filters}
                setFilters={setFilters}
                setProfiles={setProfiles}
                isMobile={isMobile}
              />
            </div>
          </div>
          {isMobile && (
            <div className={styles.profilesContainer}>
              <ProfilesResult
                profiles={profiles}
                isMobile={isMobile}
                isNewHome={true}
              />
            </div>
          )}
          <div className={styles.contentContainer}>
            <div className={styles.bannerContainer}>
            <Banner url={'https://www.youtube.com/watch?v=Vbp9LcYfnSs'} imageSource={'https://cdn.booklick.net/img/databases/booklick.png'} title={'Booklick - Connecting Minds'} description={'Disfruta de una plataforma que te da acceso a miles de contenidos educativos por medio de búsqueda, recomendación personalizada, y socialización en comunidad.'}/>
            </div>
            
            {careerString && (
              <div className={styles.fontTitle}>
                <CurrentCareer
                  career={careerString}
                  setCareer={(value) => {
                    selectCareer(value);
                  }}
                />
              </div>
            )}
            {!results ||
            !results.content ||
            results.content.lenght < 1 ||
            homeState.loadingContents ||
            homeState.loadingPage ? (
              <LoaderSearchContent />
            ) : results.content.length > 0 ? (
              <>
                <div style={{ marginTop: "25px" }}>
                  <ContentResult
                    results={results.content}
                    isMobile={isMobile}
                  />

                  {loadingScroll && <LoaderBooklistContent />}

                  {hasNextPage && !loadingScroll ? (
                    <div
                      className={styles.observerScroll}
                      id="observerScroll"
                    />
                  ) : null}
                </div>
              </>
            ) : (
              <NoResultSearch />
            )}
          </div>
          {!isMobile && (
            <div className={styles.profilesContainer}>
              <People
                profile={libraryProfile}
                title={t('titlePeopleLibrary')}
                isLoading={isLibraryLoading}
              />
              <ProfilesResult
                profiles={profiles}
                isMobile={isMobile}
                isNewHome={true}
              />
            </div>
          )}
        </div>
      </>

      {exploration.openPopUpShare && (
        <PopUpShare closePopUp={() => setPopUpShare(null, false)} />
      )}
    </div>
  );
};

function mapStateToProps({
  searchNew,
  search,
  exploration,
  homeState,
  profile,
}) {
  return {
    searchNew,
    search,
    exploration,
    homeState,
    profile,
  };
}

export default {
  component: nativeMenu(
    withRouter(
      connect(mapStateToProps, {
        setPopUpShare,
        loadNewHome,
        getPopularContentsPaginate,
        getPopularContentsWithFilters,
        openPopUp,
        loadHome,
      })(HomeV3Page)
    )
  ),
};
