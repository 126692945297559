import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles/inputGroup.scss';

export const types = {
	textArea: 'textArea',
	input: 'input'
};

const InputGroup = ({
	style,
	title,
	fontSize,
	fontWeight,
	error,
	inputName,
	onChange,
	value,
	type, 
	icon,
	placeholder,
	isDisabled
}) => {

	return (
		<div className={styles.inputName}>

			{title && (
				<p style={{
					fontSize,
					fontWeight
				}}>{title}</p>
			)}
			
			<div 
				style={{...style, fontSize, display: 'flex', justifyContent: 'flex-start', alignItems: 'center',}}
				className={icon && error ?  `${styles.input_Rectangle_Icon} ${styles.alertBorderNew}` : 
					!icon && error ?  `${styles.input_Rectangle} ${styles.alertBorderNew}` : 
						!icon ? styles.input_Rectangle : styles.input_Rectangle_Icon}>
				{icon && <img src={icon} />}
				{type === types.textArea ? (
					<textarea 
						disabled={isDisabled}
						name={inputName}
						onChange={(event) => onChange(event.target.value,inputName)}
						value={value}
						placeholder={placeholder}
					/>
				): (
					<input
						disabled={isDisabled}
						name={inputName}
						type='input'
						onChange={(event) => onChange(event.target.value,inputName)}
						value={value}
						placeholder={placeholder}
					/>
				)}
			</div>
		</div>
	);
};

InputGroup.prototype = {
	style: PropTypes.shape({
		width:PropTypes.string,
		height:PropTypes.string,
		margin:PropTypes.string,
	}),
	type: PropTypes.oneOf(Object.keys(types))
};

export default InputGroup;