import React, { Component } from 'react';
import styles from './styles/popupCreate.scss';
import { getResources } from '../../../utils/constans';
import { environment } from '../../actions';

const booklistImage = getResources(environment).defaultCover;
const iconContent = 'https://cdn.booklick.net/public/img/icons/text-white.svg';
const iconBooklist = 'https://cdn.booklick.net/public/img/icons/booklist-white.svg';

class CreateBooklistCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isActive: false,
			item: this.props.item
		};
		this.handleSelect = this.handleSelect.bind(this);
	}

	componentDidMount() {
		if(this.props.contentId && this.props.contentId === this.state.item.id) {
			this.handleSelect();
		}
		this.props.selectedContent && this.props.selectedContent.map(item => {
			if(item === this.state.item.id) {
				return this.setState({
					isActive: true
				});

			}
		});
			
		
	}
	handleSelect() {
		this.setState({
			isActive: !this.state.isActive
		});
		if(this.state.isActive) {
			this.props.handleRemoveContent(this.state.item.id);
		} else {
			this.props.handleSelectContent(this.state.item.id);

		}
	}

	render() {
		return (
			<div 
				className={this.state.isActive ? `${styles.miniCard} ${styles.miniCardActive}`: styles.miniCard}
				onClick={() => this.handleSelect()}
			>
				<div className={styles.posIconMini}>
					<img className={styles.icon20} src={this.state.item && this.state.item.type === 'content' ? iconContent :iconBooklist} alt="video"/>
				</div>
				<div className={styles.boxMiniImg}>
					<img className={styles.imgCard} src={this.state.item.image ? this.state.item.image : booklistImage} alt="user"/>
				</div>
				<div className={styles.paddmin}>
					<h2 className={styles.h2TitleMiniCard}>{this.state.item.title}</h2>
				</div> 
			</div>
		);
	}

}


export default CreateBooklistCard;