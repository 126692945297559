import React from 'react';
import ContentLoader from 'react-content-loader';
const LoaderCardProfile = (props) => {
	return (
		<div style={{
			width: '197px',
			height: '205px',
			padding: '5px',
			borderRadius: '8px',
			boxShadow: '0 3px 6px 0 rgba(123, 123, 123, 0.15)',
			backgroundColor: '#ffffff'
		}}>
			<ContentLoader
				speed={2}
				width={197}
				height={205}
				viewBox="0 0 197 205"
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				{...props}
			>
				<circle cx="100" cy="41" r="32" />
				<rect x="54" y="88" rx="0" ry="0" width="96" height="7" />
				<rect x="53" y="101" rx="0" ry="0" width="97" height="15" />
				<rect x="119" y="118" rx="0" ry="0" width="5" height="6" />
				<rect x="51" y="133" rx="12" ry="12" width="101" height="29" />
			</ContentLoader>
		</div>
	);
};

export default LoaderCardProfile;