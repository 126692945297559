import {FETCH_INSTITUTION, LOADING_INSTITUTION} from '../actions';

const initialState = {
	loading: false,
	data: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
	case LOADING_INSTITUTION:
		return {
			...state,
			loading: true
		};
	case FETCH_INSTITUTION:
		return {
			...state,
			data: action.payload,
			loading: false
		};
	default:
		return state;
	}
};
