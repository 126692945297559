import React from 'react';
import styles from './styles/dialogBottomMobile.scss';
import Button from './Button';
import { getResources } from '../../../utils/constans';
import { environment } from '../../actions';

const DialogBottomMobile = ({ onClose, content, button }) => {
	return (
		<>
			<div className={styles.overlay} onClick={onClose}></div>
			<div className={styles.dialogContainer}>
				<div className={styles.dialogContent}>
					{button.goToback && !button.goToback.hidden && (
						<div
							onClick={button.goToback.action}
							className={styles.back}
						>
							<img
								className={styles.icon20}
								src={getResources(environment).iconArrowLeft}
								alt=""
							/>
							<p>{button.goToback.title}</p>
						</div>
					)}
					{content}
					<div style={{ marginTop: '5px' }}>
						{button && (
							<Button
								title={button.title}
								width={button.style.width}
								height={button.style.height}
								borderRadius={button.style.borderRadius}
								appearance={button.type}
								marginRight={button.style.marginRight}
								icon={button.icon}
								action={button.action}
								loader={button.loading}
								disabled={button.disabled}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default DialogBottomMobile;
