import React, {Component} from 'react';
import Navbar from '../layout/NavBar';
import styles from './styles/nativeMenu.scss';
import {cognitoLogin, loginStat, fetchProfilePerformance, openPopUp} from './../../actions';
import ConnectEZProxy from '../layout/ConnectEZProxy';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import MobileMenu from '../layout/MobileMenu';
import PopUpRegister from '../register/PopUpRegister';


const breakPointResponsive = 550;

export default (ChildComponent) => {
	class NativeMenu extends Component {
		constructor(props) {
			super(props);
			this.state = {
				width: 0,
				height: 0,
				openSearch: null,
				openPopUp: false,
			};
		}
		async componentDidMount () {
			// Redirect /0.1/auth to login/perfil
		
			this.updateWindowDimensions();
  			window.addEventListener('resize', this.updateWindowDimensions);
			if (env.user && env.IdentityId) {
				const environment = window.location.host.split('.')[0] === 'booklick' ?
					window.location.host.split('.')[1] : window.location.host.split('.')[0];
				cognitoLogin(environment).then(() => {
					this.props.loginStat(`${env.user}@${env.iesDomain}`);
					this.props.fetchProfilePerformance();
				})
					.catch((e) => console.error('error cognito', e));
			} 

		}


		componentDidUpdate(prevProps) {
			if (this.props.profile.profile !== prevProps.profile.profile) {
				if(this.props.profile.profile && this.props.profile.profile.data) {
					if (
						!this.props.profile.profile.data.lastName &&
						!this.props.profile.profile.data.role
					) {
						this.setState({
							openPopUp: true
						})
						this.props.openPopUp(true)
					}
				}
			}
			if(this.props.exploration.openPopUp !== prevProps.exploration.openPopUp) {
				if(this.props.exploration.openPopUp) {
					this.setState({
						openPopUp: true
					})
				}
			}
			
	}


		componentWillUnmount() {
			window.removeEventListener('resize', this.updateWindowDimensions);
		}
		
		updateWindowDimensions = () => {
			this.setState({
				width: window.innerWidth,
				height: window.innerHeight
			});
		}

		handleSearchBarMobile = (value) => {
			this.setState({
				openSearch: value,
			})
		}

		handlePopUp = () => {
			this.props.openPopUp(false)
			this.setState({
				openPopUp: false
			})
		}

		render() {

			return (
				<>
					
					<div>
						<div className={styles.content}>
							<Navbar 
								mobileMenu={this.state.width && (this.state.width < breakPointResponsive) ? this.state.openSearch : null}
								handleSearchBarMobile={(value) => this.handleSearchBarMobile(value)}
							/>
							<ChildComponent />
						</div>						
					 {this.state.width && (this.state.width < breakPointResponsive) && !this.state.openPopUp ?
						<MobileMenu 
						mobileMenu={this.state.width && (this.state.width < breakPointResponsive) ? this.state.width : null}
						handleSearchBarMobile={(value) => this.handleSearchBarMobile(value)}
						/>
						: null
						}
						
						{this.state.openPopUp && <PopUpRegister handlePopUp={this.handlePopUp} />}
						
						
			
						<ConnectEZProxy />
					</div>
				</>
			);
		}
	}

	function mapStateToProps({ profile, exploration }) {
		return { profile, exploration };
	}

	return withRouter(connect(mapStateToProps, {
		loginStat, 
		fetchProfilePerformance,
		openPopUp
	})(NativeMenu));
};
