import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import createStore from '../helpers/createStore';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import Routes from './Routes';
import CatchError from '../client/components/shared/CatchError';
import { environment } from './actions';

import { COLORS } from '../utils/constans';

const store = createStore();

import './i18n';

// Load the Google Fonts icon stylesheet and CSS configuration here
const link = document.createElement('link');
link.href = 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,300,0,0';
link.rel = 'stylesheet';
document.head.appendChild(link);

// Determine if the environment is present in the JSON data
const isEnvironmentPresent = COLORS[environment];

const { primary, secondary, complementary, dark } = isEnvironmentPresent
	? COLORS[environment]
	: COLORS.global;

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<CatchError>
				<div
					style={{
						// Apply the colors dynamically using inline styles
						'--primary-color': primary,
						'--secondary-color': secondary,
						'--complementary-color': complementary,
						'--dark-color': dark,
					}}
				>
					{renderRoutes(Routes)}
				</div>
			</CatchError>
		</BrowserRouter>
	</Provider>,
	document.querySelector('#root')
);
