import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Cognito from '../../helpers/cognito';
import { fetchPreviewContent, setPopUpShare } from '../actions';
import ContentNewCard from '../components/content-new/ContentNewCard';
import ContentProfiles from '../components/content-new/ContentProfiles';
import nativeMenu from '../components/hocs/nativeMenu';
import CatchError from '../components/shared/CatchError';
import Loading from '../components/shared/Loading';
import PopUpShare from '../components/shared/PopUpShare';
import styles from './styles/contentNewPage.scss';
const breakPointMobile = 550;
const breakPointTablet = 1050;

class ContentNewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reader: null,
      url: '',
      target: '_self',
      width: 0,
      height: 0,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    const path = window.location.pathname.split('/');
    this.props.fetchPreviewContent(path[path.length - 1]);
    this.handleRedirectLogin(path[path.length - 1]);
  }

  handleRedirectLogin = async(contentSlug) => {
	try {
		const session = await Cognito.retrieveToken();
	} catch (error) {
		window.localStorage.setItem('contentSlug', `${contentSlug}`)
		window.location = env.proxy ? `${env.proxy}` : `${window.location.origin}`
	}
}

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  render() {
    if (this.props.content.loading || !this.props.content.data.id)
      return (
        <div className={styles.loadingContainer}>
          <Loading loading />
        </div>
      );
    return (
      <CatchError>
        <div className={styles.paddingPage}>
          <div className={styles.containerPage}>
            <div className={styles.gridContentPage}>
              <div className={styles.gridContentLeft}>
                <ContentNewCard content={this.props.content} {...this.props} />
                {this.state.width && this.state.width < breakPointTablet ? (
                  <div className={styles.contentProfilesContainer}>
                    <ContentProfiles {...this.props} />
                  </div>
                ) : null}
              </div>
              <div className={styles.gridContentRight}>
                {this.state.width &&
                this.state.width < breakPointTablet ? null : (
                  <ContentProfiles {...this.props} />
                )}
              </div>
            </div>
          </div>
          {this.props.exploration.openPopUpShare && (
            <PopUpShare
              closePopUp={() => this.props.setPopUpShare(null, false)}
            />
          )}
        </div>
      </CatchError>
    );
  }
}
function mapStateToProps({
  exploration,
  contentPreview,
  myContentList,
  profile,
  contentList,
  activity,
  institution,
}) {
  return {
    exploration,
    content: contentPreview,
    myContentList,
    profile,
    contentList,
    activity,
    institution,
  };
}

export default {
  component: nativeMenu(
    withRouter(
      connect(mapStateToProps, {
        fetchPreviewContent,
        setPopUpShare,
      })(ContentNewPage)
    )
  ),
};
