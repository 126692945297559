import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styles from "./styles/resultsContent.scss";
import { useTranslation } from "react-i18next";
import {
  iconShareNew,
  iconAddCircle,
  profileDefaultImage,
  imageByType,
  contentTypeNew,
  eventSearchType,
  getResources,
  iconMoreInfo,
  booklistsType,
} from "../../../utils/constans";
import ContextMenu from "../context-menu/ContextMenu";
import PopupContextMenu from "../context-menu/PopupContextMenu";
import useContextMenu from "../../hooks/useContextMenu";

import { putLike, openPopUp, setPopUpShare, environment } from "../../actions";
import Cognito from "../../../helpers/cognito";
import { postEventSearch } from "../../../helpers/tracking";
import { handleUrl } from "../../../utils/handleUrl";
import AddContentMenuMobile from "../context-menu/mobile/AddContentMenuMobile";
import PopUpPreview from "./PopUpPreview";

const offLineBookType = "OFFLINE_BOOKS";

const ResultsContent = ({
  resultContent,
  openPopUp,
  setPopUpShare,
  profile,
  isMobile,
  history,
}) => {
  const [content, setContent] = useState(resultContent);
  const [contextMenuInfo, setContextMenuInfo] = useState();
  const [error, setError] = useState(false);
  const [urlOfflineBook, setUrlOfflineBook] = useState();
  const [bookPopUpVisible, setBookPopUpVisible] = useState({
    preview: false,
  });
  const [isOfflineBook, setIsOfflineBook] = useState();
  const { handleOpenContextMenu, openContextMenu } = useContextMenu();
  const { t } = useTranslation();

  useEffect(() => {
    setContent(resultContent);
  }, []);

  useEffect(() => {
    setIsOfflineBook(resultContent.type == offLineBookType);
  }, [resultContent]);

  useEffect(() => {
    if (content) {
      setContextMenuInfo({
        id: content.slug,
        type: content.type,
        info: {
          author: content.author,
          description: content.description,
          image: content.image,
          id: content.id,
          slug: content.slug,
          slugId: content.id,
          title: content.title,
          link: urlOfflineBook
            ? urlOfflineBook
            : handleUrl(
                resultContent.slug,
                resultContent.type,
                resultContent.url
              ),
        },
        attributes: content,
      });
    }
  }, [content, urlOfflineBook]);

  const closePopup = () => {
    setBookPopUpVisible({
      preview: false,
    });
  };

  const handlePopUpShare = async () => {
    try {
      const session = await Cognito.retrieveToken();
      setPopUpShare(
        {
          ...content,
          search: true,
          url: urlOfflineBook
            ? urlOfflineBook
            : handleUrl(
                resultContent.slug,
                resultContent.type,
                resultContent.url
              ),
        },
        true,
        profile.profile.data.email
      );
    } catch (error) {
      openPopUp(true);
    }
  };

  const handleClickContent = async () => {
    let session = null;
    let email = null;

    try {
      session = await Cognito.retrieveToken();
      email = session ? profile.profile.data.email : null;
      postEventSearch(
        eventSearchType.contentRedirect,
        null,
        email,
        null,
        content.id
      );
    } catch (error) {
      console.warn("User is not logged in:", error);
    }

    if (resultContent.type == "BOOKLISTS") {
      history.push(`/booklist/${resultContent.slug}`);
      return;
    }

    if (resultContent.type === "DOCUMENTS") {
      history.push(`/preview/${resultContent.slug}`);
      return;
    }

    if (resultContent.type == offLineBookType) {
      setBookPopUpVisible({
        preview: true,
      });
      return;
    }
    window.open(
      `${
        urlOfflineBook
          ? urlOfflineBook
          : handleUrl(
              resultContent.slug,
              resultContent.type,
              resultContent.url,
              session ? session.accessToken.jwtToken : null
            )
      }`
    );
  };

  const handleOpenPreview = () => {
    setBookPopUpVisible({
      preview: true,
    });
  };

  const translateTypeContent = (name) => {
    switch (name) {
      case "Libros":
        return t("textSearchFilterBook");
      case "Artículos":
        return t("textSearchFilterArticle");
      case "Articulo":
        return t("textSearchFilterArticle");
      case "Revistas":
        return t("textSearchFilterPaper");
      case "Perfiles":
        return t("textSearchFilterProfiles");
      case "Booklists":
        return t("textSearchFilterBooklist");
      case "Videos":
        return t("textSearchResultVideo");
      case "Textos":
        return t("textSearchResultText");
      case "Imágenes":
        return t("textSearchResultImagen");
      case "Libro fisico":
        return t("textSearchResultOfflineBook");
      case "Enlace":
        return t("textSearchFilterLink");
      case "Libros":
        return t("textSearchFilterBook");
      case "Libro externo":
        return t("textSearchResultExternalBook");
      case "Documento":
        return t("textSearchFilterDocument");
      case "Enlace":
        return t("textSearchFilterLink");
      default:
        return name;
    }
  };

  return (
    <>
      {content && !error && (
        <div
          className={`${isMobile ? styles.mobileContainer : styles.gridRow10} ${
            styles.alignStart
          }`}
        >
          <a
            className={`${styles.boxImage} ${styles.pointer}`}
            onClick={handleClickContent}
          >
            <div className={styles.opacity} />
            <img
              className={styles.imgBox}
              src={
                content.image
                  ? content.image
                  : imageByType[content.type]
                  ? imageByType[content.type]
                  : getResources(environment).defaultCover
              }
              onError={(e) =>
                (e.target.src = imageByType[content.type]
                  ? imageByType[content.type]
                  : getResources(environment).defaultCover)
              }
            />
            {contentTypeNew[content.type] && (
              <div className={styles.posType}>
                <div
                  className={
                    booklistsType.find((elemt) => elemt === content.type)
                      ? styles.contentTypeRed
                      : styles.contentType
                  }
                >
                  <img
                    className={styles.icon15}
                    src={contentTypeNew[content.type].imgUrl}
                    alt=""
                  />
                  <h3 className={styles.fontType}>
                    {translateTypeContent(contentTypeNew[content.type].label)}
                  </h3>
                </div>
              </div>
            )}
          </a>
          <a className={styles.fontTitleGrid} onClick={handleClickContent}>
            <h2 className={styles.pointer}>{content.title}</h2>
          </a>
          <div className={styles.maxParagraph}>
            {content.author && content.author !== "" && (
              <p
                className={styles.fontGrid}
                style={{ margin: 0, marginTop: "5px" }}
              >
                <span>{`${t("inputAdvanceSearchAutor")}:`}</span>{" "}
                {`${content.author}`}
              </p>
            )}
            {content.publication && content.publication !== "" && (
              <p className={styles.fontGrid} style={{ margin: 0 }}>
                <span>{`${t("inputAdvanceSearchYear")}:`}</span>{" "}
                {`${content.publication.split("-")[0]}`}
              </p>
            )}
            {(!content.author || content.author === "") &&
              (!content.publication || content.publication === "") &&
              content.description &&
              content.description !== "" && (
                <p className={styles.fontGrid}>{content.description}</p>
              )}
          </div>
          <div className={styles.authorContainer}>
            {content.editorial ? (
              <p
                className={`${styles.fontGrid2} ${styles.sourceContent}`}
              >{`${t("textHintSource")} ${
                content.editorial === "no editorial"
                  ? t("textNoEditorial")
                  : content.editorial
              }`}</p>
            ) : null}
            {content.author && content.author.email ? (
              <div className={styles.displayFlex}>
                <div className={styles.relative}>
                  <div className={styles.circleUser}>
                    <img
                      className={styles.imgPeople}
                      src={
                        content.author.profileImgUrl
                          ? content.author.profileImgUrl
                          : profileDefaultImage
                      }
                      alt={content.author.firstName}
                    />
                  </div>
                  <div className={styles.posLogo}>
                    <div className={styles.circleLogo}>
                      <img
                        className={styles.logoUni}
                        src={content.author.institutionLogo}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <p
                  className={styles.fontGrid2}
                >{`${content.author.firstName} ${content.author.lastName}`}</p>
              </div>
            ) : null}
          </div>
          <div className={styles.gridIcons}>
            <div className={styles.posBtnMore}>
              <img
                src={iconMoreInfo}
                onClick={handleOpenPreview}
                className={`${styles.icon20} ${styles.cursorPointer}`}
                alt="more-info"
              />
            </div>
            <div className={styles.gridInterIcons}>
              {isMobile ? (
                <div className={styles.posBtnMore}>
                  <ContextMenu
                    color={"green"}
                    share={true}
                    exploration={true}
                    icon={iconShareNew}
                    item={contextMenuInfo}
                    isContent={content.id}
                  />
                </div>
              ) : (
                <img
                  src={iconShareNew}
                  onClick={handlePopUpShare}
                  className={`${styles.icon20} ${styles.cursorPointer}`}
                  alt="share"
                />
              )}
            </div>
            <div className={styles.posBtnMore}>
              <img
                src={iconAddCircle}
                onClick={() => handleOpenContextMenu(!openContextMenu)}
                className={`${styles.icon20} ${styles.cursorPointer}`}
                alt="context-menu"
              />
            </div>
          </div>
        </div>
      )}

      {openContextMenu &&
        (isMobile ? (
          <AddContentMenuMobile
            onClose={() => handleOpenContextMenu(!openContextMenu)}
            clickedContent={content}
          />
        ) : (
          <PopupContextMenu
            closePopUp={() => handleOpenContextMenu(!openContextMenu)}
            content={content}
          />
        ))}
      {bookPopUpVisible.preview && (
        <PopUpPreview
          closePopUp={closePopup}
          content={content}
          openContextMenu={() => handleOpenContextMenu(!openContextMenu)}
          openOopUpShare={handlePopUpShare}
          isMobile={isMobile}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ profile, exploration }) => {
  return {
    profile,
    exploration,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    putLike,
    openPopUp,
    setPopUpShare,
  })(ResultsContent)
);
