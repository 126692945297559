import React from 'react';
import ContentLoader from 'react-content-loader';

const LoaderSearchProfile = (props) => (
	<ContentLoader
		speed={2}
		width={675}
		height={50}
		viewBox="0 0 675 50"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		{...props}
	>
		<rect x="68" y="10" rx="4" ry="4" width="329" height="19" />
		<rect x="30" y="5" rx="50" ry="50" width="31" height="31" />
	</ContentLoader>
);

export default LoaderSearchProfile;
