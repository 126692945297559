import Cognito from "../../helpers/cognito";
import {
  GET_NEW_HOME,
  LOADING_NEW_HOME,
  LOADING_HOME_CONTENTS,
  PAGINATE_HOME,
} from "../types/homeTypes";
import { environment } from "./index";

const getLibraryProfile = async () => {
  try {
    const response = await fetch(
      `${env.msProfileNew}/api/library-profiles?institution=${environment}`,
      {
        method: "GET",
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

const getPopularContentIds = async (careerString) => {
  try {
    const response = await fetch(`${env.msHome}/api/home/content-with-mayor`, {
      method: "GET",
      headers: {
        env: environment,
        mayor: careerString,
      },
    });
    const data = await response.json();
    const contentIds = data.map((content) => content.slug);
    return contentIds;
  } catch (error) {
    console.error("Error fetching popular content IDs:", error);
    throw error;
  }
};

const getPopularContents = async (contentIds, careerString) => {
  try {
    const session = await Cognito.retrieveToken();
    const response = await fetch(
      `${env.msSearchNew}/api/search/getContent?term=${encodeURIComponent(
        careerString
      )}&size=10&pag=1`,
      {
        method: "POST",
        body: JSON.stringify({ ids: contentIds }),
        headers: {
          env: environment,
          Authorization: session.idToken.jwtToken,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching popular content:", error);
    throw error;
  }
};

export const getPopularContentsPaginate =
  (contentIds, page, careerString) => async (dispatch, getState) => {
    try {
      const session = await Cognito.retrieveToken();
      const response = await fetch(
        `${env.msSearchNew}/api/search/getContent?term=${encodeURIComponent(
          careerString
        )}&size=10&pag=${page}`,
        {
          method: "POST",
          body: JSON.stringify({ ids: contentIds }),
          headers: {
            env: environment,
            Authorization: session.idToken.jwtToken,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      dispatch({
        type: PAGINATE_HOME,
        payload: {
          contents: data,
          page: page,
        },
      });
      return data;
    } catch (error) {
      console.error("Error fetching popular content:", error);
      throw error;
    }
  };

export const getPopularContentsWithFilters =
  (contentIds, filters, page, careerString) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_HOME_CONTENTS,
        payload: {
          loadingContents: true,
        },
      });
      const session = await Cognito.retrieveToken();
      let query = "";

      if (filters.editorial && filters.editorial != "Todos") {
        query = `&editorial=${encodeURIComponent(filters.editorial)}`;
      }

      if (filters.lang && filters.lang != "Todos") {
        query = query
          ? `${query}&language=${filters.lang}`
          : `&language=${filters.lang}`;
      }

      if (filters.type) {
        query = query
          ? `${query}&type=${filters.type}`
          : `&type=${filters.type}`;
      }
      const response = await fetch(
        `${env.msSearchNew}/api/search/getContent?term=${encodeURIComponent(
          careerString
        )}&size=10${query}&pag=${page}`,
        {
          method: "POST",
          body: JSON.stringify({ ids: contentIds }),
          headers: {
            env: environment,
            Authorization: session.idToken.jwtToken,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      dispatch({
        type: LOADING_HOME_CONTENTS,
        payload: {
          loadingContents: false,
        },
      });
      return data;
    } catch (error) {
      console.error("Error fetching popular content:", error);
      throw error;
    }
  };

const getPopularProfileEmails = async () => {
  try {
    const response = await fetch(`${env.msHome}/api/home/user`, {
      method: "GET",
      headers: {
        env: environment,
      },
    });
    const data = await response.json();
    const emails = data.map((profile) => profile.targetEmail);
    // Process data or return it as needed
    return emails;
  } catch (error) {
    console.error("Error fetching popular content:", error);
    throw error;
  }
};

const getPopularProfiles = async (emails) => {
  try {
    const response = await fetch(`${env.msProfileNew}/api/profiles/emails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ emails: emails }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching popular content:", error);
    throw error;
  }
};

export const loadNewHome = (careerString) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_NEW_HOME,
    });

    const [contentIds, profileEmails, libraryProfile] = await Promise.all([
      getPopularContentIds(careerString),
      getPopularProfileEmails(),
      getLibraryProfile(),
    ]);

    const [contents, profiles] = await Promise.all([
      getPopularContents(contentIds, careerString),
      getPopularProfiles(profileEmails),
    ]);

    let newProfiles = profiles.reduce(function(arr, v, i) {
      return arr.concat(v, contents.profiles[i]); 
    }, []);

    dispatch({
      type: GET_NEW_HOME,
      payload: {
        topIds: contentIds,
        contents: contents,
        topEmails: profileEmails,
        profiles: newProfiles,
        libraryProfile: libraryProfile,
        filters: contents.filters,
      },
    });
  } catch (error) {
    console.error("Error loading home data:", error);
    throw error;
  }
};

export const loadHomeProfiles = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_NEW_HOME,
    });

    const [contentIds, profileEmails, libraryProfile] = await Promise.all([
      getPopularContentIds(),
      getPopularProfileEmails(),
      getLibraryProfile(),
    ]);

    // Dispatch action with the fetched data
    dispatch({
      type: GET_NEW_HOME,
      payload: {
        topIds: contentIds,
        topEmails: profileEmails,
        libraryProfile: libraryProfile,
      },
    });
  } catch (error) {
    console.error("Error loading home data:", error);
    throw error;
  }
};
