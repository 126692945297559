import axios from 'axios';

export const SEARCH_INPUT = 'search_input';
export const AUTOCOMPLETE = 'AUTOCOMPLETE';
export const CLEAN_SEARCH_RESULTS = 'clean_search_results';
export const onSearchInputChange = (newTerm) => async(dispatch) => {
	dispatch({
		type: SEARCH_INPUT,
		payload: newTerm
	});
	axios.get(`https://api.datamuse.com/sug?s=${newTerm}&sp&max=5`)
		.then(data =>
			dispatch({
				type: AUTOCOMPLETE,
				payload: data.data
			})
		);
};

export const CLEAN_INPUT_SEARCH = 'CLEAN_INPUT_SEARCH';
export const cleanInputSearch = () => async(dispatch) => {
	dispatch({
		type: CLEAN_INPUT_SEARCH,
	});
};


