import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Dropzone from 'react-dropzone';
import styles from './styles/popupCreate.scss'
import {connect} from 'react-redux';
import {
  putFile,
  saveLink,
  getMetadata,
  loadingProfile,
  fetchAllCategories,
  fetchLinksTypes,
  putTags,
  fetchMyContent,
  addToSelectedBooklist,
  addDocumentToBooklist,
  submitEditingContentFile,
  putFileBeforeCreate,
  postDocumentHandler,
  fetchMyBooklists,
  fetchBooklistPermissions,
  environment,
} from '../../actions';
import { termLastDate } from '../../TermsAndConditions';
import { withTranslation} from 'react-i18next';
import { getResources, termsAndConditions } from '../../../utils/constans';

const iconTermsActive = 'https://cdn.booklick.net/public/img/icons/check.svg'
const iconTermsInactive = 'https://cdn.booklick.net/public/img/icons/checkbox-line-inactive.svg'
const iconCamera = "https://cdn.booklick.net/public/img/icons/icon-camera-fill.svg";
const iconArrowUpBlue = "https://cdn.booklick.net/public/img/icons/icon-arrow-drop-up-blue.svg";
const iconDeleteTag = "https://cdn.booklick.net/public/img/icons/icon-delete-white.svg";
const iconArrowUpWhite = "https://cdn.booklick.net/public/img/icons/icon-arrow-drop-up-white.svg";
const iconArrowDownWhite = "https://cdn.booklick.net/public/img/icons/icon-arrow-drop-down-white.svg";
const iconPageWhite = "https://cdn.booklick.net/public/img/icons/icon-page-white.svg";
const iconListBlue = "https://cdn.booklick.net/public/img/icons/list-blue.svg";
const iconCloseBlue = "https://cdn.booklick.net/public/img/icons/icon-close-blue-light.svg";
const iconArrowDownGray = 'https://cdn.booklick.net/public/img/icons/arrow-down-s-fill.svg';
const imageCoverBooklick = getResources(environment).defaultCover;
const imageFileType = 'image';

class CreateFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewImage: null,
      previewTitle: null,
      previewDescription: null,
      categories: this.props.category.categories,
      categorieLabel: null,
      categoryId: null,
      dropBlue: false,
      dropWhite: false,
      link: this.props.link,
      file: this.props.data,
      imageFile: null,
      tag: null,
      tags: [],
      meta: this.props.meta,
      type: null,
      types: this.props.category.types,
      typeLabel: null,
      loadingData: false,
      dropAtToBooklist: false,
      booklistId: null,
      alertMessage: false,
      fileData: null,
      acceptedTerms: true,
      extendedTerms: false,
      fetchLink: this.props.linkOnboarding || null,
      booklists: null,
      hasNextPage:false,
      booklistsPermissions: null,
      openSubMenuMyBooklist: false,
      openSubMenuBooklistsPermissions: false,
      popUpTerms: false,
    }
    this.handlePutTag = this.handlePutTag.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitLink = this.handleSubmitLink.bind(this)
    this.handleLink = this.handleLink.bind(this)
    this.handleAddToBooklist = this.handleAddToBooklist.bind(this)
  }

  componentDidMount(){
    this.fetchData()
    let input = document.getElementById('hashtag')
    input.addEventListener('keyup', function(event) {
      if(event.keyCode === 13) {
        event.preventDefault();
        document.getElementById('tagbutton').click();
        input.value = null
      }
    })

     if (this.state.meta !== null && this.state.meta !== undefined) {
        this.setState({
          previewTitle: (this.props.meta.title == "Título del link" ? '' : this.props.meta.title) || '',
          previewImage : this.props.meta.imageURL || '',
          previewDescription: this.props.meta.description ||'',
        })
      }
    if(!this.state.link) {
      this.handleUploadFile()
    }
  }
  fetchData = async () => {
    this.setState({ loading: true, error: null})
    try {
      if(!this.props.contentList.myBooklists) {
        await this.props.fetchMyBooklists()
      }
      if(!this.props.contentList.booklistPermissions) {
        await this.props.fetchBooklistPermissions(); 
			}
      this.setState({
        booklists: this.props.contentList.myBooklists.booklists,
        hasNextPage:this.props.contentList.myBooklists? this.props.contentList.myBooklists.hasNextPage : false,
        booklistsPermissions: this.props.contentList.booklistPermissions.filter((booklist) => booklist.permissions === 'edit')
      })
      const data = await this.props.fetchMyContent(this.props.profile.profile.data.email)
      if(!this.props.category.types) {
        await this.props.fetchLinksTypes();
      }
      if(!this.props.category.categories) {
       await this.props.fetchAllCategories();
			}

      this.setState({
        loading: false,
        content: data,
        types: this.props.category.types,
        categories: this.props.category.categories,
        loadingFile: true,
      })

    } catch (error) {
      this.setState({ loading: false, error: error})
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.contentList !== prevProps.contentList) {
      if(this.props.contentList.myBooklists) {
        this.setState({
          booklists: this.props.contentList.myBooklists.booklists,
          hasNextPage:this.props.contentList.myBooklists? this.props.contentList.myBooklists.hasNextPage : false
        })
      }
      if(this.props.contentList.booklistPermissions) {
        this.setState({
          booklistsPermissions: this.props.contentList.booklistPermissions.filter((booklist) => booklist.permissions === 'edit')
        })
      }
    }
  }

  handleUploadFile = async () => {
    try {
      if(this.props.data.type.includes(imageFileType)) {
        this.setState({
          imageFile: this.props.data
        })
        let reader = new FileReader();
        reader.onloadend = () => {
          this.setState({
            previewImage: reader.result
          });
        }
        reader.readAsDataURL(this.props.data)
      }
      // this.setState({
      //   filePromise: this.props.putFileBeforeCreate(this.props.data)
      // })

    } catch (error) {
      console.error(error)
    }
  }

  openDropBlue = () => {
    this.setState({ dropBlue: !this.state.dropBlue })
  }
  closeDropBlue = () => {
    this.setState({ dropBlue: false })
  }

	openDropWhite=()=>{
		this.setState({ dropWhite:!this.state.dropWhite })
	}
	closeDropWhite=()=>{
		this.setState({ dropWhite:false })
  }

  openSubMenuMyBooklist=()=>{
    this.setState({openSubMenuMyBooklist: !this.state.openSubMenuMyBooklist})
  }

  openSubMenuBooklistsPermissions=()=>{
    this.setState({openSubMenuBooklistsPermissions: !this.state.openSubMenuBooklistsPermissions})
  }

  handleLink = async (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
    setTimeout(
      async() => {
        if (this.state.fetchLink.startsWith('http') || this.state.fetchLink.startsWith('https')) {
        this.setState({
          loadingData: true
        })
        try {
          const link = await this.props.getMetadata(this.state.fetchLink)
          if(link === undefined) {
            return this.setState({
              meta: true,
              previewTitle: null,
              previewImage : null,
              previewDescription: null,
              loadingData:false
            })
          } else {
            this.setState({
              meta: link,
              previewTitle: link.title,
              previewImage : link.imageURL,
              previewDescription: link.description,
              loadingData: false
            })
          }
        } catch (error) {
          console.error(error)
        }
      }
    }
    ,100)
  }

  handleText = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  handleTag = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handlePutTag () {
    if(this.state.tag !== null && this.state.tag !== '') {
      this.setState({
        tags: [...this.state.tags, this.state.tag],
        tag: null
      })
    }
  }
  hadleRemoveTag(item) {
    this.setState({
      tags: this.state.tags.filter(element => element !== item)
    })
  }

  handleImageUpload = (file) => {
    this.setState({
      imageFile: file[0]
    })
    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        previewImage: reader.result
      });
    }
    reader.readAsDataURL(file[0])
  }


  handleSubmit = async (idBooklist, newBooklist, permission) => {
    try {
      if(
        !this.state.previewTitle
        || !this.state.previewDescription
        || this.state.acceptedTerms === false
        ) {
        this.handleShake()
      } else {
        this.setState({
          loadingData: true
        });
        const data = {
          title: this.state.previewTitle,
          description: this.state.previewDescription,
          categoryId: this.state.categoryId,
          author: this.props.profile.profile.data.email,
          file: this.props.data
        }



        const postContent = await this.props.putFileBeforeCreate(
          null,
          data,
          null,
          this.state.imageFile
        )
        
        if (this.state.tags.length > 0) {
          await this.props.putTags(postContent.id,this.state.tags)
        }

        const  contentNew = postContent;


        if(idBooklist && permission) {
          const slug = this.state.booklistsPermissions.map(booklist => {
            if(booklist.id === idBooklist) {
              return slug = booklist.slug
            }
          })
          (window.location = `/booklist/${slug[0]}`)
        } else if(idBooklist) {
          await this.props.addDocumentToBooklist(idBooklist, contentNew.slug)
          const booklist = this.state.booklists.filter(booklist=>booklist.id === idBooklist)[0];
          (window.location = `/booklist/${booklist.slug}`)
        }

        if(this.props.mobileMenuPopUp) {
          this.props.mobileMenuPopUp(true)
        }
        this.setState({
          loadingData: false
        })
        if(newBooklist) {
          this.props.changeNewBooklist(contentNew.id,)
          this.props.changePositionPopup(3)
        }
        !newBooklist && !idBooklist && (window.location = `/preview/${contentNew.slug}`)
        this.setState({file: null, imageFile: null});
        !newBooklist && this.props.handleClosePopUp(true)

      }
    } catch (error) {
      console.error(error.message)
    }
  }
  
  handleSubmitLink = async (idBooklist, newBooklist, permission) =>  {
    try {
      if(
        !this.state.meta
        || !this.state.previewTitle
        || !this.state.previewDescription
        ) {

        this.handleShake()
      } else {
        this.setState({
          loadingData: true
        })
        const data = await this.props.saveLink({
          title: this.state.previewTitle || '',
          description: this.state.previewDescription || '',
          author: this.props.profile.profile.data.email,
          cover: this.state.imageFile,
          url: this.state.meta.url || this.state.fetchLink,
          categoryId: this.state.categoryId,
          linkTypeId: this.state.type,
          imageURL: this.state.meta.imageURL ? this.state.meta.imageURL : null,
        })

        if(idBooklist && permission) {
          let slug;
        }else if(idBooklist) {
          let slug;
        }
        if(this.props.mobileMenuPopUp) {
          this.props.mobileMenuPopUp(true)
        }
        this.setState({
          loadingData: false
        })
        if(newBooklist) {
          this.props.changeNewBooklist(data.id)
          this.props.changePositionPopup(3)
        }
        !newBooklist && !idBooklist && (window.location = `/preview/${data.slug}`)
        !newBooklist && this.props.handleClosePopUp(true)
      }


    } catch (error) {
      console.error(error.message)
    }
  }

  handleAddToBooklist = (newBooklist, idBooklist, permission) => {
    if(!newBooklist) {
      this.setState({
        dropAtToBooklist: !this.state.dropAtToBooklist,
      })
      !this.state.link ? this.handleSubmit(idBooklist, false, permission) : this.handleSubmitLink(idBooklist, false, permission)
    } else {
      this.setState({
        dropAtToBooklist: !this.state.dropAtToBooklist,
      })
      !this.state.link ? this.handleSubmit(false, true, permission) : this.handleSubmitLink(false, true, permission)
     }

  }
  handleShake = () =>{
    this.setState({
      alertMessage: true
    })
    setTimeout(
      () => this.setState({
        alertMessage:false
      })
      ,500)
  }
  handlePopUpTerms = () => {
    let scrollMobile = document.getElementById('scrollPoUp')
    scrollMobile.scrollTo(0,0);

    this.setState({ popUpTerms : true})
  }

  translateInterestTopics = (name, t) => {
		switch (name) {
			case 'Administración':
				return t('titleInterestTopicsManage')
			case 'Arquitectura':
				return t('titleInterestTopicsArchitecture')
			case 'Arte':
				return t('titleInterestTopicsArt')
			case 'Ciencia':
				return t('titleInterestTopicsScience')
			case 'Ciencias sociales':
				return t('titleInterestTopicsSciencePeople')
		    case 'Derecho':
				return t('titleInterestTopicsLaw')
			case 'Diseño':
				return t('titleInterestTopicsDesign')
			case 'Economía':
				return t('titleInterestTopicsEconomy')
			case 'Gastronomía':
				return t('titleInterestTopicsGastronomy')
		    case 'Ingeniería':
				return t('titleInterestTopicsEnginner')
			case 'Medicina':
				return t('titleInterestTopicsMedicine')
			default:
				return name
    }
  }

  translateTypeContent = (name, t) => {
		switch (name) {
			case 'Audio':
				return t('titleTypeContentAudio')
			case 'Imagen':
				return t('titleTypeContentIamge')
			case 'Lectura':
				return t('titleTypeContentReading')
			case 'Libro':
				return t('titleTypeContentBook')
			case 'Video':
				return t('titleTypeContentVideo')
			default:
				return name
    }
  }




  render() {
    const { t } = this.props;
    return (
      <>
        {this.state.popUpTerms ?
          <>
          <div className={styles.fixedBlur}></div>
					<div className={styles.containerPopUpTerms}>
						<div className={styles.PopUpTermsTitle}>
							<div className={styles.containerIconTerms}>
								<img src={iconListBlue} alt='icon'/>
							</div>
							<div className={styles.titleContainer}>
								<h5 className={styles.termsTitle}>{t('titleCreateFileTerm')}</h5>
								<p className={styles.subTitle}>{`${t('textLastVersion')} ${termLastDate}`} </p>
							</div>
						<div
							className={styles.containerIconTermsClose}
							onClick={() => this.setState({ popUpTerms: false})}
							>
							<img src={iconCloseBlue} alt='icon'/>
						</div>
						</div>
						<div className={styles.containerParagraphTerms}>
							<p>
								{
								t('extendedTerms').split('\n').map((item,index) => {
                  return <p className={styles.paragraphTerms} key={index}>{item}</p>
								})
								}

							</p>
						</div>
						<div
							className={styles.termsButton}
							onClick={() => this.setState({ popUpTerms: false, acceptedTerms: true})}
						>
							<button className={`${styles.termsButton2} ${styles.activeButtonBlue}`}>{t('buttomAgree')}</button>
						</div>
					</div>
          </>
					:
					null
				}

      <div className={styles.gridAdd}>
        <div className={`${styles.gridRow16} ${styles.alignStart}`}>
            {
            this.state.link ?
              <>
                <div className={styles.fileSearch}>

                  <h2 className={`${styles.h2LinkPaste} ${styles.none550}`}>{t('buttonCopyLink')}</h2>

									<h2 className={`${styles.h2LinkPaste} ${styles.noneMin550 } ${styles.colorGrey}`}>{t('textImage')}</h2>

                  <input
                    type="text"
                    name='fetchLink'
                    id='fetchLink'
                    className={this.state.alertMessage && !this.state.fetchLink ? `${styles.fileInput} ${styles.alertVibration}` : styles.fileInput}
                    placeholder={t('inputCopyLink')}
                    datatest="input-link-content"
                    onChange={this.handleLink}
                    value={this.state.fetchLink ? this.state.fetchLink : null}
                    required
                    />

                </div>
              </>
              : null
            }
          <div className={styles.gridInfoAdd}>
            <Dropzone onDrop={this.handleImageUpload}>
              {({ getRootProps, getInputProps }) => (
                <div  {...getRootProps()}>
                  <input {...getInputProps()} />
                  {
                    this.state.previewImage ?
                    <div className={styles.boxaddpic} >
                      <img
                        className={styles.fileImgCard}
                        src={this.state.previewImage}
                        alt="cover"
                        onError={(e)=>{
                          e.target.onerror = null;
                          e.target.src=imageCoverBooklick;
                        }}
                      />
                      <div className={styles.fileGridRow10}>
                        <div className={styles.circleIcon}>
                          <img className={styles.icon26} src={iconCamera} alt="add pic" />
                        </div>
                        <h3 className={styles.h3infocenter2}>{t('textImageChange')}</h3>
                      </div>
                    </div>
                    :
                    <div className={styles.boxaddpic}>
                      <div className={styles.gridRow10}>
                        <div className={styles.circleIcon}>
                          <img className={styles.icon26} src={iconCamera} alt="add pic" />
                        </div>
                        <h3 className={styles.h3infocenter2}>{t('textImageAdd')}</h3>
                      </div>
                    </div>
                  }
                </div>
              )}
            </Dropzone>

            <div className={`${styles.gridRow10} ${styles.alignStart}`}>
              <h2 className={styles.h2Title2}>{t('textImage')}</h2>

							<div className={`${styles.gridRow20} ${styles.noneMin550} ${styles.marginBottom10 }`}>
								<input
									name='previewTitle'
									type="text"
									className={this.state.alertMessage && !this.state.previewTitle ? `${styles.inputTitle} ${styles.alertVibration} ` : `${styles.inputTitle}` }
									placeholder={t('textTitleContent')}
                  datatest="input-title-create-content"
									value={this.state.previewTitle ? this.state.previewTitle : null}
									onChange={this.handleText}
									required
								/>

								<textarea
									name="previewDescription"
									rows="9"
									cols="1"
									className={this.state.alertMessage && !this.state.previewDescription ? `${styles.inputDescription} ${styles.alertVibration} ${styles.description550}` : `${styles.inputDescription} ${styles.description550}`}
									placeholder={t('textPreviewDescription')}
                  datatest="input-description-create-content"
									onChange={this.handleText}
									value={this.state.previewDescription  ? this.state.previewDescription : null}
									required
								>
          </textarea>
							</div>

              {
                this.state.dropBlue ?
                  <div className={styles.closeDropBlue} onClick={this.closeDropBlue}></div>
                  : null
              }

              <div className={styles.relative} >

                  <div className={styles.selectDropBlueStyle} datatest="open-area-belong" onClick={this.openDropBlue} required>
                    {this.state.categorieLabel === null ? t('textCategorieBelongs') : this.state.categorieLabel}
                  </div>


                {
                  this.state.dropBlue ?

                    <div className={styles.posArrowDropBlue} onClick={this.closeDropBlue}>
                      <img className={styles.icon26} src={getResources(environment).iconArrowDropUp} alt="drop" />
                    </div> :
                    <div className={styles.posArrowDropBlue} onClick={this.openDropBlue}>
                      <img className={styles.icon26} src={getResources(environment).iconArrowDropDown} alt="drop" />
                    </div>
                }

                {
                  this.state.dropBlue && this.state.categories ?
                    <div className={styles.posDropBlue}>
                      <div className={styles.boxDropBlue}>
                        {this.state.categories && this.state.categories.map(item => {

                          return (
                            <div key={item.id} className={styles.itemDropBlue}
                              onClick={ () => {
                                this.setState({
                                  categoryId: item.id,
                                  categorieLabel:this.translateInterestTopics(item.label,t),
                                  dropBlue: false
                                })
                              }
                                }>
                                {this.translateInterestTopics(item.label,t)}
                            </div>
                          )
                        })}

                      </div>
                     </div>

                    :
                    null
                }

              </div>

              <h2 className={styles.h2Title2}>{t('textKeywordAddContent')}</h2>

							<div className={styles.gridBtn550}>

								<input
									type="text"
									name='tag'
									id='hashtag'
									className={styles.input}
                  datatest="input-keyword"
									data-tip={t('inputKeyword')}
									placeholder={t('titleEditEntryHintListKeywordHint')}
									patter="(?:\s|^)#[A-Za-z0-9\-\.\_]+(?:\s|$)"
									onChange={this.handleTag}
								/>

								<div className={styles.btn550} onClick={this.handlePutTag}>
									{t('textAddContent')}
								</div>

							</div>

              <div className={styles.none550}>
                <ReactTooltip
                  className={styles.tagsTooltip}
                  place="top"
                  effect="solid"
                  textColor='white'
                  theme='dark'
                  arrowColor='var(--primary-color)'
                  backgroundColor='linear-gradient(to right,var(--primary-color),var(--primary-color))'
                />
              </div>
                <button id='tagbutton' onClick={this.handlePutTag} hidden></button>
              <div className={styles.flexHash}>
                {
                  this.state.tags.length !== 0 ?
                    this.state.tags.map((item, index) => {
                      return (
                        <div key={index} className={styles.btnHash}>
                          {item}
                          <div onClick={() => this.hadleRemoveTag(item)}>
                            <img className={styles.iconDelete} src={iconDeleteTag} alt="delete" />
                          </div>
                        </div>
                      )
                    })
                    : null
                }
              </div>

              {!this.state.link &&
                <div className={styles.infoTerms550}>
                  <div className={styles.circleIconTerms} onClick={() => this.setState({ acceptedTerms: !this.state.acceptedTerms})}>
                    <img className={styles.icon20px} src={this.state.acceptedTerms === true ? iconTermsActive : iconTermsInactive} alt='terms'/>
                  </div>
                  <p className={styles.colorGrey}>{t('terms')}<span onClick={this.handlePopUpTerms} className={styles.colorGreen}>{t('titleViewMore')}</span></p>
                </div>
              }


              {
                (this.state.link && this.state.dropWhite) ?
                  <div className={styles.closeDropWhite} onClick={this.closeDropWhite}></div>
                  : null
              }
              {
                this.state.link ?
                  <div className={`${styles.relative} ${styles.fileMax180}`}>
                    <div className={styles.selectDropWhiteStyle} datatest="choose-type-content" onClick={this.openDropWhite}>
                      {this.state.typeLabel ? this.state.typeLabel : t('textTypeContent')}
                  </div>

                    {
                      this.state.dropWhite ?

                        <div className={styles.posArrowDropWhite}  onClick={this.closeDropWhite}>
                          <img className={`${styles.icon26} ${styles.none550}`} src={iconArrowUpWhite} alt="drop" />
                          <img className={`${styles.icon26} ${styles.arrowGrayStyle}`} src={iconArrowDownGray} alt="drop" />
                        </div> :
                        <div className={styles.posArrowDropWhite} onClick={this.openDropWhite}>
                          <img className={`${styles.icon26} ${styles.none550}`} src={iconArrowDownWhite} alt="drop" />
                          <img className={`${styles.icon26} ${styles.arrowGrayStyle}`} src={iconArrowDownGray} alt="drop" />
                        </div>
                    }

                    {
                      this.state.types &&  this.state.dropWhite ?
                        <div className={styles.posDropWhite}>
                          <div className={styles.boxDropWhite}>
                            {this.state.types && this.state.types.map(item => {
                              return(
                                <div key={item.id} className={styles.itemDropWhite}
                                  onClick={() => {
                                    this.setState({
                                      type: item.id,
                                      typeLabel: this.translateTypeContent(item.label,t),
                                      dropWhite: false
                                    })
                                  }
                                  }>
                                  {this.translateTypeContent(item.label,t)}
                              </div>
                              )
                            })}

                          </div>
                        </div>
                        :
                        null
                    }

                  </div>
                  :
                  null
              }

            </div>

          </div>

          <input
            name='previewTitle'
            type="text"
            className={this.state.alertMessage && !this.state.previewTitle ? `${styles.inputTitle} ${styles.alertVibration} ${styles.none550}` : `${styles.inputTitle} ${styles.none550}` }
            placeholder={t('textTitleContent')}
            datatest="input-title"
            value={this.state.previewTitle ? this.state.previewTitle : null}
            onChange={this.handleText}
            required
          />

          <textarea
          name="previewDescription"
          rows="9"
          cols="1"
          className={this.state.alertMessage && !this.state.previewDescription ? `${styles.inputDescription} ${styles.none550} ${styles.alertVibration}` : `${styles.inputDescription} ${styles.none550}`}
          placeholder={t('textPreviewDescription')}
          datatest="input-description"
          onChange={this.handleText}
          value={this.state.previewDescription  ? this.state.previewDescription : null}
          required
          >
          </textarea>
          {!this.state.link &&
            <div className={this.state.alertMessage && !this.state.acceptedTerms ? `${styles.termsContainer} ${styles.none550} ${styles.alertVibration}` : `${styles.termsContainer} ${styles.none550}` }
            >
                <div className={styles.circleIconTerms} onClick={() => this.setState({ acceptedTerms: !this.state.acceptedTerms})}>
                  <img className={styles.icon20px} src={this.state.acceptedTerms === true ? iconTermsActive : iconTermsInactive} alt='terms'/>
                </div>
                <p className={this.state.extendedTerms === true ? styles.paragraphExtendedTerms: styles.paragraphTerms}>
                  {this.state.extendedTerms === true ?
                  t('extendedTerms').split('\n').map((item,index) => {
                  return <p className={styles.paragraphTerms3} key={index}>{item}</p>
                  })
                  :
                  `${t('terms')} `}
                  <div
                  className={styles.paragraphTerms2}
                  onClick={() => this.setState({ extendedTerms: !this.state.extendedTerms})}
                  >
                  {this.state.extendedTerms === true ? t('textBehind') : t('titleViewMore')}
                  </div>
                  <a className={styles.paragraphTerms2} href={termsAndConditions} target="_blank" rel="noopener noreferrer">{t('titleTermAndConditions')}</a>
                </p>
            </div>
           }

        </div>

        <div className={styles.gridRow10}>

          <div className={styles.space10}></div>

          <div className={styles.gridCard}>
            <div className={styles.boxImgCard}>
              <div className={styles.posIconTop}>
                <img className={styles.icon25} src={iconPageWhite} alt="video" />
              </div>
              <img
                className={styles.imgCard}
                src={
                  this.state.previewImage ?
                  `${this.state.previewImage}` :
                  t('imageCreateFile')
                }
                alt="user"
                onError={(e)=>{
                  e.target.onerror = null;
                  e.target.src=imageCoverBooklick;
                }}
              />
            </div>
            <div className={styles.gridInfoCard}>
            <h3 className={styles.fileH3titlecard}>
              {this.state.previewTitle ? this.state.previewTitle : t('textTitleContent')}
            </h3>
            <p className={styles.paragraphCard}>{this.state.previewDescription === null || this.state.previewDescription === '' ? t('textDescriptionContent') : this.state.previewDescription}</p>

              <div className={styles.gridBtnSocial}>
                <div className={styles.gridAuto5}>
                  <img className={styles.icon14} src={getResources(environment).iconLikeActive} alt="like" />
                  <h4 className={styles.h4likes}>150</h4>
                </div>

                <div className={styles.gridAuto5}>
                  <div className={styles.lineGreyVertical}></div>
                  <h4 className={styles.h4likes}>24 {t('viewContent')}</h4>
                </div>

                <div className={styles.flexEnd}>
                  <img className={styles.icon20} src={getResources(environment).moreFill} alt="more" />
                </div>

              </div>
            </div>
          </div>

          <div >
            <div className={`${styles.gridRow10} ${styles.grid550btn}`}>
              <div className={styles.btnGreen} datatest="button-create-content"
              onClick={() => this.state.loadingData === true ? null : !this.state.link ? this.handleSubmit(false,false) : this.handleSubmitLink(false,false)}
              >
              {this.state.loadingData ? <div className={`${styles.fileSpinner} ${styles.fileIcon}`} aria-hidden="true"></div> : t('textNavBarCreated')}
            </div>
            </div>

          </div>

        </div>


      </div>

      </>

    )
  }

}

function mapStateToProps({ profile, category, contentList }) {
	return {
    profile,
    category,
    contentList
  };
}

export default connect(mapStateToProps, {
    putFile,
    saveLink,
    getMetadata,
    loadingProfile,
    fetchAllCategories,
    putTags,
    fetchLinksTypes,
    fetchMyContent,
    addToSelectedBooklist,
    addDocumentToBooklist,
    submitEditingContentFile,
    putFileBeforeCreate,
    postDocumentHandler,
    fetchBooklistPermissions,
    fetchMyBooklists
  })(withTranslation()(CreateFile));
  
