import React from 'react';
import LoaderCardContent from '../shared/LoaderCardContent';

const LoaderSearchContent = () => {
	let loaders = [];
	for (let index = 0; index < 6; index++) {
		loaders.push(<LoaderCardContent />);
	}
	return (
		<>
			{loaders.map((item,index)=>(
				<div key={index} style={{width:'641px'  , height: '152px' , borderRadius:'8px' , boxShadow:'0 2px 6px 0 rgba(51, 51, 51, 0.12)' }}>
					{item}
				</div>)
			)}
		</>
	);
};

export default LoaderSearchContent;