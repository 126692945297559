import React from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import PopUp  from '../shared/PopUp';
import InputGroup  from '../shared/InputGroup';
import useFormFileds from '../../hooks/useFormFields';
import { useTranslation } from 'react-i18next';

import { postBooklistSection } from '../../actions';
const SectionPopUpCreate = ({
	closePopUp,
	booklistId,  
	loadingPut,
	postBooklistSection
}) => {
	const { t } = useTranslation();
	const { fields, handleOnchage } = useFormFileds({
		titleSection: '',
	  });

	const handleCreateSection = async () => {
		await postBooklistSection(booklistId , fields.titleSection);
		closePopUp();
	};

	return (
		<PopUp
			closePopUp={closePopUp}
			style={{
				popUp:{
					maxHeight:'406px',
					maxWidth:'534px'
				},
				popUpContent:{
					margin:'40px'
				},
				button:{
					marginTop:'30px',
					justifyContent:'center',
				}
			}}
    
			buttons={{
				style:{
					width:'100%',
					height:'50px',
					borderRadius:'8px',
				},
				primary:{
					title:t('buttonCreateSection'),
					type:'primary',
					action:handleCreateSection,
					loading:loadingPut,
					disabled: loadingPut,
				}
			}}
		>
			<div style={{width:'100%'}}>
				<p style={{ 
					fontSize:'20px',
					fontWeight:700,
					color: '#333333',
					paddingBottom: '13px',
					borderBottom: 'solid 2px #e1e1e1'
				}}>
					{t('titleAddSection')}
				</p>
				<div style={{marginTop:'20px'}}>
					<InputGroup 
						inputName={'titleSection'}
						title={t('inputNameSection')}
						onChange={handleOnchage}
						value={fields.titleSection}
						fontSize={'16px'}
						fontWeight={'500'}
						style={{
							width:'100%',
							height:'45px',
						
						}}
					/>
				</div>



			</div>
		</PopUp>
	);
};

const mapStateToProps = ({ booklistV2  }) => {
	return {
		loadingPut: booklistV2.loadingPut
	};
};

export default  withRouter(connect(mapStateToProps,{postBooklistSection})(SectionPopUpCreate));
