export const LOADING = 'LOADING_SEARCH';
export const LOADED = 'LOADED';
export const ERROR = 'ERROR';
export const ADVANCED_SEARCH = 'ADVANCED_SEARCH';
export const ADVANCED_SEARCH_NAVBAR = 'ADVANCED_SEARCH_NAVBAR';
export const CLEAR_ADVANCED_SEARCH = 'CLEAR_ADVANCED_SEARCH';
export const SEARCH_PROFILES = 'SEARCH_PROFILES';
export const LOADING_FILTERS = 'LOADING_FILTERS';
export const FILTERS = 'FILTERS';
export const NEXT_PAGE = 'NEXT_PAGE';

