import axios from 'axios/index';
import Cognito from '../../helpers/cognito';
import { environment } from './index';

export const getSimilarMayor = async (page) => {
	try {
		const session = await Cognito.retrieveToken();

		const request = await axios({
			method: 'GET',
			url: `${env.msRecommend}/similar/${page}`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				env: environment,
			},
		});


		
		let data = request.data.data;

		let result = data.map((profile) => {
			let roleMayor;
			if(profile.mayor !== null){
				switch (profile.role) {
				case 'student':
					roleMayor = 'Estudiante de ' + profile.mayor.toLowerCase();
					break;
				case 'teacher':
					roleMayor = 'Profesor de ' + profile.mayor.toLowerCase();
					break;
				case 'admin':
					roleMayor = 'Administrativo';
					break;
				default:
					roleMayor = 'Usuario Booklick';
					break;
				}
			}else{
				roleMayor = 'Usuario Booklick';
			}

			return {
				id: profile.id,
				uid: profile.uid,
				profileImgUrl: profile.profileImgUrl ? profile.profileImgUrl : 'https://cdn.booklick.net/public/img/profile.png',
				firstName: profile.firstName,
				lastName: profile.lastName,
				roleMayor: roleMayor,
				isFollowed: false,
				email:profile.email,
				count: profile.count,
				commonProfiles: profile.commonFollowers,
				university: profile.univName,
			};
		});

		return result;

	}catch(error){
		return [];
	}
};

export const getTeachers = async (page) => {
	try {
		const session = await Cognito.retrieveToken();

		const request = await axios({
			method: 'GET',
			url: `${env.msRecommend}/teacher/${page}`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				env: environment,
			},
		});


		
		let data = request.data.data;

		let result = data.map((profile) => {
			let roleMayor;
			if(profile.mayor !== null){
				switch (profile.role) {
				case 'student':
					roleMayor = 'Estudiante de ' + profile.mayor.toLowerCase();
					break;
				case 'teacher':
					roleMayor = 'Profesor de ' + profile.mayor.toLowerCase();
					break;
				case 'admin':
					roleMayor = 'Administrativo';
					break;
				default:
					roleMayor = 'Usuario Booklick';
					break;
				}
			}else{
				roleMayor = 'Usuario Booklick';
			}

			return {
				id: profile.id,
				uid: profile.uid,
				profileImgUrl: profile.profileImgUrl ? profile.profileImgUrl : 'https://cdn.booklick.net/public/img/profile.png',
				firstName: profile.firstName,
				lastName: profile.lastName,
				roleMayor: roleMayor,
				isFollowed: false,
				email:profile.email,
				count: profile.count,
				commonProfiles: profile.commonFollowers,
				university: profile.univName,
			};
		});

		return result;

	}catch(error){
		console.log(error);
		return error;
	}
};

export const getSameUniversity = async (page) => {
	try {
		const session = await Cognito.retrieveToken();

		const request = await axios({
			method: 'GET',
			url: `${env.msRecommend}/profiles/${page}`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				env: environment,
			},
		});
		
		let data = request.data.data;

		let result = data.map((profile) => {
			let roleMayor;
			if(profile.mayor !== null){
				switch (profile.role) {
				case 'student':
					roleMayor = 'Estudiante de ' + profile.mayor.toLowerCase();
					break;
				case 'teacher':
					roleMayor = 'Profesor de ' + profile.mayor.toLowerCase();
					break;
				case 'admin':
					roleMayor = 'Administrativo';
					break;
				default:
					roleMayor = 'Usuario Booklick';
					break;
				}
			}else{
				roleMayor = 'Usuario Booklick';
			}

			return {
				id: profile.id,
				uid: profile.uid,
				profileImgUrl: profile.profileImgUrl ? profile.profileImgUrl : 'https://cdn.booklick.net/public/img/profile.png',
				firstName: profile.firstName,
				lastName: profile.lastName,
				roleMayor: roleMayor,
				isFollowed: false,
				email:profile.email,
				count: profile.count,
				commonProfiles: profile.commonFollowers,
				university: profile.univName,
			};
		});

		return result;

	}catch(error){
		console.log(error);
		return error;
	}
};

export const getOtherUniversity = async (page) => {
	try {
		const session = await Cognito.retrieveToken();

		const request = await axios({
			method: 'GET',
			url: `${env.msRecommend}/other/${page}`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				env: environment,
			},
		});
		
		let data = request.data.data;

		let result = data.map((profile) => {
			let roleMayor;
			if(profile.mayor !== null){
				switch (profile.role) {
				case 'student':
					roleMayor = 'Estudiante de ' + profile.mayor.toLowerCase();
					break;
				case 'teacher':
					roleMayor = 'Profesor de ' + profile.mayor.toLowerCase();
					break;
				case 'admin':
					roleMayor = 'Administrativo';
					break;
				default:
					roleMayor = 'Usuario Booklick';
					break;
				}
			}else{
				roleMayor = 'Usuario Booklick';
			}

			return {
				id: profile.id,
				uid: profile.uid,
				profileImgUrl: profile.profileImgUrl ? profile.profileImgUrl : 'https://cdn.booklick.net/public/img/profile.png',
				firstName: profile.firstName,
				lastName: profile.lastName,
				roleMayor: roleMayor,
				isFollowed: false,
				email:profile.email,
				count: profile.count,
				commonProfiles: profile.commonFollowers,
				university: profile.univName,
			};
		});

		return result;

	}catch(error){
		console.log(error);
		return error;
	}
};

export const getCommonProfiles = async (emailFollow) => {
	try {
		const session = await Cognito.retrieveToken();

		const request = await axios({
			method: 'GET',
			url: `${env.msRecommend}/common/${emailFollow}`,
			headers: {
				'Authorization': session.idToken.jwtToken,
			},
		});
		
		let result = request.data.data;

		return result;

	}catch(error){
		console.log(error);
		return error;
	}
};