
export const terms = 'Al hacer click en crear o "agregar a" acepto que el contenido que estoy cargando a Booklick es legal, respeta la propiedad intelectual y que, de no ser de mi propiedad intelectual, lo he citado correctamente.';
export const extendedTerms = `
Declaraciones y garantías del Usuario
Con la aceptación de los presentes términos y condiciones garantizas que, con respecto a cualquier Contenido de Usuario que publiques en Booklick:
(1) Eres el propietario o tienes el derecho de publicar dicho Contenido de
Usuario. 
(2) Has citado al autor y/o la fuente de la propiedad intelectual del contenido. 
(3) Dicho Contenido de Usuario o su uso por parte de Booklick, no infringe los Convenios o cualquier otro derecho establecido en los Lineamientos del Usuario, la ley aplicable o la propiedad intelectual, publicidad, personalidad u otros derechos de terceros.
(4) Declaras que el Contenido de Usuario, no implica afiliación o respaldo de tu parte o de tu Contenido de Usuario por parte de Booklick o cualquier entidad o persona sin el consentimiento expreso por escrito de Booklick o dicha persona o entidad.
(5) No publicarás, total o parcialmente, sin autorización previa y expresa del
titular del derecho.
(6) No efectuarás compendios, mutilación o transformación, sin autorización
previa o expresa del titular de la obra.
(7) No adelantarás la reproducción sin autorización previa y expresa del titular,
entiéndase por fuera de Booklick y con fines de lucro.
(8) No adelantarás actos de representación, ejecución o exhibición públicamente, entiéndase por fuera de Booklick y con fines de lucro.
(9) No efectuarás el alquiler o comercialización, sin autorización previa y expresa del titular de los derechos patrimoniales correspondientes, entiéndase por fuera de Booklick y con fines de lucro.
(10) No efectuarás retransmisión, fijación, reproducción, sin autorización previa y expresa del titular, las emisiones de los organismos de radiodifusión.
(11) No adelantarás recepción, difusión o distribución, sin autorización previa y expresa del titular, las emisiones de la televisión por suscripción.
En caso de infracción de alguna de las anteriores manifestaciones como Usuario, estás de acuerdo en que, si alguien hace una reclamación contra Booklick relacionada con el Contenido de Usuario que publicas, en la medida en que lo permita la legislación local, indemnizarás y mantendrás libre a Booklick de toda responsabilidad frente a todos los daños, pérdidas y gastos (incluyendo honorarios y costos de abogado razonables) derivados de dicha reclamación.
`;

export const termLastDate = '17/09/2020';
export const termsBooklick = `
1.1 Definiciones: 
Autor: Persona que ha producido alguna obra científica, literaria o artística. 
Obras: Toda creación intelectual original de naturaleza artística, científica o literaria, susceptible de ser divulgada o reproducida en cualquier forma. 
Contenido del Usuario: Contenido cargado por el Usuario que se encuentra debidamente autorizado al ser de acceso libre, o por contar con autorización previa del autor, la Ley, o la INSTITUCIÓN. incluye cualquier contenido publicado en la Comunidad de Booklick de la INSTITUCIÓN . 
Derechos de Autor: Hace referencia a las normas que reconocen al autor de una obra intelectual o artística el derecho para autorizar su reproducción y participar en los beneficios que esta genere. 
Uso indebido: omisión de citas, cualquier evento de venta, arrendamiento, alquiler, reproducción, traducción, adaptación no autorizada por el autor de la obra, que sea adelantada por un usuario de la plataforma. 
Usuarios: Docentes, estudiantes, personal vinculado a la INSTITUCIÓN, terceros aliados de Booklick, terceros aliados de la INSTITUCIÓN, que acceden con las credenciales suministradas por la INSTITUCIÓN para acceder a la plataforma Booklick 
Credenciales: Usuario y contraseña entregados por la INSTITUCIÓN , que garantiza la identidad del usuario de la plataforma. 
INSTITUCIÓN: Institución de educación superior, INSTITUCIÓN , institución de educación básica primaria, media y bachillerato, o cualquier entidad pública o privada contratante de la plataforma Booklick. 
Plataforma: Se entiende como Booklick. 
Derechos morales: Conjunto de derechos irrenunciables e inalienables, a favor del autor o creador de una obra, que no admiten cesión o renuncia. 
Derechos patrimoniales: Conjunto de derechos pecuniarios sobre el uso y goce, a favor del autor o creador de una obra, que admiten cesión o transferencia. 
1.2 Introducción 
Te damos la Bienvenida a Booklick, la plataforma digital de uso privado, diseñada para compartir conocimiento por medio de intereses de tipo académico relacionado con contenido previamente disponible en tu INSTITUCIÓN. Booklick no es propietario del contenido disponible en la plataforma, por lo que el mismo no se encuentra habilitado para su venta o reproducción. Así mismo, el acceso a los recursos que puedes encontrar como pre- cargados, se rigen por el convenio establecido entre tu institución educativa y los proveedores de recursos bibliográficos, por lo que se encuentra prohibido el uso indebido del material académico de la plataforma. 
Por lo anterior, el contenido al que accederás en la plataforma, como usuario o miembro de la INSTITUCIÓN, se encuentra protegido por los Derechos de Autor, como consecuencia de ello, al usar la plataforma deberás: 
1. Citar el contenido de las obras disponibles en la plataforma, de acuerdo a los estándares académicos de la INSTITUCIÓN, para prevenir cualquier forma de plagio. 2. No reproducir las obras suministradas por la Biblioteca de la INSTITUCIÓN por 
cualquier forma o procedimiento, por fuera de la plataforma. 3. No comunicar por fuera de la plataforma las palabras, signos, sonidos o las imágenes relacionados con las obras disponibles en la plataforma por cualquier medio, sin autorización. 4. No adelantar la distribución pública de ejemplares o copias de la obra 
mediante venta, arrendamiento o alquiler. 5. No adelantar traducciones, adaptaciones, arreglos u otra transformación de 
obras, de forma pública y con fines comerciales. 
En caso de omitir cualquiera de las anteriores consideraciones y prohibiciones, Booklick no será responsable por la vulneración de los derechos patrimoniales ni morales de autor, por lo tanto, dichas infracciones, así como el debido uso del contenido u obras disponibles en la plataforma recaerá de forma exclusiva en los usuarios de la misma. 
Cuando cargas contenido de tu autoría en Booklick, en calidad de usuario, deberás tener en cuenta que en los términos de la Ley 23 de 1982 y la decisión 351 de 1992 de la CAN, la titularidad originaria (derechos morales) está en tu cabeza, por otra parte, los derechos patrimoniales derivados de dichas obras tendrán el mismo tratamiento derivado de la reglamentación que exista en la INSTITUCIÓN y los contratos suscritos con los usuarios vinculados a dicha INSTITUCIÓN. 
Recuerda que en lo relacionado con los derechos morales de autor: a)El autor está facultado para reivindicar en todo momento la paternidad de la obra; b)La protección del derecho de autor recae sobre la obra como expresión del espíritu del autor y no se protegen ideas que son fuente de creación. 
Respecto del contenido relacionado con la publicación de tesis de grado y trabajos de investigación que adelanten los usuarios de la plataforma, o que, obedezcan a contenido previamente publicado o suministrado por la INSTITUCIÓN, Booklick no será responsable por las afectaciones que se puedan llegar a presentar, en los trámites de registro de patentes o diseños ante las autoridades competentes, cuando el contenido sea extraído de la plataforma. 
Finalmente, con la aceptación de los términos y condiciones, reconoces que Booklick no se hace responsable del contenido que compartes en la plataforma, por lo que, toda controversia respecto de los derechos morales o patrimoniales de dicho contenido, así como las afectaciones que se puedan presentar ante terceros, es de exclusiva responsabilidad del Usuario. 
1.3 Acceso de la Plataforma 
Con la finalidad de que puedas acceder y compartir tus intereses académicos y el contenido de Booklick, deberás cumplir con los siguientes aspectos: 
1. Emplear el usuario y la contraseña proporcionada por tu INSTITUCIÓN, que 
son de uso exclusivo e intransferible. 2. Debes acceder a Booklick con las mismas credenciales que te habilita la 
INSTITUCIÓN a la que perteneces. 
En caso de hacer un uso indebido de las credenciales suministradas por la INSTITUCIÓN , Booklick se reserva el derecho de excluirte de la plataforma e informar a la INSTITUCIÓN. 
1.4 Tratamiento al Contenido de Usuario 
Respecto del Contenido de Usuario que cargues en Booklick, no debes subir a la Plataforma contenido que no cumpla con este Acuerdo o la ley. Nosotros podemos utilizar sistemas automáticos que analizan el Contenido para detectar incumplimientos y abuso, como spam, software malicioso y contenido ilegal. 
1.4.1 Autorizaciones para Booklick 
Respecto del contenido que cargas, con la finalidad de que puedas disfrutar de las funcionalidades de la plataforma y accedas al contenido que suben otros usuarios, deberás autorizar a Booklick de forma mundial, no exclusiva, sin sometimiento al pago de regalías, para sub licenciar, transferir y usar ese Contenido, lo que incluye la posibilidad de promocionar y re-distribuir el Servicio, parcialmente o en su totalidad. 
1.4.2 Autorizaciones para otros Usuarios. 
Con los presentes términos y condiciones, otorgas a otros usuarios del Servicio una autorización mundial, no exclusiva, no sujeta al pago de regalías, para acceder a tu Contenido a través de Booklick y para usar ese Contenido, lo que incluye consultarlo y compartirlo ilimitadamente al interior de la Plataforma. Para mayor claridad, esta autorización no otorga ningún derecho o permiso para que un usuario utilice su Contenido de forma independiente del Servicio. 
1.4.3 Duración de la autorización 
La vigencia del Contenido de Usuario en la nube, se mantendrá por el tiempo que comercialmente estime Booklick, después de que bajes o borres el Contenido de Usuario de la Plataforma. A su vez, con los presentes Términos y Condiciones, reconoces y aceptas que Booklick puede retener en sus servidores copias del contenido que subas y que posteriormente se bajen o sean borrados. 
1.4.4 Eliminación del Contenido por parte de Booklick 
Si creemos razonablemente que tu Contenido incumple este Acuerdo o que puede provocar perjuicios a Booklick, a otros usuarios o la INSTITUCIÓN, eliminaremos ese Contenido a nuestra discreción. 
Booklick te notificará, así como a la INSTITUCIÓN sobre el motivo de nuestra acción, a menos que creamos razonablemente que hacerlo: (a) constituiría un incumplimiento de la ley o de las indicaciones de una autoridad de aplicación de la ley, o expondría a Booklick o a nuestros Afiliados a una posible responsabilidad legal de alguna otra forma; (b) pondría en riesgo una investigación o la integridad o el funcionamiento del Servicio; o (c) causaría daño a algún usuario, a otro tercero, a Booklick o a nuestros Afiliados. 
1.4.5 Vínculos de terceros 
Booklick puede contener vínculos a sitios web y servicios en línea de terceros que no son propiedad de Booklick ni están bajo su control, por lo que es exclusiva responsabilidad del Usuario hacer uso debido de dicha funcionalidad . El usuario deberá cumplir las condiciones y la política de privacidad de cada sitio web y servicio en línea de terceros que visite y referencie. 
1.5 Consideraciones especiales sobre uso de lenguaje abusivo- publicación de 
contenido sensible 
Los usuarios de Booklick pueden publicar, cargar o contribuir con contenido a la Plataforma que puede incluir, por ejemplo, imágenes, texto, mensajes, información, títulos de listas de reproducción, descripciones y compilaciones, y/u otros tipos de contenido, considerado como “Contenido de Usuario”. 
Cuando aceptas los presentes términos y condiciones, te comprometes a no emplear lenguaje abusivo, ofensivo, peyorativo, o adelantar comportamientos que afecten los derechos fundamentales de los demás usuarios de la plataforma. Cualquier vulneración del presente evento será comunicado de forma inmediata a la INSTITUCIÓN y Booklick se reserva el derecho de adelantar la exclusión inmediata del usuario de la plataforma. 
Respecto del contenido que es posible publicar en Booklick, el usuario es el exclusivo responsable de dicho contenido, por lo que, en caso de que se detecte la generación de cualquier contenido sensible, este evento será comunicado de forma inmediata a la INSTITUCIÓN y Booklick se reserva el derecho de adelantar la exclusión inmediata del usuario de la plataforma. 
Booklick puede, pero no tiene la obligación de, controlar, revisar o editar el Contenido de Usuario. En todos los casos, Booklick se reserva el derecho de eliminar o inhabilitar el acceso a cualquier Contenido del Usuario por cualquier motivo, o sin motivo alguno, incluyendo el Contenido de Usuario que, a exclusivo criterio de Booklick, infrinja las disposiciones de los Términos y Condiciones. 
La eliminación o inhabilitación del acceso al Contenido de Usuario se realizará a nuestro exclusivo criterio, y no garantizamos eliminar o inhabilitar el acceso a ningún Contenido de Usuario específico. Eres es el único responsable de todo el Contenido de Usuario que publiques, por otra parte, Booklick tampoco respalda ninguna opinión contenida en ningún Contenido de Usuario. 
La INSTITUCIÓN, tendrá las mismas facultades descritas respecto del Contenido del Usuario, expuestas anteriormente. 
1.6 Procedimiento de exclusión 
Los procedimientos para la exclusión de los usuarios o de su contenido, se someterán a las reglas de la INSTITUCIÓN, al acuerdo entre la INSTITUCIÓN y Booklick o a la ley vigente. 
Booklick podrá invalidar o suspender la cuenta del USUARIO, de acuerdo a la ocurrencia cualquiera de las prohibiciones contenidas en el presente documento, o aquellas prohibidas por ley. 
La medida adoptada, ya sea invalidación o suspensión atenderá a los siguientes criterios: 
Tercer Strike: Entiéndase como el tercer aviso remitido por Booklick al Usuario y a la INSTITUCIÓN. 
Una vez adelantado el Tercer Strike, se generará la invalidación del Usuario, sin perjuicio de la exclusión anticipada, atendiendo a conductas que atenten las normas vigentes, así como la dignidad y derecho de otros usuarios, por lo anterior, Booklick podrá suspender y eliminar tu acceso si: (a) incumples significativa o repetidamente este Acuerdo; (b) se nos exige hacerlo para satisfacer un requisito legal o una orden judicial; o (c) creemos que existe una conducta que genera (o podría generar) responsabilidad o dañar a algún usuario, a un tercero, a Booklick o a nuestros Afiliados. 
1.6.1 Aviso de invalidación o suspensión 
Te notificaremos el motivo por el cual Booklick invalida o suspende tu cuenta, a menos que creamos razonablemente que hacerlo: (a) constituiría un incumplimiento de la ley o de las indicaciones de una autoridad de aplicación de la ley o expondría a Booklick o a nuestros Afiliados a una posible responsabilidad legal de alguna otra forma; (b) pondría en riesgo una investigación o la integridad o el funcionamiento del Servicio; o (c) causaría daño a algún usuario, a otro tercero, a Booklick o a nuestros Afiliados. Si Booklick rescinde su acceso debido a cambios del Servicio, cuando sea razonablemente posible, se le proporcionará tiempo suficiente para exportar el Contenido que tiene en el Servicio. 
1.6.2 Efecto de la suspensión o invalidación de la cuenta 
Si se invalida tu Cuenta de Booklick o se restringe el acceso de esta al Servicio, podrás seguir usando algunos aspectos del Servicio (como la visualización) sin una cuenta, y este Acuerdo seguirá aplicándose a ese uso. Si consideras que tu Cuenta de Booklick se invalidó por error, puedes comunicarte al siguiente correo electrónico info@booklick.co, o al de tu INSTITUCIÓN. 
NOTA: Del inicio de los anteriores procedimientos, así como de la decisión adoptada por Booklick, informará a la INSTITUCIÓN. 
1.7 Procedimiento de reporte 
Primer Strike: Entiéndase como el primer aviso remitido por Booklick al Usuario y a la INSTITUCIÓN. 
Segundo Strike: Entiéndase como el segundo aviso remitido por Booklick al Usuario y a la INSTITUCIÓN. 
Booklick proporcionará una funcionalidad denominada “REPORTAR”, con la finalidad de que los Usuarios, actúen en pro de la protección de los derechos de autor y propiedad intelectual, en caso de que evidencien una posible infracción. 
1.8 Consideraciones especiales sobre citas 
Para Booklick, es importante que tengas en consideración que, de conformidad con la legislación vigente, es permitido citar a un autor transcribiendo los pasajes necesarios, siempre que éstos no sean tantos y seguidos que razonadamente puedan considerarse como una reproducción simulada y sustancial, que redunde en perjuicio del autor de la obra de donde se toman. En cada cita deberá mencionarse el nombre del autor de la obra citada y el título de dicha obra. 
Consideramos citas, sin excluir otros medios, las que se hagan mediante links a contenido institucional pre- cargado, así como los enlaces referenciados por usuarios mediante el empleo de la herramienta “subir link”. 
1.9 Modificaciones de los Términos y Condiciones 
Booklick podrá en cualquier tiempo, efectuar cambios en el Servicio, por motivos legales, normativos, de seguridad o por acuerdos con la INSTITUCIÓN suscriptora del servicio. Para el efecto, te informaremos con anticipación razonable sobre cualquier modificación importante de estos Términos y Condiciones, a su vez, se dará la oportunidad de revisarla, salvo que esas modificaciones estén relacionadas con funciones del Servicio nuevas puestas a disposición de los usuarios o que se trate de modificaciones realizadas por motivos legales, las cuales entrarán en vigor de inmediato, sin previo aviso. Las modificaciones de este Acuerdo sólo se aplicarán en el futuro. Si no está de acuerdo con las condiciones modificadas, debe quitar cualquier Contenido que haya subido al Servicio y discontinuar su uso. 
1.10 Confidencialidad de la información 
Respecto de la confidencialidad de la información, al aceptar los términos y condiciones, eres consciente de los siguientes aspectos: 
1. La información que aquí ingreses es tuya y es confidencial. Esta información será visualizada por Booklick y la INSTITUCIÓN, para uso exclusivo de estadísticas que sirvan para mejorar el servicio. 2. Esta información no será utilizada para fines comerciales con otras entidades 
fuera de las del Convenio, salvo disposición contractual en contrario. 3. El servicio de Booklick, y las interacciones que aquí sucedan es propiedad 
de Booklick. 
4. Todos los logos, marcas de servicio, nombres de dominio y cualquier característica de la marca Booklick son propiedad exclusiva de Booklick o sus licenciantes, por ejemplo, tu institución. 
1.11 Declaraciones y garantías del Usuario 
Con la aceptación de los presentes términos y condiciones garantizas que, con respecto a cualquier Contenido de Usuario que publiques en Booklick: 
(1) Eres el propietario o tienes el derecho de publicar dicho Contenido de Usuario; 
(2) Has citado al autor y/o la fuente de la propiedad intelectual del contenido. 
(3) Dicho Contenido de Usuario o su uso por parte de Booklick, no infringe los Convenios o cualquier otro derecho establecido en los Lineamientos del Usuario, la ley aplicable o la propiedad intelectual, publicidad, personalidad u otros derechos de terceros. 
(4) Declaras que el Contenido de Usuario, no implica afiliación o respaldo de tu parte o de tu Contenido de Usuario por parte de Booklick o cualquier entidad o persona sin el consentimiento expreso por escrito de Booklick o dicha persona o entidad. 
(5) No publicarás, total o parcialmente, sin autorización previa y expresa del 
titular del derecho. 
(6) No efectuarás compendios, mutilación o transformación, sin autorización 
previa o expresa del titular de la obra. 
(7) No adelantarás la reproducción sin autorización previa y expresa del titular, 
entiéndase por fuera de Booklick y con fines de lucro. 
(8) No adelantarás actos de representación, ejecución o exhibición 
públicamente, entiéndase por fuera de Booklick y con fines de lucro. 
(9) No efectuarás el alquiler o comercialización, sin autorización previa y expresa del titular de los derechos patrimoniales correspondientes, entiéndase por fuera de Booklick y con fines de lucro. 
(10) No efectuarás retransmisión, fijación, reproducción, sin autorización previa y expresa del titular, las emisiones de los organismos de radiodifusión. (11) No adelantarás recepción, difusión o distribución, sin autorización previa y expresa del titular, las emisiones de la televisión por suscripción. 
En caso de infracción de alguna de las anteriores manifestaciones como Usuario, estás de acuerdo en que, si alguien hace una reclamación contra Booklick relacionada con el Contenido de Usuario que publicas, en la medida en que lo permita la legislación local, indemnizarás y mantendrás libre a Booklick de toda responsabilidad frente a todos los daños, pérdidas y gastos (incluyendo honorarios y costos de abogado razonables) derivados de dicha reclamación. 
1.12 Resolución de Conflictos 
En caso de que se presenten diferencias entre las PARTES con ocasión del uso de la plataforma, la ley aplicable y vigente será de la República de Colombia. 
A su vez, las Partes acuerdan intentar arreglar primero dichos asuntos entre ellas; para tal efecto, acudirán a una primera instancia conformada por los representantes legales de las PARTES, quienes tratarán de resolver los puntos del conflicto, en un término de treinta (30) días calendario contados a partir de la fecha en que se ponga en conocimiento la diferencia, término que podrá prorrogarse de mutuo acuerdo por un lapso igual y por una sola vez. 
Si a pesar de lo anterior el desacuerdo persiste o por alguna razón no es posible llevar a cabo el arreglo directo, lo cual se demostrará con la comunicación escrita remitida a la otra parte, dicha controversia o diferencia relativa a este contrato, se resolverá por un Tribunal de Arbitramento presentado ante el Centro de Conciliación y Arbitraje FERNANDO HINESTROSA, como institución vinculada a la oficinas nacionales de Derecho de Autor y Derechos Conexos (Dirección Nacional de Derecho de Autor) en Bogotá D.C., el cual estará sujeto a sus reglamentos y al procedimiento allí contemplado, de acuerdo con las siguientes reglas: 
a. El Tribunal estará integrado por: un (1) árbitro designado por las partes de común acuerdo. En caso de que no fuere posible, el árbitro será designado por el Centro de Conciliación y Arbitraje FERNANDO HINESTROSA, a solicitud de cualquiera de las partes. b. El Tribunal decidirá en derecho. c. El Tribunal sesionará en las instalaciones del Centro de Conciliación y Arbitraje FERNANDO HINESTROSA, en Bogotá D.C.; d. La secretaría del Tribunal estará integrada por un miembro de la lista oficial de secretarios del Centro de Conciliación y Arbitraje mencionado e. Los costos del Tribunal de Arbitramento serán asumidos por la parte vencida; v) El Tribunal deliberará en las instalaciones del Centro de Conciliación y Arbitraje FERNANDO HINESTROSA.
`;