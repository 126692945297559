import React, { useEffect, useMemo, useState } from 'react';
import styles from './styles/autoComplete.scss';
import {
	iconSearchGrey
} from '../../../utils/constans';

const AutoComplete = ({
	selectedItem,
	items,
	placeholder,
	arrow,
	disabled,
	alertMessage
}) => {
	const [openDrop, setOpenDrop] = useState(false);
	const [search, setSearch] = useState('');

	useEffect(() => {
		if(search && filterItems) {
			setOpenDrop(true);
		} else {
			setOpenDrop(false);
		}
	}, [items, filterItems,search]);

	const filterItems = useMemo(
		() =>
			items.filter((item) => {
				return item.name
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
					.toLowerCase()
					.includes(
						search
							.normalize('NFD')
							.replace(/[\u0300-\u036f]/g, '')
							.toLowerCase(),
					);
			}),
		[items, search],
	);

	return (
		<>
			{openDrop && (<div className={styles.closeDrop} onClick={() => setOpenDrop(false)}/>)}
			<div className={styles.container}>
				<div className={alertMessage ? `${styles.input_search} ${styles.alertBorderNew}` : disabled ? `${styles.input_search} ${styles.disabledColor}` : styles.input_search}>
					<img className={disabled ? `${styles.iconSearch} ${styles.disabledColor}` : styles.iconSearch} src={iconSearchGrey} />
					<input 
						id='inputSearch'
						data-test="inputSearch"
						type='input' 
						placeholder={placeholder}
						onChange={(event) => setSearch(event.target.value)}
						onFocus={() => setOpenDrop(true)}
						disabled={disabled}
						className={disabled ? styles.disabledColor : null}
					/>
					<img src={arrow} onClick={()=> setOpenDrop(!openDrop)}/>
				</div>
				{openDrop && (
					<div className={styles.dropDownContainer} >
						{filterItems.map((item) => {
							return(
								<div
									id={item.id}
									key={item.id}
									data-test={item.id}
									className={styles.itemDropDown} 
									onClick={() => selectedItem(item)}
								>
									<div className={styles.itemInfo}>
										<div className={styles.itemImage}>
											{item.logo ? <img className={styles.image} src={item.logo} alt="icon"/> : null}
										</div>
										<h2 className={styles.itemName}>{item.name}</h2>
									</div>
								</div>
							);
						})}
					</div>
				)
				}
			</div>
		</>
	);

};


export default AutoComplete;