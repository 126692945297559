import React, { useState } from 'react';
import { ClapSpinner } from 'react-spinners-kit';
import styles from './styles/contextMenu.scss';
const SubMenuAddBooklist = ({ booklist, handleAddBooklists }) => {
	const [loading, setloading] = useState(false);
	return (
		<div key={booklist.id} className={styles.gridSub} >
			<h3
				className={styles.h3sub}
				style={{display:'flex'}}
				onClick={()=>{
					setloading(true);
					handleAddBooklists();
				}}
			>
				{booklist.title} {loading && <ClapSpinner size={10} color="var(--primary-color)"
					frontColor="var(--primary-color)"
					backColor='#F0FBFF'/>}
			</h3>
		</div>
	);
};

export default SubMenuAddBooklist;