import React from "react";
import PopUp from "./PopUp";
import { reportContent } from "../../actions/contentActions";

function ReportContentPopUp({ content, onCancel }) {
  const handleReport = async () => {
    reportContent(content.id).then(() => {
      onCancel();
    });
  };

  return (
    <PopUp
      closePopUp={onCancel}
      style={{
        popUp: {
          maxHeight: "406px",
          maxWidth: "487px",
        },
        popUpContent: {
          margin: "40px",
        },
        button: {
          marginTop: "30px",
          justifyContent: "center",
        },
      }}
      buttons={{
        style: {
          width: "100%",
          height: "50px",
          borderRadius: "8px",
        },
        primary: {
          title: "Reportar",
          type: "primary",
          action: handleReport,
        },
      }}
    >
      <div style={{ width: "100%" }}>
        <div style={{ marginTop: "16px" }}>
          <p style={{ fontSize: "22px", fontWeight: "500" }}>
            ¿Desea reportar este contenido?
          </p>
          <p style={{ fontSize: "18px", fontWeight: "400" }}>
            Este reporte será enviado al equipo de Booklick y, en caso de
            infringir nuestras políticas de uso, podrá ser eliminado de la
            plataforma.
          </p>
        </div>
      </div>
    </PopUp>
  );
}

export default ReportContentPopUp;
