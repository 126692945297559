import { useState } from 'react';

const useFormFields = (initialState) => {
	const [fields, setValues] = useState(initialState);

	const handleOnchage = (value, field) => {
		setValues((prevFields) => {
			return {
				...prevFields,
				[field]: value,
			};
		});
	};

	return {
		fields,
		handleOnchage,
	};
};

export default useFormFields;