import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  cleanInputSearch,
  fetchProfilePerformance,
  loadingProfile,
  logOut,
  onSearchInputChange,
  visitStats,
  advancedSearchNew,
  fetchNotifications,
  fetchCountNotifications,
  fetchCountMessages,
  fetchNotificitionMessages,
  putPlatformNotifications,
  searchNavBar,
  openPopUp,
  environment,
} from "../../actions";
import styles from "./styles/navBar.scss";
import { openProfile } from "../../../helpers/profileRouting";
import PopUpCreate from "../create-source/PopUpCreate";
import AlertMessages from "./AlertMessages";
import io from "socket.io-client";

import Cognito from "../../../helpers/cognito";
import { iconSearchBlack, messageHeader } from "../../../utils/constans";
import { withTranslation } from "react-i18next";
import PopUpContact from "../shared/PopUpContact";

const minimunAmmountOfLetter = 3;

const keyCodeEnter = "Enter";
const iconBtnWhite =
  "https://cdn.booklick.net/public/img/icons/icon-btn-x-white.svg";
const imageProfile = "https://cdn.booklick.net/public/img/profile.png";
const iconUser = "https://cdn.booklick.net/public/img/icons/icon-user-top.svg";
const iconBell =
  "https://cdn.booklick.net/public/img/icons/icon-bell-white.svg";
const iconBellFill = "https://cdn.booklick.net/public/img/icons/fill-bell.svg";
const iconAdd =
  "https://cdn.booklick.net/public/img/icons/stream-solid-white.svg";
const logoBooklick =
  "https://cdn.booklick.net/public/img/Logo_Booklick_White.svg";
const logoUdla = "https://cdn.booklick.net/public/logos/udla.png"
const iconMaintance = "https://cdn.booklick.net/img/Maintance-Screen.gif";
const platformUpdateType = "notification-platform-update";
const messageUpdateType = "notification-message-update";


class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      maintenance: env.maintenance,
      autocomplete: false,
      input: true,
      categoryOpen: false,
      popupCreate: false,
      dropMessage: false,
      alertMessage: false,
      showSearch: false,
      url: null,
      previousSearch: "",
      isContactPopUpVisible: false,
    };

    this.autocomplete = React.createRef();
    this.searchInput = React.createRef();

    try {
      Cognito.retrieveToken().then((session) => {
        this.props.fetchNotifications(session.idToken.jwtToken);
        this.props.fetchCountNotifications(session.idToken.jwtToken);
      });
    } catch (error) {
      console.error(error);
    }
  }

  togglePopUpVisibility = () => {
    this.setState((prevState) => ({
      isContactPopUpVisible: !prevState.isContactPopUpVisible,
    }));
  };

  openCreatePopup = () => {
    this.setState({ popupCreate: !this.state.popupCreate });
  };
  handleClosePopUp(event) {
    this.setState({ popupCreate: !event });
  }
  openDropMessage = () => {
    this.setState({
      dropMessage: !this.state.dropMessage,
      alertMessage: false,
    });
  };

  openAlertMessage = async () => {
    if (
      !this.state.alertMessage &&
      this.props.notification.countNotifications !== 0
    ) {
      await this.props.putPlatformNotifications();
      this.props.fetchNotifications();
    }
    this.setState({
      alertMessage: !this.state.alertMessage,
      dropMessage: false,
    });
  };

  closePopup = (name, value) => {
    this.setState({ [name]: value });
  };

  openSearch = () => {
    this.setState({ showSearch: true });
  };

  closeSearch = () => {
    this.setState({ showSearch: false });
  };

  componentDidMount() {
    //this.props.fetchEnvironment();
    if (this.state.maintenance) this.props.logOut();
    if (!this.props.profile.profile) {
      this.props.fetchProfilePerformance();
    }
    if (!window.localStorage.getItem("reset")) {
      window.localStorage.clear();
      window.localStorage.setItem("reset", "true");
    }
    document.addEventListener("mousedown", this.handleClickOutside);
    const searchParams = new URLSearchParams(this.props.location.search);
    const term = searchParams.get("term");
    if (term && term.length >= minimunAmmountOfLetter) {
      this.props.onSearchInputChange(term);
      this.props.advancedSearchNew({ term }, true);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.autocomplete &&
      !this.autocomplete.current.contains(event.target)
    ) {
      this.setState({ autocomplete: false });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.search.searchInput !== prevProps.search.searchInput &&
      this.props.search.searchInput.startsWith("{") &&
      !this.state.autocomplete &&
      prevState.input &&
      !prevState.autocomplete
    ) {
      this.setState({ input: false });
    }
    if (this.props.mobileMenu) {
      if (this.props.mobileMenu !== prevProps.mobileMenu) {
        this.setState({
          autocomplete: this.props.mobileMenu,
        });
      }
    }
    const prevSearchParams = new URLSearchParams(prevProps.location.search);
    const currentSearchParams = new URLSearchParams(this.props.location.search);
    const prevTerm = prevSearchParams.get("term");
    const currentTerm = currentSearchParams.get("term");

    if (currentTerm && currentTerm !== prevTerm && currentTerm.length >= minimunAmmountOfLetter) {
      this.props.onSearchInputChange(currentTerm);
      this.props.advancedSearchNew({ term: currentTerm }, true);
    }
  }

  onProfileClick = () => {
    openProfile(
      this.props.profile.profile.data.email,
      this.props.profile.profile.data.id,
      this.props.loadingProfile,
      this.props.history,
      this.props.visitStats
    );
  };

  

  getHeaderButtons = () => {
    const { t } = this.props;
    if (env.maintenance) {
      return (
        <div
          className={styles.navBarBrand}
          onClick={() => this.setState({ maintenance: true })}
        >
          <i className={`fa fa-ghost ${styles.userIcon}`} /> Estamos en
          mantenimiento
        </div>
      );
    }
    {
      /*Profile Auth Start */
    }
    if (this.props.profile.profile) {
      const attributes = this.props.profile.profile.data;
      const image = attributes["profileImgUrl"];
      return (
        <div className={`${styles.gridAuto10} ${styles.relative}`}>
          <div className={styles.gridBtnsTopN}>
              <div onClick={this.togglePopUpVisibility} className={`${styles.contactButton} ${styles.pointer}`}>
                   <div className={`${styles.posRelative} ${styles.pointer}`} >
                       <img src={messageHeader} alt="contact" />
                   </div>
                   <h3 className={styles.fontTop}>{t('contactLibraryNavbarText')}</h3>
              </div>
            <div
              className={styles.btnHomeN}
              datatest="button-create-header"
              onClick={this.openCreatePopup}
            >
              <img className={styles.icon20} src={iconBtnWhite} alt="push" />
              {t("textNavBarCreated")}
            </div>

            <div>
              {this.props.notification.countNotifications !== 0 ? (
                <div
                  className={`${styles.countCircle} ${styles.backgroundWhite}`}
                >
                  {this.props.notification.countNotifications}
                </div>
              ) : undefined}
              <div
                className={`${styles.btnCircle} ${styles.backgroundRed}`}
                onClick={this.openAlertMessage}
              >
                <img
                  className={styles.icon20}
                  src={
                    this.props.notification.countNotifications !== 0
                      ? iconBellFill
                      : iconBell
                  }
                  alt="alert"
                />
              </div>
            </div>

            <div
              onClick={() => this.onProfileClick()}
              className={styles.circleUser}
            >
              <img
                className={styles.imgCircle}
                datatest="img-profile"
                src={image != null ? image : imageProfile}
                alt="test"
              />
            </div>
          </div>

          {this.state.popupCreate ? (
            <PopUpCreate
              id="PopUpCreateNavBar"
              navButton={true}
              showPopUp={this.closePopup}
              datatest={"popup-create"}
              handleClosePopUp={(event) => this.handleClosePopUp(event)}
              {...this.props}
            />
          ) : undefined}
          {this.state.dropMessage ? (
            <>
              <div
                className={styles.closeMenu}
                onClick={() => {
                  this.setState({ dropMessage: false });
                }}
              />
              <AlertMessages
                notifications={this.props.notification}
                isTypeMessage={true}
              />
            </>
          ) : undefined}
          {this.state.alertMessage ? (
            <>
              <div
                className={styles.closeMenu}
                onClick={() => {
                  this.setState({ alertMessage: false });
                }}
              />
              <AlertMessages
                notifications={this.props.notification}
                isTypeMessage={false}
              />
            </>
          ) : undefined}
        </div>
      );
    } else {
      return (
        <div className={`${styles.gridAuto10} ${styles.relative}`}>
          <div
            className={styles.navBarBrand}
            data-test={"login-button"}
            onClick={() => this.props.openPopUp(true)}
          >
            <div className={styles.gridAuto10}>
              <img className={styles.icon20} src={iconUser} alt="user" />
              {t("textNavBarLogin")}
            </div>
          </div>
        </div>
      );
    }
  };
  
  renderAddContentButton = () => {
    const { location } = this.props.history;
    if (this.props.profile.profile) {
      return (
        <Link
          className={`${
            location.search === "?add"
              ? styles.addContentOpen
              : styles.addContent
          }`}
          to="/?add"
        >
          <img className={styles.icon34} src={iconAdd} alt="hamb" />
          {/*<i className="fas fa-plus"/>*/}
        </Link>
      );
    }
  };

  renderBackground = () => {
    if (env.backgroundIESColors) {
      return <div className={styles.rotateGradient} />;
    }
    return <div className={styles.rotateGradient} />;
  };

  handleKeyDown = (event) => {
    // Search
    if (event.key === keyCodeEnter) {      
        if (this.props.location.pathname.includes("search") && this.props.search.searchInput == this.state.previousSearch 
            || this.props.search.searchInput.length < minimunAmmountOfLetter){
            return;
        } else {
            this.setState({ previousSearch: this.props.search.searchInput });
        }
        this.setState({ autocomplete: false });

        const searchTerm = this.props.search.searchInput;
        this.props.advancedSearchNew({ term: searchTerm }, true);
        
        this.props.history.push({
          pathname: `/search`,
          search: `?term=${encodeURIComponent(searchTerm)}`,
        });
    } else if (event.keyCode === 27) {
        this.props.cleanInputSearch();
        if (this.props.location.pathname.includes("search")) {
            this.props.history.push({
              pathname: `/search`,
              search: `?term=${encodeURIComponent(searchTerm)}`,
            });
        }
    }
};

  // Autocomplete
  searchTerm = (term) => {
    document.title = `${term} - Buscar en Booklick`;
    const { history } = this.props;
    this.props.onSearchInputChange(term);
    this.props.advancedSearchNew(
      { term: term },
      true,
    );
    history.push({
      pathname: `/search`,
      search: `?term=${encodeURIComponent(term)}`,
    });
  };

  renderAutocomplete = () => {
    const { t } = this.props;
    if (this.state.autocomplete) {
      return (
        <>
          {this.props.search.autocomplete.map((word) => (
            <div onClick={() => this.searchTerm(word)} key={word}>
              <h3 className={styles.h3Find}>
                {word.replace(word.split(this.props.search.searchInput)[1], "")}
              </h3>
              <mark>{word.split(this.props.search.searchInput)[1]}</mark>
            </div>
          ))}
          <div
            onClick={() => {
              this.props.mobileMenu
                ? this.props.handleSearchBarMobile(false)
                : null;
              this.props.searchNavBar();
              this.props.history.push({ pathname: `/search` });
            }}
            key={"Advance Search"}
            style={{ cursor: "pointer" }}
          >
          </div>
        </>
      );
    }
  };

  renderEquation = () => {
    if (!this.state.input) {
      const { advancedSearch } = this.props.search;
      return (
        <div
          className={styles.equation}
          onClick={() => {
            this.setState({ input: true });
            this.searchInput.focus();
          }}
        >
          {"{"}
          {Object.keys(advancedSearch).map((key) => (
            <>
              <span className={styles.category}>
                {advancedSearch[key].category}
              </span>
              :<span className={styles.term}>{advancedSearch[key].term}</span>
              <span className={styles.operator}>
                {advancedSearch[key].operator || ""}
              </span>
            </>
          ))}
          {"}"}
        </div>
      );
    }
  };

  render() {
    const { t } = this.props;
    const categories = [
      "Urgencias",
      "Cirugía General",
      "Dermatología",
      "Pediatría",
      "Anestesia",
      "Farmacia",
      "Imágenes Doagnósticas",
      "Gastroenterología",
      "Cardiología",
      "Psiquiatria",
      "Infectología",
      "Ginecología y Obstetricia",
      "Oftalmología",
      "Medicina Interna",
      "Oncología",
      "Ortopedia",
      "Neumología",
      "Urología",
      "Endocrinología",
      "Enfermería",
      "Nutrición",
      "Ortopedia",
      "Cirugía Estética",
    ];
    return (
      <>
        <nav className={styles.navBarTop}>
          <div className={styles.logoPos}>
            <Link
              className={styles.navBarBrand}
              to="/"
              data-test={"home-button"}
            >
              
              {environment == "udla" ? (
                <img
                  src={logoUdla}
                  alt="Logo"
                  className={styles.imgBrand}
                />
              ) : (
                <img
                  src={logoBooklick}
                  alt="Logo"
                  className={styles.imgBrand}
                />
              )}
            </Link>
          </div>

          <div
            className={
              this.props.mobileMenu === true
                ? `${styles.searchBar} ${styles.maxSearch} ${styles.searchBarMobileMenu}`
                : `${styles.searchBar} ${styles.maxSearch}`
            }
          >
            <div
              className={
                this.props.mobileMenu === true
                  ? `${styles.carouselContainer} ${styles.carouselContainerMobile}`
                  : styles.carouselContainer
              }
            >
              <div className={`${styles.contentContainer}`}>
                <div className={styles.search}>
                  {this.renderEquation()}
                  {this.state.input && (
                    <input
                      id="SearchInput"
                      type="text"
                      className={styles.updateSearch}
                      name="search-term"
                      datatest="search-input"
                      autoComplete="off"
                      placeholder={t("inputSearch")}
                      value={this.props.search.searchInput}
                      onFocus={() => this.setState({ autocomplete: true })}
                      onChange={(event) =>
                        this.props.onSearchInputChange(event.target.value)
                      }
                      onKeyDown={this.handleKeyDown}
                      ref={this.searchInput}
                    />
                  )}

                  <div className={styles.posSearch}>
                    <img
                      className={styles.icon25}
                      src={iconSearchBlack}
                      alt="search"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={styles.autocomplete
              }
              ref={this.autocomplete}
            >
              {this.renderAutocomplete()}
            </div>
          </div>

          <div className={styles.navBarContent}>
            <div
              className={`${styles.leftNavBarButtons} ${styles.deleteTopPadding}`}
              style={!this.props.profile.profile ? {} : { paddingTop: "2vw" }}
            >
              {this.renderAddContentButton()}
              {this.getHeaderButtons()}
            </div>
          </div>
          {this.state.maintenance && (
            <Popup close={() => this.setState({ maintenance: false })}>
              <img src={iconMaintance} />
            </Popup>
          )}

          {this.state.categoryOpen && (
            <div className={styles.menuCategory}>
              {categories.map((category) => (
                <div
                  className={styles.buttonCategory}
                  onClick={() => {
                    this.searchTerm(category);
                    this.setState({ categoryOpen: !this.state.categoryOpen });
                  }}
                >
                  {category}
                </div>
              ))}
            </div>
          )}


        </nav>

        {this.state.isContactPopUpVisible && (
          <PopUpContact contactObject={env.contact || {}} onClose={this.togglePopUpVisibility} />
        )}
      </>
    );
  }
}

function mapStateToProps({ profile, search, notification }) {
  return { profile, search,  notification };
}

export default withRouter(
  connect(mapStateToProps, {
    loadingProfile,
    fetchProfilePerformance,
    cleanInputSearch,
    onSearchInputChange,
    logOut,
    visitStats,
    advancedSearchNew,
    fetchNotifications,
    fetchCountNotifications,
    fetchCountMessages,
    fetchNotificitionMessages,
    putPlatformNotifications,
    searchNavBar,
    openPopUp,
  })(withTranslation()(NavBar))
);
