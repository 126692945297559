import {AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool} from 'amazon-cognito-identity-js';

export const COGNITO_LOGGED_IN = 'cognitoLoggedIn';
export const COGNITO_CHANGE_PASSWORD = 'cognitoChangePassword';

class Cognito {

	static register(username, identityId) {
		return new Promise((resolve, reject) => {
			const pool = Cognito._getUserPool();
			const attributeList = [];

			const dataEmail = {
				Name : 'email',
				Value : username
			};
			const attributeEmail = new CognitoUserAttribute(dataEmail);
			attributeList.push(attributeEmail);

			pool.signUp(username, identityId, attributeList, attributeList, function(err, result){
				if (err) {
					reject(err);
				}
				resolve(result);
			});
		});
	}

	static login(username, identityId) {
		return new Promise((resolve, reject) => {
			const authenticationDetails = Cognito._getAuthenticationDetails(username, identityId);
			const cognitoUser = Cognito._getCognitoUser(username);
			cognitoUser.authenticateUser(authenticationDetails, {
				onSuccess: (result) => resolve({ status: COGNITO_LOGGED_IN, data:result }),
				onFailure: (err) => reject(err),
				newPasswordRequired: (userAttributes) => {
					resolve({
						status: COGNITO_CHANGE_PASSWORD,
						data: Cognito._newPasswordChallenge(cognitoUser, userAttributes)
					});
				}
			});
		});
	}

	static retrieveCurrentUser() {
		return new Promise((resolve, reject) => {
			const cognitoUser = Cognito._retrieveCurrentCognitoUser();
			if (cognitoUser != null) {
				cognitoUser.getSession(function (err) {
					if (err) {
						reject(err);
					}

					cognitoUser.getUserAttributes(function (err, attributes) {
						if (err) {
							reject(err);
						} else {
							resolve(attributes);
						}
					});
				});
			} else {
				reject('No CognitoUser Found');
			}
		});
	}

	static retrieveToken () {
		return new Promise((resolve, reject) => {
			const cognitoUser = Cognito._retrieveCurrentCognitoUser();
			if (cognitoUser != null) {
				cognitoUser.getSession((err, session) => {
					if (err) {
						reject(err);
					}
					resolve(session);
				});
			} else {
				reject('No CognitoUser Found');
			}
		});
	}

	static singOut() {
		const cognitoUser = Cognito._retrieveCurrentCognitoUser();
		cognitoUser && cognitoUser.signOut();
	}

	static _retrieveCurrentCognitoUser() {
		const userPool = this._getUserPool();
		const lastUserKey = 'CognitoIdentityServiceProvider.' + userPool.getClientId() + '.LastAuthUser';
		const lastAuthUser = window.localStorage[lastUserKey];
		if (lastAuthUser) {
			const cognitoUser = {
				Username: lastAuthUser,
				Pool: userPool,
			};
			return new CognitoUser(cognitoUser);
		}
		return null;
	}

	static _newPasswordChallenge(cognitoUser, userAttributes) {
		delete userAttributes.email_verified;
		delete userAttributes.phone_number_verified;
		return (newPassword) => {
			return new Promise((resolve, reject) => {
				cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
					onSuccess: (result) => resolve({ status: COGNITO_LOGGED_IN, data:result }),
					onFailure: (err) => reject(err),
				});
			});
		};
	}

	static _getAuthenticationDetails(username, password) {
		const authenticationData = {
			Username : username,
			Password : password,
		};
		return new AuthenticationDetails(authenticationData);
	}

	static _getCognitoUser(username) {
		const userData = {
			Username: username,
			Pool: Cognito._getUserPool()
		};
		return new CognitoUser(userData);
	}

	static _getUserPool() {
		const poolData = {
			UserPoolId: env.cognitoUserPool,
			ClientId: env.cognitoClientId
		};
		return new CognitoUserPool(poolData);
	}

	static async verify(email, code) {
		return new Promise((resolve, reject) => {
			Cognito._getCognitoUser(email).confirmRegistration(code, true, (err, result) => {
				if (err) {
					reject(err);
				}
				resolve(result);
			});
		});
	}
	static signIn(email, password) {
		return new Promise((resolve, reject) => {
			Cognito._getCognitoUser(email).authenticateUser(Cognito._getAuthenticationDetails(email, password), {
				onSuccess: (result) => resolve({ status: COGNITO_LOGGED_IN, data:result }),
				onFailure: (err) => reject(err)
			});
		});
	}
	static resendCode(username) {
		return new Promise((resolve, reject) => {
			Cognito._getCognitoUser(username).resendConfirmationCode((err) =>{
				if (err) {
					reject(err);
				}
				resolve();
			});
		});
	}

	static recoverPassword(username) {
		return new Promise((resolve, reject) => {
			Cognito._getCognitoUser(username).forgotPassword({
				onSuccess: (result) => resolve(result),
				onFailure: (err) => reject(err)
			});
		});
	}
	static async changePassword(email, confirmationCode, password) {
		return new Promise((resolve, reject) => {
			Cognito._getCognitoUser(email).confirmPassword(confirmationCode, password, {
				onSuccess: (result) => resolve(result),
				onFailure: (err) => reject(err)
			});
		});
	}

}

export default Cognito;
