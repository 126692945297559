import axios from 'axios';

import {
	environment,
} from './index';

import { postEventSearch } from '../../helpers/tracking';

import {redirectNewSearch} from '../../utils/redirectRoutes';

import {
	LOADING,
	LOADING_FILTERS,
	LOADED,
	ERROR,
	ADVANCED_SEARCH,
	ADVANCED_SEARCH_NAVBAR,
	FILTERS,
	CLEAR_ADVANCED_SEARCH,
	NEXT_PAGE,
} from '../types/searchTypes';


import {eventSearchType, LIMIT_SEARCH, DEFAULT_PAGE} from '../../utils/constans';

export const advancedSearchNew =  (data, clear) => async(dispatch, getState) =>{
	dispatch({
		type: LOADING,
		payload: data.term
	});
	if(clear){
		dispatch({
			type: CLEAR_ADVANCED_SEARCH,
		});
	}
	try {
		const { profile } = getState();
		const email = profile.profile ? profile.profile.data.email : '';
		postEventSearch(eventSearchType.termSearch,environment,email,null,null,data.term,null);
		
		let request;
		const redirectSearch = redirectNewSearch();

		if(redirectSearch){
			request = await getSearch(data.term , '',LIMIT_SEARCH, DEFAULT_PAGE);
		}else{
			request = await axios({
				method: 'POST',
				url: `${env.msSearch}/explore`,
				data: {
					env: environment,
					term: data.term,
					author: data.author,
					title: data.title,
					date: data.date,
					editorial: data.editorial,
					limit:LIMIT_SEARCH,
					page:DEFAULT_PAGE,
				}
			});
	
		}
		dispatch({
			type: LOADED,
		});
		postEventSearch(eventSearchType.resultSearch,environment,email);

		return dispatch({
			type: ADVANCED_SEARCH,
			payload: {
				results: request.data,
				term: data.term,
			}
		});
	} catch (error) {
		return dispatch({
			type: ERROR,
			payload: error.message
		});
	}
};

export const filterSearchNew =  (filters, page) => async(dispatch,getState) =>{
	const { searchNew } = getState();
	dispatch({
		type: LOADING_FILTERS,
		payload:true
	});

	let query='';

	if(filters.editorial){
		query = `&editorial=${encodeURIComponent(filters.editorial)}`;
	} 

	if(filters.lang){
		query = query ? `${query}&language=${filters.lang}` : `&language=${filters.lang}`;
	} 

	if(filters.type){
		query = query ? `${query}&type=${filters.type}` : `&type=${filters.type}`;
	}

	
	try {
		const request = await getSearch(searchNew.searchTerm, query, LIMIT_SEARCH, page);
		dispatch({
			type: page > 1 ? NEXT_PAGE : FILTERS,
			payload:request.data
		});
		
	} catch (error) {
		console.error(error);
		dispatch({
			type: LOADING_FILTERS,
			payload:false
		});
	}
};


export const CLEAN_INPUT_SEARCH = 'CLEAN_INPUT_SEARCH';
export const searchNavBar = (clear) => (dispatch) =>{
	if(clear){
		dispatch({
			type: CLEAN_INPUT_SEARCH,
		});
	} else {
		dispatch({
			type: ADVANCED_SEARCH_NAVBAR,
		});
	}

};

const getSearch = async (term,query,limit,page) => {
	try {
		if (term.length > 100) {
            term = term.substring(0, 100);
        }
		const request = await axios({
			method: 'GET',
			url: `${env.msSearchNew}/api/search?term=${term}&size=${limit}${query}&pag=${page}`,
			headers:{
				env: environment
			}
		});
		return request;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

