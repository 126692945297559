import {
	FETCH_NOTIFICATIONS,
	FETCH_MESSAGES,
	FETCH_NOTIFICATIONS_COUNT,
	FETCH_MESSAGES_COUNT,
	LOADING_NOTIFICATIONS
} from '../types/notificiationTypes';

const initialState = {
	loading: false,
	notifications: null,
	messages: null,
	countNotifications: 0,
	countMessages: 0
};

export default (state = initialState, action) => {
	switch (action.type) {
	case LOADING_NOTIFICATIONS:
		return {
			...state,
			loading: true
		};
	case FETCH_NOTIFICATIONS:
		return {
			...state,
			loading: false,
			notifications: action.payload
		};
	case FETCH_MESSAGES:
		return {
			...state,
			loading: false,
			messages: action.payload
		};
	case FETCH_NOTIFICATIONS_COUNT:
		return {
			...state,
			loading: false,
			countNotifications: action.payload
		};
	case FETCH_MESSAGES_COUNT:
		return {
			...state,
			loading: false,
			countMessages: action.payload
		};
	default:
		return state;
	}
};
