import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles/button.scss';
import { ImpulseSpinner } from 'react-spinners-kit';

export const appearances = {
	primary: 'primary',
	secondary: 'secondary',
	red: 'red',
	input: 'input'
};
  
const Button = ({
	appearance,
	action,
	title,
	width,
	height,
	icon,
	materialIcon,
	borderRadius,
	marginRight,
	marginLeft,
	padding,
	loader,
	disabled,
	style
}) => {
	return (
		<div 
			className={appearance === appearances.primary ? styles.btn_blue : 
				appearance === appearances.input ? styles.btn_input : 
					appearance === appearances.red ? styles.btn_red :styles.btn_white 
			}
			style={{
				width,
				height,
				borderRadius,
				marginRight,
				padding,
				marginLeft:loader ? '0 !important' :  marginLeft ? marginLeft : '0 !important',
				opacity: disabled ? 0.25 : 1,
				pointerEvents: disabled ? 'none' : 'initial',
				...style
			}} 
			onClick={action}
		>
			{loader ? (
				<span className={styles.loader}>
					<ImpulseSpinner
						size={40}
						color={appearance === appearances.primary || appearance === appearances.red ? 'white' : 'blue'}
						frontColor={appearance === appearances.primary || appearance === appearances.red ? 'white' : 'blue'}
						backColor={appearance === appearances.primary || appearance === appearances.red ? 'white' : 'blue'}
						loading={loader}
						
					/>
				</span>
			):(
				<>
					{materialIcon ? (
						<span
							className={`material-symbols-outlined ${styles.materialIcon}`}>
							{materialIcon}
						</span>
					) : (
						icon && <img className={`${styles.icon20}`} src={icon} alt='' />
					)}
					<div style={{ marginLeft: !icon && !materialIcon && '0px' }}>{title}</div>
				</>
			)}
			
		</div>
	);
};

Button.propTypes = {
	appearance: PropTypes.oneOf(Object.keys(appearances))
};

export default Button;