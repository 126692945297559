import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import styles from './styles/popupCreate.scss'
import {connect} from 'react-redux';
import {
  putFile,
  saveLink,
  getMetadata,
  loadingProfile,
  fetchAllCategories,
  fetchLinksTypes,
  putTags,
  getLinkTypes,
  fetchMyContent,
  addToSelectedBooklist,
  addDocumentToBooklist,
  submitEditingContentFile,
  submitEditingContent,
  putFileBeforeCreate,
  postDocumentHandler,
  getTags,
  inputChangeContent,
  getContentCategory,
  environment
} from '../../actions';

import { withTranslation } from 'react-i18next';
import { getResources } from '../../../utils/constans';

const iconCamera = "https://cdn.booklick.net/public/img/icons/icon-camera-fill.svg";
const iconDeleteTag = "https://cdn.booklick.net/public/img/icons/icon-delete-white.svg";
const iconPageWhite = "https://cdn.booklick.net/public/img/icons/icon-page-white.svg";

const imageDefault = "https://cdn.booklick.net/public/img/images/mini-02.png";

const enterKey = 13;




class CreateFile extends Component {

  constructor(props) {
    super(props);
    this.state = {
		link: this.props.link,
      previewImage: this.props.item.info.image,
      previewTitle: this.props.item.info.title ,
      previewDescription: this.props.item.info.description,
      categories: null,
      categorieLabel: null,
      categoryId: null,
      dropBlue: false,
      dropWhite: false,
      file: this.props.data,
      imageFile: null,
      tag: null,
      tags: [],
      type: null,
      types: null,
      typeLabel: null,
      loadingData: false,
      alertMessage: false,
      fileData: null,
	  contentId: this.props.contentId
    }
    this.handlePutTag = this.handlePutTag.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount(){
    this.fetchData()
    let input = document.getElementById('hashtag')
    input.addEventListener('keyup', function(event) {
      if(event.keyCode === enterKey) {
        event.preventDefault();
        document.getElementById('tagbutton').click();
        input.value = null
      }
    })
  }

  fetchData = async () => {
    this.setState({ loading: true, error: null})
    try {

      const categoryId = await getContentCategory(this.props.item.id);
      const categories = await this.props.fetchAllCategories();
      const findCategory = categories.filter(category => category.id === categoryId);
      const tags = await getTags(this.state.contentId);
      const categoryLabel = findCategory.length !== 0 ? findCategory[0].label : '';
      this.setState({ 
        tags: tags.map(({ tag }) => tag),
        loading: false,
        categories: categories,
        categoryId,
        categorieLabel: categoryLabel
      })
      
    } catch (error) {
      this.setState({ loading: false, error: error})
  }
  }

  openDropBlue = () => {
    this.setState({ dropBlue: !this.state.dropBlue })
  }
  closeDropBlue = () => {
    this.setState({ dropBlue: false })
  }
  
	openDropWhite=()=>{
		this.setState({ dropWhite:!this.state.dropWhite })
	}
	closeDropWhite=()=>{
		this.setState({ dropWhite:false })
  }
  handleText = event => {
    this.setState({
      [event.target.name]: event.target.value 
    })
  }
  handleTag = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handlePutTag () {
    if(this.state.tag !== null && this.state.tag !== '') {
      this.setState({
        tags: [...this.state.tags, this.state.tag],
        tag: null
      })
      let input = document.getElementById('hashtag')
      input.value = null
	}
  }

  hadleRemoveTag(item) {
    this.setState({
      tags: this.state.tags.filter(element => element !== item)
    })
  }

  handleImageUpload = (file) => {
    this.setState({
      imageFile: file[0]
    })
    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        previewImage: reader.result
      });
    }
    reader.readAsDataURL(file[0])
  }

  handleSubmit = async (id, newBooklist) => {
    try {
      if(!this.state.previewTitle
        || !this.state.previewDescription
        ) {
        this.handleShake()
      } else {

        this.setState({
          loadingData: true
		})
		
		this.props.inputChangeContent('editingTitle', this.state.previewTitle);
		this.props.inputChangeContent('editingDescription', this.state.previewDescription);
		this.props.inputChangeContent('editingCoverFile', this.state.imageFile);
		  
		const sumbitEdit = await this.props.submitEditingContent(this.state.categoryId)
		const postTags = await this.props.putTags(this.props.item.info.id, this.state.tags)
        this.setState({
          loadingData: false
        })
        this.setState({file: null, imageFile: null});
        !newBooklist && this.props.handleClosePopUp(true)
      }
    } catch (error) {
      console.error(error.message)
    }
  }

  handleShake = () =>{
    this.setState({
      alertMessage: true
    })
    setTimeout(
      () => this.setState({
        alertMessage:false
      })
      ,500)
  }

  translateInterestTopics = (name, t) => {
		switch (name) {
			case 'Administración':
				return t('titleInterestTopicsManage')
			case 'Arquitectura':
				return t('titleInterestTopicsArchitecture')
			case 'Arte':
				return t('titleInterestTopicsArt')
			case 'Ciencia':
				return t('titleInterestTopicsScience')
			case 'Ciencias sociales':
				return t('titleInterestTopicsSciencePeople')
		    case 'Derecho':
				return t('titleInterestTopicsLaw')
			case 'Diseño':
				return t('titleInterestTopicsDesign')
			case 'Economía':
				return t('titleInterestTopicsEconomy')
			case 'Gastronomía':
				return t('titleInterestTopicsGastronomy')
		    case 'Ingeniería':
				return t('titleInterestTopicsEnginner')
			case 'Medicina':
				return t('titleInterestTopicsMedicine')
			default:
				return name
    }
  }

  render() {
    const {t} = this.props
    return (
      
      
      <div className={`${styles.gridAdd} ${styles.gridRemove} ${styles.marginBottom}`}>
        <div className={`${styles.gridRow16} ${styles.alignStart}`}>
          <div className={styles.gridInfoAdd}>
            <Dropzone onDrop={this.handleImageUpload}>
              {({ getRootProps, getInputProps }) => (
                <div  {...getRootProps()}>
                  <input {...getInputProps()} />
                  {
                    this.state.previewImage ? 
                    <div className={styles.boxaddpic} >
                      <img 
                        className={styles.fileImgCard} 
                        src={this.state.previewImage} 
                        alt="cover" 
                      />             
                      <div className={`${styles.fileGridRow10} ${styles.marginTop}`}>
                        <div className={styles.circleIcon}>
                          <img className={styles.icon26} src={iconCamera} alt="add pic" />
                        </div>
                        <h3 className={styles.h3infocenter2}>Cambiar imagen</h3>
                      </div>
                    </div>
                    :
                    <div className={this.state.alertMessage && !this.state.previewImage ? `${styles.boxaddpic} ${styles.alertVibration}` : styles.boxaddpic}>
                      <div className={styles.gridRow10}>
                        <div className={styles.circleIcon}>
                          <img className={styles.icon26} src={iconCamera} alt="add pic" />
                        </div>
                        <h3 className={styles.h3infocenter2}>Agregar imagen</h3>
                      </div>
                    </div>
                  }
                </div>
              )}
            </Dropzone>

            <div className={`${styles.gridRow10} ${styles.alignStart}`}>
              <h2 className={styles.h2Title2}>{t('titleEditEntryPersonalization')}</h2>

              {
                this.state.dropBlue ?
                  <div className={styles.closeDropBlue} onClick={this.closeDropBlue}></div>
                  : null
              }
              
              <div className={styles.relative} >
               
                  <div className={this.state.alertMessage && !this.state.categoryId ? `${styles.selectDropBlueStyle} ${styles.alertVibration}` : styles.selectDropBlueStyle} onClick={this.openDropBlue} required>
                    {this.state.categorieLabel === null || this.state.categoryId === null ? t('textCategorieBelongs') :  this.translateInterestTopics(this.state.categorieLabel,t)}
                  </div>
              

                {
                  this.state.dropBlue ?

                    <div className={styles.posArrowDropBlue} onClick={this.closeDropBlue}>
                      <img className={styles.icon26} src={getResources(environment).iconArrowDropUp} alt="drop" />
                    </div> :
                    <div className={styles.posArrowDropBlue} onClick={this.openDropBlue}>
                      <img className={styles.icon26} src={getResources(environment).iconArrowDropDown} alt="drop" />
                    </div>
                }

                {
                  this.state.dropBlue ?
                    <div className={styles.posDropBlue}>
                      <div className={styles.boxDropBlue}>
                        { this.state.categories && this.state.categories.map(item => {
                          return (
                            <div key={item.id} className={styles.itemDropBlue} 
                              onClick={ () => {
                                this.setState({
                                  categoryId: item.id,
                                  categorieLabel:this.translateInterestTopics(item.label,t),
                                  dropBlue: false
                                })
                              }
                                }>
                                {this.translateInterestTopics(item.label,t)}
                            </div>
                          )
                        })
                      }
                      </div>
                     </div>
                    
                    :
                    null
                }

              </div>

              <h2 className={styles.h2Title2}>{t('titleEditEntryKeyWords')}</h2>
              <div className={styles.gridBtn550}>
                <input 
                  type="text" 
                  name='tag' 
                  id='hashtag' 
                  className={this.state.alertMessage && this.state.tags.length === 0 ? `${styles.input} ${styles.alertVibration}` : styles.input} 
                  placeholder={t('titleEditEntryHintListKeywordHint')}
                  patter="(?:\s|^)#[A-Za-z0-9\-\.\_]+(?:\s|$)"
                  onChange={this.handleTag}
                  />
                  <div className={styles.btn550} onClick={this.handlePutTag}>
                    Agregar
                  </div>

              </div>
                <button id='tagbutton' onClick={this.handlePutTag} hidden></button>
              <div className={styles.flexHash}>
                {
                  this.state.tags.length !== 0 ?
                    this.state.tags.map((item, index) => {
                      return (
                        <div key={index} className={styles.btnHash}>
                          {item}
                          <div onClick={() => this.hadleRemoveTag(item)}>
                            <img className={styles.iconDelete} src={iconDeleteTag} alt="delete" />
                          </div>
                        </div>
                      )
                    })
                    : null
                }
              </div>
            </div>

          </div>

          <input 
            name='previewTitle' 
            type="text" 
            className={this.state.alertMessage && !this.state.previewTitle ? `${styles.inputTitle} ${styles.alertVibration}` : styles.inputTitle}
            placeholder={this.props.item.info.title}
            value={this.state.previewTitle ? this.state.previewTitle : null}
            onChange={this.handleText}
            required
          />

          <textarea
          name="previewDescription"
          rows="9"
          cols="1"
          className={this.state.alertMessage && !this.state.previewDescription ? `${styles.inputDescription} ${styles.alertVibration}` : styles.inputDescription}
          placeholder={this.props.item.info.description}
          onChange={this.handleText}
          value={this.state.previewDescription  ? this.state.previewDescription : null}
          required
          >            
          </textarea>
        </div>

        <div className={styles.gridRow10}>

          <div className={styles.space10}></div>

          <div className={`${styles.gridCard} ${styles.none550}`}>
            <div className={styles.boxImgCard}>
              <div className={styles.posIconTop}>
                <img className={styles.icon25} src={iconPageWhite}  alt="video" />
              </div>
              <img 
                className={styles.imgCard} 
                src={
                  this.state.previewImage ? 
                  `${this.state.previewImage}` :
                  imageDefault
                } 
                alt="user" 
              />
            </div>
            <div className={styles.gridInfoCard}>
            <h3 className={styles.fileH3titlecard}>
              {this.state.previewTitle ? this.state.previewTitle : t('titleEditEntryHintTitle')}
            </h3>
            <p className={styles.paragraphCard}>{this.state.previewDescription === null || this.state.previewDescription === '' ? t('titleEditEntryHintDescription') : this.state.previewDescription}</p>
              
              <div className={styles.gridBtnSocial}>
                <div className={styles.gridAuto5}>
                  <img className={styles.icon14} src={getResources(environment).iconLikeActive} alt="like" />
                  <h4 className={styles.h4likes}>150</h4>
                </div>

                <div className={styles.gridAuto5}>
                  <div className={styles.lineGreyVertical}></div>
                  <h4 className={styles.h4likes}>{t('titleEditEntryHintViews')}</h4>
                </div>

                <div className={styles.flexEnd}>
                  <img className={styles.icon20} src={getResources(environment).moreFill} alt="more" />
                </div>

              </div>
            </div>
          </div>

          <div >
            <div className={`${styles.gridRow10} ${styles.justifyContent}`}>
              <div className={styles.btnGreen}  onClick={() => this.handleSubmit(false,false)}>
              {this.state.loadingData ? <div className={`${styles.fileSpinner} ${styles.fileIcon}`} aria-hidden="true"></div> : t('titleEditEntryHintSubmit')}
            </div>
        	</div>
          </div>
        </div>
      </div>
    )
  }

}

function mapStateToProps({ profile }) {
	return { 
    profile,
  };
}

export default connect(mapStateToProps, {
    putFile,
    saveLink,
    getMetadata,
    loadingProfile,
    fetchAllCategories,
    fetchLinksTypes,
    putTags,
    getLinkTypes,
    fetchMyContent,
    addToSelectedBooklist,
    addDocumentToBooklist,
	submitEditingContentFile,
	submitEditingContent,
    putFileBeforeCreate,
	postDocumentHandler,
	inputChangeContent
  })(withTranslation()(CreateFile));
  
 