import React, { useEffect, useState } from 'react';
import { ImpulseSpinner } from 'react-spinners-kit';
import { getFollowersProfile, getFollowingsProfile } from '../../../actions';
import CardProfile from '../../shared/CardProfile';
import LoaderCardProfile from '../../shared/LoaderCardProfile';
import PopUp from '../../shared/PopUp';


const PopUpFollows = ({ closePopUp, showFollowers, showFollowing, email }) => {
	const [follows, setFollows] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loadingMore, setLoadingMore] = useState(false);
	const [page, setPage] = useState(1);

	useEffect(() => {
		setLoading(true);
		fetchData();
	}, []);

	useEffect(() => {
		if (follows && follows.profiles && !loadingMore && follows.hasNextPage) {
			let observerScroll = document.getElementById('observerScroll');
			let intersectionObserver = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting) {
					handleMoreFollows();
				}
			}, {
				rootMargin: '0px 0px 1px 0px',
			});
			intersectionObserver.observe(observerScroll);
		}
	}, [follows , loadingMore]);

	const fetchData = async () => {
		if (showFollowers) {
			const followers = await getFollowersProfile(email, page);
			setFollows(followers);
			setLoading(false);
		}

		if (showFollowing) {
			const followings = await getFollowingsProfile(email, page);
			setFollows(followings);
			setLoading(false);
		}
	};

	const handleMoreFollows = async () => {
		if (follows.hasNextPage && !loadingMore) {
		  setLoadingMore(true);
		  const nextPage = page + 1;
		  try {
				let newFollows;
				if (showFollowers) {
					newFollows = await getFollowersProfile(email, nextPage);
				} else if (showFollowing) {
					newFollows = await getFollowingsProfile(email, nextPage);
				}
				setFollows((prevFollows) => ({
					...newFollows, 
					profiles: [...(prevFollows.profiles || []), ...(newFollows.profiles || [])],
				}));
	  
				setPage(nextPage);
		  } catch (error) {
				console.error('Error fetching more follows:', error);
		  } finally {
				setLoadingMore(false);
		  }
		}
	  };

	return (
		<PopUp
			closePopUp={closePopUp}
			style={{
				zIndex: '5',
				popUp: {
					width: 'fit-content', 
					maxWidth: '720px', 
					maxHeight: '500px' 
				},
				popUpContent: {
					display: 'flex',
					margin: '40px',
					justifyContent: 'center',
				},
				button: {
					marginTop: '40px',
					justifyContent: 'center',
				},
			}}
			buttons={{}}
		>
			<div style={{
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'center',
				rowGap: '10px',
				columnGap: '30px'
			}}>
				{loading ? (
					Array.from({ length: 8 }, (_, index) => <LoaderCardProfile key={index} />)
				) : (
					<div style={{ 
						textAlign: 'center', 
						marginTop: '20px', 
						display: 'flex', 
						flexWrap: 'wrap', 
						justifyContent: 'center'
					}}>
						{(!follows || !follows.profiles || follows.profiles.length === 0) ? (
							<p style={{
								fontFamily: 'Roboto',
								fontSize: '16px',
								fontWeight: 'bold',
							}}>
								{showFollowers ? 'No hay seguidores' : 'No estás siguiendo a nadie'}
							</p>
						) : (
							follows.profiles.map(p => <CardProfile key={p.id} targetProfile={p} />)
						)}
					</div>
				)}

				{loadingMore &&
					<div style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
						<ImpulseSpinner
							size={40}
							color="var(--primary-color)"
							frontColor="#10CFC9"
							backColor='#F0FBFF'
							loading={loading.moreMyBooklist}
						/>
					</div>
				}

				{follows && follows.profiles && follows.hasNextPage && !loadingMore ?
					<div style={{ marginTop: '20px', padding: '5px' }} id='observerScroll' /> : null
				}
			</div>
		</PopUp>
	);
};

export default PopUpFollows;