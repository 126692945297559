import Cognito, {COGNITO_LOGGED_IN} from '../../helpers/cognito';

export const SET_EZPROXY_LOCATION = 'SET_EZPROXY_LOCATION';
export const setEzProxyLocation = (location) => async(dispatch) => {
	// TODO Analizar si se puede borrar el método
	const date = new Date();
	date.setTime(date.getTime() + 7200000);
	localStorage.setItem(SET_EZPROXY_LOCATION, `${date.getTime()}`);
	const queryParams = new URLSearchParams(location);
	if (queryParams.has('session')) {
		const session = queryParams.get('session');
		document.cookie = 'ezproxy=' + session  + '; path=/; Domain=.' + env.iesDomain;
	}
	dispatch({
		type: SET_EZPROXY_LOCATION,
		payload: location,
	});
};

export const cognitoLogin = (pEnv, nUser, nIdentityId) => {
	const userWithOutCheck = (nUser || env.user);
	const user = userWithOutCheck.includes('@') ?
		userWithOutCheck : `${userWithOutCheck}@${env.iesDomain}`;
	const identityId = nIdentityId || env.IdentityId;
	return Cognito.register(user, identityId)
		.then((responseRegister) => {
			return login(user, identityId);
		})
		.catch((error) => {
			return login(user, identityId);
		});
};

function login(user, identityId) {
	return Cognito.login(user, identityId)
		.then((responseLogin) => {
			if (responseLogin.status === COGNITO_LOGGED_IN) {
				return Cognito.retrieveCurrentUser()
					.then(() => true)
					.catch(() => {
						return false;
					});
			}
			return false;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
}
