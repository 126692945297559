import {
  GET_NEW_HOME,
  LOADING_NEW_HOME,
  LOADING_HOME_CONTENTS,
  PAGINATE_HOME,
} from "../types/homeTypes";

const initialState = {
  loadingPage: true,
  loadingContents: false,
  isFilteredSearch: false,
  page: 1,
  error: null,
  contents: [],
  library: null,
  profiles: [],
  loadingFilters: false,
  clearAdvancedSearch: false,
  filters: null,
  hasNextPage: null,
  totalPages: null,
  totalHits: null,
  topIds: [],
  topEmails: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_NEW_HOME:
      return {
        ...state,
        loadingPage: true,
      };
    case PAGINATE_HOME:
      return {
        ...state,
        contents: {
          ...state.contents,
          content: [
            ...state.contents.content,
            ...action.payload.contents.content,
          ],
        },
        page: action.payload.page,
      };
    case LOADING_HOME_CONTENTS:
      return {
        ...state,
        loadingContents: action.payload.loadingContents,
        isFilteredSearch: true,
      };
    case GET_NEW_HOME:
      return {
        ...state,
        loadingPage: false,
        profiles: action.payload.profiles,
        filters: action.payload.contents.filters,
        libraryProfile: action.payload.libraryProfile,
        contents: action.payload.contents,
        isFilteredSearch: false,
      };
    default:
      return state;
  }
};
