import React from 'react';
import styles from './styles/banner.scss'

function Banner({ imageSource, title, description, url }) {    
    return (
        <div className={styles.container} onClick={() => window.open(url)}>
            <img className={styles.image} src={imageSource} alt='' />
            <div className={styles.textContainer}>
                <p className={styles.title}>{title}</p>
                <p className={styles.description}>{description}</p>                
            </div>
        </div>
    );
}

export default Banner;
