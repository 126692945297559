import React, {Component} from 'react';
import Cognito from '../../../helpers/cognito';
import { getProfiles } from '../../actions';
import ContentProfilesItem from './ContentProfilesItem';
import styles from './styles/contentProfiles.scss';
import { withTranslation } from 'react-i18next';
const maxProfiles = 4;

class ContentProfiles extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			profiles: [],
			logged: false
		};
		this.removeProfile = this.removeProfile.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	async fetchData() {
		this.setState({
			profiles: await getProfiles(),
		});
		try {
			const session = await Cognito.retrieveToken();
			this.setState({ logged: true});
		} catch (error) {
			console.log('No logged');
		}
	}

	removeProfile(id) {
		let array = [...this.state.profiles];
		let nextProfile = array[array.length-1];
		let profileIndex = this.state.profiles.findIndex((element => element.id === id));
		if (profileIndex !== -1) {
			if(nextProfile !== undefined){
				array.splice(profileIndex, 1, nextProfile);
				array.splice(array.length - 1, 1);
		    this.setState({
					profiles: array
				});
			}
			else {
				array.splice(profileIndex, 1);
		    this.setState({
					profiles: array
				});
			}
   
		}
	}

	render(){
		const {t} = this.props;
		return (

			<div className={styles.content}>
				<h2 className={styles.h2Title}>{t('titleContentProfile')}</h2>
				
				<div className={styles.scrollH}>
					<div className={styles.gridProfiles}>
						{this.state.profiles.slice(0,maxProfiles).map((profile) =>
						{
							return (
								<ContentProfilesItem key={profile.id} {...profile} {...this.props} removeProfile={this.removeProfile.bind(this)}/>
							);
						}
						)}
					</div>
					{this.state.logged && (<div className={styles.gridEnd}>
						<div className={styles.btnMore} onClick={() => this.props.history.push('/discover-people')}>
							{t('buttonKnowMore')}
						</div>
					</div>)}
				</div>
				
			</div>
	
		);
	}
}

export default withTranslation()(ContentProfiles);

