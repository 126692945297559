import React from 'react';
import ContentLoader from 'react-content-loader';

const LoadSearch = props => {

	let loaders = [];
	for (let index = 0; index < 10; index++) {
		loaders.push(
			<ContentLoader 
				speed={2}
				width={610}
				height={160}
				viewBox="0 0 610 160"
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				{...props}
			>
				<rect x="176" y="15" rx="3" ry="3" width="380" height="9" /> 
				<rect x="181" y="141" rx="0" ry="0" width="91" height="10" /> 
				<rect x="459" y="137" rx="0" ry="0" width="91" height="12" /> 
				<rect x="9" y="11" rx="8" ry="8" width="152" height="145" /> 
				<rect x="176" y="35" rx="3" ry="3" width="380" height="9" /> 
				<rect x="176" y="55" rx="3" ry="3" width="380" height="9" />
			</ContentLoader>
		);
        
	}
	return (
		<div>
			{loaders}
		</div>
	);
};

export default LoadSearch;