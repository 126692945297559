import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';

import { removeSection } from '../../actions/booklistV2Actions';
import PopUp  from '../shared/PopUp';
import { useTranslation } from 'react-i18next';



const PopUpDeleteSection = ({
	closePopUp,
	removeSection,
	sectionId,
}) => {
	const {t} = useTranslation();
	const [loading, setLoading] = useState(false);
	const handleDeleteSection = async () => {
		setLoading(true);
		await removeSection(sectionId);
		setLoading(false);
		closePopUp();
	};

	return (
		<PopUp
			closePopUp={closePopUp}
			style={{
				popUp:{
					maxHeight:'300px',
					maxWidth:'487px'
				},
				popUpContent:{
					display:'grid',
					margin:'40px'
				},
				button:{
					marginTop:'40px',
					justifyContent:'center',
				}
			}}
			
			buttons={{
				style:{
					width:'195px',
					height:'50px',
					borderRadius:'8px',
					marginRight:'20px',	
				},
				primary:{
					title:t('textContextMenuDelete'),
					type:'red',
					action:handleDeleteSection,
					loading:loading
				},
				secondary:{
					title:t('textContextMenuCancel'),
					type:'secondary',
					action:closePopUp
				}
			}}
		>
			<div style={{marginTop:'16px'}}>
				<p style={{fontSize:'18px', fontWeight:'500'}}>{t('titleDeleteSectionPopUp')}</p>
				<p style={{fontSize:'18px'}}>{t('infoDeleteSectionPopUp')}</p>
			</div>
		</PopUp>
	
	);
};

const mapStateToProps = ({ booklistV2  }) => {
	return {
		booklistSections: booklistV2.booklistSections,
	};
};

export default  withRouter(connect(mapStateToProps,{removeSection})(PopUpDeleteSection));