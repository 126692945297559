import {
	LOADING,
	ERROR,
	PUT_USER_PERMISSIONS,
	PERMISSIONS,
	PUT_BOOKLIST_TAGS,
} from '../types/booklistTypes';

const initialState = {
	data: null,
	oldData: null,
	author: null,
	sections: null,
	booklistContent: null,
	tags: null,
	loading: false,
	error: '',
	collaborators: null,
	permissions: null,
	editContent: false
};

export default (state = initialState, action) => {
	switch (action.type) {
	case LOADING:
		return {
			...state,
			loading: true
		};
	case PERMISSIONS:
		return {
			...state,
			permissions: action.payload
		};
	case PUT_BOOKLIST_TAGS:
		return {
			...state,
			tags: action.payload
		};
	case PUT_USER_PERMISSIONS:

		return {
			...state,
			loading:false,
			collaborators: action.payload
		};
	case ERROR:
		return {
			...state,
			error: action.payload,
		};
	default:
		return state;
	}
};
