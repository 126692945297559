import React from 'react';
import { iconNoResultSearch } from '../../../utils/constans';

import { useTranslation } from 'react-i18next';
const NoResultSearch = ({ hideSubtitle, maxWidth }) => {
	const { t } = useTranslation();
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<div
				style={{
					maxWidth: maxWidth ? maxWidth : '260px',
					maxHeight: '260px',
					width: '100%',
					borderRadius: '5px',
					overflow: 'hidden',
				}}
			>
				<img
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'cover',
					}}
					src={iconNoResultSearch}
				/>
			</div>

			<div style={{ marginTop: '25px', width: '308px' }}>
				<p
					style={{
						fontSize: '18px',
						fontWeight: '500',
						textAlign: 'center',
					}}
				>
					{t('textSearchNoResultTitle')}
				</p>
				{!hideSubtitle && (
					<p style={{ fontSize: '18px', textAlign: 'center' }}>
						{t('textSearchNoResultSubTitle')}
					</p>
				)}
			</div>
		</div>
	);
};

export default NoResultSearch;
