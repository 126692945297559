import React, { useEffect, useState } from 'react';
import styles from './styles/cardsWithPaginator.scss';
import { contentType, iconNoResultSearch } from '../../../../utils/constans';
import LoaderBooklistContent from '../../booklists/LoaderBooklistContent';
import CardContent from '../../shared/CardContent';
import MenuMoreInfo from '../../shared/MenuMoreInfo';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { breakPoint } from '../../../../utils/constans';

const CardsWithPaginator = ({
  profile,
  contents,
  handleNextPage,
  isBooklistArray,
  hasNextPage,
}) => {
	const [loadingScroll, setLoadingScroll] = useState();
	const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(
		window.innerWidth < breakPoint.mobileWide,
	);

  useEffect(() => {
    handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		if (!loadingScroll && hasNextPage) {
			let observerScroll = document.getElementById('observerScroll');
			let intersectionObserver = new IntersectionObserver(
				(entries) => {
					if (entries[0].isIntersecting) {
						setLoadingScroll(true);
						handleNextPage();
					}
				},
				{
					rootMargin: '0px 0px 1px 0px',
				}
			);
			intersectionObserver.observe(observerScroll);

			return () => {
				intersectionObserver.disconnect();
			};
		}
	}, [loadingScroll, hasNextPage, handleNextPage]);

	useEffect(() => {
		if (contents) {
			setLoadingScroll(false);
		}
	}, [contents]);

  const handleResize = () => {
		setIsMobile(window.innerWidth < breakPoint.mobileWide);
	};

  return (
    <>
      {contents && profile && contents.contents.length > 0 ? (
        <div className={isMobile ? styles.contentContainerMobile : styles.contentContainer}>
          {contents.contents.map((content) => {
            const currentContent = isBooklistArray
              ? {
                  ...content,
                  type: contentType.booklist,
                }
              : content;
            const isMyContent =
							profile.email === content.uploadedBy ||
							profile.email === content.author ||
							content.isSuperUser; 
            return (
              <CardContent
                key={content.id}
                resultContent={currentContent}
                contentType={{
                  _id: currentContent.type,
                  identifier: currentContent.type,
                }}
                heightCard={"165px"}
                widthCard={"100%"}
                moreInfo={
                  currentContent.type !== contentType.booklist && isMyContent
                }
                menuMoreInfo={<MenuMoreInfo content={currentContent} />}
              />
            );
          })}
        </div>
      ) : (
        <>
          {contents ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <img
                style={{
                  marginTop: "30px",
                  objectFit: "scale-down",
                }}
                src={iconNoResultSearch}
              />
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                {t("textSearchNoResultTitle")}
              </p>
            </div>
          ) : (
            <div className={styles.contentContainer}>
              <LoaderBooklistContent width="100%" />
              <LoaderBooklistContent width="100%" />
            </div>
          )}
        </>
      )}

			{loadingScroll && (
				<div className={isMobile ? styles.contentContainerMobile : styles.contentContainer}>
					<LoaderBooklistContent width="100%" />
					<LoaderBooklistContent width="100%" />
				</div>
			)}

			{hasNextPage && !loadingScroll ? (
				<div className={styles.observerScroll} id="observerScroll" />
			) : null}
		</>
	);
};

function mapStateToProps({ profile }) {
  return { profile: profile.profile.data };
}

export default withRouter(connect(mapStateToProps, {})(CardsWithPaginator));
