import axios from 'axios/index';
import Cognito from '../../helpers/cognito';
import {
	sendEventContentAddedToBooklist,
} from '../../helpers/tracking';
import {
	putArticle,
	putExternalBook,
	putOfflineBook,
	putLink,
} from './contentActions';
import { fetchMyBooklistFunction } from './contentListActions';
import {
	BOOK_CROSSREF,
	BOOK_ELSEVIER,
	BOOK_OFFLINE,
	environment,
	JOURNAL,
	JOURNAL_ELSEVIER,
	OFFLINE_BOOK_TYPE,
} from './index';

import { REMOVE_FROM_STATE } from '../types/contentTypes';

export const START_POST_BOOKLIST = 'START_POST_BOOKLIST';
export const END_POST_BOOKLIST = 'END_POST_BOOKLIST';
export const ERROR_POST_BOOKLIST = 'ERROR_POST_BOOKLIST';

export const postBooklist = (params) => async (dispatch, getState) => {
	dispatch({
		type: START_POST_BOOKLIST,
		payload: params,
	});

	return Cognito.retrieveToken()
		.then((session) => {
			return axios({
				method: 'POST',
				url: `${env.msContentNew}/api/contents/auth`,
				data: {
					title: params.title,
					description: params.description,
					author: params.author,
					image: params.image,
					type: 'BOOKLISTS',
					isPublic: params.isPublic,
				},
				headers: {
					Authorization: session.idToken.jwtToken,
					env: environment,
				},
			})
				.then((response) => {
					fetchMyBooklistFunction(dispatch);
					return response.data;
				})
				.catch((error) => {
					console.log('action error', error);
				});
		})
		.catch(() => {
			dispatch({
				type: ERROR_POST_BOOKLIST,
				payload: 'La sesión expiró',
			});
		});
};

export const STOP_LOADING_BOOKLIST = 'STOP_LOADING_BOOKLIST';
export const ADD_TO_SELECTED_BOOKLIST = 'ADD_TO_SELECTED_BOOKLIST';
export const addToSelectedBooklist =
  (booklist, content, included, type) => async (dispatch, getState) => {
  	dispatch({
  		type: LOADING_BOOKLIST,
  	});
  	return Cognito.retrieveToken().then((session) => {
  		const newType =
        included.type === BOOK_CROSSREF || included.type === BOOK_ELSEVIER
        	? 'EXTERNAL_BOOK'
        	: included.type === JOURNAL_ELSEVIER ||
            included.type === JOURNAL_ELSEVIER
        		? 'ARTICLE'
        		: type
        			? type
        			: null;
  		dispatch({
  			type: ADD_TO_SELECTED_BOOKLIST,
  			payload: {
  				included,
  				newType,
  			},
  		});
  		switch (included.type || type) {
  		case JOURNAL:
  			return addArticleToBooklist(
  				getState,
  				content,
  				session,
  				booklist,
  				included
  			).then(() =>
  				dispatch({
  					type: STOP_LOADING_BOOKLIST,
  				})
  			);
  		case 'book':
  			return addExternalBookToBooklist(
  				getState,
  				content,
  				session,
  				booklist,
  				included
  			).then(() =>
  				dispatch({
  					type: STOP_LOADING_BOOKLIST,
  				})
  			);
  		case BOOK_OFFLINE:
  			if (content.attributes.slug) {
  				return addLinkToBooklist(
  					getState,
  					content,
  					session,
  					booklist,
  					included
  				).then(() =>
  					dispatch({
  						type: STOP_LOADING_BOOKLIST,
  					})
  				);
  			}
  			return addOfflineBookToBooklist(
  				getState,
  				content,
  				session,
  				booklist,
  				included
  			).then(() =>
  				dispatch({
  					type: STOP_LOADING_BOOKLIST,
  				})
  			);
  		case OFFLINE_BOOK_TYPE:
  		default:
  			return postContentToBooklist(
  				getState,
  				booklist,
  				content.attributes.slug
  			).then((response) => {
  				dispatch({
  					type: STOP_LOADING_BOOKLIST,
  				});
  				return response;
  			});
  		}
  	});
  };

export const addDocumentToBooklist =
  (booklistId, documentSlug) => async (getState) => {
  	return postContentToBooklist(getState, booklistId, documentSlug);
  };

function addArticleToBooklist(getState, content, session, booklist, included) {
	const { slug, title, description, author, image } =
    content.attributes.data || content.attributes;
	const { editorial } = content.attributes.related || included.attributes;
	return putArticle(
		{
			id: content.id,
			slug: slug || included.attributes.reader,
			title,
			description,
			author,
			journal: editorial,
			image,
		},
		session
	).then(() => {
		return postContentToBooklist(getState, booklist, [
			content.id.replace(/\//g, ''),
		]);
	});
}

/**
 * Crea un objeto link en el back y lo agrega a la booklist
 * @param {Object} content  Objeto que contiene los atributos del link
 * @param {Object} session  Obejto de cognito que contiene la sesion actual
 * @param {number} booklist Id de la booklist
 * @param {Object} included Objeto que contiene los atributos generales del link
 */
function addLinkToBooklist(getState, content, session, booklist, included) {
	const { slug, title, description, author, image } =
    content.attributes.data || content.attributes;
	return putLink(
		{
			url: slug || included.attributes.reader,
			title,
			description,
			author,
		},
		session
	).then((data) => {
		return postContentToBooklist(getState, booklist, [data.data.data.slug]);
	});
}

function addExternalBookToBooklist(
	getState,
	content,
	session,
	booklist,
	relatedData
) {
	const { slug, title, description, author, image } =
    content.attributes.data || content.attributes;
	const { editorial } = content.attributes.related || relatedData.attributes;
	const contentId = content.id.replace(new RegExp('/', 'g'), '');
	return putExternalBook(
		{
			slug,
			title,
			description,
			author,
			image,
			editorial,
			externalID: contentId,
			hostURL: slug,
			source: content.type,
		},
		session
	).then(() => {
		return postContentToBooklist(getState, booklist, [contentId]);
	});
}

const addOfflineBookToBooklist = async (
	getState,
	content,
	session,
	booklist,
	relatedData
) => {
	const { slug, title, description, author, image } =
    content.attributes.data || content.attributes;
	const { editorial, holding } =
    content.attributes.related || relatedData.attributes;
	try {
		const request = await putOfflineBook(
			{
				slug,
				title,
				description,
				author,
				image,
				editorial,
				externalID: content.id,
				holdings: holding,
				source: content.type,
			},
			session
		);

		return postContentToBooklist(getState, booklist, [content.id]);
	} catch (error) {
		console.error(error);
	}
};

const postContentToBooklist = async (getState, booklistId, contentSlug) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'POST',
			url: `${env.msContentNew}/api/booklist-contents`,
			data: {
				booklistId,
				contentSlug,
			},
			headers: {
				Authorization: session.idToken.jwtToken,
			},
		});

		const data = request.data;

		const { profile } = getState();
		await sendEventContentAddedToBooklist(
			booklistId,
			contentSlug,
			profile,
			data
		);
		return request;
	} catch (error) {
		console.error(error);
	}
};

export const LOADING_BOOKLIST = 'LOADING_BOOKLIST';
export const DELETE_BOOKLIST_OK = 'DELETE_BOOKLIST_OK';
export const DELETE_BOOKLIST = 'DELETE_BOOKLIST';
export const deleteBooklist = (slug) => async (dispatch) => {
	dispatch({
		type: LOADING_BOOKLIST,
	});
	return Cognito.retrieveToken().then((session) => {
		axios({
			method: 'DELETE',
			url: `${env.msContentNew}/api/contents/${slug}`,
			headers: {
				Authorization: session.idToken.jwtToken,
				env: environment,
			},
		});
		dispatch({
			type: DELETE_BOOKLIST,
			slug,
		});
		dispatch({
			type: REMOVE_FROM_STATE,
			payload: slug,
		});
		return dispatch({
			type: DELETE_BOOKLIST_OK,
		});
	});
};

export const postSearchBooklistContents = async (booklistId, query) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'POST',
			url: `${env.msContentNew}/api/v2/booklist/contents/search`,
			data: {
				booklistId,
				query,
			},
			headers: {
				Authorization: session.idToken.jwtToken,
				env: environment,
			},
		});

		let data = request.data;

		return data;
	} catch (error) {
		console.error(error);
		throw Error(error);
	}
};
