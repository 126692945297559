import React from 'react';
import ContentLoader from 'react-content-loader';

const LoaderCardContent = (props) => (
	<ContentLoader 
		speed={2}
		width={props.width ? props.width : 545}
		height={props.height ? props.height : 165}
		viewBox="0 0 545 165"
		backgroundColor="#c2c2c2"
		foregroundColor="#d4d4d4"
		{...props}
	>
		<rect x="9" y="17" rx="5" ry="5" width="121" height="123" /> 
		<rect x="142" y="25" rx="0" ry="0" width="197" height="19" /> 
		<rect x="143" y="50" rx="0" ry="0" width="197" height="17" /> 
		<rect x="142" y="74" rx="0" ry="0" width="367" height="34" /> 
		<rect x="140" y="120" rx="0" ry="0" width="61" height="19" /> 
		<rect x="195" y="127" rx="0" ry="0" width="2" height="2" /> 
		<rect x="213" y="119" rx="0" ry="0" width="61" height="19" /> 
		<rect x="285" y="119" rx="0" ry="0" width="61" height="19" />
	</ContentLoader>
);

export default LoaderCardContent;