import axios from 'axios';
import {environment} from './index';

export const LOADING_INSTITUTION = 'LOADING_INSTITUTION';
export const FETCH_INSTITUTION = 'FETCH_INSTITUTION';
export const fetchEnvironment = () => async(dispatch) => {
	dispatch({
		type: LOADING_INSTITUTION,
	});
	return axios({
		method: 'GET',
		url: `${env.msProfile}/env`,
		headers: {
			'env': environment,
		}
	}).then(response => {
		return dispatch({
			type: FETCH_INSTITUTION,
			payload: response.data
		});
	});
};

export const getInstitutions = async () => {
	try {
		const request = await axios({
			method: 'GET',
			url: `${env.msProfileNew}/api/institutions?app=false`,
		});
		return request.data;
	} catch (err) {
		throw Error(err);
	}
};