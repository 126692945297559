import React, {useState,useEffect} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getBooklistsCollaborative, getMoreBooklistsCollaborative } from '../../actions';

import { ImpulseSpinner } from 'react-spinners-kit';
import LoaderBooklistContent from '../booklists/LoaderBooklistContent';
import CardBooklist from './CardBooklist';

const CollaborativeContextMenu = ({
	loading,
	booklistPermissions,
	handleCardSelect,
	selectedBooklistId,
	getBooklistsCollaborative,
	getMoreBooklistsCollaborative,
	currentBooklistId,
}) => {

	const [page, setPage] = useState(1);

	useEffect(() => {
		if (!booklistPermissions.booklists) {
			getBooklistsCollaborative();
		}
	}, []);

	useEffect(() => {
		if (booklistPermissions.booklists && !loading.moreBooklistCollaborative && booklistPermissions.hasNextPage) {
			let observerScroll = document.getElementById('observerScroll');
			let intersectionObserver = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting) {
					handleMoreBooklistsCollaborative();
				}
			}, {
				rootMargin: '0px 0px 1px 0px',
			});
			intersectionObserver.observe(observerScroll);
		}
	}, [booklistPermissions.booklists, loading.moreBooklistCollaborative]);

	const handleMoreBooklistsCollaborative = async () => {
		if (booklistPermissions.hasNextPage) {
			getMoreBooklistsCollaborative(page + 1);
			setPage(page + 1);
		}  
	};  

	return (
		<>
			<div style={{
				margin: '20px auto', 
				display: 'grid', 
				gridTemplateColumns: '1fr 1fr', 
				gap: '28px', 
				justifyItems: 'center', 
				paddingBottom: '60px' 
			}}>

				{!booklistPermissions.booklists || loading.booklistCollaborative ?
					<LoaderBooklistContent width='347px' height='147px' /> :
					booklistPermissions.booklists.filter((booklist) => booklist.booklistId !== currentBooklistId)
						.map((booklist) => (
							<CardBooklist
							key={booklist.booklistId}
							widthCard={'347px'}
							heightCard={'147px'}
							content={{...booklist.content, id:booklist.booklistId}}
							isSelected={booklist.booklistId === selectedBooklistId}
							onSelect={handleCardSelect}
						/>
						))}
			</div>

			{loading.moreBooklistCollaborative &&
                <div style={{
                	width: '100%',
                	display: 'flex',
                	justifyContent: 'center',
                	alignItems: 'center',
                }}>
                	<ImpulseSpinner
                		size={40}
                		color="var(--primary-color)"
                		frontColor="#10CFC9"
                		backColor='#F0FBFF'
                		loading={loading.moreMyBooklist}
                	/>
                </div>
			}

			{booklistPermissions && booklistPermissions.booklists && booklistPermissions.hasNextPage && !loading.moreBooklistCollaborative ?
				<div style={{ marginTop: '20px', padding: '5px' }} id='observerScroll' /> : null
			}
		</>
	);
};

const mapStateToProps = ({ contextMenu }) => {
	return {
		loading: contextMenu.loading,
		booklistPermissions: contextMenu.booklistPermissions
	};
};

export default withRouter(connect(mapStateToProps, { getBooklistsCollaborative, getMoreBooklistsCollaborative  })(CollaborativeContextMenu)); 
