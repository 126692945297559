import React from 'react';
import styles from '../shared/styles/cardContent.scss';
import {
	contentTypeNew,
	getResources
} from '../../../utils/constans';

import {environment} from '../../actions';

const CardBooklist = ({heightCard,widthCard,content,onSelect, isSelected}) => {
	
	const handleClick = () => {
		onSelect(content.id);
	};

	return (
		<div
			className={`${styles.gridRow10} ${styles.alignStart} ${styles.pointer} ${isSelected ? styles.selected : ''}`}
			style={{
				height: heightCard || '152px',
				width: widthCard || '641px'
			}}
			onClick={handleClick}
		>
			<a className={`${styles.boxImage}`} >
				<div className={styles.opacity} />
				<img
					className={styles.imgBox}
					src={content.image ? content.image : getResources(environment).defaultCover}
					onError={(e) => e.target.src = getResources(environment).defaultCover}
				/>

				<div className={styles.posType}>
					<div className={styles.contentTypeRed}>
                    	<img className={styles.icon15} src={contentTypeNew['BOOKLISTS'].imgUrl} alt="" />
                    	<h3 className={styles.fontType}>{'Booklist'}</h3>
					</div>
				</div>
			</a>
			<div className={styles.fontTitleGrid}>
				<h2>{content.title}</h2>
			</div>
			<div className={styles.maxParagraph}>
				<p className={styles.fontGrid}>{content.description}</p>
			</div>
		</div>

	);
};

export default CardBooklist;