import { useState } from 'react';
import { openContextMenu } from '../actions';

const useContextMenu = () =>{
	const [openContextMenu, setOpenContextMenu] = useState(false);
	const handleOpenContextMenu = (openPopUp) => {
		setOpenContextMenu(openPopUp);
		//openContextMenu(openPopUp);
	};

	return {
		openContextMenu,
		handleOpenContextMenu
	};
};

export default useContextMenu;