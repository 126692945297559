import axios from 'axios';
import Cognito from '../../helpers/cognito';
import {BOOKLIST_SEARCH_TYPE, environment} from './index';
import {
	GET_MY_BOOKLISTS,
} from '../types/contentTypes';

import {
	BOOKLIST_PERMISSIONS_V1,
} from '../types/booklistTypes';

export const START_MY_CONTENT_LIST_FETCH = 'START_MY_CONTENT_LIST_FETCH';
export const FETCH_MY_CONTENT = 'FETCH_MY_CONTENT';
export const FETCH_MY_BOOKLIST = 'FETCH_MY_BOOKLIST';
export const START_CONTENT_LIST_FETCH = 'START_CONTENT_LIST_FETCH';
export const fetchMyContent = () => async(dispatch) => {
	dispatch({
		type: START_MY_CONTENT_LIST_FETCH,
	});
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${env.msContentNew}/api/contents/user`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});
		const data = request.data;
		const included = request.data;
		const total = request.length;
		dispatch({
			type: FETCH_MY_CONTENT,
			payload: {data, included, total}
		});
		return {data, included, total};

	} catch (error) {
		console.error(error);
	}

};


export function fetchMyBooklistFunction(dispatch, all = false) {
  
	const url = all ? `${env.msContent}/user?all=true` : `${env.msContent}/user`;
	return Cognito.retrieveToken()
		.then((session) => {
			dispatch({
				type: START_MY_CONTENT_LIST_FETCH,
			});
			return axios({
				method: 'GET',
				url: url,
				headers: {
					'Authorization': session.idToken.jwtToken,
					'env': environment
				}
			}).then((user) => {
				const booklistsIncluded = user.data.included.filter((content) => content.type === BOOKLIST_SEARCH_TYPE);
				const booklistsData = booklistsIncluded
					.map(booklist => user.data.data
						.filter(content => content.id === booklist.id)).flat();
				dispatch({
					type: FETCH_MY_BOOKLIST,
					payload: {data: booklistsData, included: booklistsIncluded}
				});
				return {data: booklistsData, included: booklistsIncluded};
			});
		});
}

export const fetchOthersContentFunction = async (dispatch, email)  => {
	dispatch({
		type: START_CONTENT_LIST_FETCH,
	});
	try {
		const session = await Cognito.retrieveToken();
		return fetchOtherContentAuthFunction(dispatch, email, session);
	} catch (error) {
		return fetchOtherContentAuthFunction(dispatch, email);
	}
};

function fetchOtherContentAuthFunction(dispatch, email, session) {
	const headers = session && {
		'Authorization': session.idToken.jwtToken,
	};
	return axios({
		method: 'GET',
		url: `${env.msContent}/user/${session ? 'auth/': ''}${email}`,
		headers: {
			...headers,
			'env': environment
		}
	}).then((user) => {
		const data = user.data.data;
		const included = user.data.included;
		const total = data.length;
		dispatch({
			type: FETCH_OTHERS_CONTENT,
			payload: {data, included, total}
		});
		return data;
	});
}

export const FETCH_OTHERS_CONTENT = 'FETCH_OTHERS_CONTENT';
export const fetchOthersContent = (email) => async(dispatch) => {
	return fetchOthersContentFunction(dispatch, email);
};

export const getUserBooklist = async() => {
	
	try {
		const session = await Cognito.retrieveToken();

		const request = await axios({
			method: 'GET',
			url: `${env.msContentNew}/api​/contents/use/user`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});
		let data = request.data;
		
		const booklistsData = data.filter((content) => content.type === 3);
		
		return booklistsData;
	}
	catch(error){
		return [];
	}
};

export const fetchMyBooklists = ({page=1 , limit=10, booklistId=null}={}) => async(dispatch) => {
	try {
		const request = await getMyBooklists(page,limit,booklistId);
		return dispatch({
			type: GET_MY_BOOKLISTS,
			payload: request,
		});

	} catch (error) {
		console.error(error);
	}

};

export const getMyBooklists = async (page=1 , limit=5, booklistId) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${env.msContentNew}/api/v2/booklists/my-booklists?page=${page}&limit=${limit}${booklistId && `&booklistId=${booklistId}`}`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});
		return  request.data;
	} catch (error) {
		console.error(error);
	}

};

export const fetchBooklistPermissions =  () => async(dispatch) =>{
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${env.msContentNew}/api/booklist-permissions`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});

		let response = request.data.map(booklistPermission => {
			return {
				id: booklistPermission.booklistId,
				...booklistPermission.content,
				permissions: booklistPermission.permission.identifier
			};
		});
		
		return dispatch({
			type: BOOKLIST_PERMISSIONS_V1,
			payload: response
		});

	} catch (error) {
		console.error(error);
	}
};