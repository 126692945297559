export const apaCitationFromISBN = async (isbn) => {
  const { Cite } = require("@citation-js/core");
  require("@citation-js/plugin-isbn");

  try {
    const cite = new Cite(isbn);
    const rawData = cite.data[0];

    const authors = rawData.author
      .map((author) => `${author.family}, ${author.given}`)
      .join("; ");
    const year = rawData.issued["date-parts"][0][0];
    const title = rawData.title;
    const publisher = rawData.publisher;

    let citationText = `${authors} (${year}). ${title}.`;
    if (publisher) {
      citationText += ` ${publisher}.`;
    }
    return citationText;
  } catch (error) {
    console.error("Error generating citation:", error);
    return null;
  }
};

export const apaCitationFromObject = ({author, date, title, publisher}) => {
    const authors = author;
    const year = date ? `(${date.split('-')[0]}).`: '';
    let citationText = `${authors} ${year} ${title}.`;
    if (publisher) {
      citationText += ` ${publisher}.`;
    }
    return citationText;
} 
