import React from 'react';
import styles from './styles/people.scss';
import PeopleItem from './peopleItem';
import { ImpulseSpinner } from 'react-spinners-kit';

const People = ({ profile, title, isLoading }) => {
	if (!isLoading && !profile) {
		return null;
	}

	return (
		<div className={styles.boxPeople}>
			<div className={styles.gridRow20}>
				<h2 className={styles.fontTitle}>{title}</h2>
				<div className={styles.gridRow20}>
					{!profile ? (
						<div className={styles.loadingSpinner}>
							<ImpulseSpinner
								size={80}
								color='var(--primary-color)'
								frontColor='var(--primary-color)'
								backColor='#F0FBFF'
								loading={true}
							/>
						</div>
					) : (
						<div className={styles.gridRow20}>
							<PeopleItem profile={profile} img={profile.institutionImageUrl} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default People;
