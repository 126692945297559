
import axios from 'axios/index';
import Cognito from '../../helpers/cognito';
import {
	FETCH_NOTIFICATIONS,
	FETCH_MESSAGES,
	FETCH_NOTIFICATIONS_COUNT,
	FETCH_MESSAGES_COUNT
} from '../types/notificiationTypes';

const countNotifications = 0;

export const fetchNotifications = (token) => async(dispatch) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${env.msNotificationNew}/api/notifications?isTypeMessage=false`,
			headers: {
				'Authorization': token ? token : session.idToken.jwtToken,
			},
		});

		dispatch({
			type: FETCH_NOTIFICATIONS,
			payload: request.data
		});

		return request.data;

	}catch(error){
		console.log(error);
	}
};

export const fetchNotificitionMessages = (token) => async(dispatch) => {
	try {

		const request = await axios({
			method: 'GET',
			url: `${env.msNotificationNew}/api/notifications?isTypeMessage=true`,
			headers: {
				'Authorization': token,
			},
		});

		dispatch({
			type: FETCH_MESSAGES,
			payload: request.data
		});

	}catch(error){
		console.log(error);
	}
};

export const fetchCountNotifications = (token) => async(dispatch) => {
	try {
		
		const request = await axios({
			method: 'GET',
			url: `${env.msNotificationNew}/api/notifications/count?isTypeMessage=false&isRead=false`,
			headers: {
				'Authorization': token,
			},
		});

		dispatch({
			type: FETCH_NOTIFICATIONS_COUNT,
			payload: request.data
		});

		return request.data;
	}catch(error){
		console.log(error);
	}
};

export const fetchCountMessages = (token) => async(dispatch) => {
	try {
		
		const request = await axios({
			method: 'GET',
			url: `${env.msNotificationNew}/api/notifications/count?isTypeMessage=true&isRead=false`,
			headers: {
				'Authorization': token,
			},
		});

		dispatch({
			type: FETCH_MESSAGES_COUNT,
			payload: request.data
		});

		return request.data;
	}catch(error){
		console.log(error);
	}
};

export const putNotification = async(notificationId)  =>{
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'PUT',
			url: `${env.msNotificationNew}/api/notifications/${notificationId}`,
			data:{
				'isRead': true
			},
			headers: {
				'Authorization': session.idToken.jwtToken,
			},
		});

		return request.data;
	} catch(error){
		console.log(error);
	}
};

export const putPlatformNotifications = () => async(dispatch) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'PUT',
			url: `${env.msNotificationNew}/api/notifications`,
			data:{
				'isRead': true,
				'isTypeMessage': false
			  },
			headers: {
				'Authorization': session.idToken.jwtToken,
			},
		});

		dispatch({
			type: FETCH_NOTIFICATIONS_COUNT,
			payload: countNotifications
		});

		return request.data;
	} catch(error){
		console.log(error);
	}
};