import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles/offlineBookLibraryTable.scss';
const OfflineBookLibraryTable = ({ data }) => {
	const { t } = useTranslation();
	return (
		<div className={styles.tableContainer}>
			<table className={styles.table}>
				<thead>
					<tr>
						<th className={styles.titleCell}>{t('offlineBookPopUpLibrary')}</th>
						<th className={styles.titleCell}>{t('offlineBookPopUpLocation')}</th>
						<th className={styles.titleCell}>{t('offlineBookPopUpQuantity')}</th>
					</tr>
				</thead>
				<tbody>
					{data.map((row, index) => (
						<tr key={index}>
							<td className={styles.rowCell}>{row.library}</td>
							<td className={styles.rowCell}>{row.location}</td>
							<td className={styles.rowCell}>{row.quantity}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default OfflineBookLibraryTable;
