const booklistTypeId = 3;
const profileCompletion = {
  student: 9,
  teacher: 8,
  admin: 7,
  percentage: 100,
  decimals: 2,
};

import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const MOBILE_DEVICE = "MOBILE";
const WEB_DEVICE = "WEB";

let homeHeartbeatInterval;
let sessionId;
let sessionIdPopup;
let throttleTimeoutScroll;
let positionTop;
let userTracking;

import { environment, msStats } from "../client/actions";

import { eventSearchType } from "../utils/constans";

async function getContentById(contentId) {
  try {
    const request = await axios({
      method: "GET",
      url: `${env.msContent}/content/${contentId}`,
      headers: {
        env: environment,
      },
    });
    const data = request.data;

    return data;
  } catch (error) {
    throw Error(error);
  }
}

export const trackHome = (user) => {
  if (!homeHeartbeatInterval) {
    sessionId = uuidv4();
    userTracking = user;
    initHeartbeat();
    positionTop = 0;
    window.addEventListener("scroll", onScroll);
  }
};

export const endTrackHome = () => {
  if (homeHeartbeatInterval) {
    clearInterval(homeHeartbeatInterval);
    window.removeEventListener("scroll", onScroll);
  }
};

const initHeartbeat = () => {
  postHeartbeat(userTracking, environment);
  homeHeartbeatInterval = setInterval(() => {
    if (document.visibilityState === "visible") {
      postHeartbeat(userTracking, environment);
    }
  }, 30 * 1000);
};

const postHeartbeat = (user, env) => {
  axios({
    method: "POST",
    url: `${msStats}/heartbeat`,
    data: {
      sessionId,
      user,
      env,
      device: getAgent(),
    },
  });
};

const onScroll = () => {
  const boundingRec = document.body.getBoundingClientRect();
  const distance = boundingRec.top - positionTop;
  positionTop = boundingRec.top;
  let timeDifference = true;
  if (throttleTimeoutScroll) {
    timeDifference = Math.abs(throttleTimeoutScroll - new Date()) > 3 * 1000;
  }
  if (Math.abs(distance) > 50 && timeDifference) {
    positionTop = boundingRec.top;
    throttleTimeoutScroll = new Date();
    postScroll(userTracking, distance, environment);
  }
};

const postScroll = (user, distance, env) => {
  axios({
    method: "POST",
    url: `${msStats}/scroll`,
    data: {
      sessionId,
      user,
      distance,
      env,
      device: getAgent(),
    },
  });
};

const getAgent = (event) => {
  const breakPointMobile = 810;
  if (window.innerWidth <= breakPointMobile) {
    return MOBILE_DEVICE;
  }
  return WEB_DEVICE;
};

export async function postEventPopup(eventType, institution, email) {
  try {
    if (!sessionIdPopup) {
      sessionIdPopup = uuidv4();
    }

    const request = await axios({
      method: "POST",
      url: `${env.msStats}/event`,
      data: {
        sessionId: sessionIdPopup,
        type: eventType,
        user: email,
        ies: institution ? institution : environment,
      },
    });

    return request.data;
  } catch (error) {
    throw Error(error);
  }
}

export async function postEventSharePopup(
  eventType,
  institution,
  email,
  targetEmail,
  contentId
) {
  try {
    const request = await axios({
      method: "POST",
      url: `${env.msStats}/event/share`,
      headers: {
        env: institution ? institution : environment,
      },
      data: {
        sessionId,
        type: eventType,
        profileEmail: email,
        targetEmail,
        contentId,
      },
    });

    return request.data;
  } catch (error) {
    throw Error(error);
  }
}

export async function postEventSearch(
  eventType,
  institution,
  email,
  targetEmail,
  contentSlug,
  term,
  pixelsScrolled
) {
  try {
    if (!sessionId) {
      sessionId = uuidv4();
    }
    const request = await axios({
      method: "POST",
      url: `${env.msStats}/event/search`,
      headers: {
        env: institution ? institution : environment,
      },
      data: {
        sessionId,
        type: eventType,
        user: email,
        targetEmail,
        contentSlug,
        term,
        pixelsScrolled,
      },
    });

    return request.data;
  } catch (error) {
    throw Error(error);
  }
}

export async function postEventComment(eventType, email, contentId) {
  try {
    const request = await axios({
      method: "POST",
      url: `${env.msStats}/event/comment`,
      headers: {
        env: environment,
      },
      data: {
        sessionId,
        type: eventType,
        user: email,
        contentId,
      },
    });

    return request.data;
  } catch (error) {
    throw Error(error);
  }
}

export const scrollSearch = (user) => {
  sessionId = sessionId || uuidv4();
  userTracking = user;
  positionTop = 0;
  window.addEventListener("scroll", scrollInSearch);
};

export const removeListenerScroll = () => {
  window.removeEventListener("scroll", scrollInSearch);
};

const scrollInSearch = async () => {
  const boundingRect = document.body.getBoundingClientRect();
  const distance = boundingRect.top - positionTop;
  positionTop = boundingRect.top;
  let timeDifference = true;
  if (throttleTimeoutScroll) {
    timeDifference = Math.abs(throttleTimeoutScroll - new Date()) > 3 * 1000;
  }
  if (Math.abs(distance) > 50 && timeDifference) {
    positionTop = boundingRect.top;
    throttleTimeoutScroll = new Date();
    await postEventSearch(
      eventSearchType.scrollSearch,
      null,
      userTracking,
      null,
      null,
      null,
      distance
    );
  }
};
