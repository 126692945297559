import React, { useEffect, useState } from 'react';
import styles from './styles/sideBar.scss';
import ProfileBasicInfo from './ProfileBasicInfo';
import SideBarTab from './SideBarTab';
import { deleteFollowing, environment, putFollowing } from '../../../actions';
import {
	PROFILE_TABS,
	PROFILE_TABS_MOBILE,
	getResources,
	iconFollowingWhite,
} from '../../../../utils/constans';
import Button, { appearances } from '../../shared/Button';
import { useTranslation } from 'react-i18next';
import PopUpFollows from './PopUpFollows';

const SideBar = ({
	profile,
	isSameUser,
	activeTab,
	onTabChange,
	user,
	isMobile,
}) => {
	const { t } = useTranslation();
	const [isFollow, setIsFollow] = useState(null);
	const [userData, setUserData] = useState(user);
	const [openPopUpFollows, setOpenPopUpFollows] = useState({
		open: false,
		followers: false,
		followings: false,
		email: null
	});

	useEffect(() => {
		if (user) {
			setIsFollow(user.isFollow);
		}
		setUserData(user);
	}, [user]);

	const handleTabClick = (tab) => {
		onTabChange(tab);
	};

	const handleFollow = () => {
		if (isFollow) {
			deleteFollowing(user.email);
			setUserData({
				...user,
				followersCount: userData.followersCount - 1,
			});
		} else {
			putFollowing(user.email);
			setUserData({
				...user,
				followersCount: userData.followersCount + 1,
			});
		}

		setIsFollow(!isFollow);
	};

	const handleOpenPopUpFollows = (props) => {
		setOpenPopUpFollows(props);
	};

	return (
		<>
			<div
				className={
					isMobile ? styles.mobileMainContainer : styles.mainContainer
				}>
				<ProfileBasicInfo
					handleOpenPopUpFollows={handleOpenPopUpFollows}
					userData={userData}
					isMobile={isMobile}
					isSameUser={isSameUser}
				/>
				{isSameUser ? (
					isMobile ? (
						<div className={styles.mobileTabsContainer}>
							{PROFILE_TABS_MOBILE.map((tab) => (
								<SideBarTab
									key={tab.key}
									materialLogo={tab.logo}
									title={t(tab.key)}
									onClick={() => handleTabClick(tab)}
									isTabActive={activeTab === tab}
									isMobile={isMobile}
								/>
							))}
						</div>
					) : (
						PROFILE_TABS.map((tab) => (
							<SideBarTab
								key={tab.key}
								materialLogo={tab.logo}
								title={t(tab.key)}
								onClick={() => handleTabClick(tab)}
								isTabActive={activeTab === tab}
								isMobile={isMobile}
							/>
						))
					)
				) : isFollow ? (
					<Button
						height={'48px'}
						appearance={appearances.secondary}
						borderRadius="6px"
						icon={getResources(environment).iconFollowing}
						title={t('buttonContentFollowed')}
						action={handleFollow}
					/>
				) : (
					<Button
						height={'48px'}
						appearance={appearances.primary}
						borderRadius="6px"
						icon={iconFollowingWhite}
						title={t('buttonContentFollow')}
						action={handleFollow}
					/>
				)}
			</div>
			{openPopUpFollows.open && <PopUpFollows
			    closePopUp={()=>setOpenPopUpFollows({
					open: false,
					followers: false,
					followings: false,
					email: null
				})}
				showFollowers={openPopUpFollows.followers}
				showFollowing={openPopUpFollows.followings}
				email={openPopUpFollows.email}
			/>}
		</>
	);
};

export default SideBar;
