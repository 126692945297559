import React, { useState } from 'react';

import { deleteBooklist } from '../../actions/booklistV2Actions';
import PopUp  from '../shared/PopUp';
import { useTranslation } from 'react-i18next';

const PopUpDeleteBooklist = ({
	goBackProfile,
	closePopUp,
	slugBooklist
}) => {
	const {t} = useTranslation();
	const [loading, setLoading] = useState(false);
	const handleDeleteContent = async () => {
		setLoading(true);
		await deleteBooklist(slugBooklist);
		setLoading(false);
		goBackProfile();
	};

	return (
		<PopUp
			closePopUp={closePopUp}
			style={{
				popUp:{
					maxHeight:'300px',
					maxWidth:'487px'
				},
				popUpContent:{
					display:'grid',
					margin:'40px'
				},
				button:{
					marginTop:'40px',
					justifyContent:'center',
				}
			}}
			
			buttons={{
				style:{
					width:'195px',
					height:'50px',
					borderRadius:'8px',
					marginRight:'20px',	
				},
				primary:{
					title:t('textContextMenuDelete'),
					type:'red',
					action:handleDeleteContent,
					loading:loading
				},
				secondary:{
					title:t('textContextMenuCancel'),
					type:'secondary',
					action:closePopUp
				}
			}}
		>
			<div style={{marginTop:'16px'}}>
				<p style={{fontSize:'18px', fontWeight:'500'}}>{t('titleDeleteBooklistPopUp')}</p>
				<p style={{fontSize:'18px'}}>{t('infoDeleteBooklistPopUp')}</p>
			</div>
		</PopUp>
	
	);
};



export default PopUpDeleteBooklist;