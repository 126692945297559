import React from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import PopUp  from '../shared/PopUp';
import InputGroup  from '../shared/InputGroup';
import useFormFileds from '../../hooks/useFormFields';
import {
	getResources,
	iconSaveWhite,
} from '../../../utils/constans';
import { useTranslation } from 'react-i18next';

import { environment, putBooklistSection } from '../../actions';
const SectionPopUpEdit = ({
	booklistSections,
	closePopUp,
	sectionId,  
	loadingPut,
	putBooklistSection,
	popUpDelete
}) => {
	const { t } = useTranslation();
	const { fields, handleOnchage } = useFormFileds({
		titleSection: booklistSections.filter((section)=>section.id === sectionId)[0].section  === 'Contenido' ? t('textSearchFilterContent') :  booklistSections.filter((section)=>section.id === sectionId)[0].section,
	  });

	const handleEditSection = async () => {
		await putBooklistSection(sectionId , fields.titleSection);
		closePopUp();
	};

	const handleRemoveSection = () => {
		popUpDelete();
		closePopUp();
	};



	return (
		<PopUp
			closePopUp={closePopUp}
			style={{
				popUp:{
					maxHeight:'406px',
					maxWidth:'534px'
				},
				popUpContent:{
					margin:'40px'
				},
				button:{
					marginTop:'30px',
					justifyContent:'center',
				}
			}}
    
			buttons={{
				style:{
					width:'220px',
					height:'50px',
					borderRadius:'8px',
					marginRight:'15px',	
				},
				primary:{
					materialIcon: 'save',
					title:t('textPreferenceSave'),
					type:'primary',
					action:handleEditSection,
					loading:loadingPut
				},
				secondary:{
					icon:getResources(environment).iconDelete,
					title:t('buttonDeleteSection'),
					type:'secondary',
					disabled:booklistSections.length > 1 ? false : true,
					action:handleRemoveSection
				}
			}}
		>
			<div style={{width:'100%'}}>
				<p style={{ 
					fontSize:'20px',
					fontWeight:700,
					color: '#333333',
					paddingBottom: '13px',
					borderBottom: 'solid 2px #e1e1e1'
				}}>
					{t('titleEditSection')}
				</p>
				<div style={{marginTop:'20px'}}>
					<InputGroup 
						inputName={'titleSection'}
						title={t('inputNameSection')}
						onChange={handleOnchage}
						value={fields.titleSection}
						fontSize={'16px'}
						fontWeight={'500'}
						style={{
							width:'100%',
							height:'45px',
						
						}}
					/>
				</div>



			</div>
		</PopUp>
	);
};

const mapStateToProps = ({ booklistV2  }) => {
	return {
		booklistSections: booklistV2.booklistSections,
		loadingPut: booklistV2.loadingPut
	};
};

export default  withRouter(connect(mapStateToProps,{putBooklistSection})(SectionPopUpEdit));
