import React, { useState, useEffect} from 'react';
import Dropzone from 'react-dropzone';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import styles from './styles/mobileMenu.scss';

import { openProfile } from '../../../helpers/profileRouting';
import {
	loadingProfile,
	visitStats,
	openPopUp
} from '../../actions';

import PopUpCreate from '../create-source/PopUpCreate';

const defaultImg = 'https://cdn.booklick.net/public/img/icons/iconUserFooter.svg';
const iconBooklist = 'https://cdn.booklick.net/public/img/icons/booklist-gray.svg';
const iconText = 'https://cdn.booklick.net/public/img/icons/text-fill-gray.svg';
const iconLink = 'https://cdn.booklick.net/public/img/icons/link-gray.svg';
const iconCamera = 'https://cdn.booklick.net/public/img/icons/camera-fill-gray.svg';
const iconImage = 'https://cdn.booklick.net/public/img/icons/image-fill-gray.svg';
const iconSearch = 'https://cdn.booklick.net/public/img/icons/icon-search-white.svg';
const iconNotification = 'https://cdn.booklick.net/public/img/icons/icon-bell-white.svg';
const iconMore = 'https://cdn.booklick.net/public/img/icons/add-circle-white.svg';
const iconHomeWhite = 'https://cdn.booklick.net/public/img/icons/IconHomeFooter.svg';

const initialState = {
	position: 0,
	alert: true,
	popUp: false,
	positionPopup: 1,
	camera: false
};

const positionMenu = {
	home: 0,
	search: 1,
	create: 2,
	notification: 3,
	profile: 4,
};

const positionCreate = {
	file: 1,
	link: 2,
	booklist: 3
};
const constraints = {
	video: { 
		facingMode: { exact: 'environment' } 
	} 
};

const MobileMenu = (props) =>  {
	const [ position, setPosition ] = useState(initialState.position);
	const [ alert, setAlert ] = useState(initialState.alert);
	const [popUp, setPopUp ] = useState(initialState.popUp);
	const [positionPopup, setPositionPopup ] = useState(initialState.positionPopup);
	const [file, setFile ] = useState();
	const [camera, setCamera ] = useState(initialState.camera);
	const [login, setLogin] = useState();
	const [create, setCreate] = useState();

	useEffect(() => {
		if(props.history.location.pathname.startsWith('/perfil')) {
			setPosition(positionMenu.profile);
		}
		setLogin(props.profile.profile);
	},[props.history.location.pathname,props.profile.profile ]);

	const handleRedirect = (value) => {
		props.handleSearchBarMobile(false);
		setPopUp(false);
		setPosition(value);
		switch (value) {
		case positionMenu.home:
			props.history.push('/');
			break;
		case positionMenu.search:
			props.handleSearchBarMobile(true);
			break;
		case positionMenu.create:
			if(login !== null) {
				break;
			} else {
				handleRedirect(positionMenu.profile);
			}
			break;
		case positionMenu.profile:
			if(login !== null) {
				openProfile(
					props.profile.profile.data.email,
					props.profile.profile.data.id,
					props.loadingProfile,
					props.history,
					props.visitStats,
					false,
				);
			} else {
				props.openPopUp(true);
			}			
			break;			
		default:
			break;
		}
	};

	const handlePopUp = (position) => {
		props.handleSearchBarMobile(false);
		setPopUp(true);
		setPositionPopup(position);
		
	};

	const handleFileUpload = async (file) => {
		setFile(file[0]);
		handlePopUp(positionCreate.file);
	};	

	const handleCamera = () => {
		setCamera(true);
		setTimeout(async ()=> {
			document.body.style.position = 'fixed';
			document.body.style.zIndex = '30';
			document.body.style.width = '100%';
			document.body.style.top = `-${window.scrollY}px`;
			if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
				const video = document.getElementById('video');
				let cameraProfile = await navigator.mediaDevices.getUserMedia(constraints);
				const cameraStart = (cameraProfile) => {
					video.srcObject = cameraProfile;
					video.play();
				};
				const cameraStop = (cameraProfile) => {
					let track = cameraProfile.getTracks()[0];
					track.stop();  
				};
				cameraStart(cameraProfile);
				document.getElementById('snap').addEventListener('click', function() {
					let canvas = document.createElement('canvas');
					canvas.height = video.offsetHeight;
					canvas.width = video.offsetWidth;

					const context = canvas.getContext('2d');
					let canvasScale = (canvas.height/canvas.width);
					context.drawImage(video, 0, 0, video.offsetWidth, video.offsetHeight);

					let canvasData = canvas.toDataURL();
					let canvasArray = canvasData.split(','), mime = canvasArray[0].match(/:(.*?);/)[1],
						canvasString = atob(canvasArray[1]), stringLength = canvasString.length, canvasBites = new Uint8Array(stringLength);
					while(stringLength--){
						canvasBites[stringLength] = canvasString.charCodeAt(stringLength);
					}
					setFile(new Blob([canvasBites], {type:mime}));
					cameraStop(cameraProfile);
					setTimeout(()=> {
						const scrollY = document.body.style.top;
						document.body.style.position = '';
						document.body.style.top = '';
						document.body.style.width = '';
						window.scrollTo(0, parseInt(scrollY || '0') * -1);
						handlePopUp(positionCreate.file);
						setCamera(false);
					}, 600);
				});
			}
		}		
		, 600);
	};

	return (
		<> 
			{ camera &&
			<div className={styles.cameraContainer}>
				<div className={styles.videoContainer}>
					<video className={styles.camera} id="video" autoPlay></video>

				</div>
				<button className={styles.cameraButton}  id="snap">Tomar Foto</button>
			</div>

			}
			{
				popUp ?
					<PopUpCreate 
						id='PopUpCreateContent'
						showPopUp={() => setPopUp(false)}
						handleClosePopUp={() => setPopUp(false)}
						name={'popupCreate'}
						positionPopup={positionPopup}
						data={file}
						mobileMenu={props.mobileMenu}
						mobileMenuPopUp={(value) => setCreate(value)}
						{...props}
					/> :
					undefined
			}
			
		
			<div className={styles.container}>
				<div className={styles.gridICons}>
					<div className={styles.relative}>
						<div className={
							position === positionMenu.home ? 
								`${styles.circleIcon} ${styles.backgroundBlue}` 
								: 
								styles.circleIcon
						} 
						onClick={()=> handleRedirect(positionMenu.home) }>
							<img 
								className={styles.icon20} 
								src={iconHomeWhite}
								alt="icon"
							/>
						</div>
						<div className={position === positionMenu.home ? styles.lineBlueActive : null}></div>
					</div>
					
					<div className={styles.relative}>
						<div 
							className={position === positionMenu.search ? 
								`${styles.circleIcon} ${styles.backgroundBlue}` 
								: 
								styles.circleIcon
							} 
							onClick={()=> handleRedirect(positionMenu.search)}>
							<img 
								className={styles.icon25} 
								src={iconSearch} 
								alt="icon"
							/>
						</div>
						<div className={position === positionMenu.search ? styles.lineBlueActive : null}></div>
					</div>
					
					<div className={styles.relative}>
						<div 
							className={position === positionMenu.create ? 
								`${styles.circleIcon} ${styles.backgroundBlue}` 
								: 
								styles.circleIcon
							} 
							onClick={()=> handleRedirect(positionMenu.create)}>
							<img 
								className={styles.icon25} 
								src={iconMore} 
								alt="icon"
							/>
						</div>
						<div 
							className={position === positionMenu.create ? styles.lineBlueActive : null}></div>
					</div>
					
					{/* <div className={styles.relative}>
						<div 
							className={position === positionMenu.notification ? 
								`${styles.circleIcon} ${styles.backgroundBlue}` 
								: styles.circleIcon
							} 
							onClick={()=> handleRedirect(positionMenu.notification)}>
							<img 
								className={styles.icon20} 
								src={iconNotification} 
								alt="icon"
							/>
						</div>
						<div className={position === positionMenu.notification ? styles.lineBlueActive : null}></div>
						{
							alert ?
								<div className={styles.circleAlert}>
									1
								</div>
								:
								null
						}
					</div> */}
					{login ? 
						<div className={styles.relative} >
							<div 
								className={position === positionMenu.profile ? `${styles.circleIcon} ${styles.backgroundBlue}`  : styles.circleIcon} 
								onClick={()=> handleRedirect(positionMenu.profile)}>
								<img 
									className={styles.icon20} 
									src={defaultImg} 
									alt="user"
								/>
							</div>
							{props.profile.profile && 
								props.profile.otherProfile && 
								props.profile.profile.data.email === props.profile.otherProfile.data.email ?
								<>
									<div 
										className={position === positionMenu.profile ? styles.circleBorder : null} 
										onClick={()=> handleRedirect(positionMenu.profile)}></div>
									<div className={position === positionMenu.profile ? styles.lineBlueActive : null}></div>
								</>
								: null
							}
						</div>
						: null
					}

				</div>
				
				{
					position === positionMenu.create && !popUp && !create ?
						
						<>
							<div onClick={()=> handleRedirect(positionMenu.home)} className={styles.fixedBlur} ></div>
							<div className={styles.boxMenu}>
								<Dropzone onDrop={handleFileUpload}>
									{({ getRootProps, getInputProps }) => (
										<div className={styles.btn} {...getRootProps()}>
											<input {...getInputProps()} />
											<div className={styles.itemMenu}>
												<img className={styles.icon20} src={iconImage} alt="icon"/>
												<h3 className={styles.h3menu}>Imagen</h3>
											</div>
										</div>
									)}
								</Dropzone>
								<div 
									className={styles.itemMenu}
									onClick={handleCamera}
								>
									<img className={styles.icon20} src={iconCamera} alt="icon"/>
									<h3 className={styles.h3menu}>Tomar una foto</h3>
								</div>
								<Dropzone onDrop={handleFileUpload}>
									{({ getRootProps, getInputProps }) => (
										<div className={styles.btn} {...getRootProps()}>
											<input {...getInputProps()} />
											<div className={styles.itemMenu}>
												<img className={styles.icon20} src={iconText} alt="icon"/>
												<h3 className={styles.h3menu}>Subir documento</h3>
											</div>
										</div>
									)}
								</Dropzone>
								<div 
									className={styles.itemMenu}
									datatest="create-content-with-link"
									onClick={() => handlePopUp(positionCreate.link)}
								>
									<img className={styles.icon20} src={iconLink} alt="icon"/>
									<h3 className={styles.h3menu}>Copia y pega links</h3>
								</div>
								<div 
									className={styles.itemMenu}
									onClick={() => handlePopUp(positionCreate.booklist)}
								>
									<img className={styles.icon20} src={iconBooklist} alt="icon"/>
									<h3 className={styles.h3menu}>Agrupa tu contenido creando <strong>Booklist</strong></h3>
								</div>
							</div>
						</>
						:
						null
				}
				
			</div>
		</>	
	);
};

function mapStateToProps({ profile }) {
	return { 
		profile,
	};
}

export default withRouter(connect(mapStateToProps, {
	loadingProfile,
	visitStats,
	openPopUp
})(MobileMenu));
