import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getMyBooklist, getMoreMyBooklist } from '../../../actions';

import { ImpulseSpinner } from 'react-spinners-kit';
import LoaderBooklistContent from '../../booklists/LoaderBooklistContent';
import CardBooklist from '../CardBooklist';

const MyBooklists = ({
	loading,
	myBooklist,
	handleCardSelect,
	selectedBooklistId,
	getMyBooklist,
	getMoreMyBooklist,
}) => {
	const [page, setPage] = useState(1);

	useEffect(() => {
		if (!myBooklist.booklists) {
			getMyBooklist();
		}
	}, []);

	useEffect(() => {
		if (
			myBooklist.booklists &&
			!loading.moreMyBooklist &&
			myBooklist.hasNextPage
		) {
			let observerScroll = document.getElementById('observerScroll');
			let intersectionObserver = new IntersectionObserver(
				(entries) => {
					if (entries[0].isIntersecting) {
						handleMoreMyBooklists();
					}
				},
				{
					rootMargin: '0px 0px 1px 0px',
				}
			);
			intersectionObserver.observe(observerScroll);
		}
	}, [myBooklist.booklists, loading.moreMyBooklist]);

	const handleMoreMyBooklists = async () => {
		if (myBooklist.hasNextPage) {
			getMoreMyBooklist(page + 1);
			setPage(page + 1);
		}
	};

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					maxHeight: '40vh',
					overflow: 'auto',
					justifyItems: 'center',
				}}
			>
				{!myBooklist.booklists || loading.myBooklist ? (
					<LoaderBooklistContent height="147px" width="100%" />
				) : (
					myBooklist.booklists.map((booklist) => (
						<CardBooklist
							key={booklist.id}
							widthCard={'100%'}
							heightCard={'147px'}
							content={booklist}
							isSelected={booklist.id === selectedBooklistId}
							onSelect={handleCardSelect}
						/>
					))
				)}
				{myBooklist.booklists &&
				myBooklist.hasNextPage &&
				!loading.moreMyBooklist ? (
					<div
						style={{ marginTop: '20px', padding: '5px' }}
						id="observerScroll"
					/>
				) : null}
			</div>

			{loading.moreMyBooklist && (
				<div
					style={{
						width: 'fit-content',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<ImpulseSpinner
						size={5}
						color="var(--primary-color)"
						frontColor="#10CFC9"
						backColor="#F0FBFF"
						loading={loading.moreMyBooklist}
					/>
				</div>
			)}
		</>
	);
};

const mapStateToProps = ({ contextMenu }) => {
	return {
		loading: contextMenu.loading,
		myBooklist: contextMenu.myBooklist,
	};
};

export default withRouter(
	connect(mapStateToProps, { getMyBooklist, getMoreMyBooklist })(MyBooklists)
);
