import React, {Component} from 'react';
import Cognito from '../../../helpers/cognito';
import { deleteFollowing, putFollowing, postHide, visitStats, loadingProfile } from '../../actions';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import styles from './styles/contentProfiles.scss';
import {openProfile} from '../../../helpers/profileRouting';
import { withTranslation } from 'react-i18next';

const imageProfile = 'https://cdn.booklick.net/public/img/profile.png';
const iconFollowBlue = 'https://cdn.booklick.net/public/img/icons/icon-follow-blue.svg';
const iconFollowWhite = 'https://cdn.booklick.net/public/img/icons/icon-follow-white.svg';
const iconCloseBlue = 'https://cdn.booklick.net/public/img/icons/icon-close-blue-light.svg';

class ContentProfilesItem extends Component{

	constructor(props) {
		super(props);
		this.state = {
			profile: props,
			followed: false,
			buttonFollowedText: this.props.t('buttonProfileFollowed'),
			logged: false
		};
		this.handleFollow = this.handleFollow.bind(this);
		this.handleUnFollow = this.handleUnFollow.bind(this);
		this.handleMouseEnterButtonFollow = this.handleMouseEnterButtonFollow.bind(this);
		this.handleMouseLeaveButtonFollow = this.handleMouseLeaveButtonFollow.bind(this);

	}

	componentDidMount() {
		this.fetchData();
	}

	async fetchData() {
		try {
			const session = await Cognito.retrieveToken();
			this.setState({ logged: true});
		} catch (error) {
			console.log('No logged');
		}
	}

	async handleUnFollow() {
		try {
			this.setState({
				followed: false
			});
			await deleteFollowing(this.state.profile.email);
		} catch (error) {
			console.log('Error', error);
		}
	}

	async handleFollow() {
		try {
			this.setState({
				followed: true
			});
			await this.props.putFollowing(this.state.profile.email);
		} catch (error) {
			console.log('Error', error);
		}
	}

	handleMouseEnterButtonFollow() {
		this.setState({
			buttonFollowedText: this.props.t('buttonProfileLeave')
		});
	}

	handleMouseLeaveButtonFollow()  {
		this.setState({
			buttonFollowedText: this.props.t('buttonProfileFollowed')
		});
	}

	async removeProfile(id, email){
		this.props.removeProfile(id);
		await postHide(email);
	}

	onProfileClick(email, id) {
		openProfile(
			email,
			id,
			this.props.loadingProfile,
			this.props.history,
			this.props.visitStats,
		);
	}
	getRole(){
		let roleMayor;
		const rol = this.state.profile.roleMayor ? this.state.profile.roleMayor.split(' ')[0]: '';
    	const mayor = this.state.profile.roleMayor ? this.state.profile.roleMayor.split(' ')[2]: '';
		if(this.state.profile.roleMayor) {
			switch (rol) {
			case 'Estudiante':
				roleMayor =  this.props.t('roleStudent',{mayor:mayor.toLowerCase()});
				break;
			case 'Profesor':
				roleMayor = this.props.t('roleTeacher',{mayor:mayor.toLowerCase()});
				break;
			case 'Administrativo':
				roleMayor = this.props.t('roleAdmin');
				break;
			case 'Egresado':
				roleMayor = this.props.t('titleGraduate');
				break;
			default:
				roleMayor = rol;
				break;
			}
			return roleMayor;
		}
	}

	render() {
		const {t} = this.props;
		return(
			<div className={styles.gridSpaceUsers}>
				<div className={styles.gridAuto10}>
					<div className={styles.circleUser}>
						<img className={styles.imgCard} src={this.state.profile.profileImgUrl != null ? this.state.profile.profileImgUrl : imageProfile} alt="user"
							onClick={() => this.onProfileClick(this.state.profile.email, this.state.profile.id)}/>
					</div>
					<div className={`${styles.gridRow5} ${styles.contentInfoMob}`}>
						<h2 className={styles.h2name}
							onClick={() => this.onProfileClick(this.state.profile.email, this.state.profile.id)}
						>{this.state.profile.firstName + ' ' + this.state.profile.lastName}</h2>
						<h3 className={styles.h3info}>{this.getRole()}</h3>
					</div>
				</div>

				<div>
					{this.state.logged && (this.state.followed ?
						<div className={`${styles.gridAuto5} ${styles.unfollow} ${styles.gridContentBtn}`}>
							<div className={styles.btnUnfollowRed}
								onMouseEnter={this.handleMouseEnterButtonFollow}
								onMouseLeave={this.handleMouseLeaveButtonFollow}
								onClick={() => this.handleUnFollow()}>
						        {this.state.buttonFollowedText}
							</div>
						</div>
						:
						<div className={`${styles.gridAuto5} ${styles.unfollow} ${styles.gridContentBtn}`}>
							<div className={styles.btnFollowInactive}
								onClick={() => this.handleFollow()}>
								{t('buttonProfileFollow')}
								<div className={styles.iconBlue}>
									<img className={styles.icon14} src={iconFollowBlue} alt="follow"/>
								</div>
								<div className={styles.iconWhite}>
									<img className={styles.icon14} src={iconFollowWhite} alt="follow"/>
								</div>
							</div>
							<div className={`${styles.pointer} ${styles.row1}`}>
								<img className={styles.icon22} src={iconCloseBlue}
									onClick={this.removeProfile.bind(this, this.state.profile.id, this.state.profile.email)} alt="delete"/>
							</div>
						</div>
					)}


				</div>
			</div>
		);
	}
}
function mapStateToProps() {
	return {};
}

export default withRouter(connect(mapStateToProps, {
	visitStats,
	loadingProfile,
	putFollowing,
})(withTranslation()(ContentProfilesItem)));
