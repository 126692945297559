import axios from 'axios';
import {environment, fetchOthersContentFunction} from './index';
import Cognito from '../../helpers/cognito';
import {uploadFile} from './uploadFileActions';
import { SET_EZPROXY_LOCATION} from './userActions';
import { 
	GET_PROFILE_CATEGORIES,
	PUT_PROFILE_CATEGORIES,
	PUT_PROFILE_NEW
} from '../types/profileTypes';

import {
	UPDATE_PROFILE_IMAGE
} from '../types/contentTypes';

export const LOADING_PROFILE = 'LOADING_PROFILE';
export const LOADING= 'LOADING';
export const OTHER_PROFILE = 'OTHER_PROFILE';
export const FETCH_FOLLOWERS = 'FETCH_FOLLOWERS';
export const FETCH_FOLLOWING = 'FETCH_FOLLOWING';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_PERFORMANCE = 'FETCH_PROFILE_PERFORMANCE';
export const ERROR_LOGGING_IN = 'ERROR_LOGGING_IN'
export const LOG_OUT = 'LOG_OUT';

export const loadingProfile = (email, id, isMyProfilePage) => async(dispatch) => {
	dispatch({
		type: LOADING_PROFILE,
	});
	fetchProfileByEmailAuthFunction(dispatch, email);
	if(!isMyProfilePage) {
		fetchOthersContentFunction(dispatch, email);
	}
	if (id) {
		fetchFollowersFunction(dispatch, id);
		fetchFollowingFunction(dispatch, id);
	}
};

export const logOut = () => async(dispatch) => {
	localStorage.removeItem(SET_EZPROXY_LOCATION);
	Cognito.singOut();
	return dispatch({
		type: LOG_OUT,
	});
};

export const fetchProfile = () => async(dispatch, getState) => {
	try {
		const dateSTR = localStorage.getItem(SET_EZPROXY_LOCATION);
		const now = new Date();
		if (dateSTR && now.getTime() > (new Date(Number(dateSTR))).getTime()) {
			Cognito.singOut();
			localStorage.removeItem(SET_EZPROXY_LOCATION);
			window.location.href = '/';
			return;
		}
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${env.msProfile}/profile`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});
		dispatch({
			type: FETCH_PROFILE,
			payload: request.data
		});
		return request.data;
	} catch (error) {
		console.error(error);
	}
	
};

function fetchFollowingFunction(dispatch, id) {
	return axios({
		method: 'GET',
		url: `${env.msProfile}/profile/${id}/following`,
		headers: {
			env: environment
		}
	})
		.then((response) => {
			dispatch({
				type: FETCH_FOLLOWING,
				payload: response.data
			});
		});
}

function fetchFollowersFunction(dispatch, id) {
	return axios({
		method: 'GET',
		url: `${env.msProfile}/profile/${id}/followers`,
		headers: {
			env: environment
		}
	})
		.then((response) => {
			dispatch({
				type: FETCH_FOLLOWERS,
				payload: response.data,
			});
		});
}

const fetchProfileByEmailAuthFunction = async(dispatch, email) => {
	dispatch({
		type: LOADING,
	});
	try {
		const session = await Cognito.retrieveToken();
		if (session) return fetchProfileByEmailFunction(dispatch, email, session);
		else return fetchProfileByEmailFunction(dispatch, email);
	} catch (error) {
		return fetchProfileByEmailFunction(dispatch, email);
	}
};

const fetchProfileByEmailFunction = async(dispatch, email, session) => {
	try {
		const headers = session && {
			'Authorization': session.idToken.jwtToken,
		};
		const request = await axios({
			method: 'GET',
			url: `${env.msProfile}/profile/email/${email}`,
			headers,
		});
		dispatch({
			type: OTHER_PROFILE,
			payload: request.data,
		});

		return request.data;
	} catch (error) {
		console.error(error);
	}
};

export const fetchOtherProfile = async(email) =>{
	try {
		const response = await axios({
			method: 'GET',
			url: `${env.msProfile}/profile/email/${email}`,
		});
		return response;
	} catch (error) {
		console.error(error);
	}
};

export const fetchProfileByEmail = (email) => async(dispatch) => {
	return fetchProfileByEmailAuthFunction(dispatch, email);
};

export const getProfileCategories = () => async (dispatch) =>{
	try {
		const session = await Cognito.retrieveToken();

		const requestCategory = await axios({
			method: 'GET',
			url: `${env.msProfileNew}/api/profile-categories`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				env: environment,
			},
		});

		const profileCategory = requestCategory.data.map((category) => {
			return category.category.label.toLowerCase();
		});
		dispatch({
			type: GET_PROFILE_CATEGORIES,
			payload: requestCategory.data
		});
		return profileCategory;
	} catch (error) {
		return [];
	}
};

export const editProfile = async (profileId, params, profilePicture) => {
	try {
		const session = await Cognito.retrieveToken();
		if (profilePicture) {
			const urlImage = await uploadFile(
				profilePicture,
				session,
				null,
				null
			);
			params['profileImgUrl'] = urlImage;
		}
		const request = await axios({
			method: 'PUT',
			url: `${env.msProfileNew}/api/v2/profiles/${profileId}`,
			data: params,
			headers: {
				Authorization: session.idToken.jwtToken,
				env: environment,
			},
		});

		return request.status;
	} catch (error) {
		console.error(error);
	}
};

export const EditProfileNew  = (params, file, cover) => async(dispatch) => {
	try {
		const session = await Cognito.retrieveToken();
		if(file) {
			let profile;
			const urlImage = await uploadFile(file, session, null, null);
			if(cover){
				profile = await putProfileNew(dispatch, session, params, urlImage, cover);
			}else{
				profile = await putProfileNew(dispatch, session, params, urlImage);
			}
			return profile;
		} else {
			const profile = await putProfileNew(dispatch, session, params, null);
			return profile;
		}
	} catch (error) {
		console.error(error);
	}
};

const putProfileNew = async(dispatch, session, params, imgURL, cover) => {
	if(cover){
		if (imgURL) {
			params['coverImgUrl'] = imgURL;
		}
	}else{
		if (imgURL) {
			params['profileImgUrl'] = imgURL;
			dispatch({
				type: PUT_PROFILE,
				payload: {key: 'profileImgUrl', data: imgURL}
			});
			dispatch({
				type: UPDATE_PROFILE_IMAGE,
				payload: imgURL
			});
		}
	}

	try {
		const request = await axios({
			method: 'PUT',
			url: `${env.msProfileNew}/api/profiles`,
			data: {
				...params,
			},
			headers: {
				'Authorization': session.idToken.jwtToken,
				env:environment
			}
		});
		dispatch({
			type: PUT_PROFILE_NEW,
			payload: request.data
		});
		return dispatch({
			type: END_PUT_PROFILE,
			payload: request.data
		});
	} catch (error) {
		console.error(error);
	}
};

export const fetchProfilePerformance = () => async(dispatch) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${env.msProfileNew}/api/v2/profiles`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});
		dispatch({
			type: FETCH_PROFILE_PERFORMANCE,
			payload: request.data
		});
		return request.data;
	} catch (error) {
		dispatch({
			type: ERROR_LOGGING_IN,
			payload: 'ERROR_LOGGING_IN'
		});
		console.error(error);
	}
	
};

export const fetchMyProfileNew = (email) => async(dispatch) => {
	try {
		const dateSTR = localStorage.getItem(SET_EZPROXY_LOCATION);
		const now = new Date();
		if (dateSTR && now.getTime() > (new Date(Number(dateSTR))).getTime()) {
			Cognito.singOut();
			localStorage.removeItem(SET_EZPROXY_LOCATION);
			window.location.href = '/';
			return;
		}
		const session = await Cognito.retrieveToken();
        const [profile,countContent]= await Promise.all([
            getProfileByEmail(email, session.idToken.jwtToken),
            getCountsFoProfile(email, session.idToken.jwtToken, environment)
        ]);
        const resp = {
            ...profile,
            contentsCount: countContent,
            followersCount: profile.countFollowers,
            followingCount: profile.countFollowings
        };
		dispatch({
			type: FETCH_PROFILE_PERFORMANCE,
			payload: resp
		});
		return resp;
	} catch (error) {
		console.error(error);
	}
	
};

export const fetchOtherProfileByEmail = async(email) => {
	try {
        const session = await Cognito.retrieveToken();
        const [profile,countContent]= await Promise.all([
            getProfileByEmail(email, session.idToken.jwtToken),
            getCountsFoProfile(email, session.idToken.jwtToken, environment)
        ]);
        const resp = {
            ...profile,
            contentsCount: countContent,
            followersCount: profile.countFollowers,
            followingCount: profile.countFollowings
        };     
        return resp;
    } catch (error) {
        console.error(error);
    }
	
};

export const getProfileByEmail = async (email, authorizationToken) => {
  if (!authorizationToken) {
    const session = await Cognito.retrieveToken();
    authorizationToken = session.idToken.jwtToken;
  }
  try {
    const request = await axios.get(
      `${env.msProfileNew}/api/v2/profiles/email/${email}`,
      {
        headers: {
          Authorization: authorizationToken,
          env: environment,
        },
      }
    );

    return request.data;
  } catch (error) {
    console.error(error);
  }
};
export const getCountsFoProfile = async (
  email,
  authorizationToken,
  environment
) => {
  try {
    const request = await axios.get(`${env.msContentNew}/api/contents/count`, {
      headers: {
        Authorization: authorizationToken,
        env: environment,
        email: email,
      },
    });
    return request.data.countContents;
  } catch (error) {
    console.error(error);
  }
};

export const fetchFollowers = (id) => async(dispatch) => {
	fetchFollowersFunction(dispatch, id);
};

export const fetchFollowing = (id) => async(dispatch) => {
	fetchFollowingFunction(dispatch, id);
};

export const END_PUT_PROFILE = 'END_PUT_PROFILE';
export const PUT_PROFILE = 'PUT_PROFILE';
export const putProfile = (params, file) => async(dispatch) => {
	Object.keys(params).map(key =>
		dispatch({
			type: PUT_PROFILE,
			payload: {key: key, data: params[key]}
		})
	);
	return Cognito.retrieveToken()
		.then((session) => {
			if (file) {
				return uploadFile(file, session, null, null)
					.then(url => {
						dispatch({
							type: PUT_PROFILE,
							payload: {key: 'profileImgUrl', data: url}
						});
						return putProfileEditRequest(dispatch, session, params, url);
					});
			} else {
				return putProfileEditRequest(dispatch, session, params, null);
			}
		});
};


const putProfileEditRequest = (dispatch, session, params, imgURL) => {
	const nParams = { ...params };
	if (imgURL) {
		nParams['profileImgUrl'] = imgURL;
	}
	return axios({
		method: 'PUT',
		url: `${env.msProfile}/profile`,
		data: {
			...nParams
		},
		headers: {
			'Authorization': session.idToken.jwtToken,
			'env': environment
		}
	})
		.then(response => {
			return dispatch({
				type: END_PUT_PROFILE,
				payload: response.data
			});
		});
};

export const getProfilesToShare = async () => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${env.msProfileNew}/api/profiles/share`,
			headers: {
				'Authorization': session.idToken.jwtToken,
			}
		});
		return request.data; 
	} catch (error) {
		console.error(error.message);
	}
};

export const getFollowingsProfile = async (email,page,limit=9) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${env.msProfileNew}/api/profile-followings/${email}/followings?page=${page}&limit=${limit}`,
			headers: {
				'Authorization': session.idToken.jwtToken,
			}
		});
		return request.data; 
	} catch (error) {
		console.error(error.message);
	}
};

export const getFollowersProfile = async (email,page,limit=9) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${env.msProfileNew}/api/profile-followings/${email}/followers?page=${page}&limit=${limit}`,
			headers: {
				'Authorization': session.idToken.jwtToken,
			}
		});
		return request.data; 
	} catch (error) {
		console.error(error.message);
	}
};
