import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Dropzone from 'react-dropzone';
import styles from './styles/popupCreate.scss'
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import { 
  searchProfiles,
  postPermissions,
  putPrivacy,
  putBooklist,
  putTags,
  loadingProfile,
} from '../../actions';
import { withTranslation } from 'react-i18next';

import CreateBooklistUserCard from './CreateBooklistUserCard'

const iconDeleteTag = "https://cdn.booklick.net/public/img/icons/icon-delete-white.svg";
const iconRemoveUser = 'https://cdn.booklick.net/public/img/icons/close-g.svg';
const iconArrowDown = 'https://cdn.booklick.net/public/img/icons/arrow-down-s-fill.svg';

const millisecondForRender = 600;

class CreateBooklistDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      booklist: this.props.booklistId || null,
      public: false,
      private: false,
      edit: false,
      view: false,
      tag: null,
      tags: [],
      permissions: [],
      search: null,
      searchs: [],
      users: [],
      dropGray: false,
      dropWhite: false,
      previewPermissions: [],
      generalPermissions: null,
      data: [],
      alertMessage: false,
      loadingData: false,
    }
    this.handlePutTag = this.handlePutTag.bind(this)
    this.handleAddUsers = this.handleAddUsers.bind(this)
    this.handleSubmitBooklistDetails = this.handleSubmitBooklistDetails.bind(this)
   
  }

  componentDidMount() {
    let input = document.getElementById('hashtag')
    input.addEventListener('keyup', function(event) {
      if(event.keyCode === 13) {
        event.preventDefault();
        document.getElementById('tagbutton').click();
        input.value = null
      }
    })

  }

  handleTag = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handlePutTag() {
    let tags = []
    if(this.state.tag !== null && this.state.tag !== '') {
      this.setState({
        tags: [...this.state.tags, this.state.tag],
        tag: null
      })
    }
  }
  hadleRemoveTag(item) {
    this.setState({
      tags: this.state.tags.filter(element => element !== item)
    })
  }

  handleSearch = async event => {
    try {
      this.setState({
        dropGray: true,
        searchs: [],
        [event.target.name]: event.target.value,
      })
      let searchTimeout;
      document.getElementById('searchUsers').onkeypress = () => {
        if (searchTimeout !== undefined) clearTimeout(searchTimeout);
        searchTimeout = setTimeout(request, millisecondForRender)
      }
      const request = async () => {
        try {
          const search = await this.props.searchProfiles(this.state.search)
          if(search) {
            this.setState({
              searchs: search
            })
          } else {
            this.setState({
              searchs: []
            })
          }          
        }
        catch (error) {
          console.error(error)
        }
      }
    } catch (error) {
      console.error(error)
    }
    
  }

  handleSelectUser = user => {
    document.getElementById('searchUsers').value = ''
    const repeatedUserInSearch = this.state.users.every(item => item.email !== user.email)
    const repeatedUserInPreview = this.state.previewPermissions.every(permission => permission.email !== user.email)
    if(!repeatedUserInSearch) {
      this.setState({
        search: null,
        searchs: null,
        dropGray: false
      })
    } else {
      if(!repeatedUserInPreview) {
        this.setState({
          search: null,
          searchs: null,
          dropGray: false
        })
      } else {
        this.setState({
          searchs: null,
          search: null,
          dropGray: false,
          users: [...this.state.users, user],
        })
      }
    }
  }

  handleRemoveUser = (user, span) => {
    if(span) {
      const filter = this.state.users.filter(item => item.email !== user)
      this.setState({
        users: filter,
      })
    } else {
      const deletePreview = this.state.previewPermissions.filter(item => item.email !== user.email) 
      const newData = this.state.data.filter(item => item.email !== user.email)
      this.setState({
        previewPermissions: deletePreview,
        data: newData
      })

    }
    
  }

  handleAddUsers() {
    const permission = this.state.edit ? 'edit' : 'view';
    const data = this.state.users.map(item => {
      return ({
        email: item.email,
        permissions: [
          permission
        ]
      })
    })
    this.setState({
      generalPermissions: permission,
      previewPermissions: [...this.state.previewPermissions, ...this.state.users],
      data: [...this.state.data, ...data],
      users: [],
      view: false,
      edit: false
    })
  }

  handleEditPemission = (email, permissions) => {
    const newData = this.state.data.map(item => {
      if(email === item.email) {
        return ({
          email: item.email,
          permissions: [
            permissions
        ]
        })
      } else {
        return ({
          email: item.email,
          permissions: item.permissions
        })
      }
    })

    this.setState({
      data: newData
    })
  }

  handleSubmitBooklistDetails = async() => {
    try {
      if(
        (this.state.public === false && this.state.private === false)
        ) {
        this.handleShake()
      } else {
        this.setState({
          loadingData: true
        })
       const tags = await this.props.putTags(this.state.booklist.id, this.state.tags)
  
        const booklist = {
          title: this.state.booklist.title,
          description: this.state.booklist.description,
          slug: this.state.booklist.slug,
          isPublic: this.state.public ? true : false
        }
        const privacy = await this.props.putPrivacy(booklist)
        //await this.props.putBooklist(putIsPublic, null);
        if(this.state.data.length !== 0) {
          await this.props.postPermissions(parseInt(this.state.booklist.id), this.state.data)
        }
        this.setState({
          loadingData: false
        })
        if(this.props.mobileMenuPopUp) {
          this.props.mobileMenuPopUp(true)
        }
        this.props.handleClosePopUp(true)
        (window.location = `/booklist/${this.state.booklist.slug}`)

      }
    } catch (error) {
      console.error(error)
    }
   
  }
  handleShake = () =>{
    this.setState({
      alertMessage: true
    })
    setTimeout(
      () => this.setState({
        alertMessage:false
      })
      ,500)
  }

  
  render() {

    const {t} = this.props
    return (
      
      <div className={`${styles.gridRow10} ${styles.alignStart}` }>
								
      <div className={styles.gridLastPop}>
        
        <div className={styles.gridRow10}>
          <h2 className={styles.h2Title2}>{t('titleCreateBooklistDetails')}</h2>
          <div className={styles.gridBtn550}>
            <input 
              name='tag'
              id='hashtag'
              type="text" 
              className={styles.inputLightSimple} 
              data-tip={t('inputKeyword')}
              placeholder={t('titleEditEntryHintListKeywordHint')}
              onChange={this.handleTag}
              />
              <div className={styles.btn550} onClick={this.handlePutTag}>
                {t('textCreateBooklistDetailsAdd')}
              </div>

          </div>
            <div className={styles.none550}>
              <ReactTooltip 
                  className={styles.tagsTooltip} 
                  place="top" 
                  effect="solid" 
                  textColor='white' 
                  theme='dark' 
                  arrowColor='var(--primary-color)'
                  backgroundColor='linear-gradient(to right,var(--primary-color),var(--primary-color))'
                />

            </div>
            <button id='tagbutton' onClick={this.handlePutTag} hidden></button>
        </div>        
        <div className={styles.flexHash}>
          {
            this.state.tags.length !== 0 ?
              this.state.tags.map((item, index) => {
                return (
                  <div key={index} className={styles.btnDarkHash}>
                    {item}
                    <div onClick={() => this.hadleRemoveTag(item)}> 
                      <img className={styles.iconDelete} src={iconDeleteTag} alt="delete"/>
                    </div>
                  </div>
                )
              })
              :
              null
          }
        </div>
        
      </div>
      
      <div className={styles.lineDivisionGrey}></div>
      
      <div className={styles.gridAuto20Last}>
        <h2 className={styles.h2Title2}>{t('textCreateBooklistDetailsPrivacity')}</h2>
        <div className={styles.privacyBtnContainer}>
          {
            this.state.public === false ? 
              <div 
                className={this.state.alertMessage && 
                (this.state.public === false && this.state.private === false) ? 
                `${styles.btnAdd} ${styles.alertVibration} ${styles.btnMobile}` 
                : `${styles.btnAdd} ${styles.btnMobile}`
                }
                onClick={() => {
                  this.setState({
                    public: true,
                    private: false
                  })
                  }
                }
              >
                {t('textCreateBooklistDetailsPrivacityPublic')}
              </div>
              :
              <div className={`${styles.btnGreen} ${styles.btnMobile}`}
                onClick={() => {
                  this.setState({
                    public: false,
                  })
                }
              }
              >
                {t('textCreateBooklistDetailsPrivacityPublic')}
              </div>
          }

          {
            this.state.private === false  ?
              <div 
              className={this.state.alertMessage && 
                (this.state.public === false && this.state.private === false) ?
                `${styles.btnAdd} ${styles.alertVibration} ${styles.btnMobile}` 
                : `${styles.btnAdd} ${styles.btnMobile}`
                }
                onClick={() => {
                  this.setState({
                    private: true,
                    public: false,
                  })
                  }
                }
              >
                {t('textCreateBooklistDetailsPrivacityPrivate')}
              </div>
              :
              <div className={`${styles.btnGreen} ${styles.btnMobile}`}
                onClick={() => {
                  this.setState({
                    private: false,
                  })
                  }
                }
              >
                {t('textCreateBooklistDetailsPrivacityPrivate')}
              </div>

          }
        
          

        </div>
        
      </div>
      
      <div className={styles.gridSpaceLast}>
        
        <h2 className={styles.h2Title2}>{t('textEditCollaborators')}</h2>
        
        <div className={styles.gridAuto20Last}>
          <h2 className={`${styles.h2Title2} ${styles.none550}`}>{t('textEditCollaboratorsMay')}</h2>
          
        </div>
      
      </div>
      

      <div className={styles.relative}>
        <div className={styles.searchUserContainer} >
          <div className={styles.searchUserInput} >
            <ul className={styles.searchUserInputContainer}>
              {this.state.users.length !== 0 && this.state.users.map(item => {
                return (
                  <li className={styles.searchInputItem}>
                    <div className={styles.searchInputItemName}>{`${item.firstName} ${item.lastName}`}</div>
                    <div className={styles.searchInputItemImg} onClick={() => this.handleRemoveUser(item.email, true)}>
                      <img className={styles.iconDelete} src={iconRemoveUser} />
                    </div>
                  </li>
                )
              })}
              <li className={styles.searchInputLi}>
                <input
                  id='searchUsers'
                  autocomplete='off'
                  type="text"
                  name="search"
                  className={`${styles.inputLightSimple} ${styles.boxShadowNone}`} 
                  placeholder={t('textEditCollaboratorsMail')}
                  onChange= {this.handleSearch}
                />
              </li>
            </ul>
          </div>
          <div className={styles.searchMobile}>
            <div className={styles.relativeSearch}>
              <div className={styles.selectDropWhiteSearch}  onClick={() => this.setState({ dropWhite: !this.state.dropWhite})}>
                {
                  this.state.view ? 
                    t('buttonView')
                  :
                    this.state.edit ? 
                    t('textContextMenuEdit')
                    :
                    t('textEditCollaboratorsSelect')
                } 
                <div className={styles.dropDownArrowContainer2}>
                      <img className = {styles.arrowImg}src={iconArrowDown} alt='arrow' />
                    </div>
              </div>
            {
              this.state.dropWhite ?
                <div className={styles.posDropWhite}>
                  <div className={styles.boxDropWhite}>
                    <div className={styles.itemDropWhite} 
                      onClick={() => this.setState({ dropWhite: false, edit: true, view: false})}
                    >
                      {t('textContextMenuEdit')}
                    </div>
                    {!this.state.public && 
                      <div className={styles.itemDropWhite} 
                        onClick={() => this.setState({ dropWhite: false, view: true, edit: false})}
                      >
                        {t('buttonView')}
                      </div>
                    }
                  </div>
                </div>
              :
              null
            }
            </div>
            
            <button onClick={this.handleAddUsers}  className={styles.btnGreen2} >{t('textCreateBooklistDetailsAdd')}</button>

          </div>
      </div>
        {this.state.dropGray ?
          <div className={styles.posDropGray}>
            <div className={styles.boxDropGray}>
                {this.state.search !== '' && this.state.searchs && this.state.searchs.map((item, index) => {
                  return (
                    <CreateBooklistUserCard 
                    key={index} 
                    handleSelectUser={(item) => this.handleSelectUser(item)} item={item}
                    />
                  )
                })}
          
               
            </div>
          </div>
            :
            null
        }

      </div>
      


      <div className={styles.boxUsersPop}>
        
        <div className={styles.gridUsers}>
          {this.state.previewPermissions && this.state.previewPermissions.length !== 0 && this.state.previewPermissions.map((item, index) => {
         
            return (
              <CreateBooklistUserCard
                key={item.email}
                generalPermissions={this.state.generalPermissions} 
                handleSelectUser={(item) => this.handleSelectUser(item)}
                item={item}
                permission={true}
                handleRemoveUser={(item) => this.handleRemoveUser(item)}
                handleEditPemission={(email,permission) => this.handleEditPemission(email,permission)}
                />
            )
          })}
        </div>
        
      </div>
      
      
      
      <div className={styles.grid}>
        <div className={styles.gridEnd}>
          <div 
            className={`${styles.btnGreen} ${styles.widthSubmit} ${styles.btnPreferences}`}
            onClick={this.handleSubmitBooklistDetails}
          >
             {this.state.loadingData ? <div className={`${styles.fileSpinner} ${styles.fileIcon}`} aria-hidden="true"></div> : t('textPreferenceSave')}
          </div>
        </div>
      </div>
      
    </div>
    
    )
  }

}

function mapStateToProps({ profile }) {
	return { 
    profile,
  
  };
}

export default withRouter(connect(mapStateToProps, {
  searchProfiles,
  postPermissions,
  putBooklist,
  putPrivacy,
  putTags,
  loadingProfile
} )(withTranslation()(CreateBooklistDetails)));