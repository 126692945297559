import { redirectNewSearch } from '../../utils/redirectRoutes';
import {
	LOADING,
	LOADED,
	ERROR,
	ADVANCED_SEARCH,
	ADVANCED_SEARCH_NAVBAR,
	CLEAR_ADVANCED_SEARCH,
	SEARCH_PROFILES,
	FILTERS,
	LOADING_FILTERS,
	NEXT_PAGE
} from '../types/searchTypes';


const initialState = {
	inputSearch: null,
	results: null,
	loading: false,
	loadingFilters: false,
	error: '',
	clearAdvancedSearch: false,
	profiles: null,
	searchTerm: null,
	filters:null,
	hasNextPage:null,
	totalPages:null,
	totalHits:null
};

const profileType = 'profiles';
const contentType = 'content';
const filterType = 'filters';

export default (state = initialState, action) => {
	switch (action.type) {
	case LOADING:
		return {
			...state,
			loading: true,
			results: null,
			profiles: null,
			searchTerm: action.payload,
		};
	case LOADING_FILTERS:
		return {
			...state,
			loadingFilters: action.payload,
		};
	case CLEAR_ADVANCED_SEARCH:
		return {
			...state,
			clearAdvancedSearch: true,
		};
	case ADVANCED_SEARCH_NAVBAR:
		return {
			...state,
			loading: false,
		};
	case ADVANCED_SEARCH:
		let response = action.payload.results;
		let results = [];
		let profiles = [];
		let filters;
		const redirectSearch = redirectNewSearch();
		if (redirectSearch) {
			for (let key in response) {
				if (key === profileType) {
					profiles.push(...response[key]);
				} else if (key === contentType) {
					results.push(...response[key]);
				} else if (key === filterType) {
					filters = { ...response[key] };
				}
			}

			return {
				...state,
				loading: false,
				clearAdvancedSearch: false,
				results,
				profiles,
				searchTerm: action.payload.term,
				filters,
				hasNextPage: action.payload.results.hasNextPage,
				totalPages: action.payload.results.totalPages,
				totalHits: action.payload.results.totalHits,
			};
		} else {
			for (let key in response) {
				if (key === profileType) {
					profiles.push(...response[key]);
				} else {
					results.push(...response[key]);
				}
			}
			results = results.sort(() => Math.random() - 0.5);

			return {
				...state,
				loading: false,
				clearAdvancedSearch: false,
				results,
				profiles,
				searchTerm: action.payload.term,
			};
		}

	case FILTERS:
	
		return {
			...state,
			results: action.payload.content,
			profiles:action.payload.profiles,
			hasNextPage:action.payload.hasNextPage,
			totalPages:action.payload.totalPages,
			loadingFilters: false,

		};
	
	case NEXT_PAGE:
		return {
			...state,
			results: [...new Set([...state.results, ...action.payload.content])],
			profiles:[...new Set([...state.profiles, ...action.payload.profiles])],
			hasNextPage: action.payload.hasNextPage,
			totalPages:action.payload.totalPages,
			loadingFilters: false,
		};
	
	
	case LOADED:
		return {
			...state,
			loading: false
		};
	case ERROR:
		return {
			...state,
			error: action.payload,
		};
	case SEARCH_PROFILES:
		return {
			...state,
			profiles: action.payload
		};
	default:
		return state;
	}
};
