import {
	END_PUT_PROFILE,
	FETCH_FOLLOWERS,
	FETCH_FOLLOWING,
	FETCH_PROFILE,
	FETCH_PROFILE_PERFORMANCE,
	FOLLOW_PROFILE,
	LOADING,
	LOADING_PROFILE,
	LOG_OUT,
	OTHER_PROFILE,
	PUT_PROFILE,
	UNFOLLOW_PROFILE,
} from '../actions';
import { ERROR_LOGGING_IN } from '../actions/profileActions';
import {
	PUT_PROFILE_NEW,
	GET_PROFILE_NEW,
	OPEN_POPUP_FOLLOWER_FOLLOWING
} from '../types/profileTypes';

const initialState = {
	profile: null,
	error: null,
	loading: false,
	otherProfile: null,
	followers: null,
	following: null,
	categories: null,
	openPopUpFollowerFollowing:false
};

export default (state = initialState, action) => {
	switch (action.type) {
	case LOADING_PROFILE:
		return {
			...state,
			loading: true,
		};
	case FETCH_PROFILE:
		const profileData = action.payload.data;
		return {
			...state,
			loading: false,
			profile: { data: profileData },
		};
	case FETCH_PROFILE_PERFORMANCE:
		const profileDataPerf = action.payload;
		return {
			...state,
			loading: false,
			profile: { data: profileDataPerf },
		};
	case LOADING:
		return {
			...state,
			loading: true,
		};
	case PUT_PROFILE:
		const newProfile = { ...state.profile.data };
		Object.keys(state.profile.data).map((key) =>
			action.payload.key === key
				? (newProfile[key] = action.payload.data)
				: newProfile
		);
		return {
			...state,
			profile: { data: newProfile },
			otherProfile: { data: newProfile },
		};
	case END_PUT_PROFILE:
		return {
			...state,
			loading: false,
		};
	case OTHER_PROFILE:
		return {
			...state,
			otherProfile: action.payload,
			loading: false,
		};
	case FOLLOW_PROFILE:
		const profile = { ...state.profile.data };
		profile.following.push(action.payload);
		profile.followingCount += 1;
		return {
			...state,
			otherProfile: { data: action.payload },
			profile: { data: profile },
		};
	case UNFOLLOW_PROFILE:
		const profile2 = { ...state.profile.data };
		profile2.following = profile2.following.filter(
			(pro) => pro.email !== action.payload
		);
		profile2.followingCount -= 1;
		const otherProfile = { ...state.otherProfile.data };
		otherProfile.followersCount -= 1;
		const followers = state.followers.filter((pro) => pro.id !== profile2.id);
		return {
			...state,
			followers,
			otherProfile: { data: otherProfile },
			profile: { data: profile2 },
		};
	case FETCH_FOLLOWERS:
		return {
			...state,
			followers: action.payload.data,
			loading: false,
		};
	case FETCH_FOLLOWING:
		return {
			...state,
			following: action.payload.data,
			loading: false,
		};
	case LOG_OUT:
		return initialState;
	case PUT_PROFILE_NEW:
		return {
			...state,
			profile: {
				data: {
					...state.profile.data,
					...action.payload,
				},
			},
		};
	case GET_PROFILE_NEW:
		return {
			...state,
			loading: false,
			profile: { data: action.payload.data },
		};
	case OPEN_POPUP_FOLLOWER_FOLLOWING:
			return {
				...state,
				openPopUpFollowerFollowing: !action.payload
			};
	case ERROR_LOGGING_IN:
		return {
			...state,
			error: action.payload
		};
	default:
		return state;
	}
};
