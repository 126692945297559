import React, {Component} from 'react';
import styles from './styles/loading.scss';

class Loading extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className={styles.loadingContainer} style={this.props.style}>
				<img src="https://s3-sa-east-1.amazonaws.com/cdn.booklick.net/img/loading.gif" alt="Loading"/>
			</div>
		);
	}
}

export default Loading;
