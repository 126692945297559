import React, { Component } from 'react';
import {connect} from 'react-redux';
import styles from './styles/connectEZProxy.scss';

class ConnectEZProxy extends Component {

	render() {
		return <div>
			{this.props.user.location && <iframe className={styles.iframe} src={this.props.user.location} />}
			<iframe className={styles.iframe} id="clientLogin" name="clientLogin" />
		</div>;
	}
}

function mapStateToProps({ user }) {
	return { user };
}

export default connect(mapStateToProps)(ConnectEZProxy);
