import React, { useEffect, useState } from 'react';
import styles from './styles/createdContent.scss';
import useFormFileds from '../../../hooks/useFormFields';
import DropDown from '../../shared/DropDown';
import { useTranslation } from 'react-i18next';
import InputGroup from '../../shared/InputGroup';
import { contentType, iconSearchGreyDark } from '../../../../utils/constans';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getBooklistFollowingsOfProfile } from '../../../actions';
import LoaderBooklistContent from '../../booklists/LoaderBooklistContent';
import CardContent from '../../shared/CardContent';
import CardsWithPaginator from './CardsWithPaginator';

const FollowedBooklists = ({
	profile,
	myContent,
	getBooklistFollowingsOfProfile,
	email,
	isMobile,
}) => {
	const { t } = useTranslation();
	const [hasNextPage, setHasNextPage] = useState(false);
	const [page, setPage] = useState(1);
	const { fields, handleOnchage } = useFormFileds({
		searchTerm: '',
	});

	useEffect(() => {
		getBooklistFollowingsOfProfile(email, page);
	}, []);

	useEffect(() => {
		if (myContent) {
			setHasNextPage(myContent.hasNextPage);
		}
	}, [myContent]);

	const handleMoreResults = () => {
		getBooklistFollowingsOfProfile(email, page + 1);
		setPage(page + 1);
	};

	return (
		<>
			<div style={{ height: '100%', width: '100%', overflow: 'auto' }}>
				{myContent ? (
					<CardsWithPaginator
						contents={{
							...myContent,
							contents: myContent.booklistFollowings,
						}}
						handleNextPage={handleMoreResults}
						hasNextPage={hasNextPage}
						isBooklistArray={true}
						page={page}
					/>
				) : (
					<div className={isMobile ? styles.contentContainerMobile : styles.contentContainer}>
						<LoaderBooklistContent width="100%" />
						<LoaderBooklistContent width="100%" />
					</div>
				)}
			</div>
		</>
	);
};

function mapStateToProps({ profile, contentList }) {
	return {
		myContent: contentList.myBooklistsFollowings,
		profile: profile.otherProfile,
	};
}

export default withRouter(
	connect(mapStateToProps, {
		getBooklistFollowingsOfProfile,
	})(FollowedBooklists)
);
