import { useState } from 'react';
import {
	apaCitationFromISBN,
	apaCitationFromObject,
} from '../../utils/handleCitation';
import Cognito from '../../helpers/cognito';


const useClickContent = (typeContent, reader, slug) => {
	const [citation, setCitation] = useState('');
	const [url, setUrl] = useState('');

	const handleClickOfflineBook = async (offlineBook) => {
		let citationValue;
		if (offlineBook.isbn) {
			citationValue = await apaCitationFromISBN(offlineBook.isbn);
		} else {
			citationValue = apaCitationFromObject({
				author: offlineBook.author,
				date: offlineBook.date,
				publisher: offlineBook.editorial,
				title: offlineBook.title,
			});
		}
		setCitation(citationValue);
	};

	const handleClickContent = async () => {
		switch (typeContent) {
		case 'BOOKS':
		case 'DOCUMENTS':
		case 'ARTICLES':
		case 2:
			const session = await Cognito.retrieveToken();
			const urlDocument =  `${reader}?access_token=${session.accessToken.jwtToken}`;
			setUrl(urlDocument);
			break;
		case 3:
		case 'booklist':
		case 'BOOKLISTS':
			setUrl(`${window.location.origin}/booklist/${slug}`);
			break;
		default:
			setUrl(reader);
			break;
		}
	};



	return {
		citation,
		url,
		handleClickContent,
		handleClickOfflineBook
	};

};

export default useClickContent;