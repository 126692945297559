import { useState, useEffect } from 'react';
const regexEmail = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;

export const useIsValidEmail = (email) => {
	const [isValidEmail, setIsValidEmail] = useState(false);
	useEffect(() => {
		setIsValidEmail(email && email.search(regexEmail) ? false : true);
	}, [email]);

	return isValidEmail;
};