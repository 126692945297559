import {
	SEARCH_PROGRAM,
	LOADING_PROGRAM
} from '../types/profileHomeTypes';


const initialState = {
	programs: null,
	loading: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
	case LOADING_PROGRAM:
		return {
			...state,
			loading: true,
		};
	case SEARCH_PROGRAM:
		return {
			...state,
			programs: action.payload,
			loading: false,
		};
	default:
		return state;
	}
};
