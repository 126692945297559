export const LOADING = 'LOADING_CONTENT';
export const ERROR = 'ERROR';
export const GET_BOOKLIST_CONTENT = 'GET_BOOKLIST_CONTENT';
export const GET_MY_BOOKLISTS= 'GET_MY_BOOKLISTS';
export const PUT_CONTENT_TAGS= 'PUT_CONTENT_TAGS';
export const UPDATE_CONTENT_PREVIEW = 'UPDATE_CONTENT_PREVIEW';
export const PROFILE_CONTENTS = 'PROFILE_CONTENTS';
export const PROFILE_BOOKLIST_FOLLOWINGS= 'PROFILE_BOOKLIST_FOLLOWINGS';
export const PROFILE_BOOKLIST_PERMISSIONS = 'PROFILE_BOOKLIST_PERMISSIONS';
export const PRIVATE_BOOKLISTS_PERMISSION = 'PRIVATE_BOOKLISTS_PERMISSION';
export const CLEAR_PROFILE_CONTENT = 'CLEAR_PROFILE_CONTENT';
export const DELETE_CONTENT_FROM_PROFILE = 'DELETE_CONTENT_FROM_PROFILE';
export const CLEAR_PROFILE_LIKED_CONTENT = 'CLEAR_PROFILE_LIKED_CONTENT';
export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE';
export const REMOVE_FROM_STATE = 'REMOVE_FROM_STATE';
export const PROFILE_LIKED_CONTENTS = 'PROFILE_LIKED_CONTENTS';
export const PROFILE_COLLABORATIVE_BOOKLISTS = 'PROFILE_COLLABORATIVE_BOOKLISTS';
