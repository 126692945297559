import {
	GET_MY_BOOKLIST, 
	LOADING_BOOKLIST_COLLABORATIVE, 
	LOADING_MORE_MY_BOOKLIST, 
	LOADING_MY_BOOKLIST, 
	MORE_MY_BOOKLIST, 
	OPEN_POPUP,
	GET_BOOKLIST_COLLABORATIVE,
	MORE_BOOKLIST_COLLABORATIVE,
	RESET_CONTEXT_MENU
} from '../types/contextMenuTypes';
const initialState = {
	loading:{
		myBooklist:true,
		booklistCollaborative:false,
		moreMyBooklist:false,
		moreBooklistCollaborative:false
	},
	openPopUp: false,
	myBooklist:{
		booklists:null,
		hasNextPaga:false,
		page:0
	},
	booklistPermissions:{
		booklists:null,
		totalItems: 0,
		totalPages: 0,
		hasNextPage: false
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
	case OPEN_POPUP:
		return {
			...state,
			openPopUp: !action.payload
		};
	case LOADING_MY_BOOKLIST:
		return {
			...state,
			loading:{
				...state.loading,
				myBooklist:action.payload
			}
		};
	case LOADING_MORE_MY_BOOKLIST:
		return {
			...state,
			loading:{
				...state.loading,
				moreMyBooklist:action.payload
			}
		};
	case LOADING_BOOKLIST_COLLABORATIVE:
		return {
			...state,
			loading:{
				...state.loading,
				booklistCollaborative:action.payload
			}
		};
	case GET_MY_BOOKLIST:
		return {
			...state,
			myBooklist:action.payload,
			loading:{
				...state.loading,
				myBooklist:false
			}
		};
	case MORE_MY_BOOKLIST:
	 return {
			...state,
			myBooklist:{
				...action.payload,
				booklists:[...state.myBooklist.booklists,...action.payload.booklists]
			}
		};
	case GET_BOOKLIST_COLLABORATIVE:
		return {
			...state,
			booklistPermissions:action.payload,
			loading:{
				...state.loading,
				booklistCollaborative:false
			}
		};
	case MORE_BOOKLIST_COLLABORATIVE:
	 return {
			...state,
			booklistPermissions:{
				...action.payload,
				booklists:[...state.booklistPermissions.booklists,...action.payload.booklists]
			}
		};
	case RESET_CONTEXT_MENU:
		return initialState;
	default:
		return state;
	}
};	