
import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';

import styles from './styles/peopleItem.scss';
import {openProfile} from '../../../helpers/profileRouting';

import { 
	loadingProfile, 
	visitStats, 
	putFollowing,
	openPopUp
} from '../../actions';

import {
	profileDefaultImage
} from '../../../utils/constans';
import Cognito from '../../../helpers/cognito';

const PeopleItem = ({
	history,
	profile,
	visitStats,
	loadingProfile,
	title,
	openPopUp
}) => {
	const { t } = useTranslation();

	const handleOnClickProfile = async() => {
		try {
			await Cognito.retrieveToken();
			openProfile(
				profile.email,
				profile.profileId,
				loadingProfile,
				history,
				visitStats,
				true
			);
		} catch (error) {
			openPopUp(true);
		}
	};

	return (
		<>
			<div className={styles.gridPeople}>
				<div className={styles.relative}>
					<div className={styles.circleUser} onClick={handleOnClickProfile}>
						<img 
							className={styles.imgPeople} 
							src={profile.profileImgUrl ? profile.profileImgUrl : profileDefaultImage} 
							onError={(e)=>{e.target.onerror = null; e.target.src=profileDefaultImage;}}
							alt={profile.firstName}
						/>
					</div>
				</div>

				<div className={styles.gridRow5}>
					<div  onClick={handleOnClickProfile}>
						<h2 className={styles.fontTitle}>{title}</h2>
						<h2 className={styles.font}>{profile.firstName + ' ' + profile.lastName}</h2>
						<h2 className={styles.font}>{profile.mayor}</h2>
					</div>
					
					<div className={styles.btn} onClick={handleOnClickProfile}>
						{t('buttonViewPeople')}
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = () => {
	return {};
};

export default withRouter(connect(mapStateToProps, {
	visitStats,
	loadingProfile,
	putFollowing,
	openPopUp
})(PeopleItem));
