import {
	GET_HOME,
	OPEN_POPUP,
	REFRESH_HOME,
	SET_POPUP_SHARE
} from '../types/explorationTypes';

const initialState = {
	schools: null,
	school: null,
	contentsOfSchool: null,
	topicsOfSchool: null,
	contentTypes: null,
	students: null,
	teachers: null,
	source: null,
	schoolId: null,
	openPopUp: false,
	refresh: false,
	refreshProgram: null,
	contentToShare: null,
	openPopUpShare: false,
	libraryProfile:null
};

export default (state = initialState, action) => {
	switch (action.type) {
	case GET_HOME:
		return {
			...state,
			schools: action.payload.schools,
			school: action.payload.school,
			libraryProfile: action.payload.libraryProfile
		};
	case OPEN_POPUP:
		return {
			...state,
			openPopUp: action.payload ? action.payload : false
		};
	case REFRESH_HOME:
		return {
			...state,
			refresh: true,
			refreshProgram: action.payload
		};
	case SET_POPUP_SHARE:
		return {
			...state,
			contentToShare: action.payload.content,
			openPopUpShare: action.payload.openPopUpShare,
		};
	default:
		return state;
	}
};
