import React , {useEffect} from 'react';
import { getResources, iconSuccessBlue } from '../../../utils/constans';
import PopUp from '../shared/PopUp';
import { useTranslation } from 'react-i18next';
import { environment } from '../../actions';

const PopupSuccess = ({ closePopUp }) => {
	const { t } = useTranslation();

	useEffect(() => {
		const timer = setTimeout(() => {
			closePopUp();
		}, 5000);
    
		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<PopUp
			closePopUp={closePopUp}
			style={{
				popUp: {
					maxHeight: '406px',
					maxWidth: '534px'
				},
				popUpContent: {
					margin: '40px'
				}
			}}

			buttons={{}}
		>
			<div style={{width:'100%',  marginTop: '16px', display:'flex', flexDirection:'column', alignItems:'center' }}>
				<div style={{width:'60px'}}>
					<img
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'contain'
						}}
						src={getResources(environment).iconSuccess}
					/>
				</div>
				<p style={{ marginTop:'15px', fontSize: '18px' }}>{t('textPopUpContextMenuAddSuccess')}</p>
			</div>
		</PopUp>

	);
};

export default PopupSuccess;