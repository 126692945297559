import React, { useEffect, useState } from 'react';
import useOptionDropDown from '../../hooks/useOptionDropDown';
import AvatarProfile from '../shared/AvatarProfile';
import DropDown from '../shared/DropDown';
import styles from './styles/booklistsPopUpEdit.scss';
import { useTranslation } from 'react-i18next';
import { getResources } from '../../../utils/constans';
import { environment } from '../../actions';
const CardProfilePermission = ({
	permision,
	handleDeleteColaborator,
	onProfileClick,
	handleEditColaborator,
	profileId
}) => {
	const { t } = useTranslation();
	const [listOptions, setListOption] = useState([]);
	const [options, value, handleValue, resetDropDownValue,defaultValue] = useOptionDropDown(listOptions);
	useEffect(() => {
		let option = [];
		option.push({
			value: 'edit',
			label: t('textEdit'),
		});
		option.push({
			value: 'view',
			label:t('textReader'),
		});
		setListOption(option);
		defaultValue(permision.permission.identifier === 'edit' ? option.filter((item) =>  item.value === 'edit') :  option.filter((item) =>item.value === 'view'));
	}, []);

	useEffect(() => {
		if (value && value.value) {
			handleEditColaborator(permision.email.email,value.value);
		}
	}, [value]);
    
	return (
		<div className={styles.cardPermission}>
			<div style={{
				gridRowStart: '2',
				gridRowEnd: '4',
				marginLeft: '10%'
			}}>
				<AvatarProfile
					styles={{
						width: '36px',
						height: '36px',
						borderRadius: '50%',
						marginRight: '12px'
					}}
					profileImgUrl={permision.email.profileImgUrl}
					onClick={() => { onProfileClick(permision.email.email, profileId); }}
				/>
			</div>
			<div style={{ margin: '0', fontSize: '14px', color: '#333', gridColumnStart: '2',  gridColumnEnd: '4',gridRowStart: '3'}}>
				<DropDown
					listOptions={listOptions}
					maxWidth={'160px'}
					minHeight={5}
					options={options}
					value={value}
					handleValue={handleValue}
					isSearchable={false}
					borderRadius={4}
				/>
			</div>
			<h3 style={{ margin: '0', fontSize: '15px', color: '#333', gridColumnStart: '2',gridRowStart: '2', marginTop: '5px', whiteSpace: 'nowrap' }}>{`${permision.email.firstName} ${permision.email.lastName}`}</h3>
			<img style={{ height: '10px', width: '10px', gridColumnEnd: '5', margin: '10px 10px 0 0', cursor: 'pointer' }} src={getResources(environment).iconClose} alt="" onClick={() => handleDeleteColaborator(permision.email.email)} />
		</div>
	);
};

export default CardProfilePermission;