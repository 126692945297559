import React from 'react';
import ResultsContent from './ResultsContent';

const ContentResult = ({ results, isMobile }) => {
	return results.map((content, index) => {
		return (
				<div key={`${content.id} ${index}`}>
					<ResultsContent
						resultContent={content}
						isMobile={isMobile}
						contentType={{
							_id: content.type,
							identifier: content.type,
							imgUrl: content.typeImgUrl,
							name: content.typeLabel,
						}}
					/>
				</div>
			
		);
	})
};

export default ContentResult;
