import React, {Component} from 'react';
import AlertMessagesItem from './AlertMessagesItem';
import { withRouter } from 'react-router-dom';
import Loading from '../shared/Loading';

import styles from './styles/alertMessages.scss';
import { withTranslation } from 'react-i18next';

const timeOut = 500;

class AlertMessages extends Component{

	constructor(props) {
		super(props);

		this.state = {
			notifications: [],
			search: ''
		};
	}

	componentDidMount(){
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.notifications !== prevProps.notifications) {
			  this.setState({
					notifications: this.props.isTypeMessage ? this.props.notifications.messages : this.props.notifications.notifications,
					results: this.props.isTypeMessage ? this.props.notifications.messages : this.props.notifications.notifications
		  		});
		}
	}

	fetchData(){
		this.setState({
			notifications: this.props.isTypeMessage ? this.props.notifications.messages : this.props.notifications.notifications,
			results: this.props.isTypeMessage ? this.props.notifications.messages : this.props.notifications.notifications
		});
	}

	handleSearch = event => {
		this.setState({
		  [event.target.name]: event.target.value,
		})
			setTimeout(
				() => this.setState({
					notifications: this.state.results.filter(notification => notification.message.toLowerCase().search(new RegExp(this.state.search.toLowerCase())) !== -1)
				})
				,500)
		if(this.state.search === '') {
		  this.setState({
			content: this.state.results
		  })
		}
	}

	render(){
		const {t} = this.props
		if (this.props.notifications.loading) return <div className={styles.loadingContainer}><Loading loading/></div>;
		return (
			<div className={styles.posMessage}>
				<div className={styles.boxMessage}>
					<div className={styles.gridRow10}>
						<div className={styles.gridSpaceDrop}>
							<div className={styles.gridRow10}>
								<h2 className={styles.h2Title}>{this.props.isTypeMessage ? t('titleAlertMessagessMessa') : t('titleAlertMessagessNotification')}</h2>
								<h3 className={styles.h3info}>{t('titleAlertMessagessRecent')}</h3>
							</div>
							<input type="search"
								name='search'
								placeholder={this.props.isTypeMessage ? t('titleAlertMessagessSearchMessa') : t('titleAlertMessagessSearchNotification')} className={styles.input}
								onChange={this.handleSearch}
								value={this.state.search}/>
						</div>

						<div className={styles.contentMessage}>
							<div className={styles.gridRow5}>

								{this.state.notifications && this.state.notifications.map(notification => <AlertMessagesItem key={notification._id} notification={notification} />)}
							</div>

						</div>

					</div>
				</div>

			</div>


		);
	}
}

export default withTranslation()(withRouter(AlertMessages));

