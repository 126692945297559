import React, {Component} from 'react';
import Dropzone from 'react-dropzone';

import CreateFile from './CreateFile';
import CreateBooklist from './CreateBooklist';
import CreateBooklistDetails from './CreateBooklistDetails';
import EditContent from './EditContent';

import styles from "./styles/popupCreate.scss"
import {
	getMetadata,
	fetchMyBooklists,
	fetchBooklistPermissions,
	openPopUp
} from '../../actions';
import {connect} from 'react-redux';
import { withTranslation , Trans} from 'react-i18next';
import CreateLink from './CreateLink';

const iconClose = "https://cdn.booklick.net/public/img/icons/icon-close-popup.svg";
const iconPageGreen = "https://cdn.booklick.net/public/img/icons/icon-page-green.svg";
const iconImageGreen = "https://cdn.booklick.net/public/img/icons/icon-image-green.svg";
const iconVideoGreen = "https://cdn.booklick.net/public/img/icons/icon-video-green.svg";
const iconAudioGreen = "https://cdn.booklick.net/public/img/icons/icon-audio-green.svg";
const iconBookGreen = "https://cdn.booklick.net/public/img/icons/icon-book-green.svg";
const iconFileUpload = "https://cdn.booklick.net/public/img/icons/icon-file-upload-white.svg";
const iconLinkWhite = "https://cdn.booklick.net/public/img/icons/icon-link-white.svg";
const iconFolderWhite = "https://cdn.booklick.net/public/img/icons/icon-folder-white.svg";
const iconGroup = "https://cdn.booklick.net/public/img/icons/icons-group.svg";
const iconBooklistWhite = "https://cdn.booklick.net/public/img/icons/icon-booklist-white.svg";

const iconArrowBack = 'https://cdn.booklick.net/public/img/icons/back.svg'

const titleResponsive = {
	create: 'Crear Contenido',
	booklist: 'Crear Booklist',
	edit: 'Editar Contenido',
	preferences: 'Editar Preferencias'
}

const positionPopup = {
	booklist: 3,
	detail: 4,
	editContent: 5,
}

class PopUpCreate extends Component{
	constructor(props) {
		super(props);
		this.state = {
			positionPopup: this.props.positionPopup || 0,
			data: this.props.data || null,
			meta: null,
			booklistId: null,
			loadingLink: false,
			link: null,
			newBooklist: null,
			loginUser: this.props.profile.profile,
			createBooklistMobile: false,
			mobileMenuPreviewStep: null,
			inputError: false,
		}
		this.handleLink = this.handleLink.bind(this)
		this.fetchLinkMeta = this.fetchLinkMeta.bind(this)
	}

	componentDidMount() {
		if(!this.props.contentList.myBooklists) {
			this.props.fetchMyBooklists()
		}
		if(!this.props.contentList.booklistPermissions) {
			this.props.fetchBooklistPermissions(); 
		}
		if(this.props.navButton) {
			if(document.getElementById('PopUpCreateContentOnboarding')) {
				document.getElementById('PopUpCreateContentOnboarding').click()
			}
			if(document.getElementById('PopUpCreateContent')) {
				document.getElementById('PopUpCreateContent').click()
			}
		}
		document.body.style.position = 'fixed';
		document.body.style.width = '100%';
		document.body.style.top = `-${window.scrollY}px`;
	}

	changePosition(positionPopup) {
		if(this.state.loginUser !== null) {
			this.setState({ positionPopup: positionPopup })
		} else {
			this.props.openPopUp(true)
		}
	}
	changeBooklistId(id) {
		this.setState({ booklistId: id })
	}

	changeNewBooklist(id) {
		this.setState({ newBooklist: id })
	}

	handleFileUpload = async (file) => {
		if(this.state.loginUser !== null) {
			this.setState({
				data: file[0]
			})
			this.setState({
				positionPopup: 1,
			})

		} else {
			this.props.openPopUp(true)
		}
	}

	handleLink = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		})
	}
	fetchLinkMeta = async () => {
		try {
			if(this.state.loginUser !== null) {
				if (this.state.link.startsWith('http') || this.state.link.startsWith('https')) {
					this.setState({
						loadingLink: true
					})
					const metadata = await this.props.getMetadata(this.state.link)
					if(metadata === undefined) {
						this.setState({
							meta: true,
							loadingLink: false
						})
						this.setState({
							positionPopup: 2,
						})
					} else {
						this.setState({
							meta: metadata,
							loadingLink: false
						})
						this.setState({
							positionPopup: 2,
						})
					}

				} else {
					this.setState({
						inputError: true
					  });
				}
			} else {
				this.props.openPopUp(true)
			}
		} catch (error) {
			console.error(error)
		}
	}
	handleCreateBooklistMobile = (createBooklistMobile) => {
		this.setState({
			createBooklistMobile,
			mobileMenuPreviewStep: true,
		})
	}
	handleMenuPreviewStep = () => {
		this.setState({
			mobileMenuPreviewStep: false,
			createBooklistMobile:false
		})
	}
	componentWillUnmount() {
		const scrollY = document.body.style.top;
		document.body.style.position = '';
		document.body.style.top = '';
		document.body.style.width = '';
		window.scrollTo(0, parseInt(scrollY || '0') * -1);
	}
	render(){
		const { t } = this.props;
		if(this.state.positionPopup === 2 && !this.props.mobileMenuPopUp) {
			return (
				<>
					<div className={styles.fixedBlur}></div>
					<CreateLink meta={this.state.meta} link={this.state.link} closePopUp={() => this.props.handleClosePopUp(true)} />
				</>
			);
		}
		return (
				<div id='scrollPoUp' className={styles.fixedBox}>
					<div className={styles.fixedBlur}></div>
					<div id={this.props.id ||null} className={styles.fixedClose} onClick={()=>{this.props.showPopUp(this.props.name,false);}}></div>

					<div
						className={this.state.positionPopup === positionPopup.booklist &&
							this.state.createBooklistMobile ?
							`${styles.boxPopup} ${styles.boxPopUpBooklist}`
							:
							this.state.positionPopup === positionPopup.detail ?
								`${styles.boxPopup} ${styles.heightVh}`
								:
								this.state.positionPopup === positionPopup.booklist ?
									`${styles.boxPopup} `
									:
									styles.boxPopup
							}
						datatest="popUpCreate"
						>

						<div className={`${styles.posClose} ${styles.none550}`} onClick={()=>{this.props.showPopUp('popupCreate',false)}}>
							<img className={styles.icon22} src={iconClose} alt="user"/>
						</div>
						{this.state.createBooklistMobile ?
							<div className={`${styles.barReturn550} ${styles.marginLeft}`}>
								<img  className={styles.icon22}  onClick={this.handleMenuPreviewStep} src={iconArrowBack} alt="user"/>
								<h3 className={styles.h2InfoBar}>
									{this.state.positionPopup === positionPopup.booklist ?
										titleResponsive.booklist
										:
										this.state.positionPopup === positionPopup.detail ?
											titleResponsive.preferences
											:
											titleResponsive.create
									}
								</h3>
							</div>
							:
							<div className={styles.barReturn550}>
								<img  className={styles.icon22}  onClick={()=>{this.props.showPopUp('popupCreate',false)}} src={iconArrowBack} alt="user"/>
								<h3 className={styles.h2InfoBar}>
									{this.state.positionPopup === positionPopup.booklist ?
										titleResponsive.booklist
										:
										this.state.positionPopup === positionPopup.detail ?
											titleResponsive.preferences
											:
											this.state.positionPopup === positionPopup.editContent ?
												titleResponsive.edit
												:
												titleResponsive.create
									}
								</h3>
							</div>
						}



						{
							this.state.positionPopup === 0 ?

								<div className={styles.gridPopup}>
									<div className={styles.grid}>

										<div className={`${styles.gridRow20} ${styles.border1}`  }>
											<div className={styles.gridSpaceTitle}>
												<h2 className={styles.h2Title}>{t('titleCreateContent')}</h2>

												<div className={styles.gridAuto10}>
													<img className={styles.icon26} src={iconPageGreen} alt="user"/>
													<img className={styles.icon22} src={iconImageGreen} alt="user"/>
													<img className={styles.icon22} src={iconVideoGreen} alt="user"/>
													<img className={styles.icon22} src={iconAudioGreen} alt="user"/>
													<img className={styles.icon22} src={iconBookGreen} alt="user"/>
												</div>
											</div>

											<div className={styles.gridAuto10}>
												<img className={styles.icon22} src={iconFileUpload} alt="user"/>
												<h3 className={styles.h3info}> <Trans i18nKey="titleDragContent"/></h3>
											</div>

											<div className={`${styles.gridEnd} ${styles.deleteEnd550}`}>
												<Dropzone onDrop={this.handleFileUpload}>
													{({ getRootProps, getInputProps }) => (
														<div className={styles.btn} {...getRootProps()}>
															<input type="file" {...getInputProps()} />
															{t('titleSelectFolder')}
														</div>
													)}
												</Dropzone>
											</div>
										</div>

										<div className={`${styles.gridRow20} ${styles.border2}`  }>

											<div className={styles.gridAuto10}>
												<img className={styles.icon22} src={iconLinkWhite} alt="user"/>
												<h3 className={styles.h3info}><Trans i18nKey="titleCopyLink"/> </h3>
											</div>

											<div>
												<input
													type="text"
													className={styles.input}
													datatest="input-link-content"
													placeholder={t('inputCopyLink')}
													name='link'
													onChange={this.handleLink}
													/>
											</div>
											<div className={`${styles.gridEndCreate} ${styles.deleteEnd550}`}>
												<div className={styles.errorContainer}>
													{this.state.inputError &&
													<h4 className={styles.h4Error}><Trans i18nKey="textErrorIncludesHttp"/></h4>
													}
												</div>
												<div className={styles.buttonContainer}>
													<div
													className={styles.btn}
													datatest="button-created"
													onClick={this.fetchLinkMeta}>
													{this.state.loadingLink ? <div className={`${styles.fileSpinner} ${styles.fileIcon}`} aria-hidden="true"></div> : t('textNavBarCreated')}
													</div>
												</div>
												</div>
										</div>

									</div>


									<div className={`${styles.gridRow20} ${styles.alignStart} ${styles.paddrightside}`}>
										<div className={styles.gridAuto20}>
											<img className={styles.icon22} src={iconFolderWhite} alt="user"/>
											<h2 className={styles.h2Title}>{t('titleGroupContent')}</h2>
										</div>
										<h3 className={`${styles.h3info} ${styles.marginTop5}`}> <Trans i18nKey="textGroupContent"/></h3>

										<div className={styles.paddSide20}>
											<img className={styles.imgFluid} src={t('imagePopUpCreated')} alt="user"/>
										</div>

										<div className={styles.btn1} datatest="button-create-booklist" onClick={() => this.changePosition(3)}>
											<img className={styles.icon20} src={iconBooklistWhite} alt="user"/>
											{t('buttonCreatedBooklist')}
										</div>
									</div>

								</div> : null
						}


						{
							this.state.positionPopup === 1 ?

								<CreateFile
									link={false}
									meta={this.state.meta}
									changeNewBooklist={(contentId) => this.changeNewBooklist(contentId)}
									changePositionPopup={position => this.changePosition(position)}
									handleClosePopUp={close => this.props.handleClosePopUp(close)}
									mobileMenuPopUp={this.props.mobileMenuPopUp ? (value) => this.props.mobileMenuPopUp(value) : null}
									{...this.props}
									data={this.state.data}
									/>
								: null
						}

						{this.state.positionPopup === 2 && this.props.mobileMenuPopUp && (
							<CreateFile
								link={true}
								linkOnboarding={this.state.link}
								meta={this.state.meta}
								changeNewBooklist={(contentId) =>
									this.changeNewBooklist(contentId)
								}
								changePositionPopup={(position) => this.changePosition(position)}
								handleClosePopUp={(close) => this.props.handleClosePopUp(close)}
								mobileMenuPopUp={
									this.props.mobileMenuPopUp
										? (value) => this.props.mobileMenuPopUp(value)
										: null
								}
								{...this.props}
							/>
						)}

						{
							this.state.positionPopup === 3 ?

								<CreateBooklist
									positionPopup={position => this.changePosition(position)}
									booklistId={id => this.changeBooklistId(id)}
									contentId={this.state.newBooklist}
									contextMenu={this.props.contextMenu ? this.props.contextMenu : null}
									mobileMenu={this.props.mobileMenu}
									handleCreateBooklistMobile={(value) => this.handleCreateBooklistMobile(value)}
									mobileMenuPreviewStep={this.state.mobileMenuPreviewStep}
									/>
								: null
						}


						{
							this.state.positionPopup === 4 ?

								<CreateBooklistDetails
									handleClosePopUp={close => this.props.handleClosePopUp(close)}
									booklistId={this.state.booklistId}
									mobileMenuPopUp={this.props.mobileMenuPopUp ? (value) => this.props.mobileMenuPopUp(value) : null}
									{...this.props}
									/>
								:
								null

						}

						{
							this.state.positionPopup === 5 ?

								<EditContent
									link={false}
									data={this.state.data}
									meta={this.state.meta}
									changeNewBooklist={(contentId) => this.changeNewBooklist(contentId)}
									changePositionPopup={position => this.changePosition(position)}
									handleClosePopUp={close => this.props.handleClosePopUp(close)}
									{...this.props}
									/>
								: null
						}


					</div>

				</div>
		);
	}
}

function mapStateToProps({ contentList }) {
	return {
    contentList
  };
}

export default connect(mapStateToProps, {
	getMetadata,
	fetchMyBooklists,
	fetchBooklistPermissions,
	openPopUp
})(withTranslation()(PopUpCreate));

