import React, { useState, useEffect } from "react";
import styles from "./styles/CurrentCareer.scss";
import { searchProgramByName } from "../../actions/ProfilesHomeActions";
import { useTranslation } from "react-i18next";
import DropDown from "../shared/DropDown";

const CurrentCareer = ({ career, setCareer }) => {
  const { t } = useTranslation();
  const [programs, setPrograms] = useState([]);
  const [program, setProgram] = useState({
    label: career,
    value: "id",
  });
  const selectProgram = (value) => {
    setProgram(value);
    setCareer(value.label);
  };

  useEffect(() => {
    handleSearch("a");
  }, []);

  const handleInputChange = (query) => {
    if (query == "") {
      // NO SEARCH OR SELECT NEW TERM
    } else {
      handleSearch(query);
    }
  };

  const handleSearch = (query) => {
    searchProgramByName(query).then((programs) => {
      const programsArray = [];
      if (programs) {
        programs.map((program) => {
          programsArray.push({
            label: program.name,
            value: program.id,
          });
        });
      }
      setPrograms(programsArray);
    });
  };
  if (!career) {
    return (
      <div className={styles.container}>
        <h2 className={styles.searchedContentLabel}>
          Contenido popular de tu institución.
        </h2>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <h2 className={styles.searchedContentLabel}>Contenido más buscado en:</h2>
      <div className={styles.dropDownContainer}>
        <DropDown
          options={programs}
          isSearchable={true}
          placeholder={t("placeholderProgram")}
          handleValue={selectProgram}
          handleInputChange={handleInputChange}
          value={program}
          minHeight={"48px"}
          minWidth={"50px"}
        />
      </div>
    </div>
  );
};

export default CurrentCareer;
