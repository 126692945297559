import React, { useEffect, useState } from 'react';
import styles from './styles/createdContent.scss';
import useFormFileds from '../../../hooks/useFormFields';
import DropDown from '../../shared/DropDown';
import { useTranslation } from 'react-i18next';
import useOptionDropDown from '../../../hooks/useOptionDropDown';
import InputGroup from '../../shared/InputGroup';
import {
	CONTENT_TYPES,
	allOption,
	iconSearchGreyDark,
} from '../../../../utils/constans';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LoaderBooklistContent from '../../booklists/LoaderBooklistContent';
import {
	clearProfileLikedContent,
	getAllLikedContent,
	getAllLikedContentWithFilters,
} from '../../../actions/contentActions';
import CardsWithPaginator from './CardsWithPaginator';

const LikedContent = ({
	email,
	getAllLikedContent,
	likedContents,
	clearProfileLikedContent,
	isMobile,
}) => {
	const { t } = useTranslation();
	const [listTypes, setListTypes] = useState([]);
	const [hasNextPage, setHasNextPage] = useState(false);
	const [page, setPage] = useState(1);
	const [types, type, handleTypes, resetTypes] = useOptionDropDown(
		listTypes,
		true
	);
	const [isFilteredSearch, setIsFilteredSearch] = useState(false);
	const [listOrders, setListOrders] = useState([]);
	const [orders, order, handleOrders, resetOrders, defaultOrder] =
		useOptionDropDown(listOrders, false);
	const [content, setContent] = useState();
	const [searchTerm, setSearchTerm] = useState('');
	const { fields, handleOnchage } = useFormFileds({
		searchTerm: '',
	});
	const [filters, setFilters] = useState({
		type: undefined,
		order: 'AZ-ASC',
	});

	useEffect(() => {
		setListTypes([
			{
				value: CONTENT_TYPES.documents.name,
				label: t(CONTENT_TYPES.documents.translationKey),
			},
			{
				value: CONTENT_TYPES.links.name,
				label: t(CONTENT_TYPES.links.translationKey),
			},
		]);
		setListOrders([
			{
				value: 'AZ-ASC',
				label: t('profileFiltersAsc'),
			},
			{
				value: 'AZ-DESC',
				label: t('profileFiltersDesc'),
			},
			{
				value: 'DATE-ASC',
				label: t('profileFiltersDateAsc'),
			},
			{
				value: 'DATE-DESC',
				label: t('profileFiltersDateDesc'),
			},
		]);
		defaultOrder({
			value: 'AZ-ASC',
			label: 'A-Z',
		});
		return () => {
			clearProfileLikedContent();
		};
	}, []);

	useEffect(() => {
		if (content) {
			setHasNextPage(content.hasNextPage);
		}
	}, [content]);

	useEffect(() => {
		if (type.value && type.value !== filters.type) {
			setFilters({ ...filters, type: type.value });
		}
	}, [type]);

	useEffect(() => {
		if (
			(!filters.type || filters.type === allOption.value) &&
			searchTerm == ''
		) {
			if (likedContents) {
				setHasNextPage(likedContents.hasNextPage);
				setPage(likedContents.page || 1);
				setContent(likedContents);
				setIsFilteredSearch(false);
			}
		} else {
			setIsFilteredSearch(true);
			handleFilteredSearch(1);
		}
	}, [filters.type]);

	useEffect(() => {
		if (likedContents) {
			setContent(likedContents);
			setPage(likedContents.page);
		} else {
			getAllLikedContent(email);
		}
	}, [likedContents]);

	const handleMoreResults = () => {
		isFilteredSearch
			? handleFilteredSearch(page + 1)
			: getAllLikedContent(email, page + 1);
		setPage(page + 1);
	};

	const handleFilteredSearch = (page) => {
		const filterType =
			filters.type == allOption.value || !filters.type
				? ''
				: filters.type;
		setPage(page);
		getAllLikedContentWithFilters(email, page, filterType).then(
			(response) => {
				setHasNextPage(response.hasNextPage);
				if (page > 1) {
					const mergedContents = [
						...content.contents,
						...response.contents,
					];

					const updatedContent = {
						...response,
						contents: mergedContents,
					};

					setContent(updatedContent);
				} else {
					setContent(response);
				}
			}
		);
	};

	return (
		<>
			{
				<div className={isMobile ? styles.rowMobile : styles.row}>
					<DropDown
						listOptions={listTypes}
						maxWidth={'100%'}
						width={'147px'}
						minHeight={40}
						fontSizeTitle={'15px'}
						fontSizeDropDown={14}
						options={types}
						placeholder={t('textTypeContentFilter')}
						value={type.value ? type : null}
						handleValue={handleTypes}
						boxShadow={'0 2px 6px 0 rgba(0, 0, 0, 0.1)'}
						borderRadius={4}
					/>
				</div>
			}
			<div style={{ height: '100%', width: '100%', overflow: 'auto' }}>
				{content ? (
					<CardsWithPaginator
						contents={{
							...content,
							contents: content.contents.map((element) => ({
								...element,
								isLike: true,
							})),
						}}
						handleNextPage={handleMoreResults}
						hasNextPage={hasNextPage}
						isBooklistArray={false}
						page={page}
					/>
				) : (
					<div className={isMobile ? styles.contentContainerMobile : styles.contentContainer}>
						<LoaderBooklistContent width="100%" />
						<LoaderBooklistContent width="100%" />
					</div>
				)}
			</div>
		</>
	);
};

function mapStateToProps({ contentList }) {
	return { likedContents: contentList.likedContents };
}

export default withRouter(
	connect(mapStateToProps, { getAllLikedContent, clearProfileLikedContent })(
		LikedContent
	)
);
