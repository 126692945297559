import React, { useState } from "react";
import styles from "./styles/menuMoreInfo.scss";
import { getResources } from "../../../utils/constans";
import { environment } from "../../actions";
import { useTranslation } from "react-i18next";
import PopUpDeleteContent from "../booklists/PopUpDeleteContent";
import PopUpEditContent from "../booklists/PopUpEditContent";

const MenuMoreInfo = ({ content }) => {
  const [popUpDelete, setPopUpDelete] = useState(false);
  const [popUpEdit, setPopUpEdit] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div className={`${styles.container}`} onClick={() => setPopUpEdit(true)}>
        <img
          src={getResources(environment).iconEdit}
          className={`${styles.deleteIcon}`}
          alt="editContent"
        />
        <h3>{t("textContextMenuEdit")}</h3>
      </div>
      <div
        className={`${styles.container}`}
        onClick={() => setPopUpDelete(true)}
      >
        <img
          src={getResources(environment).iconDelete}
          className={`${styles.deleteIcon}`}
          alt="deleteContent"
        />
        <h3>{t("textContextMenuDelete")}</h3>
      </div>
      {popUpDelete && (
        <PopUpDeleteContent
          slugContent={content.slug}
          contentId={content.id}
          isMyContent={true}
          closePopUp={() => setPopUpDelete(false)}
        />
      )}
      {popUpEdit && (
        <PopUpEditContent
          isMyContent={true}
          closePopUp={() => setPopUpEdit(false)}
          content={content}
        />
      )}
    </>
  );
};

export default MenuMoreInfo;
