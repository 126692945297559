import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import nativeMenu from '../components/hocs/nativeMenu';
import {connect} from 'react-redux';
import styles from './styles/discoverPeoplePage.scss';
import FollowsPeople from '../components/follows/FollowsPeople';
import { loadingProfile, fetchProfileByEmail, fetchFollowers, fetchFollowing } from '../actions';


class FollowsNewPage extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
		};

	}

	async componentDidMount(){
		if (this.props.profile.otherProfile && !this.props.profile.followers && !this.props.profile.loading) {
			this.props.loadingProfile(this.props.profile.otherProfile.data.email, this.props.profile.otherProfile.data.id);
		}
		if (!this.props.profile.otherProfile && !this.props.profile.loading) {
			const slug = decodeURIComponent(escape(window.atob( window.location.pathname.split('/').pop() )));
			await this.props.fetchProfileByEmail(slug);
			await this.props.fetchFollowers(this.props.profile.otherProfile.data.id);
			await this.props.fetchFollowing(this.props.profile.otherProfile.data.id);
			this.setState({ refresh: true });
		}
	}
	
	render() {
		return (
			
			<div className={styles.paddingPage}>
				<div className={styles.containerPage}>
					<div className={styles.height30}></div>
					<FollowsPeople />
				</div>
			</div>
		);
	}
	
}

function mapStateToProps({profile}) {
	return {profile};
}

export default {
	component: nativeMenu(withRouter(
		connect(mapStateToProps, {
			loadingProfile,
			fetchProfileByEmail,
			fetchFollowers,
			fetchFollowing
		})(FollowsNewPage)
	))
};
