import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import pt from '../../lang/pt.json';
import es from '../../lang/es.json';
import en from '../../lang/en.json';
// let lenguage = window.location.href.split('/')[3] ? window.location.href.split('/')[3]: navigator.language.split('-')[0];

let lenguage = navigator.language.split('-')[0];
const resources = { es, pt, en };
i18n
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: lenguage,
		fallbackLng: 'en',
		keySeparator: false, // we do not use keys in form messages.welcome
		interpolation: {
			escapeValue: false // react already safes from xss
		},
		returnObjects:true
	});

export default i18n;
