import React from 'react';
import LoaderBooklistContent from '../booklists/LoaderBooklistContent';
import CardBooklist from './CardBooklist';
import NoResultSearch from '../search/NoResultSearch';

const SearchBooklistContextMenu = ({
	results,
	handleCardSelect,
	selectedBooklistId,
	loading,
	currentBooklistId,
}) => {
	if (!results || loading) {
		return (
			<div
				style={{
					margin: '20px auto',
					display: 'grid',
					gridTemplateColumns: '1fr 1fr',
					gap: '28px',
					justifyItems: 'center',
					paddingBottom: '60px',
				}}
			>
				<LoaderBooklistContent width="347px" height="147px" />
			</div>
		);
	}

	if (
		!results.filter((booklist) => booklist.id !== currentBooklistId)
			.length > 0
	) {
		return (
			<div>
				<NoResultSearch />
			</div>
		);
	}

	return (
		<div
			style={{
				margin: '20px auto',
				display: 'grid',
				gridTemplateColumns: '1fr 1fr',
				gap: '28px',
				justifyItems: 'center',
				paddingBottom: '60px',
			}}
		>
			{results
				.filter((booklist) => booklist.id !== currentBooklistId)
				.map((booklist) => (
					<CardBooklist
						key={booklist.id}
						widthCard={'347px'}
						heightCard={'147px'}
						content={booklist}
						isSelected={booklist.id === selectedBooklistId}
						onSelect={handleCardSelect}
					/>
				))}
		</div>
	);
};

export default SearchBooklistContextMenu;
