import App from './App';
import LoadingPage from './pages/LoadingPage';
import ContentNewPage from './pages/ContentNewPage';
import FollowsNewPage from './pages/FollowsNewPage';
import SearchV2Page from './pages/SearchV2Page';
import BooklistV2Page from './pages/BooklistV2Page';
import ProfilePage from './pages/ProfilePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import HomeV3Page from './pages/HomeV3Page';

export default [
	{
		component: App,
		routes: [			
			{
				...HomeV3Page,
				path: '/',
				exact: true
			},
			{
				...LoadingPage,
				path: '/v0.1/auth'
			},
			{
				...ContentNewPage,
				path: '/preview/:slug'
			},
			{
				...SearchV2Page,
				path: '/search/'
			},
			{
				...SearchV2Page,
				path: '/search/:term'
			},
			{
				...ProfilePage,
				path: '/perfil/:environment/:user'
			},
			{
				...BooklistV2Page,
				path: '/booklist/:slug'
			},
			{
				...ContentNewPage,
				path: '/content/:slug'
			},
			{
				...FollowsNewPage,
				path: '/follows/:user'
			},
			{
				...PrivacyPolicyPage,
				path: '/privacy'
			},
		]
	}
];