/* eslint-disable no-undef */
import axios from 'axios/index';
import Cognito from '../../helpers/cognito';
import { uploadFile, environment } from './index';
import {
	LOADING,
	ERROR,
	BOOKLIST_INFO,
	BOOKLIST_CONTENT,
	LOADING_BOOKLIST_CONTENT,
	MORE_BOOKLIST_CONTENT,
	PUT_BOOKLIST_INFO,
	PUT_BOOKLIST_SECTION,
	LOADING_PUT_BOOKLIST,
	DELETE_CONTENT_BOOKLIST,
	GET_BOOKLIST_SECTIONS,
	LOADING_BOOKLIST_SECTIONS,
	POST_BOOKLIST_SECTION,
	BOOKLIST_PERMISSIONS,
	ADD_COLABORATOR,
	DELETE_COLABORATOR,
	DELETE_BOOKLIST_SECTION,
	EDIT_COLABORATOR,
} from '../types/booklistV2Types';

export const getBooklistDetail = (slug) => async (dispatch) => {
	dispatch({
		type: LOADING,
		payload: true
	});

	try {
		const booklist = await fetchBooklist(slug);

		dispatch({
			type: BOOKLIST_INFO,
			payload: booklist
		});

		dispatch({
			type: LOADING,
			payload: false
		});

	} catch (error) {
		dispatch({
			type: LOADING,
			payload: false
		});
		dispatch({
			type: ERROR,
			payload: error
		});
	}
};

export const getBooklistSession = (booklistId) => async (dispatch) => {
	try {
		
		const sessions = await fetchSectionsOfBooklist(booklistId);

		dispatch({
			type: GET_BOOKLIST_SECTIONS,
			payload: sessions
		});

		dispatch({
			type: LOADING_BOOKLIST_SECTIONS,
			payload: false
		});


	} catch (error) {
		dispatch({
			type: LOADING_BOOKLIST_SECTIONS,
			payload: false
		});
		dispatch({
			type: ERROR,
			payload: error.message
		});
	}
};

export const getContentsBooklists = (booklistId, sectionId) => async (dispatch) => {
	try {

		dispatch({
			type: LOADING_BOOKLIST_CONTENT,
			payload: true
		});

		const contentsBooklists = await fetchContentsByBooklistst(booklistId, sectionId, 1);

		dispatch({
			type: BOOKLIST_CONTENT,
			payload: contentsBooklists
		});

		dispatch({
			type: LOADING_BOOKLIST_CONTENT,
			payload: false
		});

	} catch (error) {

		dispatch({
			type: LOADING_BOOKLIST_CONTENT,
			payload: false
		});

		dispatch({
			type: ERROR,
			payload: error.message
		});

		throw Error(error);

	}
};

export const getMoreContentsBooklist = (booklistId, sectionId, page) => async (dispatch) => {
	try {

		const contentsBooklists = await fetchContentsByBooklistst(booklistId, sectionId, page);

		dispatch({
			type: MORE_BOOKLIST_CONTENT,
			payload: contentsBooklists
		});

	} catch (error) {

		dispatch({
			type: ERROR,
			payload: error.message
		});

		throw Error(error);

	}
};

export const putBooklist = (booklist, file) => async (dispatch) => {

	try {
		const session = await Cognito.retrieveToken();

		if (file) {
			const imageUrl = await uploadFile(file, session, null, dispatch);
			booklist.image = imageUrl;
		}

		dispatch({
			type: PUT_BOOKLIST_INFO,
			payload: booklist
		});

		updateBooklist(booklist);

	} catch (error) {
		console.error(error);
	}
};

export const deleteBooklist = async (slug) => {
	const session = await Cognito.retrieveToken();
	try {
		await axios({
			method: 'DELETE',
			url: `${env.msContentNew}/api/contents/${slug}`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});
	} catch (error) {
		console.error(error);
	}
};

export const putBooklistSection = (booklistSectionId, section) => async (dispatch) => {
	dispatch({
		type: LOADING_PUT_BOOKLIST,
		payload: true
	});
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'PUT',
			url: `${env.msContentNew}/api/booklist-sections/${booklistSectionId}`,
			data: {
				section,
			},
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});
		dispatch({
			type: PUT_BOOKLIST_SECTION,
			payload: {
				booklistSectionId,
				section
			}
		});
		dispatch({
			type: LOADING_PUT_BOOKLIST,
			payload: false
		});
		return request;
	} catch (error) {
		dispatch({
			type: LOADING_PUT_BOOKLIST,
			payload: false
		});
		console.error(error);
	}
};

export const postBooklistSection = (booklistId, section) => async (dispatch) => {
	dispatch({
		type: LOADING_PUT_BOOKLIST,
		payload: true
	});
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'POST',
			url: `${env.msContentNew}/api/booklist-sections`,
			data: {
				booklistId,
				section,
			},
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});
		dispatch({
			type: POST_BOOKLIST_SECTION,
			payload: request.data
		});
		dispatch({
			type: LOADING_PUT_BOOKLIST,
			payload: false
		});
		return request;
	} catch (error) {
		dispatch({
			type: LOADING_PUT_BOOKLIST,
			payload: false
		});
		console.error(error);
	}
};

export const deleteContentBooklist = (booklistId, slug,contentId) => async(dispatch) =>{
	try {
		const session = await Cognito.retrieveToken();
		dispatch({
			type: DELETE_CONTENT_BOOKLIST,
			payload: slug
		});
		await axios({
			method: 'DELETE',
			url: `${env.msContentNew}/api/v2/booklist-contents`,
			data: {
				booklistId,
				contentId,
			},
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});
		dispatch({
			type: LOADING_PUT_BOOKLIST,
			payload: false
		});

	} catch (error) {
		console.error(error);
		dispatch({
			type: LOADING_PUT_BOOKLIST,
			payload: false
		});
		dispatch({
			type: ERROR,
			payload: error.message
		});
	}
};

export const fetchContentsByBooklistst = async (booklistId, sectionId, page) => {
	try {
		const limit = 8;
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${env.msContentNew}/api/v2/booklist/contents?booklistId=${booklistId}&isDefaultSection=${false}&booklistSectionId=${sectionId}&limit=${limit}&page=${page}`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});
		return request.data;
	} catch (error) {
		console.error(error);
		throw Error(error);
	}
};

export const getBooklistPermissions = (booklistId) => async(dispatch)=>{
	const currentUser = await Cognito.retrieveCurrentUser();
	const emailValue = currentUser.reduce((acc, attr) => {
		if (attr.Name === 'email') {
			return attr.Value;
		} else {
			return acc;
		}
	}, null);

	fetchPermission(booklistId).then(permissions=>{			
		
		dispatch({
			type: BOOKLIST_PERMISSIONS,
			payload: {permissions: permissions,currentUser:emailValue }
		});

	}).catch(error => {
		console.error(error);
	});
};

export const addColaborator = (profile) => (dispatch) => {
	dispatch({
		type: ADD_COLABORATOR,
		payload: profile
	});
};

export const deleteColaborator = (email) => (dispatch) => {
	dispatch({
		type: DELETE_COLABORATOR,
		payload: email
	});
};

export const editColaborator = (email,permission) => (dispatch) => {
	dispatch({
		type: EDIT_COLABORATOR,
		payload: {email,permission}
	});
};

export const postBooklistPermissions = async (booklistId, users) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'PUT',
			url: `${env.msContentNew}/api/booklist-permissions`,
			data: {
				booklistId,
				users
			},
			headers: {
				'Authorization': session.idToken.jwtToken,
			}
		});
		return request;
	} catch (error) {
		console.error(error);
	}
};

export const putContentToBooklist = (booklistSectionId, contentId, booklistId) => async (dispatch) => {
	dispatch({
		type: LOADING_PUT_BOOKLIST,
		payload: true
	});
	try {
		const request = await putMoveContentToSection(booklistId,contentId,booklistSectionId);
		dispatch({
			type: LOADING_PUT_BOOKLIST,
			payload: false
		});
		return request;
	} catch (error) {
		dispatch({
			type: LOADING_PUT_BOOKLIST,
			payload: false
		});
		console.error(error);
	}
};

export const fetchBooklist = async (slug) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${env.msContentNew}/api/v2/booklist?slug=${slug}`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment,
			}
		});

		return request.data;
	} catch (error) {
		console.error('error in fetch' , error);
		throw error;
	}

};

export const fetchPermission = async (booklistId) => {
	const session = await Cognito.retrieveToken();
	const permissions = await axios({
		method: 'GET',
		url: `${env.msContentNew}/api/v2/booklist/collaborators?booklistId=${booklistId}`,
		headers: {
			'Authorization': session.idToken.jwtToken,
		}
	});

	return permissions.data;

};

export const updateBooklist = async (booklist) => {
	const session = await Cognito.retrieveToken();

	await axios({
		method: 'PUT',
		url: `${env.msContentNew}/api/contents`,
		data: booklist,
		headers: {
			'Authorization': session.idToken.jwtToken,
			'env': environment
		}
	});

};

export const removeSection = (booklistId) => async (dispatch) => {
	dispatch({
		type: DELETE_BOOKLIST_SECTION,
		payload: booklistId
	});
	await deleteSection(booklistId);

};

export const deleteSection = async (booklistId) => {
	try {
		const session = await Cognito.retrieveToken();
		await axios({
			method: 'DELETE',
			url: `${env.msContentNew}/api/booklist-sections/${booklistId}`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});

	} catch (error) {
		console.error(error);
	}
};

export const fetchSectionsOfBooklist = async (booklistId) => {
	try {
		const request = await axios({
			method: 'GET',
			url: `${env.msContentNew}/api/booklist-sections?booklistId=${booklistId}`
		});

		return request.data;
	} catch (error) {
		console.error(error);
	}
};

export const putMoveContentToSection = async (booklistId,contentId,booklistSectionId) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'PUT',
			url: `${env.msContentNew}/api/booklist-contents`,
			data: {
				booklistId,
				contentId,
				booklistSectionId,
			},
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});
		return request;
	} catch (error) {
		throw error.response;
	}
};
