import React from 'react';
import PropTypes from 'prop-types';
const defaultProfileImg = 'https://cdn.booklick.net/public/img/profile.png';

const AvatarProfile = ({styles,onClick,profileImgUrl}) => {
	return (
		<div style={{...styles,overflow:'hidden'}}>
			<img
				style={{ 
					cursor: 'pointer',
					width: '100%',
	                height: '100%',
	                objectPosition: 'center',
	                objectFit:'cover' 
				}}
				data-for='tooltip'
				src={profileImgUrl || defaultProfileImg} 
				alt="user"
				onClick={onClick}
			/>
		</div>
	);
};

AvatarProfile.propTypes = {
	styles: PropTypes.shape({
		width: PropTypes.string,
		height: PropTypes.string,
		borderRadius: PropTypes.string,
		marginRight: PropTypes.string
	}),
	onClick: PropTypes.func,
	profileImgUrl:  PropTypes.string
};


export default AvatarProfile;