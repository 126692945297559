export const booklistType = 'booklist';
export const contentType = 'content';

export const handleUrl = (slug, type, reader, token) => {
	const accessToken = token ? `?access_token=${token}` : '';
	switch (type) {
		case 3:
		case booklistType:
		case 'BOOKLISTS':
			return `${window.location.origin}/booklist/${slug}`;
		case 'DOCUMENTS':
			if (reader) {
				return `${reader}${accessToken}`;
			} else {
				return `${window.location.origin}/preview/${slug}`;
			}

		default:
			if (reader) {
				return reader;
			} else {
				return `${window.location.origin}/preview/${slug}`;
			}
	}
};
