import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import nativeMenu from "../components/hocs/nativeMenu";
import { connect } from "react-redux";
import CatchError from "../components/shared/CatchError";
import styles from "./styles/profilePage.scss";
import SideBar from "../components/profile/SideBar/SideBar";
import {
  PROFILE_TABS,
  PROFILE_TABS_MOBILE,
  breakPoint,
  eventSearchType,
} from "../../utils/constans";
import ContentHandler from "../components/profile/content/ContentHandler";
import {
  PROFILE_TYPE,
  fetchOtherProfileByEmail,
  setPopUpShare,
  visitStats,
} from "../actions";
import { ImpulseSpinner } from "react-spinners-kit";
import PopUpShare from "../components/shared/PopUpShare";
import { ERROR_LOGGING_IN, fetchMyProfileNew } from "../actions/profileActions";
import { postEventSearch } from "../../helpers/tracking";

const ProfilePage = ({
  profile,
  match,
  exploration,
  setPopUpShare,
  fetchMyProfileNew,
  visitStats,
  history,
}) => {
  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);
  const [isSameUser, setIsSameUser] = useState(null);
  const [selectedTab, setSelectedTab] = useState(
    isMobile ? PROFILE_TABS_MOBILE[0] : PROFILE_TABS[0]
  );
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < breakPoint.mobileWide
  );

  useEffect(() => {
    setLoading(true);
    handleResize();
    window.addEventListener("resize", handleResize);
    document.body.style.backgroundColor = "#FCFCFC";
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    isMobile
      ? (document.body.style.backgroundColor = "white")
      : (document.body.style.backgroundColor = "#f0f0f0");
  }, [isMobile]);

  useEffect(() => {
    setUserEmail(atob(match.params.user));
  }, [match.params]);

  useEffect(() => {
    setLoading(true);
    if (profile && profile.profile) {
      const isSameUser = profile.profile.data.email == atob(match.params.user);
      setIsSameUser(isSameUser);
      handleProfile(isSameUser, atob(match.params.user));
    }

    if (profile.error === ERROR_LOGGING_IN) {
      window.localStorage.setItem("emailSlug", match.params.user);
      if (env.proxy) {
        window.location = env.proxy
          ? `${env.proxy}`
          : `${window.location.origin}`;
      } else {
        history.push("/");
      }
    }
  }, [profile, userEmail]);

  useEffect(() => {
    if (isSameUser) {
      setLoading(true);
      fetchMyProfileNew(atob(match.params.user));
    }
  }, [isSameUser]);

  const handleProfile = (isSameUser, email) => {
    if (isSameUser && profile && profile.profile) {
      const userData = profile.profile.data;
      setUser({
        fullName: `${userData.firstName || ""} ${userData.lastName || ""}`,
        email: userData.email,
        description: userData.description,
        followersCount: userData.countFollowers || 0,
        followingCount: userData.countFollowings || 0,
        profileImage: userData.profileImgUrl,
        contentsCount: userData.contentsCount,
        isFollow: null,
      });
      setLoading(false);
    } else if (!isSameUser && email) {
      let myEmail;
      if (profile && profile.profile) myEmail = profile.profile.data.email;
      postEventSearch(eventSearchType.profileRedirect, null, myEmail, email);
      fetchOtherProfileByEmail(email)
        .then((response) => {
          setUser({
            fullName: `${response.firstName || ""} ${response.lastName || ""}`,
            email: response.email,
            description: response.description,
            followersCount: response.countFollowers,
            followingCount: response.countFollowings,
            contentsCount: response.contentsCount,
            profileImage: response.profileImgUrl,
            isFollow: response.isFollow,
          });
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  };

  const handleTabChange = (tabKey) => {
    setSelectedTab(tabKey);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < breakPoint.mobileWide);
  };

  return (
    <CatchError>
      {!loading ? (
        <div
          className={
            isMobile ? styles.mobileMainContainer : styles.mainContainer
          }
        >
          <SideBar
            user={user}
            isSameUser={isSameUser}
            onTabChange={handleTabChange}
            activeTab={selectedTab}
            profile={profile}
            isMobile={isMobile}
          />
          <ContentHandler
            selectedTab={selectedTab.key}
            email={userEmail}
            profile={profile}
            isMobile={isMobile}
          />
        </div>
      ) : (
        <div className={styles.loadingContainer}>
          <ImpulseSpinner
            size={150}
            color="var(--primary-color)"
            frontColor="#10CFC9"
            backColor="#F0FBFF"
          />
        </div>
      )}
      {exploration.openPopUpShare && (
        <PopUpShare closePopUp={() => setPopUpShare(null, false)} />
      )}
    </CatchError>
  );
};

function mapStateToProps({ profile, exploration }) {
  return { profile, exploration };
}

export default {
  component: nativeMenu(
    withRouter(
      connect(mapStateToProps, {
        setPopUpShare,
        fetchMyProfileNew,
        visitStats,
      })(ProfilePage)
    )
  ),
};
