import React from 'react';
import LoaderCardContent from '../shared/LoaderCardContent';

const LoaderBooklistContent = ({width = '545px',height = '165' }) => {
	let loaders = [];
	for (let index = 0; index < 4; index++) {
		loaders.push(<LoaderCardContent width={width} height={height} />);
	}
	return (
		<>
			{loaders.map((item,index)=>(
				<div key={index} style={{width:`${width}`  , height: `${height}` , borderRadius:'8px' , boxShadow:'0 2px 6px 0 rgba(51, 51, 51, 0.12)' }}>
					{item}
				</div>)
			)}
		</>
	);
};

export default LoaderBooklistContent;