import { useState, useEffect } from 'react';
import {
	allOption
} from '../../utils/constans';
import { useTranslation } from 'react-i18next';
const useOptionDropDown = (listOptions, setOptionAll) => {
	const { t } = useTranslation();
	const [options , setOptions] = useState([]);
	const [value , setValue] = useState({});

	
	useEffect(() => {
		setOptions([...listOptions]);
		if(setOptionAll){
			setOptions([{
				value: allOption.value,
				label: t('placeholderFilterContent'),
			}, ...listOptions]);
		}
	}, [listOptions]);



	const handleValue = val => {
		if(options.value === allOption.value) {
			setValue({});
		} else {
			setValue(val);
		}
	};

	const resetDropDownValue = () => {
		setValue({});
	  };

	const menuOpen = () => {
		setValue({});
	};

	const defaultValue = val => {
		val && setValue(val);
	};

	return [
		options,
		value,
		handleValue,
		resetDropDownValue,
		defaultValue,
		menuOpen
	];
};

export default useOptionDropDown;