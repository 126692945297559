import {combineReducers} from 'redux';
import usersReducer from './usersReducer';
import contentPreviewReducer from './contentPreviewReducer';
import searchReducer from './searchReducer';
import categoryReducer from './categoryReducer';
import uploadDocumentReducer from './uploadDocumentReducer';
import profileReducer from './profileReducer';
import contentReducer from './contentReducer';
import myContentListReducer from './myContentListReducer';
import notificationReducer from './notificationReducer';
import institutionReducer from './institutionReducer';
import profileHomeReducer from './profileHomeReducer';
import booklistReducerNew from './booklistReducerNew';
import booklistV2Reducer from './booklistV2Reducer';
import searchReducerNew from './searchReducerNew';
import explorationReducer from './explorationReducer';
import contextMenuReducer from './contextMenuReducer';
import homeReducer from './homeReducer';



export default combineReducers({
	user: usersReducer,
	contentPreview: contentPreviewReducer,
	search: searchReducer,
	category: categoryReducer,
	profile: profileReducer,
	contentList: contentReducer,
	institution: institutionReducer,
	booklistNew: booklistReducerNew,
	booklistV2: booklistV2Reducer,
	profileHome: profileHomeReducer,
	searchNew: searchReducerNew,
	myContentList: myContentListReducer,
	notification: notificationReducer,
	uploadDocument: uploadDocumentReducer,
	exploration: explorationReducer,
	contextMenu: contextMenuReducer,
	homeState: homeReducer,
});
