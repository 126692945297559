import {
	FETCH_ALL_CATEGORIES,
	FETCH_LINK_TYPES
} from '../types/categoryTypes';

const initialState = {
	categories: null,
	selected: null,
	selectedContent: [],
	types: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
	case FETCH_ALL_CATEGORIES:
		return {
			...state,
			categories: action.payload,
		};
	case FETCH_LINK_TYPES:
		return {
			...state,
			types: action.payload,
		};
	default:
		return state;
	}
};