import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { openProfile } from '../../../helpers/profileRouting';
import {
	loadingProfile,
	visitStats,
	deleteFollowBooklist,
	putFollowBooklist,
	environment,
} from '../../actions';
import styles from './styles/booklistInfoMobile.scss';

import { getResources } from '../../../utils/constans';
import Button, { appearances } from '../shared/Button';
import {
	iconFollowingWhite,
	iconEditWhite,
} from '../../../utils/constans';
import BooklistPopUpEdit from './BooklistPopUpEdit';
import PopUpDeleteBooklist from './PopUpDeleteBooklist';
import SectionPopUpCreate from './SectionPopUpCreate';
import AvatarProfile from '../shared/AvatarProfile';
import { useTranslation } from 'react-i18next';

const BooklistInfoMobile = ({
	history,
	booklistInfo,
	loadingProfile,
	putFollowBooklist,
	isEditor,
}) => {
	const { t } = useTranslation();
	const [openPopUp, setOpenPopUp] = useState({
		editBooklist: false,
		deleteBooklist: false,
		addContent: false,
		addSection: false,
	});

	const [countFollows, setCountFollows] = useState(
		booklistInfo.countFollowings
	);
	const [isFollow, setIsFollow] = useState(booklistInfo.isFollow);

	const onProfileClick = (email, id) => {
		openProfile(email, id, loadingProfile, history, visitStats, true);
	};

	const handlePopupDelete = () => {
		setOpenPopUp({
			...openPopUp,
			editBooklist: false,
			deleteBooklist: true,
		});
	};

	const handleFollow = () => {
		if (isFollow) {
			deleteFollowBooklist(booklistInfo.id);
			setCountFollows(countFollows - 1);
		} else {
			putFollowBooklist(booklistInfo.id);
			setCountFollows(countFollows + 1);
		}
		setIsFollow(!isFollow);
	};

	return (
		<div className={styles.container}>
			<div className={`${styles.boxImage}`}>
				<img
					className={styles.imgBox}
					src={booklistInfo.image ? booklistInfo.image : getResources(environment).defaultCover}
				/>
			</div>

			<div style={{gap: '15px'}}>
				<div style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: '15px',
				}}>
					{booklistInfo.title && (
						<div
							className={`${styles.font25} ${styles.fontWeightBold} ${styles.title}`}
						>
							{booklistInfo.title}
						</div>
					)}

					{booklistInfo.description && (
						<div
							className={`${styles.font16} ${styles.fontWeightRegular} ${styles.description} ${styles.justify}`}
						>
							{booklistInfo.description}
						</div>
					)}
					<div
						className={`${styles.font16} ${styles.fontWeightMedium} ${styles.follows}`}
					>{`${countFollows} ${t('textFollowsContent')}`}</div>
					<div className={`${styles.author}`}>
						<AvatarProfile
							styles={{
								width: '30px',
								height: '30px',
								borderRadius: '50%',
								marginRight: '12px',
							}}
							profileImgUrl={booklistInfo.author.profileImgUrl}
							onClick={() => {
								onProfileClick(
									booklistInfo.author.email,
									booklistInfo.author.id
								);
							}}
						/>
						<div
							className={`${styles.font16} ${styles.fontWeightMedium}`}
						>{`${booklistInfo.author.firstName} ${booklistInfo.author.lastName}`}</div>
					</div>
				</div>

				{booklistInfo.isMyBooklist || isEditor ? (
					<div className={styles.btn_container}>
						<Button
							height={'50px'}
							width={'130px'}
							appearance={appearances.primary}
							borderRadius="6px"
							padding={'0px 0px 0px 15px'}
							marginRight={'30px'}
							icon={iconEditWhite}
							title={t('titlePopUpEdit')}
							action={() =>
								setOpenPopUp({
									...openPopUp,
									editBooklist: true,
								})
							}
							style={{ paddingLeft: '15px' }}
						/>
						<Button
							height={'50px'}
							width={'130px'}
							appearance={appearances.secondary}
							icon={getResources(environment).iconAdd}
							borderRadius="6px"
							padding={'0px 0px 0px 15px'}
							title={t('textKeywordAddToSection')}
							action={() =>
								setOpenPopUp({
									...openPopUp,
									addSection: true,
								})
							}
						/>
					</div>
				) : (
					<div className={styles.btn_container}>
						{isFollow ? (
							<Button
								height={'50px'}
								width={'130px'}
								appearance={appearances.secondary}
								borderRadius="6px"
								icon={getResources(environment).iconFollowing}
								title={t('buttonContentFollowed')}
								action={handleFollow}
							/>
						) : (
							<Button
								height={'50px'}
								width={'130px'}
								appearance={appearances.primary}
								borderRadius="6px"
								icon={iconFollowingWhite}
								title={t('buttonContentFollow')}
								action={handleFollow}
							/>
						)}
					</div>
				)}
			</div>
			{openPopUp.editBooklist && (
				<BooklistPopUpEdit
					isMobile={true}
					closePopUp={() =>
						setOpenPopUp({
							...openPopUp,
							editBooklist: false,
						})
					}
					popUpDelete={handlePopupDelete}
				/>
			)}

			{openPopUp.deleteBooklist && (
				<PopUpDeleteBooklist
					closePopUp={() =>
						setOpenPopUp({
							...openPopUp,
							deleteBooklist: false,
						})
					}
					history={history}
					slugBooklist={booklistInfo.slug}
				/>
			)}

			{openPopUp.addSection && (
				<SectionPopUpCreate
					closePopUp={() =>
						setOpenPopUp({
							...openPopUp,
							addSection: false,
						})
					}
					booklistId={booklistInfo.id}
				/>
			)}
		</div>
	);
};

function mapStateToProps({ booklistV2 }) {
	return {
		booklistInfo: booklistV2.booklistsInfo,
		isEditor: booklistV2.isEditor,
	};
}

export default withRouter(
	connect(mapStateToProps, {
		loadingProfile,
		visitStats,
		putFollowBooklist,
	})(BooklistInfoMobile)
);
