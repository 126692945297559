import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from './styles/updateProfile.scss';
import { useTranslation } from 'react-i18next';
import {
	eventType,
	rolType,
	NUMBER_SEMESTER_LENGTH,
	profileTypes,
} from '../../../utils/constans';
import { ImpulseSpinner } from 'react-spinners-kit';
import {
	EditProfileNew,
	fetchRoles,
	refreshHome
} from '../../actions';
import { useIsValidEmail } from '../../hooks/useValidateEmail';
import DropDown from '../shared/DropDown';
import { searchProgramByName } from '../../actions/ProfilesHomeActions';
import useOptionDropDown from '../../hooks/useOptionDropDown';

const UpdateProfile = ({
	sendEventPopup,
	EditProfileNew,
	fetchRoles,
	closePopUp,
	refreshHome,
	profile,
}) => {
	const [firstName, setFirstName] = useState();
	const [lastName, setLastName] = useState();
	const [semester, setSemester] = useState();
	const [program, setProgram] = useState();
	const [programs, setPrograms] = useState()
	const [otherEmail, setOtherEmail] = useState();
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const { t, i18n } = useTranslation();
	const [isProxy, setIsProxy] = useState(false);
	const isValidEmail = useIsValidEmail(otherEmail);
	const [profileTypesList, setProfileTypesList] = useState([]);
	const [
		roleOptions,
		selectedRole,
		handleRole,
		resetSelectedRole,
		defaultRole,
	] = useOptionDropDown(profileTypesList, false);

	useEffect(() => {
		env.proxy ? setIsProxy(true) : setIsProxy(false);
	}, []);

	useEffect(() => {
		const data = profile?.profile?.data;
		if (data) {
			handleRole(
				profileTypesList.find(
					(role) => role.value === profile.profile.data.identifier,
				),
			);
			setFirstName(data.firstName || '');
			setLastName(data.lastName || '');
			setSemester(data.semester || '');
			if (data.programImportId || data.mayor) {
				const programFromProfile = {
					value: data.programImportId,
					label: data.mayor,
				};
				setProgram(programFromProfile);
			}
		}
	}, [profile]);	

	useEffect(() => {
		const translatedProfiles = profileTypes.map((type) => ({
			value: type.value,
			label: t(type.translationKey),
		}));
		setProfileTypesList(translatedProfiles);
	}, [t]);

	const handleSearch = (query) => {
		searchProgramByName(query).then((programs) => {
			const programsArray = [];
			if (programs) {
				programs.map((program) => {
					programsArray.push({
						label: program.name,
						value: program.id,
					});
				});
			}
			setPrograms(programsArray);
		});
	};

	const handleSemester = (event) => {
		let number = event.target.value;
		if (number.toString().length <= NUMBER_SEMESTER_LENGTH) setSemester(number);
	};


	const handleSubmit = async () => {
		if (
			!firstName ||
			!lastName ||
			!selectedRole ||
			(selectedRole.value !== rolType.admin &&
				selectedRole.value !== rolType.staff &&
				!program) ||
			(otherEmail && otherEmail !== '' && !isValidEmail)
		) {
			setError(true);
		} else {
			const params = {
				firstName,
				lastName,
				selectedRole: selectedRole.value,
				mayor: program ? (program.label ? program.label : program) : null,
				semester,
				programImportId: program && program.value ? program.value : null,
				otherEmail: otherEmail,
			};
			EditProfileNew(params);
			sendEventPopup(eventType.signUpCompleted);
			refreshHome(params.mayor);
			closePopUp();
		}
	};

	const selectProgram = (value) => {
		setProgram(value);
	};

	const handleInputChange = (query) => {
		if (query == '') {
			// NO SEARCH OR SELECT NEW TERM
		} else {
			handleSearch(query);
		}
	};

	return (
		<div
		className={
			!isProxy && error
				? styles.containerProfileError
				: !isProxy
				? styles.containerProfile
				: isProxy &&
				  selectedRole &&
				  selectedRole.value !== rolType.admin &&
				  selectedRole.value !== rolType.staff
				? `${styles.containerProfileProxy} ${styles.containerProfileStudent}`
				: styles.containerProfileProxy
		}>
			<h2 className={styles.fontTitle}>{t('titleUpdateProfile')}</h2>
			{isProxy &&
				<div>
					<div className={styles.containerProgram}>
						<div className={styles.containerWidth100}>
							<p className={`${styles.fontp} ${styles.margin}`}>{t('emailInput')}</p>
							<div className={!isValidEmail && otherEmail && otherEmail !== '' ? `${styles.input_Rectangle} ${styles.alertBorderNew}` : styles.input_Rectangle}>
								<input
									name='otherEmail'
									type="input"
									onChange={(event) => setOtherEmail(event.target.value)}
								/>
							</div>
						</div>
					</div>
					{!isValidEmail && otherEmail && otherEmail !== '' &&
						<p className={styles.errorMessageParagraph}>{t('errorEmailLogin')}</p>
					}
				</div>
			}
			<div>
				<div className={styles.gridName}>
					<div className={styles.inputName}>
						<p className={styles.fontp}>{t('inputFirstName')}</p>
						<div className={error && !firstName ? `${styles.input_Rectangle} ${styles.alertBorderNew}` : styles.input_Rectangle}>
							<input
								name='firstName'
								data-test="inputFirstName"
								type='input'
								value={firstName || ''}
								onChange={(event) => setFirstName(event.target.value)}
							/>
						</div>
					</div>
					<div className={styles.inputName}>
						<p className={styles.fontp}>{t('inputLastName')}</p>
						<div className={error && !lastName ? `${styles.input_Rectangle} ${styles.alertBorderNew}` : styles.input_Rectangle}>
							<input
								name='lastName'
								data-test="inputLastName"
								type='input'
								value={lastName || ''}
								onChange={(event) => setLastName(event.target.value)}
							/>
						</div>
					</div>
				</div>
				{error && (!firstName ||
					!lastName) &&
					<p className={styles.errorMessageParagraph}>{t('errorUpdateName')}</p>
				}
			</div>
			<div>
				<p className={styles.fontp}>{t('textChooseRole')}</p>
				<div className={!isProxy ? styles.containerMultiSelect : `${styles.containerMultiSelect} ${styles.containerMultiSelectProxy}`}>
					<DropDown
						options={roleOptions}
						isSearchable={false}
						placeholder={t('textChooseRole')}
						handleValue={handleRole}
						value={selectedRole}
						minHeight={'38px'}
						fontSizeDropDown={14}
						width={'99%'}
					/>
				</div>
				{error && !selectedRole &&
					<p className={styles.errorMessageParagraph}>{t('errorChooseRole')}</p>
				}
			</div>
			{selectedRole && selectedRole.value !== rolType.admin && selectedRole.value !== rolType.staff &&
				<div className={styles.containerProgram}>
					<div className={selectedRole.value === rolType.student ? styles.containerWidth80 : styles.containerWidth100}>
						<p className={`${styles.fontp} ${styles.margin}`}>{t('textProgram')}</p>
						<div className={error && !program && `${styles.input_Rectangle} ${styles.alertBorderNew}`}>
							<DropDown
								options={programs}
								isSearchable={true}
								placeholder={t('placeholderProgram')}
								handleValue={selectProgram}
								handleInputChange={handleInputChange}
								value={program}
								minHeight={'38px'}
								fontSizeDropDown={14}
								width={'99%'}
							/>
						</div>
						{error && (!program) && (
							<p className={styles.errorMessageParagraph}>
								{t('errorChooseCareer')}
							</p>
						)}
					</div>
					{selectedRole.value === rolType.student &&
						<div className={styles.containerSemester} >
							<p className={`${styles.fontp} ${styles.margin}`}>{t('inputSemester')}</p>
							<span className={styles.number_wrapper}>
								<input
									placeholder="0"
									data-test="inputSemester"
									value={semester || ''}
									type="number"
									min="1" max="12"
									maxLength='2'
									onChange={handleSemester}
								/>
							</span>
						</div>
					}
				</div>
			}
			<div data-test="buttonUpdateProfile" className={`${styles.button_blue} ${styles.buttonPosition}`} onClick={!loading ? handleSubmit : null}>
				{!loading ?
					t('buttonEndForm')
					:
					<ImpulseSpinner
						size={40}
						color="white"
						frontColor="white"
						backColor='white'
						loading={loading}
					/>
				}
			</div>
		</div>
	);
};

const mapStateToProps = ({ profile }) => {
	return {
		profile,
	};
};

export default withRouter(connect(mapStateToProps, {
	EditProfileNew,
	fetchRoles,
	refreshHome
})(UpdateProfile));