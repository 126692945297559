import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from './styles/createProfile.scss';
import { ImpulseSpinner } from 'react-spinners-kit';
import {
	iconLibrary,
	iconUser,
	iconLock,
	eventType,
	STEP_FORGOT_PASSWORD
} from '../../../utils/constans';

import {
	EditProfileNew,
	getProfile
} from '../../actions';
import { useIsValidEmail } from '../../hooks/useValidateEmail';

import Cognito, {COGNITO_LOGGED_IN} from '../../../helpers/cognito';
import ReactCodeInput from 'react-verification-code-input';
import { useTranslation } from 'react-i18next';

const tabCreateProfile = 1;
const tabVerifyCode= 2;
const tabLogin= 0;
const queryPopUpSingUp ='popUpSingUp';
const errorTypeProfile = 'UsernameExistsException';

const CreateProfile = ({
	handleChangeStep,
	sendEventPopup,
	setProfileEmail,
	closePopUp,
	getProfile,
	EditProfileNew,
	removeClosePopUp,
	setInstitution,
	institution,
	setInstitutionSelected,
}) => {
	const [tabPosition, setTabPosition] = useState(tabLogin);
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [code, setCode] = useState('');
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [profileExists,setProfileExists] = useState(false);
	const [validEmail, setValidEmail] = useState(false);
	const { t, i18n } = useTranslation();
	const isValidEmail = useIsValidEmail(email);

	useEffect(() => {
		const defaulTap = window.location.search.includes(queryPopUpSingUp);
		if(defaulTap) {
			setTabPosition(tabCreateProfile);
		}
	}, []);


	const handleLogin = async() => {
		try {
			setLoading(true);
			setError(false);
			const responseLogin = await Cognito.signIn(email, password);
			if (responseLogin.status === COGNITO_LOGGED_IN) {
				await Cognito.retrieveCurrentUser();
				await getProfile();
				sendEventPopup(eventType.login);
				if(institution && !institution.slug) {
					await EditProfileNew({
						'requestedInstitution': institution.name
					});
				}
			}
		} catch (error) {
			setError(true);
			setLoading(false);
		}
	};

	const handleCreateProfile =	async () => {
		try {
			setLoading(true);
			setProfileExists(false);
			setError(false);
			await Cognito.register(email, password);
			sendEventPopup(eventType.signUp);
			setLoading(false);
			setError(false);
			setTabPosition(tabVerifyCode);
			removeClosePopUp(true);
		} catch (error) {
			if(error.code === errorTypeProfile) {
				setProfileExists(true);
			}
			setError(true);
			setLoading(false);
		}
	};

	const handleVerification = async () => {
		try {
			setLoading(true);
			await Cognito.verify(email, code);
			sendEventPopup(eventType.verifyEmail);
			handleLogin();
		} catch (error) {
			setError(true);
			setLoading(false);
		}
	};

	const handleChangeTap = (position) => {
		setTabPosition(position);
		removeClosePopUp(false);
		setError(false);
		setLoading(false);
		setProfileExists(false);
	};

	const handleResendCode = async() => {
		await Cognito.resendCode(email);
	};


	return (
		<div className={styles.container}>
			{tabPosition !== tabVerifyCode ?
				<>
					<div className={styles.gridTab}>
						<div className={styles.gridSelectTab}>
							<div className={tabPosition=== tabLogin ? styles.btnTabActive: styles.btnTab} onClick={()=>handleChangeTap(tabLogin)}>
								<h2 className={styles.h2TabMedium} >{t('textNavBarLogin')}</h2>
								<div className={styles.lineActiveTab}></div>
							</div>
							<div data-test="tabSignUp" className={tabPosition=== tabCreateProfile ? styles.btnTabActive: styles.btnTab} onClick={()=>handleChangeTap(tabCreateProfile)}>
								<h2 className={styles.h2TabMedium}>{t('tapSignUp')}</h2>
								<div className={styles.lineActiveTab}></div>
							</div>
						</div>
					</div>
					<div className={error && !profileExists ? `${styles.loginContainer} ${styles.gapNone}` : styles.loginContainer}>
						{tabPosition === tabCreateProfile  ?
							institution && institution.slug ?
								null
								:
								<div>
									<p className={styles.fontp}>{t('intitutionName')}</p>
									<div className={error && !profileExists && (!institution || (institution && !institution.name)) ? `${styles.input_Rectangle_Icon} ${styles.alertBorderNew}` : styles.input_Rectangle_Icon}>
										<input 
											id="institution"
											data-test="institution"
											value={institution ? institution.name : null}
											name='institution'
											type='input' 
											placeholder={t('intitutionPlaceholder')}
											onChange={(event) => {
												setInstitution({name: event.target.value});
												setInstitutionSelected(event.target.value);
											}}
										/>
										<img src={iconLibrary} />
									</div>
									{error && !profileExists && (!institution || (institution && !institution.name)) &&
									<p className={styles.errorMessageParagraph}>{t('errorInstitution')}</p>
									}
								</div>
							: null
						}
						<div>
							<p className={styles.fontp}>{t('emailInput')}</p>
							<div className={error && (!isValidEmail || profileExists) ? `${styles.input_Rectangle_Icon} ${styles.alertBorderNew}` : styles.input_Rectangle_Icon}>
								<input
									id="emailLogin"
									data-test='emailLogin'
									value={email}
									name='email'
									type='input'
									onChange={(event) => {
										setEmail(event.target.value.toLocaleLowerCase());
										setProfileEmail(event.target.value);
									}}
									placeholder={t('emailPlaceholder')}
								/>
								<img src={iconUser} />
							</div>
							{error && (!isValidEmail || profileExists) &&
								<p data-test='errorLoginProfileNotExist' className={styles.errorMessageParagraph}>{profileExists ? t('errorProfileExists') : t('errorEmailLogin')}</p>
							}
						</div>
						<div>
							<p className={styles.fontp}>{tabPosition === tabLogin ? t('titlePassword') : t('createPassword')}</p>
							<div className={error && !profileExists ? `${styles.input_Rectangle_Icon} ${styles.alertBorderNew}` : styles.input_Rectangle_Icon}>
								<input
									id="password"
									data-test='password'
									value={password}
									name='password'
									type='password' 
									placeholder={t('passwordPlaceholder')}
									onChange={(event) => setPassword(event.target.value)}
								/>
								<img src={iconLock} />
							</div>
							{error && !profileExists &&
								<p data-test='errorPassword' className={styles.errorMessageParagraph}>{tabPosition === tabCreateProfile ? t('errorCreatePassword'): t('errorPasswordLogin')}</p>
							}
						</div>
					</div>
					<div>
						<div className={`${styles.button_blue} ${styles.buttonPosition}` } 
							onClick={!loading ?  tabPosition === tabCreateProfile  ? handleCreateProfile : handleLogin : null}
							data-test="buttonLogin"
						>
							{!loading ?  
								tabPosition === tabCreateProfile ? t('createProfile'): t('textNavBarLogin')
								: 	
								<ImpulseSpinner
									size={40}
									color="white"
									frontColor="white"
									backColor='white'
									loading={loading}
								/>
							}
						</div>

						{tabPosition === tabLogin  && 
						<p onClick={() => handleChangeStep(STEP_FORGOT_PASSWORD)} className={`${styles.textBlue} ${styles.textPosition}`}>{t('forgotPassword')}</p>}
					</div>


				</>
				:
				<div className={styles.containerVerification}>
					<div>
						<p data-test='codeVerification' className={styles.fontp}>{t('textCodeVerification', { email: email })}</p>
						<ReactCodeInput
							onChange={(code) => setCode(code)}
							className={error ? styles.input_code_error : styles.input_code}
							fieldWidth={40}
							fieldHeight={45}
						/>
						{error &&
							<p className={styles.errorMessageParagraphCenter}>{t('errorCode')}</p>
						}
						<div className={styles.textBlue} onClick={handleResendCode}>{t('resendCode')}</div>
					</div>
		      <div className={`${styles.button_blue} ${styles.buttonPosition}`} 
						onClick={!loading ?  handleVerification : null}>
						{!loading ?  
							t('buttonVerification')
							: 	
							<ImpulseSpinner
								size={40}
								color="white"
								frontColor="white"
								backColor='white'
								loading={loading}
							/>
						}
					</div>
				</div>
			}

		</div>
	);
};

const mapStateToProps = ({ profile  }) => {
	return {
		profile
	};
};

export default withRouter(connect(mapStateToProps, {
	getProfile,
	EditProfileNew,
})(CreateProfile));