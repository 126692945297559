import {
	DELETE_BOOKLIST, 
	FETCH_OTHERS_BOOKLISTS, 
	FETCH_OTHERS_CONTENT, 
	START_CONTENT_LIST_FETCH,
} from '../actions';
import {
	LOADING,
	ERROR,
	GET_MY_BOOKLISTS,
	PROFILE_CONTENTS,
	PROFILE_BOOKLIST_FOLLOWINGS,
	PROFILE_BOOKLIST_PERMISSIONS,
	PRIVATE_BOOKLISTS_PERMISSION,
	CLEAR_PROFILE_CONTENT,
	UPDATE_PROFILE_IMAGE,
	REMOVE_FROM_STATE,
	PROFILE_LIKED_CONTENTS,
	PROFILE_COLLABORATIVE_BOOKLISTS,
	CLEAR_PROFILE_LIKED_CONTENT,
	DELETE_CONTENT_FROM_PROFILE,
} from '../types/contentTypes';

import {
	BOOKLIST_PERMISSIONS_V1,
} from '../types/booklistTypes';

const initialState = {
	loading: false,
	error: '',
	page: 0,
	data: [],
	included: [],
	totalContent: 0,
	myBooklists: null,
	myBooklistsNew: null,
	myBooklistsFollowings: null,
	myBooklistsPermissions: null,
	privateBooklists: null,
	privateBooklistsPermissions: null,
	myContent: null,
	likedContents: null,
	othersContent: null,
	booklistPermissions: null,
};

let filterBooklists;
const allBooklists = (filterBooklists, booklists) => {
	for(let i =0; i< booklists.length; i++) {
		let booklistNotIncluded = filterBooklists.every(booklist => booklist.slug !== booklists[i].slug);
		if(booklistNotIncluded) {
			filterBooklists.push(booklists[i]);
		}
	}
	return filterBooklists;
};

export default (state = initialState, action) => {
	switch (action.type) {
	case START_CONTENT_LIST_FETCH:
		return {
			...initialState,
			loading: true,
		};
	case FETCH_OTHERS_BOOKLISTS:
		return {
			...state,
			data: action.payload.data,
			included: action.payload.included,
			loading: false,
		};
	case DELETE_BOOKLIST:
		const data = state.data.filter(pData => action.slug !== pData.attributes.slug);
		return {
			...state,
			data,
			loading: false
		};
	case FETCH_OTHERS_CONTENT:
		return {
			...state,
			data: action.payload.data,
			included: action.payload.included,
			totalContent: action.payload.total,
			loading: false,
		};
	case GET_MY_BOOKLISTS:
		return {
			...state,
			myBooklists: {
				...action.payload
			}
		};
	case BOOKLIST_PERMISSIONS_V1:
		return {
			...state,
			booklistPermissions: action.payload
		};
	case LOADING:
		return {
			...state,
			loading: true
		};
	case ERROR:
		return {
			...state,
			error: action.payload
		};
	case PROFILE_CONTENTS:
		if(state.myContent) {
			filterBooklists = allBooklists(state.myContent.contents, action.payload.contents);
		}
		return {
			...state,
			myContent: {
				...action.payload,
				contents: state.myContent ? filterBooklists : action.payload.contents
			}
		};
	case PROFILE_BOOKLIST_FOLLOWINGS:
		if(state.myBooklistsFollowings) {
			filterBooklists = allBooklists(state.myBooklistsFollowings.booklistFollowings, action.payload.booklistFollowings);
		}
		return {
			...state,
			myBooklistsFollowings: {
				...action.payload,
				booklistFollowings: state.myBooklistsFollowings ? filterBooklists : action.payload.booklistFollowings
			},
		};
	case PROFILE_BOOKLIST_PERMISSIONS:
		if(state.myBooklistsPermissions) {
			filterBooklists = allBooklists(state.myBooklistsPermissions.booklistPermissions, action.payload.booklistPermissions);
		}
		return {
			...state,
			myBooklistsPermissions: {
				...action.payload,
				booklistPermissions: state.myBooklistsPermissions ? filterBooklists : action.payload.booklistPermissions
			},
		};
	case PROFILE_LIKED_CONTENTS:
		if(state.likedContents) {
			filterBooklists = allBooklists(state.likedContents.contents, action.payload.contents);
		}
		return {
			...state,
			likedContents: {
				...action.payload,
				contents: state.likedContents ? filterBooklists : action.payload.contents
			}
		};
	case PROFILE_COLLABORATIVE_BOOKLISTS:
		if(state.collaborativeBooklists) {
			filterBooklists = allBooklists(state.collaborativeBooklists.contents, action.payload.contents);
		}
		return {
			...state,
			collaborativeBooklists: {
				...action.payload,
				contents: state.collaborativeBooklists ? filterBooklists : action.payload.contents
			},
		};
	case PRIVATE_BOOKLISTS_PERMISSION:
		if(state.privateBooklistsPermissions) {
			filterBooklists = allBooklists(state.privateBooklistsPermissions.booklistPermissions, action.payload.booklistPermissions);
		}
		return {
			...state,
			privateBooklistsPermissions: {
				...action.payload,
				booklistPermissions: state.privateBooklistsPermissions ? filterBooklists : action.payload.booklistPermissions
			}
		};
	case CLEAR_PROFILE_CONTENT:
		return {
			...state,
			myContent: null,
		};
	case CLEAR_PROFILE_LIKED_CONTENT:
		return {
			...state,
			likedContents: null
		};
	case DELETE_CONTENT_FROM_PROFILE:
		return {
			...state,
			likedContents: null,
			myContent: null,
		}
	case UPDATE_PROFILE_IMAGE:
		if(state.myBooklistsNew) {
			let updateImgUrl = state.myBooklistsNew.booklists.map(booklist => {
				return({
					...booklist,
					author: {
						...booklist.author,
						profileImgUrl: action.payload
					}
				});
			});
			return{
				...state,
				myBooklistsNew: {
					...state.myBooklistsNew,
					booklists: updateImgUrl
				}
			};
		} else {
			return {
				...state,
			};
		}
	case REMOVE_FROM_STATE:
		let publicBooklists = state.myBooklistsNew;
		let privateBooklists = state.privateBooklists;
		let publicContent = state.myContent;
		if(state.myBooklistsNew) {
			publicBooklists = {
				...state.myBooklistsNew,
				booklists: state.myBooklistsNew.booklists.filter(booklist => booklist.slug !== action.payload)
			};
		}
		if(state.privateBooklists) {
			privateBooklists = {
				...state.privateBooklists,
				booklists: state.privateBooklists.booklists.filter(booklist => booklist.slug !== action.payload)
			}; 
		}
		if(state.myContent) {
			publicContent = {
				...state.myContent,
				contents: state.myContent.contents.filter(content => content.slug !== action.payload)
			}; 
		}
		return {
			...state,
			myBooklistsNew: publicBooklists,
			privateBooklists,
			myContent: publicContent
		};
	default:
		return state;
	}
};
