import {END_SUBMIT_EMAIL_USER, SET_EZPROXY_LOCATION, START_SUBMIT_EMAIL_USER} from '../actions';

const initialState = {
	step: 0,
	loading: false,
	email: '',
	location: undefined,
	ldapName: undefined,
	ldapSurname: undefined,
	ldapEmail: undefined,
	ldapDepartment: undefined,
	ldapRole: undefined,
};

export default (state = initialState, action) => {
	switch (action.type) {
	case START_SUBMIT_EMAIL_USER:
		return {
			...state,
			loading: true,
			email: action.payload,
		};
	case END_SUBMIT_EMAIL_USER:
		return {
			...state,
			loading: false,
			step: action.payload ? 2 : 1,
		};
	case SET_EZPROXY_LOCATION:
		return {
			...state,
			location: action.payload,
		};
	default:
		return state;
	}
};
