import axios from 'axios/index';
import Cognito from '../../helpers/cognito';
import { fetchOtherProfile } from './index';

import { LOADING, PUT_USER_PERMISSIONS } from '../types/booklistTypes';

export const postPermissions = (booklistId, users) => async (dispatch) => {
	dispatch({
		type: LOADING,
	});
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'PUT',
			url: `${env.msContentNew}/api/booklist-permissions`,
			data: {
				booklistId: booklistId,
				users: users,
			},
			headers: {
				Authorization: session.idToken.jwtToken,
			},
		});
		let collaborators = [];
		let collaboratorsData = await request.data.map(async (collaborator) => {
			try {
				const profile = await fetchOtherProfile(collaborator.email);
				collaborators.push({
					...collaborator,
					data: profile.data.data,
				});
			} catch (error) {
				console.error(error);
			}
		});
		return dispatch({
			type: PUT_USER_PERMISSIONS,
			payload: collaborators,
		});
	} catch (error) {
		throw new Error('Error:', error);
	}
};
export const postContentToBooklist = async (booklistId, slug, profile) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'POST',
			url: `${env.msContentNew}/api/booklist-contents`,
			data: {
				booklistId,
				contentSlugs: slug,
			},
			headers: {
				Authorization: session.idToken.jwtToken,
			},
		});

		return request.data.data;
	} catch (error) {
		console.error(error);
	}
};
