import {
	START_PUT_FILE,
	PUT_FILE_FILE_PROGRESS,
	PUT_FILE_IMAGE_PROGRESS,
	END_PUT_FILE,
	ERROR_PUT_FILE, CLEAR_UPLOAD_FILE,
} from '../actions';

const initialState = {
	loading: false,
	imageProgress: 0,
	fileProgress: 0,
	totalProgress: 0,
	document: null,
	error: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
	case START_PUT_FILE:
		return {
			...initialState,
			loading: true,
		};
	case PUT_FILE_IMAGE_PROGRESS:
		return {
			...state,
			imageProgress: action.payload,
			totalProgress: getProgress(action.payload, state.fileProgress)
		};
	case PUT_FILE_FILE_PROGRESS:
		return {
			...state,
			fileProgress: action.payload,
			totalProgress: getProgress(state.imageProgress, action.payload)
		};
	case END_PUT_FILE:
		return {
			...initialState,
			document: action.payload,
		};
	case ERROR_PUT_FILE:
		return {
			...initialState,
			error: action.payload
		};
	case CLEAR_UPLOAD_FILE:
		return {
			...initialState,
		};
	default:
		return state;
	}
};

const getProgress = (image, file) => {
	return image * 0.2 + file * 0.7;
};
