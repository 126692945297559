import React from 'react';
import styles from '../sass/components/loadingSpinner.scss';

const LoadingSpinner = (props) => {
	return (
		<div className={styles.loadingSpinner}>
			{!props.count || <div className={styles.count}>{props.count}</div>}
			<div className={`${styles.loadingRed} ${styles.circles}`}/>
			<div className={`${styles.loadingGreen} ${styles.circles}`}/>
			<div className={`${styles.loadingOrange} ${styles.circles}`}/>
			<div className={`${styles.loadingBlue} ${styles.circles}`}/>
		</div>
	);
};

export default LoadingSpinner;
