import React, { useEffect } from 'react';
import ResultsPeople from './ResultsPeople';
import styles from '../../pages/styles/searchV2Page.scss';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

const ProfilesResult = ({ profiles, searchTerm, isMobile, isNewHome }) => {
	const { t } = useTranslation();
	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<div
			className={
				profiles && profiles.length >= 1
					? styles.contentColumn
					: styles.displayNone
			}>
			{isNewHome ? (
				<h2 className={styles.fontTitle}>Perfiles con más visitas</h2>
			) : (
				<h2 className={styles.fontTitle}>
					{t('titleSearchProfile', { query: searchTerm })}
				</h2>
			)}
			<div
				style={{
					marginTop: '25px',
					borderRadius: '8px',
					boxShadow: '0 1px 8px 0 rgb(0 0 0 / 10%)',
				}}>
				{isMobile ? (
					<Slider {...settings}>
						{profiles &&
							profiles.map((profileResult) => (
								<div key={profileResult.id} className={styles.profilesCard}>
									<ResultsPeople profileResult={profileResult} />
								</div>
							))}
					</Slider>
				) : (
					<div
						style={{
							padding: '25px 12px 20px 15px',
							display: 'grid',
							gap: '30px',
						}}>
						{profiles &&
							profiles.map((profileResult) => (
								<div key={profileResult.id}>
									<ResultsPeople profileResult={profileResult} />
								</div>
							))}
					</div>
				)}
			</div>
		</div>
	);
};

export default ProfilesResult;
