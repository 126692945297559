import {
	environment,
} from '../client/actions/index';

export const redirectNewHome = () => {
  return (
    environment === "uniagustiniana" ||
	environment === "ucp" ||
	environment === "luisamigo" ||
	environment === "unisinu" ||
    environment === "unitropico" ||
    environment === "colmayorantioquia" ||
	environment === "ibero" ||
	environment === "uniguajira" ||
    environment === "global" ||
    environment === "qa" ||
    environment === "test" ||
    environment === "beta" ||
    environment === "127"
  );
};

export function isPublicSearchAllowed() {
  const allowedEnvironments = [
    "global",
    "test",
    "beta",
    "127",
    "uniagustiniana",
  ];
  return allowedEnvironments.includes(environment);
}


export const showChat = () => {
	return environment === 'areandina'|| 
		environment === 'udec' || 
		environment === 'qa' || 
		environment === 'test' || 
		environment === '127' ||
		environment === 'agrosavia' ||
		environment === 'uniminuto' ||
		environment === 'corposucre' ||
		environment === 'corsalud' ||
		environment === 'escolme' ||
		environment === 'itm' ||
		environment === 'uniagustiniana' ||
		environment === 'ucatolica' ||
		environment === 'unicolmayor' ||
		environment === 'ucc' ||
		environment === 'unicartagena' ||
		environment === 'unibague' ||
		environment === 'pupr';
};

export const redirectNewSearch = () => {
	return 	environment === 'global' ||
		environment === 'qa' ||
		environment === 'test' ||
		environment === 'beta' ||
		environment === 'ufps'||
		environment === 'ucr'||
		environment === 'unimagdalena'||
		environment === 'uis'||
		environment === 'utb'||
		environment === 'universidadean'||
		environment === 'juanncorpas'||
		environment === 'eur'||
		environment === 'pupr'||
		environment === 'itm'||
		environment === 'javeriana'||
		environment === 'ucatolica'||
		environment === 'udca'||
		environment === 'udec'||
		environment === 'uexternado'||
		environment === 'unbosque'||
		environment === 'uniandes'||
		environment === 'unibague'||
		environment === 'unicartagena'||
		environment === 'unimilitar'||
		environment === 'unipiloto'||
		environment === 'unisabana'||
		environment === 'unisinu'||
		environment === 'uptc'||
		environment === 'usantotomas'||
		environment === 'utadeo'||
		environment === 'univalle'||
		environment === 'corposucre'||
		environment === 'udistrital'||
		environment === 'utp'||
		environment === 'unipamplona'||
		environment === 'usb'||
		environment === 'uniautonoma'||
		environment === 'konradlorenz'||
		environment === 'ces'||
		environment === 'uan'||
		environment === 'usc'||
		environment === 'eia'||
		environment === 'udemedellin'||
		environment === 'uniguajira'||
		environment === 'unitolima'||
		environment === 'espol'||
		environment === 'ibero'||
		environment === 'cientifica'||
		environment === 'unitropico'||
		environment === 'cun'||
		environment === 'colmayorantioquia'||
		environment === 'areandina'||
		environment === 'ucentral'||
		environment === 'remington'||
		environment === 'ugc'||
		environment === 'luisamigo'||
		environment === 'uniagustiniana'||
		environment === 'cuc'||
		environment === 'ucp'||
		environment === 'udla'||
		environment === 'uninorte'||
		environment === 'uniminuto'||
		environment === 'unicolmayor'||
		environment === 'sena'||
		environment === 'usco'||
		environment === 'cecar';
};
