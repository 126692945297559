import React, { useEffect, useState } from "react";
import PopUp from "../shared/PopUp";
import { useTranslation } from "react-i18next";
import useTypeContent from "../../hooks/useTypeContent";
import useClickContent from "../../hooks/useClickContent";
import styles from "./styles/popUpPreview.scss";
import Button, { appearances } from "../shared/Button";
import OfflineBookLibraryTable from "./OfflineBookLibraryTable";
import { environment } from "../../actions";
import {
  getResources,
  contentTypeNew,
  iconShareNew,
  iconAddCircle,
  booklistsType,
} from "../../../utils/constans";
import ReportContentPopUp from "../shared/ReportContentPopUp";
import { withRouter } from "react-router-dom";
const offLineBookType = "OFFLINE_BOOKS";
const PopUpPreview = ({
  closePopUp,
  content,
  openOopUpShare,
  openContextMenu,
  isMobile,
  history,
}) => {
  const { t } = useTranslation();
  const [isReportOpen, setIsReportOpen] = useState(false);
  const { labelKey } = useTypeContent(contentTypeNew[content.type].label);
  const { citation, url, handleClickContent, handleClickOfflineBook } =
    useClickContent(content.type, content.url || content.reader, content.slug);
  const [keywords, setKeywords] = useState([]);
  const [date, setDate] = useState("");
  const [libraryDataArray, setLibraryDataArray] = useState([]);
  const [author, setAuthor] = useState("");

  useEffect(() => {
    if (typeof content.author === "object") {
      setAuthor(`${content.author.firstName} ${content.author.lastName}`);
    } else {
      setAuthor(content.author);
    }
    if (content.keywords && Array.isArray(content.keywords)) {
      setKeywords(content.keywords);
    }
    if (content.publication && content.publication !== "") {
      const year = content.publication.split("-");
      setDate(year[0]);
    }
    if (content.holdings) {
      let libraries = [];
      content.holdings.map((library) => {
        libraries.push({
          library: library.library,
          location: library.location,
          quantity: library.count,
        });
      });
      setLibraryDataArray(libraries);
    } else if (content.library) {
      setLibraryDataArray([
        {
          library: content.library,
          location: content.location,
          quantity: content.count || content.quantity,
        },
      ]);
    } else {
      setLibraryDataArray(null);
    }

    loadContent();
  }, [content]);

  const loadContent = async () => {
    await handleClickContent();
  };

  const handleClickPreview = async () => {
    let session = null;
    try {
      session = await Cognito.retrieveToken();
    } catch (error) {
      console.warn(`User not logged in: ${error}`);
    }
    if (content.type == offLineBookType) {
      await handleClickOfflineBook(content);
      try {
        await navigator.clipboard.writeText(citation);
      } catch (error) {
        console.error("Failed to copy citation to clipboard:", error);
      }
      return;
    }
    if (!session) {
      if (content.type == "BOOKLISTS") {
        history.push(`/booklist/${content.slug}`);
        return;
      }

      if (content.type === "DOCUMENTS") {
        history.push(`/preview/${content.slug}`);
        return;
      }
    }
    window.open(url);
  };

  return (
    <PopUp
      closePopUp={closePopUp}
      style={{
        zIndex: "5",
        popUp: { maxWidth: "1000px", maxHeight: "75vh" },
        popUpContent: {
          display: "flex",
          margin: isMobile ? "10px 25px" : "40px 40px 0 10px",
        },
        button: {
          marginTop: "40px",
          justifyContent: "center",
        },
      }}
      buttons={{
        style: {
          width: "195px",
          height: "50px",
          borderRadius: "8px",
          marginRight: "20px",
        },
      }}
    >
      <div
        className={
          isMobile ? styles.popupContainerMobile : styles.popupContainer
        }
      >
        <div className={styles.cover}>
          <img
            className={styles.imageContainer}
            src={
              content.image
                ? content.image
                : getResources(environment).defaultCover
            }
            onError={(e) =>
              (e.target.src = getResources(environment).defaultCover)
            }
            alt="Cover image"
          />
          {content.type === offLineBookType && content.url && (
            <Button
              borderRadius="6px"
              appearance={appearances.secondary}
              action={() => window.open(url)}
              title={t("buttonView")}
              height={"50px"}
              style={{ marginBottom: "10px" }}
            />
          )}
          <Button
            icon={contentTypeNew[content.type].imgUrl}
            borderRadius="6px"
            appearance={
              booklistsType.find((elemt) => elemt === content.type)
                ? appearances.red
                : appearances.primary
            }
            action={handleClickPreview}
            title={
              content.type == offLineBookType
                ? `${t("offlineBookPopUpButtonCopy")} (BETA)`
                : `${t("buttonView")} ${t(labelKey)}`
            }
            height={"50px"}
          />
          {citation && <p className={styles.citationText}>{citation}</p>}
        </div>

        <div>
          <div
            className={isMobile ? styles.textColumnMobile : styles.textColumn}
          >
            <p className={styles.titleText}>{content.title}</p>
            {content.author && content.author !== "" && (
              <p className={styles.infoAditionalText}>
                <span>{`${t("inputAdvanceSearchAutor")}:`}</span> {`${author}`}
              </p>
            )}
            {content.author_corporative &&
              content.author_corporative !== "" && (
                <p className={styles.infoAditionalText}>
                  <span>{`${t("inputAdvanceSearchCorporativeAutor")}:`}</span>{" "}
                  {`${content.author_corporative}`}
                </p>
              )}
            {content.isbn && content.isbn !== "" && (
              <p className={styles.infoAditionalText}>
                <span>{`${t("offlineBookPopUpIsbn")}:`}</span>{" "}
                {` ${content.isbn}`}
              </p>
            )}
            {content.editorial && content.editorial !== "" && (
              <p className={styles.infoAditionalText}>
                <span>{`${t("offlineBookPopUpSource")}:`}</span>{" "}
                {` ${content.editorial}`}
              </p>
            )}
            {content.description && content.description !== "" && (
              <div className={!isMobile && styles.descriptionContainer}>
                <p className={styles.descriptionText}>
                  <span>{`${t("textDescriptionContent")}:`}</span>
                  {` ${content.description}`}
                </p>
              </div>
            )}

            <div className={styles.infoAditionalContainer}>
              {keywords.length != 0 && (
                <p className={styles.infoAditionalText}>
                  <span>{`${t("titleEditEntryKeyWords")}:`}</span>{" "}
                  {` ${keywords.toString()}`}
                </p>
              )}
              {content.pages && (
                <p className={styles.infoAditionalText}>
                  <span>{`${t("textPreviewPages")}:`}</span>{" "}
                  {`${content.pages}`}
                </p>
              )}
              {content.publisher && content.publisher !== "" && (
                <p className={styles.infoAditionalText}>
                  <span>{`${t("inputAdvanceSearchPublisher")}:`}</span>{" "}
                  {` ${content.publisher}`}
                </p>
              )}
              {content.edition && content.edition !== "" && (
                <p className={styles.infoAditionalText}>
                  <span>{`${t("titleEdition")}:`}</span> {`${content.edition}`}
                </p>
              )}
              {date !== "" && (
                <p className={styles.infoAditionalText}>
                  <span>{`${t("inputAdvanceSearchYear")}:`}</span>
                  {` ${date}`}
                </p>
              )}
            </div>

            {content.type == offLineBookType && libraryDataArray && (
              <OfflineBookLibraryTable data={libraryDataArray} />
            )}
          </div>

          {!isMobile && (
            <div className={styles.gridIcons}>
              <div className={styles.gridInterIcons}>
                <img
                  src={iconShareNew}
                  onClick={openOopUpShare}
                  className={`${styles.icon20} ${styles.cursorPointer}`}
                  alt="share"
                />
              </div>
              <div className={styles.posBtnMore}>
                <img
                  src={iconAddCircle}
                  onClick={openContextMenu}
                  className={`${styles.icon20} ${styles.cursorPointer}`}
                  alt="context-menu"
                />
              </div>
              <span
                className={`${styles.materialIcon} ${styles.cursorPointer} material-symbols-outlined`}
                onClick={() => setIsReportOpen(true)}
              >
                report
              </span>
            </div>
          )}
        </div>
      </div>

      {isReportOpen && (
        <ReportContentPopUp
          content={content}
          onCancel={() => setIsReportOpen(false)}
        />
      )}
    </PopUp>
  );
};

export default withRouter(PopUpPreview);
