import React, {Component} from 'react';
import { visitStats, deleteFollowing, putFollowing, loadingProfile, getCommonProfiles} from '../../actions';
import ReactTooltip from 'react-tooltip';
import {openProfile} from '../../../helpers/profileRouting';
import styles from './styles/discoverPeople.scss';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';

const defaultProfileImg = 'https://cdn.booklick.net/public/img/profile.png';
const iconFollowBlue = 'https://cdn.booklick.net/public/img/icons/icon-follow-blue.svg';
const iconFollowWhite = 'https://cdn.booklick.net/public/img/icons/icon-follow-white.svg';
const unfollowMessage = 'Dejar de seguir';
const followedMessage = 'Seguido';
const breakPointMobile = 550;

class DiscoverPeopleItem extends Component{

	constructor(props) {
		super(props);
		this.state = {
			profile: props,
			followed: false,
			message: this.props.t('buttonProfileFollowed'),
			common: []
		};

		this.handleUnfollow = this.handleUnfollow.bind(this);
		this.handleFollow = this.handleFollow.bind(this);
	}

	componentDidMount(){
		this.fetchData();
	}

	async fetchData(){
		const following = this.props.profile.profile && this.props.profile.profile.data.following ? this.props.profile.profile.data.following.filter(
			(profile) => profile.id === Number(this.props.id)).length > 0 : false;

		const common = await getCommonProfiles(this.state.profile.attributes.email);

		this.setState({
			followed: following,
			common
		});
	}

	async handleFollow() {
		try {
			this.setState({
			  followed: true
			});
			  await this.props.putFollowing(this.state.profile.email || this.state.profile.attributes.email);
		  } catch (error) {
			  console.log('Error', error);
		  }
	}

	async handleUnfollow() {
		try {
			this.setState({
			  followed: false
			});
			await deleteFollowing(this.state.profile.email || this.state.profile.attributes.email);
		} catch (error) {
			console.log('Error', error);
		}
	}

	onProfileClick(email, id) {
		openProfile(
			email,
			id,
			this.props.loadingProfile,
			this.props.history,
			this.props.visitStats,
			true
		);
	}

	mapImages(commonProfiles){
		switch (commonProfiles.length) {
		case 1:
			return(
				<div className={styles.gridFollow}>
					<div className={styles.circleUserFollow}>
						<img
							data-tip={`${commonProfiles[0].firstName} ${commonProfiles[0].lastName}`}
							data-for='tooltip'
							className={styles.imgCircle}
							src={commonProfiles[0].profileImgUrl ? commonProfiles[0].profileImgUrl: defaultProfileImg} alt="user"
							onClick={() =>{
								this.onProfileClick(commonProfiles[0].email, commonProfiles[0].id);
							}}
						/>
					</div>
					<ReactTooltip className={styles.reactTooltip} place="bottom" effect="solid" textColor='#333333' theme='dark' backgroundColor='#EEEEEE'/>
				</div>
			);
		case 2:
			return(
				<div className={styles.gridFollow}>
					<div className={styles.circleUserFollow}>
						<img
							data-tip={`${commonProfiles[0].firstName} ${commonProfiles[0].lastName}`}
							data-for='tooltip'
							className={styles.imgCircle}
							src={commonProfiles[0].profileImgUrl ? commonProfiles[0].profileImgUrl : defaultProfileImg}
							alt="user"
							onClick={() =>{
								this.onProfileClick(commonProfiles[0].email, commonProfiles[0].id);
							}}
						/>
					</div>
					<div className={`${styles.circleUserFollow} ${styles.backImg}`}>
						<img
							data-tip={`${commonProfiles[1].firstName} ${commonProfiles[1].lastName}`}
							data-for='tooltip'
							className={styles.imgCircle}
							src={commonProfiles[1].profileImgUrl ? commonProfiles[1].profileImgUrl : defaultProfileImg}
							alt="user"
							onClick={() =>{
								this.onProfileClick(commonProfiles[1].email, commonProfiles[1].id);
							}}
						/>
					</div>
					<ReactTooltip className={styles.reactTooltip} id='tooltip' place="bottom" effect="solid" textColor='#333333' backgroundColor='#EEEEEE' />
				</div>
			);
		default:
			return(
				<div className={styles.gridFollow}>
					<div className={styles.circleUserFollow}>
						<img
							data-tip={`${commonProfiles[0].firstName} ${commonProfiles[0].lastName}`}
							data-for='tooltip'
							className={styles.imgCircle}
							src={commonProfiles[0].profileImgUrl ? commonProfiles[0].profileImgUrl: defaultProfileImg}
							alt="user"
							onClick={() =>{
								this.onProfileClick(commonProfiles[0].email, commonProfiles[0].id);
							}}
						/>
					</div>
					<div className={`${styles.circleUserFollow} ${styles.backImg}`}>
						<img
							data-tip={`${commonProfiles[1].firstName} ${commonProfiles[1].lastName}`}
							data-for='tooltip'
							className={styles.imgCircle}
							src={commonProfiles[1].profileImgUrl ? commonProfiles[1].profileImgUrl: defaultProfileImg}
							alt="user"
							onClick={() =>{
								this.onProfileClick(commonProfiles[1].email, commonProfiles[1].id);
							}}
						/>
					</div>
					<div className={`${styles.circleUserFollow} ${styles.backImg}`}>
						<img
							data-tip={`${commonProfiles[2].firstName} ${commonProfiles[2].lastName}`}
							data-for='tooltip'
							className={styles.imgCircle}
							src={commonProfiles[2].profileImgUrl ? commonProfiles[2].profileImgUrl: defaultProfileImg}
							alt="user"
							onClick={() =>{
								this.onProfileClick(commonProfiles[2].email, commonProfiles[2].id);
							}}
						/>
					</div>
					<ReactTooltip className={styles.reactTooltip} id='tooltip' place="bottom" effect="solid" textColor='#333333' backgroundColor='#EEEEEE'/>
				</div>
			);
		}
	}

	getRole(){
		let roleMayor;
		const rol = this.state.profile.roleMayor ? this.state.profile.roleMayor.split(' ')[0]: '';
    	const mayor = this.state.profile.roleMayor ? this.state.profile.roleMayor.split(' ')[2]: '';
		if(this.state.profile.roleMayor) {
			switch (rol) {
			case 'Estudiante':
				roleMayor =  this.props.t('roleStudent',{mayor:mayor.toLowerCase()});
				break;
			case 'Profesor':
				roleMayor = this.props.t('roleTeacher',{mayor:mayor.toLowerCase()});
				break;
			case 'Administrativo':
				roleMayor = this.props.t('roleAdmin');
				break;
			default:
				roleMayor = this.props.t('roleUserBooklick');
				break;
			}
			return roleMayor;
		}else{

			let roleMayor;
			switch (this.state.profile.attributes['role']) {
			case 'student':
				roleMayor = this.props.t('roleStudent',{mayor: this.state.profile.attributes['mayor'].toLowerCase()});
				break;
			case 'teacher':
				roleMayor = this.props.t('roleTeacher',{mayor:this.state.profile.attributes['mayor'].toLowerCase()});
				break;
			case 'admin':
				roleMayor = this.props.t('roleAdmin');
				break;
			default:
				roleMayor = this.props.t('roleUserBooklick');
				break;
			}
			return roleMayor;


		}

	}

	render() {
		const {t} = this.props;
		return(
			<div className={styles.gridRow5}>
				<div className={styles.circleUser} onClick={() => this.onProfileClick(this.state.profile.email || this.state.profile.attributes.email, this.state.profile.id)}>
					<img className={styles.imgCircle} src={this.state.profile.profileImgUrl || this.state.profile.attributes['profile-img-url'] || defaultProfileImg} alt="user"/>
				</div>
				<h2 className={styles.textName} onClick={() => this.onProfileClick(this.state.profile.email || this.state.profile.attributes.email, this.state.profile.id)}>{`${this.state.profile.firstName || this.state.profile.attributes['first-name']} ${this.state.profile.lastName || this.state.profile.attributes['last-name']}`}</h2>
				<h3 className={styles.textRang}>{this.getRole()}</h3>
				{this.state.profile.commonProfiles && this.state.profile.commonProfiles.length > 0 ?
					this.mapImages(this.state.profile.commonProfiles)
					:
					this.state.common.length > 0 ?
						this.mapImages(this.state.common)
						:
						<div className={`${styles.gridFollow} ${styles.textNoFollow} ${styles.textFollow}`}>
							{this.state.profile.university || this.props.profile.profile.data.belongsTo.name}
						</div>
				}

				{this.state.profile.count && this.state.profile.count !== 0 ?
					<h3 className={styles.textFollow}>{this.state.profile.count} {t('titleDiscoverPeopleFollower')}</h3>
					:
					this.state.common.length !== 0 ?
						<h3 className={styles.textFollow}>{this.state.common.length} {t('titleDiscoverPeopleFollower')}</h3>
						:
						undefined
				}

				{Number(this.props.id) !== this.props.profile.profile.data.id ?
					this.state.followed ?
						<div className={styles.btnFollowInactive} onClick={this.handleUnfollow}
							onMouseEnter={() => {
								this.setState({
									message: t('buttonProfileLeave')
								});
							}}
							onMouseLeave={() =>{
								this.setState({
									message: t('buttonProfileFollowed')
								});
							}}>
							{this.state.message}
						</div>
						:
						<div className={styles.btnFollowActive} onClick={this.handleFollow}>
							{t('buttonContentFollow')}
							<img className={styles.icon17user} src={iconFollowBlue} alt="user"/>
							<img className={styles.icon17userWhite} src={iconFollowWhite} alt="user"/>
						</div>
					:
					<div className={styles.containerBtnFollow}></div>
				}
			</div>
		);
	}
}
function mapStateToProps({profile}) {
	return {profile};
}

export default withRouter(connect(mapStateToProps, {
	visitStats,
	loadingProfile,
	putFollowing,
})(withTranslation()(DiscoverPeopleItem)));
