import React, {Component} from 'react';
import styles from './styles/discoverPeople.scss';
import DiscoverPeopleRow from './DiscoverPeopleRow';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

const following = 'following';
const followers = 'followers';
class FollowsPeople extends Component{
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	render(){
		return (
			<div className={styles.grid}>
				<div className={styles.gridRow30}>
					<DiscoverPeopleRow type={following} {...this.props.profile}/>
					<DiscoverPeopleRow type={followers} {...this.props.profile}/>
				</div>
			</div>
		
		);
	}
}
function mapStateToProps({profile, search, institution}) {
	return {profile, search, institution};
}

export default withRouter(connect(mapStateToProps, {
})(FollowsPeople));

