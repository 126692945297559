import React, { Component } from 'react';
import styles from './styles/popupCreate.scss';
import { withTranslation } from 'react-i18next';

const userImage = 'https://cdn.booklick.net/public/img/profile.png';
const lineGray = 'https://cdn.booklick.net/public/img/icons/line-gray.svg';
const iconRemoveUser = 'https://cdn.booklick.net/public/img/icons/close-g.svg';
const iconArrowDown = 'https://cdn.booklick.net/public/img/icons/arrow-down-s-fill.svg';
const iconCheckBox = 'https://cdn.booklick.net/public/img/icons/checkbox-circle-green.svg';


class CreateBooklistUserCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			item: this.props.item,
			permission: this.props.permission,
			individualPermission: this.props.generalPermissions,
			dropDownPermissions: false,
		};
		this.handleSelect = this.handleSelect.bind(this);
	}

	handleOpenDropdown() {
		this.setState({
			dropDownPermissions: !this.state.dropDownPermissions
		});
	}
	handleSelect() {
		this.props.handleSelectUser(this.state.item);
	}
	handleEditPemission(value) {
		this.setState({ 
			individualPermission: value,
		});
		this.props.handleEditPemission(
			this.state.item.email, 
			value, 
		);
	}

	defineRolTranslate(rol,t) {
		const role = rol ? rol.split(' ')[0]: '';
    	const mayor = rol ? rol.split(' ')[2]: '';
		switch (role) {
		  case 'Administrativo':
			return t('roleAdmin');
		  case 'Estudiante':
			return t('roleStudent',{mayor});
		  case 'Profesor':
			return t('roleTeacher',{mayor});
		  default:
			return rol;
		}
	  }


	render() {
		const {t} = this.props;
		
		if(this.state.permission) {
			return(
				<div  className={styles.boxUser}>
					<div className={styles.spaceCard}>
						<div className={styles.minUser}>
							<img className={styles.imgUser} src={this.state.item.profileImgUrl || userImage} alt="user"/>
						</div>
						<div className={styles.descriptionUser}> 
							<h2 className={styles.h2NameUser}>{`${this.state.item.firstName} ${this.state.item.lastName}`}</h2>
							<p className={styles.paragraphEmailUser}>{this.state.item.email}</p>
						</div>
						<div className={styles.alingRight2}>
							<div className={styles.iconRemove}
								onClick={() => this.props.handleRemoveUser(this.state.item)}
							>
								<img className={styles.icon15} src={iconRemoveUser} alt="icon-close"/>
							</div>
							<div className={styles.userPermission}>
								<div onClick={() => this.handleOpenDropdown()} className={styles.dropDownContainer}>
									<span className={styles.dropDownLabel}>{this.state.individualPermission === 'view'? t('textReader') : t('textEdit')} </span>
									<div className={styles.dropDownArrowContainer}>
										<img className = {styles.arrowImg}src={iconArrowDown} alt='arrow' />
									</div>
									{this.state.dropDownPermissions &&
										<div className={styles.dropDownPanelContainer}>
											<div className={styles.dropDownPanel}>
												<div className={styles.dropDownPanelWrapper}>
													<ul className={styles.dropDownPanelItems}>
														<li 
															onClick={() => this.handleEditPemission('view')} 
															className={styles.dropDownPanelItem}>
															{t('textReader')}{this.state.individualPermission === 'view' && <img className={styles.iconMini} src={iconCheckBox} alt="check"/>}
														</li>
														<li 
															onClick={() => this.handleEditPemission('edit')} 
															className={styles.dropDownPanelItem}>
															{t('textEdit')}{this.state.individualPermission === 'edit' && <img className={styles.iconMini} src={iconCheckBox} alt="check"/>}
														</li>
													</ul>
												</div>
											</div>
										</div>
									}
								</div>


							</div>
						</div>	
					</div>
				</div>
			);
		} else {
			return (
				<div
					className={styles.itemDropGray} 
					onClick={() => this.handleSelect()}
				>
					<div className={styles.spaceCardSearch}>
						<div className={styles.minUser}>
							<img className={styles.imgUser} src={this.state.item.profileImgUrl ? this.state.item.profileImgUrl : userImage} alt="user"/>
						</div>
						<div className={styles.userNameContainer}> 
							<h2 className={styles.h2NameUser2}>{`${this.state.item.firstName} ${this.state.item.lastName}`}</h2>
							<p className={styles.paragraphEmailUser3}>{this.state.item.email}</p>
						</div>
						<div className={styles.containerLineGray}>
							<img className={styles.lineGray} src={lineGray} />
							<h2 className={styles.paragraphEmailUser2}>{this.state.item.roleMayor ? this.defineRolTranslate(this.state.item.roleMayor,t) : null}</h2>
						</div>
					</div>
				</div>
			);
		}
	}

}


export default withTranslation()(CreateBooklistUserCard);