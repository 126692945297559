import React from 'react';
import ContentLoader from 'react-content-loader';
const LoaderBooklistFilters = (props) => (
	<div style={{margin:'0px auto' , maxWidth:'1200px'}}>
	 <ContentLoader 
			speed={2}
			width={1200}
			height={73}
			viewBox="0 0 1200 73"
			backgroundColor="#c2c2c2"
			foregroundColor="#d4d4d4"
			{...props}
		>
			<rect x="0" y="25" rx="0" ry="0" width="547" height="48" /> 
			<rect x="573" y="25" rx="0" ry="0" width="260" height="48" /> 
			<rect x="859" y="25" rx="0" ry="0" width="260" height="48" />
		</ContentLoader>
	</div>

);
  
export default LoaderBooklistFilters;