import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import styles from './styles/dropDown.scss';
import { breakPoint } from '../../../utils/constans';

const DropDown = ({
	options,
	value,
	handleValue,
	menuOpen,
	title,
	maxWidth,
	width,
	minHeight,
	fontSizeTitle,
	customOption,
	isSearchable,
	boxShadow,
	fontSizeDropDown,
	borderRadius,
	placeholder,
	height,
	handleInputChange,
	withSeparator,
}) => {
	const { t } = useTranslation();
	const [isMobile, setIsMobile] = useState(
		window.innerWidth < breakPoint.mobileWide,
	);

	useEffect(() => {
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleResize = () => {
		setIsMobile(window.innerWidth < breakPoint.mobileWide);
	};

	return (
		<div
			style={{ maxWidth, width, height }}
			className={
				isMobile ? styles.gridContentDropMobile : styles.gridContentDrop
			}>
			{title && (
				<h3 style={{ fontSize: fontSizeTitle }} className={styles.fonth3}>
					{title} </h3> )}
			<Select
				value={value ? value : null}
				onChange={handleValue}
				options={options}
				autoFocus={false}
				placeholder={placeholder || t('placeholderFilterContent')}
				isSearchable={isSearchable}
				onInputChange={handleInputChange}
				noOptionsMessage={() => t('filterContentDefault')}
				onMenuOpen={menuOpen}
				styles={{
					menu:base=>({
						...base,
						fontSize: fontSizeDropDown || 16,
						color:'#000000',
						border:'1px solid #D8D8D8',
						borderTop:0,
						borderRadius:'0 0 4px 4px',
						padding:0,
						boxShadow:'none',
						marginTop:0,
						marginBottom:0,
						textAlign:'left',
						zIndex:3
					}),
					menuList:base=>({
						...base,
						maxHeight:220,
						fontSize: fontSizeDropDown || 16,
						overflowY:'scroll',
						zIndex:3,
						'&::-webkit-scrollbar':{
							width:7,
							display:'grid'
						},
						'&::-webkit-scrollbar-thumb':{
							borderRadius:4,
							backgroundColor:'rgba(0, 0, 0, .5)',
							boxShadow:' 0 0 1px rgba(255, 255, 255, .5)'
						}
						
					}),
					indicatorSeparator:(base)=>({
						...base,
						marginTop:0,
						marginBottom:0,
						width:0
					}),
					control:  (base) => ({
						...base,
						minHeight: minHeight,
						borderRadius:borderRadius || 8,
						background:'#ffffff',
						textAlign:'center',
						border:'solid 0.5px #b3b3b3',
						color:'#6d6c6c',
						boxShadow: boxShadow ? boxShadow :  'none',
						'&:hover': {
							boxShadow: boxShadow ? boxShadow :  'none',
						}
					}),
					singleValue: base => ({
						...base,
						fontSize: fontSizeDropDown || 16,
						color: 'inherit'
					}),
					dropdownIndicator: base => ({
						...base,
						color: 'inherit',
						...(withSeparator && {
							position: 'relative',
							'&::before': {
								content: '""',
								position: 'absolute',
								left: -2.5,
								top: '50%',
								transform: 'translateY(-50%)',
								width: 1,
								height: '70%',
								backgroundColor: 'rgba(109, 108, 108, 0.6)',
							},
						}),
					}),
					input: base=>({
						...base,
						fontSize: fontSizeDropDown || 16,
						textAlign:'center'
					}),
					option: (base,state) => ({
						...base,
						background:state.isSelected && '#fafafa',
						color:state.isSelected && '#000000',
						textAlign:state.isSelected && 'left',
						'&:hover':{
							background:'#fafafa',
							cursor:'pointer',
							textAlign:'left'
						}
					}),
					placeholder:(base)=>({
						...base,
						fontSize: fontSizeDropDown || 16,

					})
                    

				}}
				components={customOption && { Option: customOption }}
			/>
		</div>
	);
};

export default DropDown;