import axios from 'axios/index';
import Cognito from '../../helpers/cognito';
import { environment } from './index';
import {
	SEARCH_PROGRAM,
	LOADING_PROGRAM,
} from '../types/profileHomeTypes';
import {
	SEARCH_PROFILES
} from '../types/searchTypes';
import {
	GET_PROFILE_NEW
} from '../types/profileTypes';
import { SET_EZPROXY_LOCATION } from './userActions';
let enviromentTrial = 'enviromentTrial';

export const getProfiles = async () => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${env.msRecommend}/recommend/profiles`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				env: environment
			}
		});
		const data = request.data.data.slice(0, 12);
		let profiles = [];
		for (const item of data) {

			let roleMayor;
			switch (item.role) {
			case 'student':
				roleMayor = 'Estudiante de ' + item.mayor.toLowerCase();
				break;
			case 'teacher':
				roleMayor = 'Profesor de ' + item.mayor.toLowerCase();
				break;
			case 'admin':
				roleMayor = 'Administrativo';
				break;
			}

			const profile = {
				id: item.id,
				uid: item.uid,
				profileImgUrl: item.profileImgUrl,
				firstName: item.firstName,
				lastName: item.lastName,
				roleMayor: roleMayor,
				isFollowed: false,
				email: item.email
			};
			profiles.push(profile);
		}
		return profiles;

	} catch (error) {
		const request = await axios({
			method: 'GET',
			url: `${env.msProfile}/loc-home/profile?pag=${0}`,
			headers: {
				'Access-Control-Allow-Origin': '*',
				Accept: 'application/json',
				'Content-Type': 'application/json',
				env: environment
			}
		});
		const data = request.data.data.slice(0, 6);
		let profiles = [];
		for (let item of data) {
			let roleMayor;
			switch (item.attributes.role) {
			case 'student':
				roleMayor = 'Estudiante de ' + item.attributes.mayor.toLowerCase();
				break;
			case 'teacher':
				roleMayor = 'Profesor de ' + item.attributes.mayor.toLowerCase();
				break;
			case 'admin':
				roleMayor = 'Administrativo';
				break;
			}

			const profile = {
				id: item.attributes.uid,
				uid: item.attributes.uid,
				profileImgUrl: item.attributes.profileImgUrl,
				firstName: item.attributes.firstName,
				lastName: item.attributes.lastName,
				roleMayor: roleMayor,
				isFollowed: false,
				email: item.id
			};
			profiles.push(profile);
		}

		return profiles;
	}

};

export const putFollowing = async(email) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'PUT',
			url: `${env.msProfile}/profile/following/${email}`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				env: environment
			}
		});

		return request.data.data;
	} catch (error) {
		throw new Error('Error:', error);
	}
};

export const deleteFollowing = async (email) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'DELETE',
			url: `${env.msProfile}/profile/following/${email}`,
			headers: {
				'Authorization': session.idToken.jwtToken
			}
		});
		return request.data.data;
	} catch (error) {
		throw new Error('Error:', error);
	}
};

export const postHide = async (email) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'POST',
			url: `${env.msRecommend}/hide`,
			data: {
				hideProfile: `${email}`
			}
			,
			headers: {
				'Authorization': session.idToken.jwtToken,
				env: environment
			}
		});
		return request.data.data;
	} catch (error) {
		throw new Error('Error:', error);
	}
};

export const searchProfiles = (query, limit) => async (dispatch) => {
	try {
		const serchProfiles = await getSearchProfile(query, limit);
		
		let profiles = [];
		for (const item of serchProfiles) {
			let roleMayor;
			switch (item.role) {
			case 'student':
				roleMayor = 'Estudiante de ' + item.mayor.toLowerCase();
				break;
			case 'teacher':
				roleMayor = 'Profesor de ' + item.mayor.toLowerCase();
				break;
			case 'admin':
				roleMayor = 'Administrativo';
				break;
			}

			const profile = {
				email: item.email,
				firstName: item.firstName,
				lastName: item.lastName,
				mayor: item.mayor,
				profileImgUrl: item.profileImgUrl,
				role: item.role,
				roleMayor: roleMayor,
			};
			profiles.push(profile);
		}

		dispatch({
			type: SEARCH_PROFILES,
			payload: profiles
		});

		return profiles;
	} catch (error) {
		console.error(error);
	}
};

export const getSearchProfile = async (term, limit) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'POST',
			url: `${env.msProfileNew}/api/profiles/search`,
			data: {
				query: term,
				limit: limit ? limit : null
			},
			headers: {
				'Authorization': session.idToken.jwtToken,
				env: environment
			}
		});

		return request.data;
	} catch (error) {
		console.error(error);
		return [];
	}
	
};

export const GET_ONBOARDING_COUNT = 'GET_ONBOARDING_COUNT';
export const END_GET_ONBOARDING_COUNT = 'END_GET_ONBOARDING_COUNT';
export const onboardingCount = () => async (dispatch) => {
	dispatch({
		type: GET_ONBOARDING_COUNT,
	});
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'PUT',
			url: `${env.msProfileNew}/api/profiles/onboarding`,
			headers: {
				'Authorization': session.idToken.jwtToken,
			}
		});
		dispatch({
			type: END_GET_ONBOARDING_COUNT
		});
		return request.data;
	} catch (error) {
		console.error(error);
	}
};

export const getProfile = () => async (dispatch) => {
	try {
		let slugTrial = window.localStorage.getItem(enviromentTrial);
		const ezProxyDate = localStorage.getItem(SET_EZPROXY_LOCATION);
		const now = new Date();
		if (ezProxyDate && now.getTime() > (new Date(Number(ezProxyDate))).getTime()) {
			Cognito.singOut();
			localStorage.removeItem(SET_EZPROXY_LOCATION);
			window.location.href = '/';
			return;
		}
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${env.msProfileNew}/api/v2/profiles`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});
		dispatch({
			type: GET_PROFILE_NEW,
			payload: request
		});
		return request.data;
	} catch (error) {
		console.error(error);
	}
};


export const GET_LINK_TYPES = 'GET_LINK_TYPES';
export const END_GET_LINK_TYPES = 'END_GET_LINK_TYPES';
export const getLinkTypes = () => async (dispatch) => {
	dispatch({
		type: GET_LINK_TYPES,
	});
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${env.msContentNew}/api/link-types`,
			headers: {
				'Authorization': session.idToken.jwtToken,
			}
		});
		dispatch({
			type: END_GET_LINK_TYPES
		});
		return request.data;
	} catch (error) {
		console.error(error);
	}
};

export const fetchRoles = () => async (dispatch) => {
	const roles = await axios.get(`${env.msProfileNew}/api/roles`);
	return roles.data;
};

export const searchProgram = (query) => async (dispatch) => {
	dispatch({
		type: LOADING_PROGRAM,
	});
	try {
		let slugTrial = window.localStorage.getItem(enviromentTrial);
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'POST',
			url: `${env.msProfileNew}/api/program-imports/search`,
			data: {
				query: `${query}`
			},
			headers: {
				'Authorization': session.idToken.jwtToken,
				env: slugTrial ? slugTrial : environment
			}
		});
		dispatch({
			type: SEARCH_PROGRAM,
			payload: request.data
		});
	} catch (error) {
		console.error(error);
	}
};

export const searchProgramByName = async (query) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'POST',
			url: `${env.msProfileNew}/api/program-imports/search`,
			data: {
				query: `${query}`,
			},
			headers: {
				Authorization: session.idToken.jwtToken,
				env: environment,
			},
		});
		return request.data;
	} catch (error) {
		console.error(error);
	}
};
