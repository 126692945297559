import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DialogBottomMobile from '../../shared/DialogBottomMobile';
import ButtonGroup from '../../shared/ButtonGroup';
import { useTranslation } from 'react-i18next';
import useFormFileds from '../../../hooks/useFormFields';
import { iconSearchGreyDark } from '../../../../utils/constans';
import InputGroup from '../../shared/InputGroup';
import {
	clearProfileContent,
	fetchSectionsOfBooklist,
	getMoreMyBooklist,
	getMyBooklist,
	postAddContentInBooklist,
	postContent,
	postSearchBooklistsCollaborative,
	postSearchMyBooklists,
	putMoveContentToSection,
	resetState,
} from '../../../actions';
import CollaborativeContent from './CollaborativeBooklists';
import SectionsBooklistContextMenu from '../SectionsBooklistContextMenu';
import MyBooklists from './MyBooklists';
import SearchBooklists from './SearchBooklists';
import PopupSuccess from '../PopupSuccess';
import PopUp from '../../shared/PopUp';

const AddContentMenuMobile = ({ clickedContent, onClose, resetState, clearProfileContent }) => {
	const [selectedBooklistId, setSelectedBooklistId] = useState({
		myBooklistId: null,
		booklistCollaborativeId: null,
	});
	const [tabPage, setTabPage] = useState({
		myBooklist: true,
		collaborative: false,
	});
	const [loading, setLoading] = useState({
		search: false,
		save: false,
	});
	const [selectedSection, setSelectedSection] = useState(null);
	const [showSections, setShowSections] = useState(false);
	const [booklistSections, setBooklistSections] = useState(null);
	const [moveToSection, setMoveToSection] = useState(false);
	const [openPopupSuccess, setOpenPopupSuccess] = useState(false);
	const [isSearch, setIsSearch] = useState(false);
	const [resultBooklists, setResultBooklists] = useState(null);
	const { t } = useTranslation();
	const { fields, handleOnchage } = useFormFileds({
		searchContent: '',
	});

	useEffect(() => {
		return () => {
			resetState();
		};
	}, []);

	useEffect(() => {
		const delaySearch = setTimeout(() => {
			handleSearch(fields.searchContent);
		}, 600);

		return () => clearTimeout(delaySearch);
	}, [fields.searchContent]);

	const handleCardSelect = (booklistId) => {
		if (tabPage.myBooklist) {
			setSelectedBooklistId({
				myBooklistId: booklistId,
				booklistCollaborativeId: null,
			});
		} else if (tabPage.collaborative) {
			setSelectedBooklistId({
				myBooklistId: null,
				booklistCollaborativeId: booklistId,
			});
		}
	};

	const handleTabPage = (value) => {
		setResultBooklists([]);
		setLoading({ ...loading, search: false });
		setIsSearch(false);
		setTabPage(value);
		handleOnchage('', 'searchContent');
	};

	const handleClickAddContent = async () => {
		const booklistId =
			selectedBooklistId.myBooklistId ||
			selectedBooklistId.booklistCollaborativeId;
		if (booklistId) {
			try {
				setLoading({ ...loading, save: true });
				const sectionsOfBooklist = await fetchSectionsOfBooklist(
					booklistId
				);
				if (sectionsOfBooklist.length === 1) {
					if (clickedContent['resource_origin'] !== 'content-user') {
						const resultContent = await createContent(
							clickedContent
						);
						clickedContent = {
							...clickedContent,
							...resultContent,
						};
					}
					await postAddContentInBooklist(
						clickedContent.slug,
						booklistId,
						sectionsOfBooklist[0].id
					);
					setLoading({ ...loading, save: false });
					setOpenPopupSuccess(true);
				} else {
					const sectionId = sectionsOfBooklist[0].id;
					setBooklistSections(sectionsOfBooklist);
					setSelectedSection(sectionId);
					setShowSections(true);
					setLoading({ ...loading, save: false });
				}
			} catch (error) {
				setLoading({ ...loading, save: false });
				console.error(error);
			}
		}
	};

	const handleClickAddContentWithSection = async () => {
		try {
			const booklistId =
				selectedBooklistId.myBooklistId ||
				selectedBooklistId.booklistCollaborativeId;
			setLoading({ ...loading, save: true });

			if (clickedContent['resource_origin'] !== 'content-user') {
				const resultContent = await createContent(clickedContent);
				clickedContent = { ...clickedContent, ...resultContent };
			}

			await postAddContentInBooklist(
				clickedContent.slug,
				booklistId,
				selectedSection
			);
			setLoading({ ...loading, save: false });
			setOpenPopupSuccess(true);
		} catch (error) {
			if (error === 409) {
				setLoading({ ...loading, save: false });
				setMoveToSection(true);
			}
			setLoading({ ...loading, save: false });
			console.error(error);
		}
	};

	const handleMoveToSection = async () => {
		const booklistId =
			selectedBooklistId.myBooklistId ||
			selectedBooklistId.booklistCollaborativeId;
		try {
			setLoading({ ...loading, save: true });
			await putMoveContentToSection(
				booklistId,
				clickedContent.id,
				selectedSection
			);
			setLoading({ ...loading, save: false });
			setMoveToSection(false);
			setOpenPopupSuccess(true);
		} catch (error) {
			setLoading({ ...loading, save: false });
			console.error(error);
		}
	};

	const handleGoToBack = () => {
		setShowSections(false);
	};

	const handleSearch = async (term) => {
		if (term === '') {
			setResultBooklists([]);
			setLoading({ ...loading, search: false });
			setIsSearch(false);
		} else {
			try {
				setLoading({ ...loading, search: true });
				setIsSearch(true);
				let resultSearch;
				if (tabPage.myBooklist) {
					resultSearch = await postSearchMyBooklists(
						term.toLowerCase()
					);
				} else if (tabPage.collaborative) {
					resultSearch = await postSearchBooklistsCollaborative(
						term.toLowerCase()
					);
				}

				if (resultSearch) {
					setResultBooklists([...resultSearch]);
					setLoading({ ...loading, search: false });
				} else {
					setLoading({ ...loading, search: false });
				}
			} catch (error) {
				console.error(error);
			}
		}
	};

	const createContent = async (clickedContent) => {
		const createContent = await postContent(
			clickedContent,
			clickedContent.type
		);
		return createContent;
	};

	const buttons = [
		{
			label: t('textContextMySubMenuBooklist'),
			action: () =>
				handleTabPage({ collaborative: false, myBooklist: true }),
		},
		{
			label: t('textMyBooklistCollabote'),
			action: () =>
				handleTabPage({ myBooklist: false, collaborative: true }),
		},
	];

	if (moveToSection) {
		return (
			<PopUp
				closePopUp={onClose}
				style={{
					popUp: {
						maxHeight: '406px',
						maxWidth: '534px',
					},
					popUpContent: {
						margin: '40px',
					},
					button: {
						marginTop: '30px',
						justifyContent: 'center',
					},
				}}
				buttons={{
					style: {
						width: '220px',
						height: '50px',
						borderRadius: '8px',
						marginRight: '15px',
					},
					primary: {
						title: t('textPreferenceSave'),
						type: 'primary',
						action: handleMoveToSection,
						loading: loading.save,
					},
					secondary: {
						title: t('textContextMenuCancel'),
						type: 'secondary',
						disabled: false,
						action: onClose,
					},
				}}
			>
				<div style={{ marginTop: '16px' }}>
					<p style={{ fontSize: '18px', fontWeight: '500' }}>
						{t('textPopUpContextMenuAlreadyExists')}
					</p>
					<p style={{ fontSize: '18px' }}>
						{t('textPopUpContextMenuAlreadyExistsSection')}
					</p>
				</div>
			</PopUp>
		);
	}

	if (openPopupSuccess) {
		clearProfileContent();
		return(
			<PopupSuccess closePopUp={onClose} />
		);
	}

	return (
		<DialogBottomMobile
			onClose={onClose}
			button={{
				title: t('textKeywordAdd'),
				style: {
					height: '50px',
					borderRadius: '8px',
				},
				type: 'primary',
				action: showSections
					? handleClickAddContentWithSection
					: handleClickAddContent,
				disabled:
					(tabPage.myBooklist && !selectedBooklistId.myBooklistId) ||
					(tabPage.collaborative &&
						!selectedBooklistId.booklistCollaborativeId)
						? true
						: false,
				goToback: {
					title: t('back'),
					action: handleGoToBack,
					hidden: !showSections,
				},
			}}
			content={
				<div>
					<div style={{ marginTop: '16px' }}>
						<p
							style={{
								fontSize: '22px',
								fontWeight: '500',
								textAlign: 'center',
							}}
						>
							{t('textPopUpContextMenuBooklistSelect')}
						</p>
					</div>
					{showSections ? (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<div style={{ marginTop: '16px' }}>
								<p
									style={{
										fontSize: '22px',
										fontWeight: '500',
									}}
								>
									{t('textPopUpContextMenuSectionSelect')}
								</p>
							</div>
						</div>
					) : (
						<>
							<ButtonGroup
								buttons={buttons}
								initialSelectedIndex={0}
								style={{
									buttonGroup: {
										height: '45px',
										marginBottom: '15px',
									},
								}}
							/>
							<InputGroup
								inputName={'searchContent'}
								style={{
									height: '48px',
									margin: '0px',
									fontSize: '16px',
									width: '100%',
									marginBottom: '5px',
								}}
								icon={iconSearchGreyDark}
								placeholder={`${t('titleSearchBooklist')}...`}
								onChange={handleOnchage}
								value={fields.searchContent}
							/>
						</>
					)}
					{showSections ? (
						<SectionsBooklistContextMenu
							booklistSections={booklistSections}
							setSelectedSection={setSelectedSection}
							sectionId={selectedSection}
						/>
					) : isSearch ? (
						<SearchBooklists
							results={resultBooklists}
							loading={loading.search}
							handleCardSelect={handleCardSelect}
							selectedBooklistId={
								tabPage.myBooklist
									? selectedBooklistId.myBooklistId
									: selectedBooklistId.booklistCollaborativeId
							}
						/>
					) : tabPage.myBooklist && !isSearch ? (
						<MyBooklists
							handleCardSelect={handleCardSelect}
							selectedBooklistId={selectedBooklistId.myBooklistId}
						/>
					) : tabPage.collaborative && !isSearch ? (
						<CollaborativeContent
							handleCardSelect={handleCardSelect}
							selectedBooklistId={
								selectedBooklistId.booklistCollaborativeId
							}
						/>
					) : null}
				</div>
			}
		/>
	);
};

const mapStateToProps = ({ contextMenu }) => {
	return {
		loading: contextMenu.loading,
		myBooklist: contextMenu.myBooklist,
	};
};

export default withRouter(
	connect(mapStateToProps, {
		getMyBooklist,
		getMoreMyBooklist,
		resetState,
		clearProfileContent,
	})(AddContentMenuMobile)
);
