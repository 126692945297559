import axios from 'axios';
import { environment, msStats } from './index';

export const loginStat = (user) => async () => {
	axios({
		method: 'POST',
		url: `${msStats}/login`,
		data: {
			env: environment,
			user,
		},
	});
};


export const visitStats = (slug, type) => async (dispatch, getState) => {
	const { profile } = getState();
	try {
		const request = await axios({
			method: 'POST',
			url: `${msStats}/visit`,
			data: {
				env: environment,
				user: profile.profile ? profile.profile.data.email : 'Invitado',
				slug,
				type,
			},
		});

		const data = request.data;

		return data;

	} catch(error) {
		console.log(error);
		return error;
	}
};

export const getVisits = async (slug) =>{
	try {
		const request = await axios({
			method: 'GET',
			url: `${env.msStats}/visit?contentSlug=${slug}`,
			headers: {
				env: environment,
			},
		});

		let data = request.data.visit;

		return data;

	} catch (error) {
		return 0;
	}
};


export const postRedirectSearch = (source, slug, reader) => async (
	dispatch,
	getState
) => {
	const { profile } = getState();
	await axios({
		method: 'POST',
		url: `${env.msStats}/redirect`,
		data: {
			env: environment,
			user: profile.profile ? profile.profile.data.email : null,
			source,
			slug,
			reader,
		},
	});
};
export const postRedirectContent = (contentId) => async (dispatch, getState) => {
	const { profile } = getState();
	try {
		const request = await axios({
			method: 'POST',
			url: `${env.msContentNew}/api/contents/redirect`,
			data:{
				email: profile.profile ? profile.profile.data.email : null,
				contentId
			},
			headers: {
				env: environment,
			},
		});

		let data = request.data;

		return data;

	} catch (error) {
		throw Error(error);
	}
};
