import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './styles/booklistInfo.scss';
const LoaderBooklistIfo = (props) => (
	<div className={styles.container}>
		<div style={{margin:'0px auto' , maxWidth:'1200px'}}>
			<ContentLoader 
				speed={2}
				width={1200}
				height={300}
				viewBox="0 0 1200 300"
				backgroundColor="#c2c2c2"
				foregroundColor="#d4d4d4"
				{...props}
			>
				<rect x="7" y="7" rx="0" ry="0" width="256" height="232" /> 
				<circle cx="290" cy="26" r="12" /> 
				<rect x="310" y="16" rx="0" ry="0" width="116" height="19" /> 
				<rect x="283" y="51" rx="0" ry="0" width="397" height="58" /> 
				<rect x="332" y="73" rx="0" ry="0" width="12" height="5" /> 
				<rect x="285" y="118" rx="0" ry="0" width="287" height="19" /> 
				<rect x="285" y="148" rx="0" ry="0" width="802" height="19" /> 
				<rect x="283" y="186" rx="8" ry="8" width="181" height="50" />
			</ContentLoader>
		</div>
	</div>
);
  
export default LoaderBooklistIfo;