import React, {useEffect} from 'react';
import nativeMenu from '../components/hocs/nativeMenu';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import LoadingSpinner from '../components/LoadingSpinner';
import styles from '../sass/index.scss';
import { useTranslation } from 'react-i18next';
import { environment } from '../actions';


const booklistQuery = 'booklistSlug';
const contentQuery = 'contentSlug';
const profileQuery = 'emailSlug';
const termQuery = 'termQuery';

const LoadingPage = ({ profile}) => {

	const { t } = useTranslation();

	useEffect(()=> {
		if(profile.profile) {
			if (
				profile.profile.data.lastName &&
				profile.profile.data.role
			) {
				handleRedirect();
			} else{
				window.location = `${window.location.origin}`;
			}
		}
	}, [profile]);


	const handleRedirect = () => {
		let booklistSlug = window.localStorage.getItem(booklistQuery);
		let contentSlug = window.localStorage.getItem(contentQuery);
		let emailSlug = window.localStorage.getItem(profileQuery);
		let searchTerm = window.localStorage.getItem(termQuery);
		if(searchTerm){
			window.localStorage.removeItem(termQuery);
			window.location = `${window.location.origin}/search?term=${searchTerm}`;
		} else if(booklistSlug) {
			window.localStorage.removeItem(booklistQuery);
			window.location = `${window.location.origin}/booklist/${booklistSlug}`;
		} else if(contentSlug) {
			window.localStorage.removeItem(contentQuery);
			window.location = `${window.location.origin}/preview/${contentSlug}`;
		} else if(emailSlug) {
			window.localStorage.removeItem(profileQuery);
			window.location = `${window.location.origin}/perfil/${environment}/${emailSlug}`;
		} else {
			window.location = `${window.location.origin}`;
		}
	};
	
	
	return (
		<div className={styles.loadingContainer}>
			<div style={{width: '10%'}}>
				<LoadingSpinner/>
			</div>
			<h1>{t('titleAuthenticating')}</h1>
		</div>
	);
	
};

const mapStateToProps = ({profile}) => {
	return {profile};
};

export default {
	component: nativeMenu(withRouter(connect(mapStateToProps)(LoadingPage)))
};
