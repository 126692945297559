import React from 'react';
import { withRouter } from 'react-router-dom';
import nativeMenu from '../components/hocs/nativeMenu';
import { connect } from 'react-redux';
import styles from './styles/privacyPolicyPage.scss';
import CatchError from '../components/shared/CatchError';

const PrivacyPolicyPage = () => {
	return (
		<CatchError>
			<div className={styles.mainContainer}>
				<h1>AVISO DE PRIVACIDAD</h1>

				<p>La protección de sus datos es fundamental para nosotros.</p>
				<p>
					Conforme a la Ley 1581 de 2012, Decreto 1074 de 2015 y a cualquier
					norma que los modifique, complemente o sustituya, así como de las
					mejores prácticas a nivel internacional en materia de protección de
					datos, <strong>BOOKLICK S.A.S</strong>, sociedad comercial
					identificada con el NIT 900.934.869-6, con domicilio principal en la
					en la carrera 7 #77 - 07 oficina 802 en la ciudad de Bogotá D.C.
					(Colombia), en desarrollo de su objeto misional enfocado a la
					comercialización y creación de plataformas digitales de intercambio de
					intereses académicos como herramienta para los usuarios vinculados,
					recolectará, usará, almacenará, circulará, transmitirá, transferirá y,
					en general, realizará el tratamiento de sus datos personales, en su
					calidad de Responsable del Tratamiento. El tratamiento de sus datos
					personales se realizará para (i) conocer de manera suficiente al
					Titular con quien se propone entablar relaciones, prestar servicios, y
					valorar el riesgo presente o futuro de las mismas relaciones y
					servicios. (ii) Efectuar las gestiones pertinentes para el desarrollo
					de la etapa precontractual, contractual y pos contractual con la
					Compañía, así como dar cumplimiento a la ley colombiana o extranjera y
					a las órdenes de autoridades judiciales o administrativas; (iii)
					Implementar estrategias de relacionamiento con clientes, proveedores,
					accionistas y otros terceros con los cuales la Empresa tenga
					relaciones contractuales o legales; (iv) Dar a conocer, transferir y/o
					trasmitir Datos Personales dentro y fuera del país a las compañías
					matrices, filiales, subsidiarias o a terceros como consecuencia de un
					contrato, ley o vínculo lícito que así lo requiera. (v) Verificar la
					identidad del titular, realizar estudios de seguridad y/o aplicar los
					protocolos de seguridad a fin de prevenir y mitigar el riesgo de
					fraude, lavado de activos y/o financiación del terrorismo. (vi)
					Conocer, almacenar y procesar toda la información suministrada por los
					Titulares de datos en una o varias bases de datos, en el formato que
					estime más conveniente. (vii) Realizar todas las gestiones de orden
					tributario, contable, fiscal y de facturación.
				</p>

				<p>
					Los titulares de datos personales que sean recolectados en desarrollo
					de la actividad comercial descrita, tienen el derecho a: i) Acceder en
					forma gratuita a los datos proporcionados que hayan sido objeto de
					tratamiento; (ii) Conocer, actualizar y rectificar su información
					frente a datos parciales, inexactos, incompletos, fraccionados, que
					induzcan a error, o a aquellos cuyo tratamiento esté prohibido o no
					haya sido autorizado; (iii) Solicitar prueba de la autorización
					otorgada; (iv) Presentar ante la Superintendencia de Industria y
					Comercio (SIC) quejas por infracciones a lo dispuesto en la
					normatividad vigente; (v) Revocar la autorización y/o solicitar la
					supresión del dato, siempre que no exista un deber legal o contractual
					que impida eliminarlos. (vi) Abstenerse de responder las preguntas
					sobre datos sensibles. Tendrá carácter facultativo las respuestas que
					versen sobre datos sensibles o sobre datos de las niñas, niños y
					adolescentes.
				</p>

				<p>
					La información recolectada en las bases de datos o archivos se
					adelantará en los términos establecidos en las normas vigentes y en la
					Política de Tratamiento de Datos Personales, las cuales podrán ser
					consultadas en cualquier momento en el siguiente enlace:
					<a
						href='http://initial.booklick.co/td.html'
						target='_blank'
						rel='noopener noreferrer'>
						{' '}
						http://initial.booklick.co/td.html
					</a>
				</p>

				<p>
					Las finalidades del tratamiento de los datos personales que
					actualmente tiene Booklick, son los siguientes: Medir niveles de
					satisfacción y demás fines administrativos internos y/o comerciales
					tales como, investigación de mercados, auditorías, reportes contables,
					análisis estadísticos, facturación, realizar encuestas, enviar
					invitaciones a eventos, Realizar actualización de datos, enviar
					publicidad; desarrollar actividades propias de intercambio de material
					académico; Realizar recolección de información para medir el
					rendimiento académico de los estudiantes que usan la plataforma;
					suministrar información académica por solicitud de la INSTITUCIÓN,
					salvo aquella información que se encuentre protegida por ley o que
					requiera orden judicial; Enviar invitaciones para conocer tendencias
					en la plataforma académica; Atender solicitudes realizadas por
					autoridades públicas a nivel nacional.
				</p>

				<p>
					Agradecemos nos contacte en la eventualidad de requerir aclarar,
					actualizar, corregir o suprimir alguno de sus datos personales,
					siempre que sea procedente con la legislación aplicable. Para este
					fin, los canales de comunicación establecidos son la línea telefónica
					+57 3104330768 en Bogotá, y/o al correo electrónico{' '}
					<a
						href='mailto:soporte@booklick.co'
						target='_blank'
						rel='noopener noreferrer'>
						{' '}
						soporte@booklick.co
					</a>
				</p>

				<h1>AUTORIZACIÓN PARA LA RECOLECCIÓN DE DATOS PERSONALES</h1>

				<p>
					Dando click en “ACEPTAR” Declaro que autorizo a Booklick SAS, para la
					recolección y tratamiento de mis datos personales, por el término de
					10 años, conforme a la política de datos personales disponible
					<a
						href='http://initial.booklick.co/td.html'
						target='_blank'
						rel='noopener noreferrer'>
						{' '}
						http://initial.booklick.co/td.html
					</a>
					, entiendo que los datos serán objeto de recolección, almacenamiento,
					uso, circulación, supresión, transferencia, transmisión, cesión y todo
					el tratamiento, con la finalidad de obtener información sobre los
					servicios prestados por la plataforma “Booklick”.
				</p>

				<p>
					Declaro que, se me ha informado como Titular de la información que
					tengo derecho a conocer, actualizar y rectificar mis datos personales,
					solicitar prueba de la autorización otorgada para su tratamiento, ser
					informado sobre el uso que se ha dado a los mismos, presentar quejas
					ante la Superintendencia de Industria y Comercio por infracción a la
					ley, revocar la autorización y/o solicitar la supresión de mis datos
					en los casos en que sea procedente y acceder en forma gratuita a los
					mismos. Así mismo, manifiesto que, en caso de ser menor de edad, la
					autorización ha sido otorgada por mi representante legal, y que, en
					caso de cumplir la mayoría de edad, no se requerirá nuevamente
					autorización. La vigencia de los datos recolectados es de 10 años,
					contados a partir de la presente autorización.
				</p>

				<p>
					Así mismo, se me ha informado que las consultas y reclamos podrán ser
					presentados a Booklick, como responsable del tratamiento de la
					información, línea telefónica en Bogotá +57 3104330768, y/o al correo
					electrónico{' '}
					<a
						href='mailto:soporte@booklick.co'
						target='_blank'
						rel='noopener noreferrer'>
						{' '}
						soporte@booklick.co
					</a>
					, en cumplimiento de la Ley 1581 de 2012, el Decreto No. 1377 de 2013
					y demás normas concordantes.
				</p>

				<h1>Política de Tratamiento de Datos Personales</h1>

				<p>
					BOOKLICK SAS, identificada con el NIT 900.934.869-6, con domicilio en
					la ciudad de Bogotá D.C., como responsable del tratamiento de datos
					personales, se permite informar que la actualización del AVISO DE
					PRIVACIDAD y la POLÍTICA DE PROTECCIÓN DE DATOS PERSONALES de sus
					clientes, usuarios, proveedores y empleados fue comunicado y publicado
					en el portal{' '}
					<a href='www.booklick.co' target='_blank' rel='noopener noreferrer'>
						{' '}
						www.booklick.co
					</a>
					, el pasado 17 de marzo del 2020.
				</p>

				<p>
					Esta política aplica para todos los titulares de datos personales que
					se utilicen y/o se encuentren en las bases de datos de la compañía
					BOOKLICK S.A.S quien actúa en calidad de responsable del tratamiento.
					El objetivo de la presente Política es, junto con las medidas
					técnicas, humanas y administrativas implementadas, garantizar el
					adecuado cumplimiento de la Ley de Protección de Datos Personales, Ley
					1581 de 2012 y sus decretos reglamentarios, así como la definición de
					los lineamientos para la atención de consultas y reclamos de los
					Titulares de los Datos Personales sobre los que la Compañía realiza
					algún tipo de Tratamiento, estableciendo procedimientos para
					garantizar el adecuado cumplimiento de la Ley, y en especial, para
					asegurar el efectivo ejercicio de los derechos de los titulares.
				</p>

				<p>
					Toda la información recibida por Booklick SAS a través de sus
					diferentes canales de comunicación, en medios digitales o impresos, y
					que conforma nuestras bases de datos, obtenidos de clientes,
					proveedores, empleados o contratistas, y demás titulares de
					información, se rige por las siguientes políticas de uso.
				</p>

				<section>
					<h2>1. Marco Legal.</h2>

					<p>
						La política de protección de datos según la normatividad colombiana,
						debe respetar los principios, derechos y garantías constitucionales
						y legales, por lo que debe establecer bases en la Constitución
						Política de Colombia la cual al respecto en su artículo 15,
						establece que cualquier persona tiene derecho a conocer, actualizar
						y rectificar los datos personales que existan sobre ella en banco de
						datos o archivos de entidades públicas o privadas. Igualmente,
						ordena a quienes tienen datos personales de terceros respetar los
						derechos y garantía previstos en la Constitución cuando se
						recolecta, trata y circula esa clase de información.{' '}
					</p>

					<ul>
						<li>
							La Ley Estatutaria 1581 de 2012, “Por la cual se dictan
							disposiciones generales para la protección de datos personales”
							establece las condiciones mínimas para realizar el tratamiento
							legítimo de los datos personales. El artículo 2 establece el
							ámbito de aplicación dando alcance a los datos personales
							registrados en cualquier base de datos que los haga susceptibles
							de tratamiento por entidades de naturaleza pública o privada. Por
							lo cual se implementa esta política de protección de datos.
						</li>

						<li>
							El literal k) del artículo 18 de dicha ley obliga a los
							responsables del tratamiento de datos personales a “adoptar un
							manual interno de políticas y procedimientos para garantizar el
							adecuado cumplimiento de la ley y en especial, para la atención de
							consultas y reclamos”. Y el artículo 25 de la misma ley, establece
							que las políticas de tratamiento de datos son de obligatorio
							cumplimiento y que su desconocimiento acarreará sanciones.{' '}
						</li>

						<li>
							Por su parte, el Decreto 1377 de 2013, define la información que
							debe contener como mínimo las políticas de tratamiento de
							información. Mismas políticas que no pueden garantizar un nivel de
							tratamiento inferior al establecido en la ley 1581 de 2012. Y
							BOOKLICK S.A.S., se obliga a respetar estas políticas en el
							cumplimiento de sus funciones y/o actividades. De igual manera, se
							comprometen a guardar estricta confidencialidad en relación con
							los datos tratados.
						</li>

						<li>
							Reglamento General Europeo de Protección de Datos (UE) 2016/679
							(el GDPR) y las demás normas que la reglamenten, adicionen,
							modifiquen o sustituyan.
						</li>
					</ul>

					<p>
						Booklick S.A.S. aplicará las disposiciones de ley mencionadas, así
						como las complementarias con respecto de la recolección,
						almacenamiento, uso, circulación de información personal, dándoles
						el tratamiento adecuado. Así mismo, se compromete con el respeto y
						garantía de los derechos adoptando políticas y procedimientos de
						tratamiento de datos personales, que son de obligatoria aplicación.
					</p>
				</section>

				<section>
					<h2>2. Definiciones:</h2>

					<p>
						Los términos enumerados debajo tienen el siguiente significado en
						esta Política:
					</p>
					<ul>
						<li>
							<strong>Datos Personales.</strong> Se refiere a cualquier
							información a través de la cual un Sujeto de Datos de Clientes
							puede ser identificado directa o indirectamente;
						</li>
						<li>
							<strong>Tratamiento.</strong> Significa cualquier operación o
							conjunto de operaciones sobre Datos Personales como, la
							recolección, el almacenamiento, el uso, la circulación,
							transferencia, transmisión, actualización o supresión de los Datos
							Personales, entre otros.
						</li>
						<li>
							<strong>Titular del dato.</strong> Significa la persona natural
							cuyos datos personales sean objeto de Tratamiento.
						</li>
						<li>
							<strong>Responsable.</strong> Persona natural o jurídica, pública
							o privada, que por sí misma o en asocio con otros, decida sobre la
							base de datos y/o el Tratamiento de los datos.
						</li>
						<li>
							<strong>Encargado.</strong> Significa la persona natural o
							jurídica que realiza el Tratamiento de datos por cuenta del
							Responsable del Tratamiento.
						</li>
						<li>
							<strong>Autorización.</strong> Es el consentimiento que da
							cualquier persona para que las empresas o personas responsables
							del tratamiento de la información, puedan utilizar sus datos
							personales. Para que esto se pueda hacer, el responsable del
							tratamiento de los datos debe adoptar procedimientos internos para
							solicitar la autorización al titular, a más tardar en el momento
							de la recolección de su información.
						</li>
						<li>
							<strong>Base de Datos.</strong> Significa el conjunto organizado
							de Datos Personales que sean objeto de Tratamiento, electrónico o
							no, cualquiera que fuere la modalidad de su formación,
							almacenamiento, organización y acceso.
						</li>
						<li>
							<strong>Dato Personal Privado.</strong> Significa el dato que por
							su naturaleza íntima o reservada sólo es relevante para el
							Titular.
						</li>
						<li>
							<strong>Dato Personal Público.</strong> Significa el dato
							calificado como tal según los mandatos de la ley o de la
							Constitución Política y todos aquellos que no sean semiprivados,
							privados o sensibles. Por ejemplo: los datos contenidos en
							documentos públicos, registros públicos, gacetas y boletines
							oficiales y sentencias judiciales debidamente ejecutoriadas que no
							estén sometidos a reserva, los relativos al estado civil de las
							personas, a su profesión u oficio y a su calidad de comerciante o
							de servidor público. Son públicos los Datos Personales existentes
							en el registro mercantil de las Cámaras de Comercio (Artículo 26
							del C.Co.).
						</li>
						<li>
							<strong>Dato Personal Semiprivado.</strong> Significa el dato que
							no tiene naturaleza íntima, reservada, ni pública y cuyo
							conocimiento o divulgación puede interesar no sólo a su Titular
							sino a cierto sector o grupo de personas, o a la sociedad en
							general. Por ejemplo: el dato referente al cumplimiento e
							incumplimiento de las obligaciones financieras o los datos
							relativos a las relaciones con las entidades de la seguridad
							social.
						</li>
						<li>
							<strong>Dato Personal Sensible.</strong> Significa el dato que
							afecta la intimidad de la persona o cuyo uso indebido puede
							generar su discriminación. Por ejemplo: aquellos que revelen el
							origen racial o étnico, la orientación política, las convicciones
							religiosas o filosóficas, la pertenencia a sindicatos,
							organizaciones sociales, de derechos humanos o que promueva
							intereses de cualquier partido político o que garanticen los
							derechos y garantías de partidos políticos de oposición, datos
							relativos a la salud, a la vida sexual y los datos biométricos
							(huellas dactilares, fotografías, grabaciones en cámaras de
							seguridad), entre otros.
						</li>
						<li>
							<strong>Aviso de privacidad.</strong> Documento físico y/o
							electrónico generado por el Responsable del tratamiento que es
							puesto a disposición del titular con la información relativa a la
							existencia de las políticas de tratamiento de información que le
							serán aplicables, la forma de acceder a las mismas y las
							características del Tratamiento que se pretende dar a los datos
							personales.
						</li>
					</ul>
				</section>

				<section>
					<h2>3. Principios para el tratamiento de datos personales.</h2>

					<p>
						En el desarrollo, interpretación y aplicación de la presente ley, se
						aplicarán, de manera armónica e integral, los siguientes principios:
					</p>

					<ol type='a'>
						<li>
							<strong>Principio de finalidad:</strong> El Tratamiento de los
							datos personales recogidos por Booklick SAS debe ser informada al
							Titular.
						</li>
						<li>
							<strong>Principio de libertad:</strong> El Tratamiento sólo puede
							ejercerse con el consentimiento, previo, expreso e informado del
							titular. Los datos personales no podrán ser obtenidos o divulgados
							sin previa autorización, o en ausencia de mandato legal o judicial
							que releve el consentimiento.
						</li>
						<li>
							<strong>Principio de veracidad o calidad:</strong> La información
							sujeta a Tratamiento debe ser veraz, completa, exacta,
							actualizada, comprobable y comprensible. Se prohíbe el Tratamiento
							de datos parciales, incompletos, fraccionados o que induzcan a
							error.
						</li>
						<li>
							<strong>Principio de transparencia:</strong> En el Tratamiento
							debe garantizarse el derecho del Titular a obtener del Responsable
							en cualquier momento y sin restricciones, información acerca de la
							existencia de datos que le conciernan.
						</li>
						<li>
							<strong>Principio de acceso y circulación restringida:</strong>{' '}
							Los datos personales, salvo la información pública, no podrán
							estar disponibles en Internet u otros medios de divulgación o
							comunicación masiva, salvo que el acceso sea técnicamente
							controlable para brindar un conocimiento restringido sólo a los
							titulares o terceros autorizados.
						</li>
						<li>
							<strong>Principio de seguridad:</strong> La información sujeta a
							Tratamiento por parte del Responsable, se deberá manejar con las
							medidas técnicas, humanas y administrativas que sean necesarias
							para otorgar seguridad a los registros evitando su adulteración,
							pérdida, consulta, uso o acceso no autorizado o fraudulento.
						</li>
						<li>
							<strong>Principio de confidencialidad:</strong> Todas las personas
							que intervengan en el tratamiento de datos personales que no
							tengan la naturaleza de públicos están obligadas a garantizar la
							reserva de la información, inclusive después de finalizada su
							relación con alguna de las labores que comprende el tratamiento.
						</li>
					</ol>
				</section>

				<section>
					<h2>
						4. Información de la empresa responsable del tratamiento de datos
						personales.
					</h2>
					<p>La empresa responsable del tratamiento del dato personal:</p>
					<table>
						<tr>
							<td>Razón social:</td>
							<td>BOOKLICK S.A.S.</td>
						</tr>
						<tr>
							<td>Nit:</td>
							<td>900.934.869-6</td>
						</tr>
						<tr>
							<td>Email:</td>
							<td>
								<a
									href='mailto:soporte@booklick.co'
									target='_blank'
									rel='noopener noreferrer'>
									soporte@booklick.co
								</a>
							</td>
						</tr>
						<tr>
							<td>Teléfono:</td>
							<td>(57) 310 433 0768</td>
						</tr>
					</table>
				</section>

				<section>
					<h2>
						5. Tratamiento al cual serán sometidos los Datos Personales y su
						finalidad.
					</h2>
					<p>
						Sus datos personales han sido y están siendo tratados conforme con
						nuestra Política de datos personales En el evento que se recolecten
						datos personales sensibles, el Titular podrá negarse a autorizar su
						Tratamiento. booklick S.A.S. generalmente no solicita información
						sensible (por ejemplo, los datos relacionados con la raza o el
						origen étnico, las creencias religiosas, antecedentes penales, salud
						física, mental, o la orientación sexual) de los visitantes. En caso
						necesario, obtendremos su consentimiento expreso para recolectar y
						utilizar dicha información.
					</p>
				</section>

				<section>
					<h2>6. Derechos de los titulares de la información.</h2>
					<p>
						De conformidad con lo establecido en la Ley 1581 de 2012, el titular
						de los datos personales tiene los siguientes derechos:
					</p>
					<ul className={styles.numberList}>
						<li className={styles.numberListItem}>
							<strong>6.1.</strong> Conocer, actualizar y corregir sus Datos
							Personales. Con la facultad de ejercer este derecho, entre otros,
							en relación con la información, parcial, inexacta, incompleta,
							dividida, información engañosa o cuyo tratamiento sea prohibido o
							no autorizado;
						</li>
						<li className={styles.numberListItem}>
							<strong>6.2.</strong> Requerir prueba del consentimiento otorgado
							para la recolección y el tratamiento de los Datos Personales;
						</li>
						<li className={styles.numberListItem}>
							<strong>6.3.</strong> Ser informado por Booklick SAS del uso que
							se le han dado a los Datos Personales;
						</li>
						<li className={styles.numberListItem}>
							<strong>6.4.</strong> Presentar quejas ante la Superintendencia de
							Industria y Comercio en el caso en que haya una violación por
							parte de Booklick SAS, de las disposiciones de la Ley 1581 de
							2012, el Decreto 1377 de 2013 y otras normas que los modifiquen,
							adicionen o complementen, de conformidad con las disposiciones
							sobre el requisito de procedibilidad establecido en el artículo 16
							Ley 1581 de 2012;
						</li>
						<li className={styles.numberListItem}>
							<strong>6.5.</strong> Revocar la autorización otorgada para el
							tratamiento de los Datos Personales;
						</li>
						<li className={styles.numberListItem}>
							<strong>6.6.</strong> Solicitar ser eliminado de su base de datos.
							Esta supresión o eliminación implica la eliminación total o
							parcial de la información personal de acuerdo con lo solicitado
							por el titular en las bases de datos de Booklick SAS. Es
							importante tener en cuenta que, el derecho de supresión no es
							absoluto y el responsable puede negar el ejercicio del mismo
							cuando el titular tenga el deber legal y/o contractual de
							permanecer en la base de datos, la supresión de los datos
							obstaculice actuaciones judiciales o administrativas o la
							Investigación y persecución de delitos, los datos que sean
							necesarios para cumplir con una obligación legalmente adquirida
							por el titular;
						</li>
						<li className={styles.numberListItem}>
							<strong>6.7.</strong> Tener acceso a los Datos Personales que
							Booklick SAS hayan recolectado y tratado;
						</li>
					</ul>
				</section>

				<section>
					<h2>7. Deberes del responsable de la información:</h2>
					<p>
						En calidad de Responsable del Tratamiento de los datos personales, y
						de conformidad con lo establecido en la Ley 1581 de 2012, Booklick
						SAS, se compromete a cumplir con los siguientes deberes, en lo
						relacionado con el tratamiento de datos personales:
					</p>
					<ul className={styles.numberList}>
						<li className={styles.numberListItem}>
							<strong>7.1.</strong> Deberes respecto del titular del dato
						</li>
						<ul>
							<li>
								Solicitar y conservar, en las condiciones previstas en esta
								política, copia de la respectiva autorización otorgada por el
								titular.
							</li>
							<li>
								Informar de manera clara y suficiente al titular sobre la
								finalidad de la recolección y los derechos que le asisten por
								virtud de la autorización otorgada.
							</li>
							<li>
								Garantizar al titular, en todo tiempo, el pleno y efectivo
								ejercicio del derecho de hábeas data, es decir, conocer,
								actualizar o rectificar sus datos personales.
							</li>
							<li>
								Informar a solicitud del titular sobre el uso dado a sus datos
								personales.
							</li>
							<li>
								Tramitar las consultas y reclamos formulados en los términos
								señalados en la presente política.
							</li>
						</ul>
						<li className={styles.numberListItem}>
							<strong>7.2.</strong> Deberes Respecto De La Calidad, Seguridad Y
							Confidencialidad De Los Datos Personales.
						</li>
						<ul>
							<li>
								Observar los principios de veracidad, calidad, seguridad y
								confidencialidad en los términos establecidos en la legislación
								colombiana.
							</li>
							<li>
								Conservar la información bajo las condiciones de seguridad
								necesarias para impedir su adulteración, pérdida, consulta, uso
								o acceso no autorizado o fraudulento.
							</li>
							<li>Actualizar la información cuando sea necesario.</li>
							<li>
								Rectificar los datos personales cuando ello sea procedente.
							</li>
						</ul>

						<li className={styles.numberListItem}>
							<strong>7.3.</strong> Deberes cuando realiza el tratamiento a
							través de un encargado.
						</li>
						<ul>
							<li>
								Suministrar al Encargado del tratamiento únicamente los datos
								personales que está autorizado a suministrar a terceros.
							</li>
							<li>
								Garantizar que la información que se suministre al Encargado del
								tratamiento sea veraz, completa, exacta, actualizada,
								comprobable y comprensible.
							</li>
							<li>
								Comunicar de forma oportuna al Encargado del tratamiento todas
								las novedades respecto de los datos que previamente le haya
								suministrado y adoptar las demás medidas necesarias para que la
								información suministrada a este se mantenga actualizada.
							</li>
							<li>
								Informar de manera oportuna al Encargado del tratamiento las
								rectificaciones realizadas sobre los datos personales para que
								éste proceda a realizar los ajustes pertinentes.
							</li>
							<li>
								Exigir al Encargado del tratamiento, en todo momento, el respeto
								a las condiciones de seguridad y privacidad de la información
								del titular.
							</li>
							<li>
								Informar al Encargado del tratamiento cuando determinada
								información se encuentre en discusión por parte del titular, una
								vez se haya presentado la reclamación y no haya finalizado el
								trámite respectivo.
							</li>
						</ul>

						<li className={styles.numberListItem}>
							<strong>7.4.</strong> Deberes respecto de la superintendencia de
							industria y comercio.
						</li>
						<ul>
							<li>
								Informarle las eventuales violaciones a los códigos de seguridad
								y la existencia de riesgos en la administración de la
								información de los titulares.
							</li>
							<li>
								Cumplir las instrucciones y requerimientos que imparta la
								Superintendencia de Industria y Comercio
							</li>
							<li>
								Aunque la ley obliga a los responsables del tratamiento de datos
								a contar con una autorización expresa de los titulares, en los
								siguientes casos no es necesario pedirla:
							</li>
							<ul>
								<li>
									Cuando la información es requerida por una entidad pública o
									administrativa en ejercicio de sus funciones legales o por
									orden judicial.
								</li>
								<li>
									En caso de hacer tratamiento de datos de naturaleza pública.
								</li>
								<li>Casos de urgencia médica o sanitaria.</li>
								<li>
									Para el tratamiento de información autorizado por la ley para
									fines históricos, estadísticos o científicos.
								</li>
							</ul>
						</ul>
					</ul>
				</section>
				<section>
					<h2>8. Autorización para el tratamiento de datos.</h2>
					<p>
						Booklick SAS, en su condición de Responsable del tratamiento de
						datos personales, ha dispuesto de los mecanismos necesarios para
						obtener la autorización de los titulares, garantizando en todo caso
						que sea posible verificar el otorgamiento de la misma. Dicha
						autorización deberá ser previa, expresa e informada. Esta obligación
						no es necesaria cuando se trate de datos de naturaleza pública.
					</p>
					<p>
						Para obtener la autorización, es necesario informarle al Titular de
						los Datos Personales de forma clara y expresa lo siguiente:
					</p>
					<ul>
						<li>
							El Tratamiento al cual serán sometidos sus Datos Personales y la
							finalidad del mismo;
						</li>
						<li>
							El carácter facultativo de la respuesta a las preguntas que le
							sean hechas, cuando estas versen sobre datos sensibles o sobre los
							datos de las niñas, niños y adolescentes;
						</li>
						<li>
							Los derechos que le asisten como Titular previstos en el Artículo
							8 de la Ley 1581 de 2012;
						</li>
						<li>
							La identificación, dirección física o electrónica de la Compañía.
						</li>
					</ul>
					<p>
						La Autorización del Titular debe obtenerse a través de cualquier
						medio que pueda ser objeto de consulta posterior, tal como la página
						web, facturas o formularios, formatos, mensajes de datos. La
						autorización también podrá obtenerse a partir de conductas
						inequívocas del Titular del Dato que permitan concluir de manera
						razonable que éste otorgó su consentimiento para el Tratamiento.
					</p>
					<p>
						Los titulares de los datos personales pueden revocar el
						consentimiento al tratamiento de sus datos personales en cualquier
						momento, siempre y cuando no lo impida una disposición legal. Para
						ello, deberán comunicarse con BOOKLICK S.AS., mediante correo
						electrónico:
						<a
							href='mailto:soporte@booklick.co'
							target='_blank'
							rel='noopener noreferrer'>
							{' '}
							soporte@booklick.co{' '}
						</a>{' '}
						o mediante comunicación escrita remitida en Carrera 7 # 77 -07
						Oficina 802
					</p>
				</section>
				<section>
					<h2>9. Forma y mecanismos para otorgar la autorización. </h2>
					<p>
						La Autorización del Titular debe obtenerse a través de cualquier
						medio que pueda ser objeto de consulta posterior, tal como la página
						web, facturas o formularios, formatos, mensajes de datos. La
						autorización también podrá obtenerse a partir de conductas
						inequívocas del Titular del Dato que permitan concluir de manera
						razonable que éste otorgó su consentimiento para el Tratamiento.
					</p>
					<p>
						La autorización podrá constar en un documento físico, electrónico o
						en cualquier otro formato que permita garantizar su posterior
						consulta. La mencionada autorización, podrá ser obtenida por alguno
						de los siguientes medios: a) Cotizaciones, b) Facturas de Venta, c)
						Actividades de Mercadeo, d) Eventos, e) Órdenes de servicio, f)
						Inicio de sesión en la{' '}
					</p>
				</section>
				<section>
					<h2>10. Finalidad de la autorización.</h2>
					<p>
						El Tratamiento de los datos personales de los Titulares se llevará a
						cabo por parte de Booklick SAS, con la siguiente finalidad: Medir
						niveles de satisfacción y demás fines administrativos internos y/o
						comerciales tales como, investigación de mercados, auditorías,
						reportes contables, análisis estadísticos, facturación, realizar
						encuestas, enviar invitaciones a eventos, Realizar actualización de
						datos, enviar publicidad; desarrollar actividades propias de
						intercambio de material académico; Realizar recolección de
						información para medir el rendimiento académico de los estudiantes
						que usan la plataforma; suministrar información académica por
						solicitud de la INSTITUCIÓN, salvo aquella información que se
						encuentre protegida por ley o que requiera orden judicial; Enviar
						invitaciones para conocer tendencias en la plataforma académica;
						Atender solicitudes realizadas por autoridades públicas a nivel
						nacional.
					</p>
					<p>
						Advertimos que en estas actividades pueden estar involucrados
						terceros proveedores y/o contratistas, con los cuales BOOKLICK
						puede, entre otras, contratar el almacenamiento y/o procesamiento de
						la información y datos personales para la correcta ejecución de los
						contratos celebrados con nosotros. Dichos contratistas y/o
						proveedores se encuentran en la obligación de hacer un uso adecuado
						de la información proporcionada por nuestros clientes, usuarios y
						trabajadores. En su calidad de titulares de los datos recolectados,
						al aceptar esta política de privacidad, nuestros clientes, usuarios
						y trabajadores autorizan a Booklick SAS, el suministro de dicha
						información a autoridades de control y vigilancia, de policía o
						judiciales, en virtud de un requerimiento legal o reglamentario y/o
						usar o revelar esta información y datos personales en defensa de sus
						derechos y/o su patrimonio en cuanto dicha defensa tenga relación
						con los productos y/o servicios contratados por sus clientes.
					</p>
				</section>
				<section>
					<h2>
						11. Procedimiento para que los Titulares puedan ejercer sus derechos
					</h2>
					<p>
						Para el ejercicio pleno y efectivo de los derechos que le asisten a
						los Titulares de los Datos Personales, o a las personas legitimadas
						por ley para tal efecto, la Compañía dispone del correo electrónico
						<a
							href='mailto:soporte@booklick.co'
							target='_blank'
							rel='noopener noreferrer'>
							{' '}
							soporte@booklick.co
						</a>{' '}
						a través del cual podrán presentar todas las consultas, quejas y/o
						reclamos asociados al derecho de Habeas Data.
					</p>
					<p>
						Todas las solicitudes que realicen las personas legitimadas para
						conocer los Datos Personales que reposan en la Compañía se podrán
						realizar a través del mencionado correo electrónico en el cual
						constará la fecha de recibo de la consulta y la identidad del
						solicitante.
					</p>
					<p>
						La solicitud debe dirigirse a Booklick S.A.S y contener como mínimo
						la siguiente información:
					</p>
					<ol>
						<li>Nombres y apellidos.</li>
						<li>Tipo de documento.</li>
						<li>Número de documento.</li>
						<li>Teléfono.</li>
						<li>Correo electrónico.</li>
						<li>País.</li>
						<li>Asunto.</li>
					</ol>
					<p>
						Una vez recibida la solicitud se procederá con la verificación de la
						identidad del peticionario o de la legitimación de éste para tal
						fin. La respuesta a la consulta deberá comunicarse al solicitante en
						un término máximo de diez (10) días hábiles contados a partir de la
						fecha de recibo de la misma. Cuando no fuere posible atender la
						consulta dentro de dicho término, se deberá informar al interesado
						los motivos de la demora y la fecha en que se atenderá su consulta,
						la cual en ningún caso podrá superar los cinco (5) días hábiles
						siguientes al vencimiento del primer término.
					</p>
					<p>
						Si la solicitud o el reclamo resulta incompleto, se requerirá al
						interesado dentro de los cinco (5) días siguientes a su recepción
						para que subsane las fallas. Transcurridos dos (2) meses desde la
						fecha del requerimiento, sin que el solicitante presente la
						información requerida, se entenderá que ha desistido del reclamo.
					</p>
					<p>
						El término máximo para atender el reclamo será de quince (15) días
						hábiles contados a partir del día siguiente a la fecha de su recibo.
						Cuando no fuere posible atender el reclamo dentro de dicho término,
						se informará al interesado los motivos de la demora y la fecha en
						que se atenderá su reclamo, la cual en ningún caso podrá superar los
						ocho (8) días hábiles siguientes al vencimiento del primer término.
					</p>
				</section>
				<section>
					<h2>
						12. Medidas de seguridad y confidencialidad de la información.{' '}
					</h2>
					<p>
						Para ingreso a Booklick, el usuario se autentica utilizando las
						credenciales institucionales, razón por la cual son datos que NO
						recolecta ni almacena. Para el almacenamiento de los datos, los
						mismos están protegidos por medio de un firewall y de encriptación
						que otorgan altos niveles de integridad, confidencialidad y
						disponibilidad. No obstante, el cliente asume los riesgos que se
						derivan de entregar esta información en un medio como internet, el
						cual está sometido a diversas variables – ataques de terceros,
						fallas técnicas o tecnológicas, entre otras. encargados, se hace con
						archivos protegidos con contraseña. Los accesos a los sistemas están
						protegidos con un alto nivel de seguridad en la autenticación y
						autorización. Las bases de datos y servidores están alojadas en la
						AWS, un entorno que provee los mayores estándares de la industria de
						seguridad física y digital para el acceso y modificación de la
						información.
					</p>
				</section>
				<section>
					<h2>13. Veracidad de la información. </h2>
					<p>
						Nuestros clientes, contratistas, empleados, proveedores, aliados y
						usuarios deberán suministrar información veraz sobre sus datos
						personales para efectos de hacer posible la prestación de los
						servicios por parte de BOOKLICK.
					</p>
					<p>
						BOOKLICK presume la veracidad de la información suministrada y no
						verifica, ni asume la obligación de verificar, la identidad de
						clientes, empleados, contratistas y proveedores, ni la veracidad,
						vigencia, suficiencia y autenticidad de los datos que cada uno de
						ellos proporcione. Por tanto, no asume responsabilidad por daños y/o
						perjuicios de toda naturaleza que pudieran tener origen en la falta
						de veracidad, vigencia, suficiencia o autenticidad de la
						información, incluyendo daños y perjuicios que puedan deberse a la
						homonimia o a la suplantación de la identidad.
					</p>
				</section>
				<section>
					<h2>14. Designación</h2>
					<p>
						Booklick S.A.S designa el e al Área de Tecnología o quien haga sus
						veces, para cumplir con la función de protección de datos
						personales, así como para dar trámite a las solicitudes de los
						titulares, para el ejercicio de los derechos como titular de la
						información.
					</p>
				</section>
				<section>
					<h2>15. Recolección de datos personales en menores de edad.</h2>
					<p>
						La recolección de datos personales en este caso, se adelanta en los
						mismos términos y procedimientos efectuados por la Institución a la
						que se encuentra vinculada el usuario de la plataforma digital.
					</p>
				</section>
				<section>
					<h2>16. Vigencia de la Política.</h2>
					<p>El presente manual rige a partir de su fecha de publicación.</p>
				</section>
			</div>
		</CatchError>
	);
};

function mapStateToProps({ privacyPolicy, exploration }) {
	return { privacyPolicy, exploration };
}

export default {
	component: nativeMenu(
		withRouter(connect(mapStateToProps, {})(PrivacyPolicyPage)),
	),
};
