import React , {useState} from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../booklists/styles/popupMoveContentToSection.scss';

const SectionsBooklistContextMenu = ({
	booklistSections,
	setSelectedSection,
	sectionId
}) => {
	const { t } = useTranslation();
	const [checkboxSections, setCheckboxSections] = useState(Object.fromEntries(booklistSections.map(section=>([section.id,section.id === sectionId]))));
    
	const handleInputChange = (e) => {
		const target = e.target;
		const name = target.name;
		let sections = {};
		for (const key in checkboxSections) {
			if(key === name){
				setSelectedSection(key);
				sections[key] = target.checked;
			}else{
				sections[key] = false;
			}
		}

		setCheckboxSections(sections);
		
	};

	return (
		<div style={{ marginTop: '5px' , marginBottom:'30px', display:'grid', justifyContent:'space-evenly' }}>
			{booklistSections.map(section => (
				<div key={section.id} className={styles.containerRadioButton}>
					<div className={styles.radio}>
						<input
							type='checkbox'
							onChange={(e) => handleInputChange(e)}
							name={section.id}
							checked={checkboxSections[section.id]}
						/>
					</div>
					<label className={`${styles.fontp} ${styles.cursor}`}>{section.section === 'Contenido' ? t('textSearchFilterContent') : section.section}</label>
				</div>
			))}
		</div>
	);
};

export default SectionsBooklistContextMenu;