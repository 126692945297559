import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from './styles/selectIntitution.scss';
import { postEventPopup } from '../../../helpers/tracking';
import { useTranslation } from 'react-i18next';
import {
	eventType,
	iconCloseGray,
	iconArrowDown,
	STEP_CREATE_PROFILE,
	enviromentTrial
} from '../../../utils/constans';
import AutoComplete from '../shared/AutoComplete';

import {
	getInstitutions
} from '../../actions';

const SelectIntitution = ({
	handleChangeStep,
	closePopUp,
	setInstitution,
	institution,
	sendEventPopup,
	setInstitutionSelected,
}) => {
	const [institutions, setInstitutions] = useState();
	const [isChecked, setIsChecked] = useState(false);
	const [alertMessage, setAlertMessage] = useState(false);
	const { t, i18n } = useTranslation();

	useEffect(() => {
		handleIntitutions();
	},[]);

	const handleIntitutions = async() => {
		const institutions = await getInstitutions();
		setInstitutions(institutions);
	};

	const handleSelectInstitution = (institution) =>{
		setInstitution(institution);
		setInstitutionSelected(institution ? institution.slug : null);
	};

	const handleSubmit = async() => {
		if(!institution && !isChecked) {
			setAlertMessage(true);
		} else {
			if(isChecked || (institution && !institution.domain)) {
				sendEventPopup(eventType.selectInstitution);
				handleChangeStep(STEP_CREATE_PROFILE);
			} else {
				sendEventPopup(eventType.selectInstitution);
				window.location = institution.domain;
			}
		}
	};
	const handleRemoveInstitution = () => {
		window.localStorage.removeItem(enviromentTrial);
		handleSelectInstitution(null);
	};

	return (
		<div className={styles.container}>
			<h2 className={styles.fontTitle}>{t('titleSelectInstitution')}</h2>
			<div className={styles.dropDownContainer}>
				<p className={styles.fontp}>{t('textSelectInstitution')}</p>
				{!institution && institutions &&
				<AutoComplete 
					items={institutions}
					placeholder={t('textInputSelectInstitution')}
					arrow={iconArrowDown}
					selectedItem={handleSelectInstitution}
					disabled={isChecked}
					alertMessage={alertMessage && !isChecked && !institution}
				/>
				}
				{institution &&
					<div className={styles.institution}>
						<img className={styles.institutionImage} src={institution.logo} />
						<p className={`${styles.fontp} ${styles.intitutionName}`}>{institution.name}</p>
						<img className={styles.iconClose9px} src={iconCloseGray} onClick={handleRemoveInstitution} />
					</div>
				}
        
			</div>
			<div>
				{!institution &&
				<div className={styles.containerCheckBox}>
					<input id="checkbox-institution" data-test="checkbox-institution" className={alertMessage && !isChecked ?  styles.alertBorderNew : null} type='checkbox' 
						onChange={() => {
							setIsChecked(!isChecked);
							setInstitutionSelected(eventType.unlistedInstitution);
							window.localStorage.removeItem(enviromentTrial);
						}}/>
					<label className={`${styles.fontp} ${styles.cursor}`} htmlFor="checkbox-institution" >{t('textHelpSelectInstitution')}</label>
				</div>
				}

				{alertMessage && !isChecked && !institution &&
					<p className={styles.errorMessageParagraph}>{t('textErrorSelectInstitution')}</p>
				}
			</div>

			<div className={styles.button_blue} onClick={handleSubmit} data-test="buttonContinue">
				{t('buttonContinue')}
			</div>

			<div onClick={closePopUp} className={styles.textBlue}>Omitir</div>

		</div>
	);
};

const mapStateToProps = ({   }) => {
	return {
	};
};

export default withRouter(connect(mapStateToProps, {
})(SelectIntitution));