import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	iconCameraBlack,
	iconSaveWhite,
	iconSearchGreyDark,
	getResources
} from '../../../utils/constans';
import { useTranslation } from 'react-i18next';

import useFormFileds from '../../hooks/useFormFields';
import useOptionDropDown from '../../hooks/useOptionDropDown';


import { 
	putBooklist, 
	getSearchProfile,
	visitStats,
	loadingProfile,
	addColaborator ,
	deleteColaborator,
	postBooklistPermissions,
	getBooklistPermissions,
	editColaborator,
	environment
} from '../../actions';
import Button, { appearances } from '../shared/Button';
import DropDown from '../shared/DropDown';
import InputGroup, { types } from '../shared/InputGroup';
import Dropzone from 'react-dropzone';
import PopUp from '../shared/PopUp';
import AvatarProfile from '../shared/AvatarProfile';
import {openProfile} from '../../../helpers/profileRouting';

import styles from './styles/booklistsPopUpEdit.scss';
import LoaderSearchProfile from './LoaderSearchProfiles';
import CardProfilePermission from './CardProfilePermission';

const BooklistPopUpEdit = ({
	closePopUp,
	popUpDelete,
	booklistInfo,
	putBooklist,
	booklistsPermissions,
	loadingProfile,
	addColaborator,
	deleteColaborator,
	getBooklistPermissions,
	isEditor,
	isViewer,
	editColaborator,
	isMobile,
}) => {
	const { t } = useTranslation();
	const [previewImage, setPreviewImage] = useState(booklistInfo.image ? booklistInfo.image : getResources(environment).defaultCover);
	const [editImage, setEditImage] = useState(null);
	const [listOptions, setListOption] = useState([]);
	const [optionSearchProfiles, setOptionSearchProfiles] = useState({
		resultSearch: [],
		loadingSearch: false,
		showResult: false
	});
	const [booklistPermissions, setBooklistPermissions] = useState([]);
	const [loading, setLoading] = useState(false);

	const [options, value, handleValue,resetDropDownValue, defaultValue] = useOptionDropDown(listOptions);
	const { fields, handleOnchage } = useFormFileds({
		titleBooklist: booklistInfo.title,
		descriptionBooklist: booklistInfo.description,
		searchProfiles: null,
	});

	useEffect(() => {
		let option = [];
		option.push({
			value: 'Public',
			label: t('textMyBoolistsPublic'),
		});
		option.push({
			value: 'Private',
			label: t('textMyBoolistsPrivate'),
		});
		setListOption(option);
		defaultValue(booklistInfo.isPublic ? option.filter((item) =>  item.value === 'Public') :  option.filter((item) =>item.value === 'Private'));
	}, []);

	useEffect(() => {
		setBooklistPermissions(booklistsPermissions);
	}, [booklistsPermissions]);
	

	useEffect(() => {
		if (fields.searchProfiles && fields.searchProfiles !== '') {
			
			setOptionSearchProfiles({
				loadingSearch:true,
				resultSearch:[],
				showResult:true
			});

			const delaySearch = setTimeout(() => {
				handleSearch(fields.searchProfiles);
			}, 600);

			return () => clearTimeout(delaySearch);
		}else{
			setOptionSearchProfiles({
				loadingSearch:false,
				resultSearch:[],
				showResult:false
			});
		}

	}, [fields.searchProfiles]);

	const onProfileClick = (email, id) => {
		openProfile(
			email,
			id,
			loadingProfile,
			history,
			visitStats,
			true
		);
	};

	const handleSearch = async (term) => {
		const profiles = await getSearchProfile(term);
		setOptionSearchProfiles({
			loadingSearch:false,
			resultSearch:profiles,
			showResult:true
		});
	};

	const handleChangeCover = (file) => {
		if (file[0].type.startsWith('image')) {
			let reader = new FileReader();
			reader.onloadend = () => {
				setPreviewImage(reader.result);
			};
			reader.readAsDataURL(file[0]);
			setEditImage(file[0]);
		}
	};

	const handleEditBooklist = async () => {
		setLoading(true);
		let isPublic;
		if(Array.isArray(value)) {
			isPublic = value[0].value === listOptions[0].value ;
		}else{
			isPublic = value.value === listOptions[0].value;
		}

		const data = {
			title: fields.titleBooklist,
			description: fields.descriptionBooklist,
			image: previewImage,
			isPublic,
			slug: booklistInfo.slug
		};

		await putBooklist(data, editImage);
		
		if(booklistPermissions.length !== 0){
			await postBooklistPermissions(booklistInfo.id, booklistPermissions.map(colaborator=>({email:colaborator.email.email, permissions: [colaborator.permission.identifier]})));
		}else if(booklistPermissions.length === 0 &&  booklistsPermissions ){
			await postBooklistPermissions(booklistInfo.id, []);
		}

		getBooklistPermissions(booklistInfo.id);
		setLoading(false);
		closePopUp();
	};



	const handleAddCollaborator = (profile) => {

		if(!booklistPermissions){
			addColaborator(profile);
		}else{
			const existColaborator = booklistPermissions.findIndex(permision=>permision.email.email === profile.email);
			if(existColaborator === -1){
				addColaborator(profile);
			} 
		}
			
		setOptionSearchProfiles({
			loadingSearch:false,
			resultSearch:[],
			showResult:false
		});

		fields.searchProfiles = '';

	}; 

	const handleDeleteColaborator = (email) => {
		deleteColaborator(email);
	};

	const handleEditColaborator = (email,permission) => {
		editColaborator(email,permission);
	};


	return (
		<PopUp
			closePopUp={closePopUp}
			style={{
				popUp: {
					maxHeight: 644,
					maxWidth: '1068px'
				},
				popUpContent: isMobile ? {
					display: 'flex',
					margin: isMobile ? '50px 40px 20px' : '40px',
					flexDirection: 'column',
					width: '100p%',
					alignItems: 'center',
				} :{
					display: 'grid',
					margin: '40px'
				},
				button: {
					marginTop: '30px',
					justifyContent: 'center',
					marginLeft: isMobile ? '0px': '160px',
				}
			}}

			buttons={!isEditor ? {
				style: {
					width: isMobile? '100px' : '258px',
					height: '50px',
					borderRadius: '8px',
					marginRight: '25px',
					secondary: {
						flexDirection: isMobile && 'column',
					}
				},
				primary: {
					title: !isMobile && t('textPreferenceSave'),
					type: 'primary',
					materialIcon: 'save',
					action: handleEditBooklist,
					loading: loading
				},
				secondary: {
					icon: getResources(environment).iconDelete,
					title: !isMobile && t('titleButtonDeleteBooklist'),
					type: 'secondary',
					action: popUpDelete,
				}
			} : {
				style: {
					width: isMobile? '100px' : '258px',
					height: '50px',
					borderRadius: '8px',
					marginRight: '25px',
				},
				primary: {
					icon: iconSaveWhite,
					title: !isMobile && t('textPreferenceSave'),
					type: 'primary',
					action: handleEditBooklist,
					loading: loading
				}
			}
		
			}
		>
			
			{!isMobile && (<div className={`${styles.boxImage}`}>
				<img className={styles.imgBox} src={previewImage} />
			</div>)}

			<div style={{ width: '100%', marginLeft: isMobile? '0px':'40px' }}>

				{isMobile && (<div className={`${styles.boxImage}`}>
					<img className={styles.imgBox} src={previewImage} />
				</div>)}

				<p className={styles.title}>{t('titlePopUpEdit')}</p>
				<div style={{ marginTop: '12px', display: 'flex' }}>
					<div style={{ display: 'grid', gap: '12px', alignItems: 'center', marginRight: '25px' }}>
						<h3 style={{
							fontSize: '16px',
							fontFamily: 'Roboto',
							color: '#333333',
							fontWeight: '500',
							margin: '0'
						}}>
							{t('textInputPhoto')}
						</h3>

						<Dropzone onDrop={handleChangeCover}>
							{({ getRootProps, getInputProps }) => (
								<div  {...getRootProps()}>
									<input {...getInputProps()} accept="image/*,.png,.jpg,.gif" />
									<Button
										appearance={appearances.input}
										borderRadius={'8px'}
										width={'257px'}
										height={'52px'}
										icon={iconCameraBlack}
										title={t('IpuntoChagePhotoBooklist')}
									/>
								</div>
							)}
						</Dropzone>
					</div>

					{!isMobile && <DropDown
						title={t('editBooklistPrivacy')}
						listOptions={listOptions}
						maxWidth={'160px'}
						minHeight={48}
						fontSizeTitle={'16px'}
						options={options}
						value={value}
						handleValue={handleValue}
						isSearchable={false}
						boxShadow={'0 2px 4px 0 rgba(51, 51, 51, 0.1)'}

					/>}

				</div>

				{isMobile && <div style={{ marginTop: '30px' }}><DropDown
					title={t('editBooklistPrivacy')}
					listOptions={listOptions}
					maxWidth={'160px'}
					minHeight={48}
					fontSizeTitle={'16px'}
					options={options}
					value={value}
					handleValue={handleValue}
					isSearchable={false}
					boxShadow={'0 2px 4px 0 rgba(51, 51, 51, 0.1)'}

				/></div> }

				{((isEditor && environment === 'eur') || (!isEditor)) && (
					<div style={{ marginTop: '30px' , position:'relative'}}>
						<InputGroup
							inputName={'searchProfiles'}
							style={{
								height: '48px',
								margin: '0px',
								fontSize: '16px',
							}}
							fontSize={'16px'}
							fontWeight={'500'}
							title={t('textEditCollaborators')}
							icon={iconSearchGreyDark}
							placeholder={t('searchProfiles')}
							onChange={handleOnchage}
							value={fields.searchProfiles}
						/>

						{optionSearchProfiles.showResult && optionSearchProfiles.loadingSearch && (
							<div className={styles.dropSearchProfile}>
								<LoaderSearchProfile />
								<LoaderSearchProfile />
								<LoaderSearchProfile />
								<LoaderSearchProfile />
							</div>
						)}

						{optionSearchProfiles.showResult && !optionSearchProfiles.loadingSearch &&
							optionSearchProfiles.resultSearch && optionSearchProfiles.resultSearch.length !== 0 && (
							<div className={styles.dropSearchProfile}>
								{optionSearchProfiles.resultSearch.map(profile => (
									<div className={styles.profile} onClick={()=>handleAddCollaborator(profile)} key={profile.email}>
										<AvatarProfile
											styles={{
												width: '31px',
												height: '31px',
												borderRadius: '50%',
												marginRight: '6px'
											}}
											profileImgUrl={profile.profileImgUrl}
										/>
										<h3 className={styles.profile_description}>
											{`${profile.firstName} ${profile.lastName} - ${profile.institution.name}`}
										</h3>
									</div>
								))}
							</div>
						)}



						{booklistPermissions.lenght !== 0 && (
							<div style={{ display: 'grid', gridTemplateColumns: 'repeat(3,1fr)', rowGap: '9px', columnGap: '15px', marginTop: '15px' }}>
								{booklistPermissions.map(permision => (
									<CardProfilePermission 
										permision={permision}
										key={permision.email.email}
										handleDeleteColaborator = {handleDeleteColaborator}
										onProfileClick={onProfileClick}
										profileId={booklistInfo.author.id}
										handleEditColaborator={handleEditColaborator}
									/>
								))}
							</div>
						)}


					</div>
				)}

				<div style={{ marginTop: '30px', display: 'grid', gap: 30 }}>
					<InputGroup
						inputName={'titleBooklist'}
						title={t('textInputTitleBooklist')}
						onChange={handleOnchage}
						value={fields.titleBooklist}
						fontSize={'16px'}
						fontWeight={'500'}
						type={types.input}
						style={{
							width: '100%',
							height: '45px'
						}}
					/>

					<InputGroup
						inputName={'descriptionBooklist'}
						title={t('textDescriptionContent')}
						onChange={handleOnchage}
						value={fields.descriptionBooklist}
						fontSize={'16px'}
						fontWeight={'500'}
						type={types.textArea}
						style={{
							width: '100%',
							height: '100px'
						}}
					/>
				</div>


			
			</div>
		</PopUp>

	);
};

const mapStateToProps = ({ booklistV2 }) => {
	return {
		booklistInfo: booklistV2.booklistsInfo,
		loadingPut: booklistV2.loadingPut,
		booklistsPermissions: booklistV2.booklistsPermissions,
		isEditor: booklistV2.isEditor,
		isViewer: booklistV2.isViewer
	};
};

export default withRouter(connect(mapStateToProps, { 
	putBooklist,
	loadingProfile,
	addColaborator,
	deleteColaborator,
	editColaborator,
	getBooklistPermissions
})(BooklistPopUpEdit));