import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from './styles/cardContent.scss';
import {
	iconLikeNew,
	iconShareNew,
	iconAddCircle,
	breakPoint,
	imageByType,
	contentTypeNew,
	eventSearchType,
	iconMoreFill,
	getResources,
	iconMoreInfo,
	iconFollowingGrey
} from '../../../utils/constans';
import ContextMenu from '../context-menu/ContextMenu';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import PopupContextMenu from '../context-menu/PopupContextMenu';
import useContextMenu from '../../hooks/useContextMenu';

import {
	putFollowBooklist,
	deleteFollowBooklist,
	putLike,
	deleteLike,
	openPopUp,
	setPopUpShare,
	getContentExists,
	addContent,
	environment,
} from '../../actions';
import { postEventSearch } from '../../../helpers/tracking';
import { handleUrl } from '../../../utils/handleUrl';
import { useTranslation } from 'react-i18next';
import AddContentMenuMobile from '../context-menu/mobile/AddContentMenuMobile';
import PopUpPreview from '../search/PopUpPreview';
import useClickContent from '../../hooks/useClickContent';

const booklistsType = 'BOOKLISTS';
const offLineBookType = 'OFFLINE_BOOKS';

const CardContent = ({
	heightCard,
	widthCard,
	resultContent,
	putLike,
	contentType,
	openPopUp,
	setPopUpShare,
	profile,
	moreInfo,
	menuMoreInfo,
	putFollowBooklist,
}) => {
	const { t } = useTranslation();

	const [countLikes, setCountLikes] = useState(
		resultContent.countLikes ? parseInt(resultContent.countLikes) : 0
	);
	const [isLike, setIsLike] = useState(
		resultContent.isLike ? resultContent.isLike : false
	);
	const [isFollow, setIsFollow] = useState(
		resultContent.isFollow || resultContent.isfollow
	);
	const [author, setAuthor] = useState();

	const [content, setContent] = useState(resultContent);
	const width = useWindowWidth();
	const [isMobile, setIsMobile] = useState(
		window.innerWidth < breakPoint.mobileWide
	);
	const [contextMenuInfo, setContextMenuInfo] = useState();
	const [urlOfflineBook, setUrlOfflineBook] = useState();
	const [openMoreInfo, setOpenMoreInfo] = useState(false);
	const [bookPopUpVisible, setBookPopUpVisible] = useState(false);
	const { handleOpenContextMenu, openContextMenu } = useContextMenu();
	const { citation,url,handleClickContent,handleClickOfflineBook } = useClickContent(content.type,content.url || content.reader,content.slug);

	useEffect(() => {
		setIsMobile(width < breakPoint.mobileWide);
	}, [width]);

	useEffect(() => {
		if (resultContent.isOffline) {
			fetchContent();
		}
		setIsFollow(resultContent.isFollow || resultContent.isfollow);
		if (content.author) {
			setAuthor(content.author.email || content.author);
		}
	}, [resultContent]);

	useEffect(() => {
		if (content) {
			setContextMenuInfo({
				id: content.slug,
				type: content.type,
				info: {
					author: content.author,
					description: content.description,
					image: content.image,
					id: content.id,
					slug: content.slug,
					slugId: content.id,
					title: content.title,
					link: urlOfflineBook
						? urlOfflineBook
						: handleUrl(
							resultContent.slug,
							resultContent.type,
							resultContent.url
						  ),
				},
				attributes: content,
			});
			handleClickContent();
		}
	}, [content, urlOfflineBook]);

	const fetchContent = async () => {
		let contentExists = await getContentExists(resultContent.id);
		if (contentExists) {
			setUrlOfflineBook(
				`${window.location.origin}/preview/${resultContent.id}`
			);
		} else {
			let result = await addContent(
				{ info: resultContent },
				{ type: offLineBookType },
				offLineBookType
			);
			setUrlOfflineBook(
				`${window.location.origin}/preview/${result.data.data.slug}`
			);
		}
	};

	const handleLike = async () => {
		try {
			setIsLike(true);
			setCountLikes(parseInt(countLikes) + 1);
			if (contentType._id === booklistsType) {
				putFollowBooklist(content.id);
			} else {
				putLike(content.id);
			}
		} catch (error) {
			openPopUp(true);
		}
	};

	const handleDislike = async () => {
		setIsLike(false);
		setCountLikes(countLikes - 1);
		if (contentType._id === booklistsType) {
			deleteFollowBooklist(content.id);
		} else {
			deleteLike(content.id);
		}
	};

	const handleFollow = async () => {
		setIsFollow(!isFollow);
		if (isFollow) {
			await deleteFollowBooklist(content.id);
		} else {
			// handleFollow
			await putFollowBooklist(content.id);
		}
	};

	const handlePopUpShare = async () => {
		try {
			setPopUpShare(
				{
					...content,
					search: true,
					url: urlOfflineBook
						? urlOfflineBook
						: handleUrl(
							resultContent.slug,
							resultContent.type,
							resultContent.url
						  ),
				},
				true,
				profile.profile.data.email
			);
		} catch (error) {
			openPopUp(true);
		}
	};
	const closePopup = () => {
		setBookPopUpVisible(false);
	};

	const handleOpenPreview = () => {
		setBookPopUpVisible(true);
	};


	const handleClick = async () => {
		postEventSearch(
			eventSearchType.contentRedirect,
			null,
			profile.profile.data.email,
			null,
			content.id
		);
		if (resultContent.type == offLineBookType) {
			setBookPopUpVisible(true);
			return;
		}
		window.open(url);
	};

	const translateTypeContent = (name) => {
		switch (name) {
		case 'Libros':
			return t('textSearchFilterBook');
		case 'Artículos':
		case 'Articulo':
			return t('textSearchFilterArticle');
		case 'Revistas':
			return t('textSearchFilterPaper');
		case 'Perfiles':
			return t('textSearchFilterProfiles');
		case 'Booklists':
			return t('textSearchFilterBooklist');
		case 'Videos':
			return t('textSearchResultVideo');
		case 'Textos':
			return t('textSearchResultText');
		case 'Imágenes':
			return t('textSearchResultImagen');
		case 'Libro fisico':
			return t('textSearchResultOfflineBook');
		case 'Libro externo':
			return t('textSearchResultExternalBook');
		case 'Documento':
			return t('textSearchFilterDocument');
		case 'Enlace':
			return t('textSearchFilterLink');
		default:
			return name;
		}
	};

	return (
		<>
			<div
				className={`${styles.gridRow10} ${styles.alignStart}`}
				style={{
					height: heightCard || '152px',
					width: widthCard || '641px',
				}}
			>
				<a
					className={`${styles.boxImage} ${styles.pointer}`}
					onClick={handleClick}
				>
					<div className={styles.opacity} />
					<img
						className={styles.imgBox}
						src={
							content.image
								? content.image
								: imageByType[content.type]
									? imageByType[content.type]
									: getResources(environment).defaultCover
						}
						onError={(e) =>
							(e.target.src = imageByType[content.type]
								? imageByType[content.type]
								: getResources(environment).defaultCover)
						}
					/>
					{contentTypeNew[resultContent.type] && (
						<div className={styles.posType}>
							<div
								className={
									resultContent.type === booklistsType
										? styles.contentTypeRed
										: styles.contentType
								}
							>
								<img
									className={styles.icon15}
									src={
										contentTypeNew[resultContent.type]
											.imgUrl
									}
									alt=""
								/>
								<h3 className={styles.fontType}>
									{translateTypeContent(
										contentTypeNew[resultContent.type].label
									)}
								</h3>
							</div>
						</div>
					)}
				</a>
				<div className={styles.fontTitleGrid}>
					<div className={styles.titleRow}>
						<h2
							className={styles.pointer}
							onClick={handleClick}
						>
							{content.title}
						</h2>
						{contentType._id == booklistsType &&
						content.isPublic === true ? (
								<>
									<span
										className="material-symbols-outlined"
										style={{
											color: 'green',
											margin: '0 5px',
										}}
									>
									lock_open_right
									</span>
									<span>{t('textMyBoolistsPublic')}</span>
								</>
							) : content.isPublic === false ? (
								<>
									<span
										className="material-symbols-outlined"
										style={{
											color: 'red',
											marginLeft: '5px',
										}}
									>
									lock
									</span>
									<span>{t('textMyBoolistsPrivate')}</span>
								</>
							) : null}
					</div>
				</div>
				<div className={styles.maxParagraph}>
					<p className={styles.fontGrid}>{content.description}</p>
				</div>

				<div className={styles.gridIcons}>
					{resultContent.type === 'BOOKLISTS' ? (
						<>
							{profile.profile.data.email !== author && (
								<div className={styles.gridInterIcons}>
									<div className={styles.followContainer} onClick={handleFollow}>
										<img src={isFollow ? getResources(environment).iconFollowing : iconFollowingGrey} 
											alt="follow icon" 
											className={styles.followLogo} />
										<p className={`${styles.followText} ${isFollow ? styles.following : ''}`}>{isFollow
											? t('buttonContentFollowed')
											: t('buttonProfileFollow')}</p>
									</div>
								</div>
							)}
						</>
					) : (
						<div className={styles.gridInterIcons}>
							<img
								src={
									isLike
										? getResources(environment)
											.iconLikeActive
										: iconLikeNew
								}
								onClick={isLike ? handleDislike : handleLike}
								className={`${styles.icon20} ${styles.cursorPointer}`}
								alt="like"
							/>
							<p
								className={`${styles.fontGrid} ${styles.marginNone}`}
							>
								{countLikes}
							</p>
						</div>
					)}

					<div className={styles.gridInterIcons}>
						{isMobile ? (
							<div className={styles.posBtnMore}>
								<ContextMenu
									color={'green'}
									share={true}
									exploration={true}
									icon={iconShareNew}
									item={contextMenuInfo}
									isContent={content.id}
								/>
							</div>
						) : (
							<img
								src={iconShareNew}
								onClick={handlePopUpShare}
								className={`${styles.icon20} ${styles.cursorPointer}`}
								alt="share"
							/>
						)}
					</div>
					<div className={styles.posBtnMore}>
						<img
							src={iconAddCircle}
							onClick={() =>
								handleOpenContextMenu(!openContextMenu)
							}
							className={`${styles.icon20} ${styles.cursorPointer}`}
							alt="context-menu"
						/>
					</div>
					<div className={styles.posBtnMore}>
						<img
							src={iconMoreInfo}
							onClick={handleOpenPreview}
							className={`${styles.icon20} ${styles.cursorPointer}`}
							alt="more-info"
						/>
					</div>

				</div>

				{moreInfo && (
					<div style={{ position: 'relative', gridColumnStart: '4' }}>
						<div
							className={`${styles.gridInterIcons}`}
							onClick={() => setOpenMoreInfo(!openMoreInfo)}
						>
							<img
								src={iconMoreFill}
								className={`${styles.icon20} ${styles.cursorPointer}`}
								alt="moreFill"
							/>
						</div>

						{openMoreInfo && (
							<div className={styles.menuMoreInfo}>
								<span className={styles.menuMoreInfoSpan}>
									<em className={styles.menuMoreInfoEm}></em>
								</span>
								{menuMoreInfo}
							</div>
						)}
					</div>
				)}
			</div>
			{bookPopUpVisible && (
				<PopUpPreview 
					closePopUp={closePopup} 
					content={content} 
					openContextMenu={() => handleOpenContextMenu(!openContextMenu)} 
					openOopUpShare={handlePopUpShare} 
					isMobile={isMobile}
				/>
			)}
			{openContextMenu &&
				(isMobile ? (
					<AddContentMenuMobile
						onClose={() => handleOpenContextMenu(!openContextMenu)}
						clickedContent={content}
					/>
				) : (
					<PopupContextMenu
						closePopUp={() =>
							handleOpenContextMenu(!openContextMenu)
						}
						content={content}
					/>
				))}
		</>
	);
};

const mapStateToProps = ({ profile, exploration }) => {
	return {
		profile,
		exploration,
	};
};
export default withRouter(
	connect(mapStateToProps, {
		putLike,
		openPopUp,
		setPopUpShare,
		putFollowBooklist,
	})(CardContent)
);
