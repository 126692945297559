import axios from 'axios';
import { setCache, getCache } from '../../helpers/cacheStore';
import {
	FETCH_ALL_CATEGORIES,
	FETCH_LINK_TYPES
} from '../types/categoryTypes';

export const fetchAllCategories = () => async(dispatch) => {
	try {
		const cachedCategories = await getCache(FETCH_ALL_CATEGORIES);
		if(cachedCategories) {
			dispatch({
				type: FETCH_ALL_CATEGORIES,
				payload: cachedCategories
			});
			return cachedCategories;
		} else {
			const allCategories = await axios.get(`${env.msContentNew}/api/categories`);
			dispatch({
				type: FETCH_ALL_CATEGORIES,
				payload: allCategories.data,
			});
			setCache(FETCH_ALL_CATEGORIES, allCategories.data);
			return allCategories.data;
		}
		
	} catch (error) {
		console.error(error);
	}
};


export const fetchLinksTypes = () => async(dispatch) => {
	const linksTypes = await axios.get(`${env.msContentNew}/api/link-types`);
	dispatch({
		type: FETCH_LINK_TYPES,
		payload: linksTypes.data
	});
	return linksTypes.data;
};


