import React from 'react';
import styles from '../../pages/styles/searchV2Page.scss';
import { useTranslation } from 'react-i18next';
const iconSearchGreen =
  'https://cdn.booklick.net/public/img/icons/search-line-green.svg';

const SearchPlaceholder = () => {
	const { t } = useTranslation();
	return (
		<div className={styles.containerSearchMessage}>
			<img className={styles.iconSearch} src={iconSearchGreen} />
			<div className={styles.paragraphSearch}>{t('inputSearch')}</div>
			<div className={styles.paragraphSearch2}>
				{t('textSearchInfoSubtitle')}
			</div>
		</div>
	);
};

export default SearchPlaceholder;
