import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from './styles/popupRegister.scss';
import { postEventPopup } from '../../../helpers/tracking';
import { useTranslation } from 'react-i18next';
import {
	imageCreateProfile,
	imageUpdateProfile,
	eventType,
	imageInstitutionResponsive,
	STEP_CREATE_PROFILE,
	STEP_SELECT_INSTITUTION,
	STEP_UPDATE_PROFILE,
	STEP_FORGOT_PASSWORD,
	breakPoint,
	enviromentTrial,
	imageUpdateProfileResponsive,
	getResources
} from '../../../utils/constans';

import {useWindowWidth} from '../../hooks/useWindowWidth';

import SelectIntitution from './SelectIntitution';
import CreateProfile from './CreateProfile';
import UpdateProfile from './UpdateProfile';
import ForgotPassword from './ForgotPassword';
import { environment } from '../../actions';


const PopUpRegister = ({
	handlePopUp,
	profile
}) => {
	const [institutionSelected, setInstitutionSelected] = useState(null);
	const [email, setEmail] = useState(null);
	/***
	 * TODO: Add update profile behavior to update all users data
	 */
	const [popUpStep, setPopUpStep] = useState(
		profile.profile &&
			(
				!profile.profile.data.role ||
				(
					profile.profile.data.role !== 'admin' &&
					profile.profile.data.role !== 'staff' &&
					!profile.profile.data.programImportId
				)
			)
			? STEP_UPDATE_PROFILE
			: STEP_SELECT_INSTITUTION,
	);
	
	const width = useWindowWidth();
	const [image, setImage] = useState(imageCreateProfile);
	const [removeClosePopUp ,setRemoveClosePopUp] = useState(false);
	const [institution, setInstitution] = useState();
	const { t, i18n } = useTranslation();

	useEffect(() => {
		if (profile.profile) {
			const profileData = profile.profile.data;
			if (
				!profileData.firstName ||
				!profileData.lastName ||
				!profileData.role ||
				((profileData.role != 'admin' && profileData.role != 'staff') &&
					!profileData.programImportId)
			) {
				handleChangeStep(STEP_UPDATE_PROFILE);
			} else {
				handlePopUp();
			}
		}
	}, [profile]);

	useEffect(() => {
		if(institution && institution.isTrial && institution.slug)  {
			window.localStorage.setItem(enviromentTrial, institution.slug);
		}
		if(institution && !institution.isTrial) {
			window.localStorage.removeItem(enviromentTrial);
		}
	},[institution]);


	useEffect(()=> {
		postEventPopup(eventType.initPopup);
		document.body.style.position = 'fixed';
		document.body.style.width = '100%';
		document.body.style.top = `-${window.scrollY}px`;
		return () => {
			const scrollY = document.body.style.top;
			document.body.style.position = '';
			document.body.style.top = '';
			document.body.style.width = '';
			window.scrollTo(0, parseInt(scrollY || '0') * -1);
		  };
	},[]);

	useEffect(() => {
		handleImage();
	}, [width, popUpStep]);
	const handleChangeStep = (step) => {
		setPopUpStep(step);
	};
	const handleImage = () => {
		if(popUpStep === STEP_UPDATE_PROFILE) {
			width < breakPoint.mobileWide ? 
				setImage(imageUpdateProfileResponsive)
				:
				setImage(imageUpdateProfile) ;
		} else {
			width < breakPoint.mobileWide ? 
				setImage(imageInstitutionResponsive)
				:
				setImage(imageCreateProfile);
		}
	};

	const sendEventPopup = (eventType) => {
		postEventPopup(eventType, institutionSelected, email);
	};

	return (
		<div data-test='popUpLogin' className={popUpStep !== STEP_SELECT_INSTITUTION ? `${styles.contentPop} ${styles.paddingNone}` : styles.contentPop}>
			<div className={styles.fixedClosePop} onClick={popUpStep !== STEP_UPDATE_PROFILE && !removeClosePopUp ? handlePopUp : null}></div>
			<div className={popUpStep !== STEP_SELECT_INSTITUTION ? `${styles.boxPopUp} ${styles.height100vh}` : styles.boxPopUp}>
				{(popUpStep === STEP_CREATE_PROFILE ||popUpStep === STEP_FORGOT_PASSWORD)  && (
					<div onClick={() => setPopUpStep(STEP_SELECT_INSTITUTION)} className={styles.back}>
						<img className={styles.icon17} src={getResources(environment).iconArrowLeft} alt="" />
						<p>{t('back')}</p>
					</div>
				)}
				{(popUpStep !== STEP_UPDATE_PROFILE && !removeClosePopUp) && (
					<div onClick={handlePopUp} className={(popUpStep === STEP_CREATE_PROFILE ||popUpStep === STEP_FORGOT_PASSWORD) ? styles.iconClose : `${styles.iconClose} ${styles.displayNone}`}>
						<img className={styles.icon17} src={getResources(environment).iconClose} alt="" />
					</div>
				)}
				<div className={styles.gridPopup}>
					<div className={popUpStep !== STEP_SELECT_INSTITUTION ? `${styles.boxImage} ${styles.borderRadius}` : styles.boxImage}>
						<img className={styles.coverImage} 
							src={image ? image : null} 
							alt="" />
					</div>

					<div className={styles.boxContent}>
						{
							popUpStep === STEP_SELECT_INSTITUTION && 
							<SelectIntitution 
								handleChangeStep={handleChangeStep} 
								sendEventPopup={sendEventPopup}
								setInstitutionSelected={setInstitutionSelected}
								closePopUp={handlePopUp}
								institution={institution} 
								setInstitution={setInstitution}
							/>
						}
						{
							popUpStep === STEP_CREATE_PROFILE && 
							<CreateProfile 
								handleChangeStep={handleChangeStep}
								sendEventPopup={sendEventPopup}
								setProfileEmail={setEmail}
								setInstitutionSelected={setInstitutionSelected}
								removeClosePopUp={(value) => setRemoveClosePopUp(value)}
								closePopUp={handlePopUp}
								institution={institution} 
								setInstitution={setInstitution}
							/>
						}
						{
							popUpStep === STEP_UPDATE_PROFILE && 
							<UpdateProfile 
								closePopUp={handlePopUp} 
								sendEventPopup={sendEventPopup}
							/>
						}
						{
							popUpStep === STEP_FORGOT_PASSWORD && 
								<ForgotPassword 
									handleChangeStep={handleChangeStep}
									closePopUp={handlePopUp} 
								/>
						}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = ({  profile }) => {
	return {
		profile
	};
};

export default withRouter(connect(mapStateToProps, {
})(PopUpRegister));