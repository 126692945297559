import { useState, useEffect } from 'react';

export const useWindowWidth = () => {  
	const [width, setWidth] = useState(0);

	useEffect(()=> {
		window.addEventListener('resize', setWidth(window.innerWidth));
		return () => {
			window.removeEventListener('resize', setWidth(window.innerWidth));
		  };
	},[]);

	return width;
};