import axios from 'axios/index';
import {
	environment,
} from './index';

import {
	GET_HOME,
	OPEN_POPUP,
	REFRESH_HOME,
	SET_POPUP_SHARE
} from '../types/explorationTypes';

import {
	eventShareType
} from '../../utils/constans';

import { postEventSharePopup } from '../../helpers/tracking';


export const loadHome = () => async (dispatch) => {
	try {
		const libraryProfile = await getLibraryProfile();

		dispatch({
			type: GET_HOME,
			payload: {
				libraryProfile,
			}
		});
	} catch (error) {
		throw Error(error);
	}
};

export const openPopUp = (closePopUp) => (dispatch) => {
	dispatch({
		type: OPEN_POPUP,
		payload: closePopUp
	});
};

export const refreshHome = (program) => (dispatch) => {
	dispatch({
		type: REFRESH_HOME,
		payload: program,
	});
};

export const setPopUpShare = (content, openPopUpShare, profile) => async (dispatch) => {
	openPopUpShare ? postEventSharePopup(eventShareType.popupOpen, environment, profile, null, content.id) : postEventSharePopup(eventShareType.popupClose, environment, profile, null, null);
	dispatch({
		type: SET_POPUP_SHARE,
		payload: {
			content,
			openPopUpShare,
		},
	});
};

export const getLibraryProfile = async() => {
	try {
		const request = await axios({
			method: 'GET',
			url: `${env.msProfileNew}/api/library-profiles?institution=${environment}`,
		});
		return request.data;
	} catch (error) {
		throw Error(error);
	}

};
