import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getMoreContentsBooklist, getContentsBooklists, environment } from '../../actions';

import CardContent from '../shared/CardContent';
import NoContentBooklist from './NoContentBooklist';

import styles from './styles/booklistInfo.scss';
import LoaderBooklistContent from './LoaderBooklistContent';
import PopUpDeleteContent from './PopUpDeleteContent';

import { getResources } from '../../../utils/constans';
import PopUpMoveContentToSection from './PopUpMoveContentToSection';
import PopUpEditContent from './PopUpEditContent';
import { useTranslation } from 'react-i18next';

const limit = 8;
const BooklistContent = ({
	myProfile,
	booklistContent,
	booklistId,
	isMyBooklist,
	getMoreContentsBooklist,
	page,
	handlePage,
	loadingContent,
	isEditor,
	isViewer,
	section,
	handleSection,
	isMobile,
}) => {

	const { t } = useTranslation();

	const [loadingScroll, setLoadingScroll] = useState(false);

	const [openPopUp, setOpenPopUp] = useState({
		EditContent: false,
		MoveToOtherSection: false,
		DeleteContent: false
	});
	const [selectedContent, setSelectedContent] = useState();

	useEffect(() => {
		setLoadingScroll(false);
	}, [booklistContent]);

	useEffect(() => {
		if (booklistContent.totalItems >= limit && !loadingScroll && booklistContent.hasNextPage) {
			let observerScroll = document.getElementById('observerScroll');
			let intersectionObserver = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting) {
					setLoadingScroll(true);
					handleMoreContent();
				}
			}, {
				rootMargin: '0px 0px 1px 0px',
			});
			intersectionObserver.observe(observerScroll);
		}

	}, [loadingScroll]);


	const handleMoreContent = () => {
		getMoreContentsBooklist(booklistId, section, page + 1);
		handlePage(page + 1);
	};

	const handleClickMenuMoreInfo = (content, option) => {
		setSelectedContent(content);
		switch (option) {
		case 'edit':
			setOpenPopUp({
				EditContent: true,
				MoveToOtherSection: false,
				DeleteContent: false
			});
			break;
		case 'move':
			setOpenPopUp({
				EditContent: false,
				MoveToOtherSection: true,
				DeleteContent: false
			});
			break;
		case 'delete':
			setOpenPopUp({
				EditContent: false,
				MoveToOtherSection: false,
				DeleteContent: true
			});
			break;

		default:
			break;
		}
	};


	const MenuMoreInfoCardContent = ({ content }) => (
		<div style={{ marginTop: '10px' }}>
			{((content.author.email === myProfile.data.email && content.type !== 'BOOKLISTS') ||
				(content.uploadedBy === myProfile.data.email && content.type !== 'BOOKLISTS') || 
				(content.isSuperUser && content.type !== 'BOOKLISTS')) && (
					<div className={`${styles.gridInterIcons}`} onClick={() => handleClickMenuMoreInfo(content, 'edit')}>
						<img
							src={getResources(environment).iconEdit}
							className={`${styles.icon15}`}
							alt="edit-content"
						/>
						<h3>{t('textContextMenuEdit')}</h3>
					</div>
			)}

			<div className={`${styles.gridInterIcons}`} onClick={() => handleClickMenuMoreInfo(content, 'move')}>
				<img
					src={getResources(environment).iconMoveSection}
					className={`${styles.icon15}`}
					alt="move-to-section"
				/>
				<h3>{t('textContextMenuMove')}</h3>
			</div>
			{(isEditor || isMyBooklist || content.isSuperUser) && (
				<div className={`${styles.gridInterIcons}`} onClick={() => handleClickMenuMoreInfo(content, 'delete')}>
					<img
						src={getResources(environment).iconDelete}
						className={`${styles.icon15}`}
						alt="deleteContent"
					/>
					<h3>{t('textContextMenuDelete')}</h3>
				</div>
			)}
		</div>
	);

	return (
		<>
			{booklistContent.contents.length > 0 ? (
				<>
					{!loadingContent ? booklistContent.contents.map(content => (
						<CardContent
							key={content.id}
							resultContent={content}
							contentType={{
								_id: content.type,
								identifier: content.type,
							}}
							heightCard={'165px'}
							widthCard={'100%'}
							moreInfo={isMyBooklist || isEditor || content.isSuperUser || isViewer && true}
							menuMoreInfo={<div>{<MenuMoreInfoCardContent content={content} />}</div>}
						/>
					)) : (<LoaderBooklistContent />)
					}

					{loadingScroll && (
						<LoaderBooklistContent />
					)}

					{booklistContent.totalItems >= limit && !loadingScroll && booklistContent.hasNextPage ?
						<div className={styles.observerScroll} id='observerScroll' /> : null
					}

				</>
			) : (
				<div
					style={{
						display: 'grid',
						justifyContent: 'center',
						marginTop: '50px',
						marginBottom: '95px',
						width: isMobile ? '420px' : '1200px',
						alignContent: 'center',
					}}>
					<NoContentBooklist />
				</div>
			)
			}

			{openPopUp.DeleteContent && (
				<PopUpDeleteContent
					booklistId={booklistId}
					slugContent={selectedContent.slug}
					contentId={selectedContent.id}
					closePopUp={() => setOpenPopUp({
						EditContent: false,
						MoveToOtherSection: false,
						DeleteContent: false
					})}

				/>
			)}

			{openPopUp.EditContent && (
				<PopUpEditContent
					handleSection={handleSection}
					deleteContentToBooklist={handleClickMenuMoreInfo}
					sectionId={section}
					content={selectedContent}
					closePopUp={() => setOpenPopUp({
						EditContent: false,
						MoveToOtherSection: false,
						DeleteContent: false
					})}

				/>
			)}

			{openPopUp.MoveToOtherSection && (
				<PopUpMoveContentToSection
					booklistId={booklistId}
					sectionId={section}
					contentId={selectedContent.id}
					handleSection={handleSection}
					closePopUp={() => setOpenPopUp({
						EditContent: false,
						MoveToOtherSection: false,
						DeleteContent: false
					})}
				/>
			)}


		</>
	);
};

const mapStateToProps = ({ booklistV2, profile }) => {
	return {
		booklistContent: booklistV2.booklistContent,
		booklistId: booklistV2.booklistsInfo.id,
		isMyBooklist: booklistV2.booklistsInfo.isMyBooklist,
		isEditor: booklistV2.isEditor,
		isViewer: booklistV2.isViewer,
		myProfile: profile.profile,
	};
};

export default withRouter(connect(mapStateToProps, { getMoreContentsBooklist, getContentsBooklists })(BooklistContent));
