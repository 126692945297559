import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import nativeMenu from '../components/hocs/nativeMenu';
import { connect } from 'react-redux';
import {
	getBooklistDetail,
	getContentsBooklists,
	setPopUpShare,
	getBooklistSession,
	postSearchBooklistContents,
	getBooklistPermissions,
} from '../actions';

import styles from './styles/bookListV2Page.scss';
import LoaderBooklistIfo from '../components/booklists/LoaderBooklistInfo';
import BooklistInfo from '../components/booklists/BooklistInfo';
import BooklistInfoMobile from '../components/booklists/BooklistInfoMobile';
import LoaderBooklistContent from '../components/booklists/LoaderBooklistContent';
import BooklistContent from '../components/booklists/BooklistContent';
import BooklistSearchContent from '../components/booklists/BooklistSearchContent';
import PopUpShare from '../components/shared/PopUpShare';
import LoaderBooklistFilters from '../components/booklists/LoaderBooklistFilters';
import BooklistFilters from '../components/booklists/BooklistFilters';
import SectionPopUpEdit from '../components/booklists/SectionPopUpEdit';
import useOptionDropDown from '../hooks/useOptionDropDown';
import PopUpDeleteSection from '../components/booklists/PopUpDeleteSection';
import { useTranslation } from 'react-i18next';
import { breakPoint } from '../../utils/constans';

const BooklistV2Page = ({
	match,
	history,
	booklistV2,
	getBooklistDetail,
	getContentsBooklists,
	exploration,
	setPopUpShare,
	getBooklistSession,
	getBooklistPermissions,
}) => {
	const { t } = useTranslation();
	const [section, setSection] = useState();
	const [page, setPage] = useState(1);
	const [loadingContent, setLoadingContent] = useState(false);
	const [contents, setContents] = useState([]);
	const [popUpEditSection, setPopUpEditSection] = useState(false);
	const [popUpRemoveSection, setPopUpRemoveSection] = useState(false);
	const [sectionId, setSectionId] = useState();
	const [listOptions, setListOption] = useState([]);
	const [isSearch, setIsSearch] = useState(false);
	const [booklistSlug, setBooklistSlug] = useState();
	const [isMobile, setIsMobile] = useState(
		window.innerWidth < breakPoint.mobileWide
	);
	const [isSuperUser, setIsSuperUser] = useState(false);

	const [options, value, handleValue, resetDropDownValue ,defaultValue] =
    useOptionDropDown(listOptions);

	useEffect(() => {
		const { slug } = match.params;
		setBooklistSlug(slug);
		getBooklistDetail(slug);		
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	
	useEffect(() => {
		if (booklistV2.error === 'No CognitoUser Found' ) {
			if (env.proxy) {
				window.localStorage.setItem('booklistSlug', booklistSlug);
				window.location = env.proxy ? `${env.proxy}` : `${window.location.origin}`;
			} else {
				history.push('/');
			}
		}
	}, [booklistV2.error]);

	useEffect(() => {
		if (booklistV2.booklistsInfo) {
			getBooklistPermissions(booklistV2.booklistsInfo.id);
			getBooklistSession(booklistV2.booklistsInfo.id);
			setIsSuperUser(booklistV2.booklistsInfo.isSuperUser);
		}
	}, [booklistV2.booklistsInfo]);

	useEffect(() => {
		if (booklistV2.booklistSections) {
			setListOption(
				booklistV2.booklistSections.map((section) => {
					return {
						value: section.id,
						label:
              section.section === 'Contenido'
              	? t('textSearchFilterContent')
              	: section.section,
					};
				})
			);

			setSection(booklistV2.booklistSections[0].id);
			getContentsBooklists(
				booklistV2.booklistsInfo.id,
				sectionId ? sectionId : booklistV2.booklistSections[0].id
			);
		}
	}, [booklistV2.booklistSections]);

	useEffect(() => {
		if (booklistV2.booklistContent) {
			setContents([...booklistV2.booklistContent.contents]);
		}
	}, [booklistV2.booklistContent]);

	const handlePage = (value) => {
		setPage(value);
	};

	const handleSection = (value) => {
		setSection(value);
		const sectionSelect = listOptions.find(
			(section) => section.value === value
		);
		defaultValue(sectionSelect);
		setPage(1);
		getContentsBooklists(booklistV2.booklistsInfo.id, value);
	};

	const handleSearch = async (term) => {
		if (term === '') {
			setContents([...booklistV2.booklistContent.contents]);
			setLoadingContent(false);
			setIsSearch(false);
		} else {
			try {
				setLoadingContent(true);
				setIsSearch(true);
				const resultSearch = await postSearchBooklistContents(
					booklistV2.booklistsInfo.id,
					term.toLowerCase()
				);
				if (resultSearch) { 
					setContents([...resultSearch.contents]);
					setLoadingContent(false);
				} else {
					setLoadingContent(false);
				}
			} catch (error) {
				console.error(error);
			}
		}
	};

	const handleEditSection = (value) => {
		setSectionId(value);
		setPopUpEditSection(true);
	};

	const handleRemoveSection = () => {
		setPopUpRemoveSection(true);
	};

	const handleResize = () => {
		setIsMobile(window.innerWidth < breakPoint.mobileWide);
	};
	return (
		
		<div className={styles.paddingPage}>
			<div className={styles.containerPage}>
				{(!booklistV2.booklistsInfo || booklistV2.loading) && <LoaderBooklistIfo />}
				{booklistV2.booklistsInfo && !booklistV2.loading && (
  					isMobile ? <BooklistInfoMobile /> : <BooklistInfo />)}


				{booklistV2.loadingFilter ? (
					<LoaderBooklistFilters />
				) : (
					<BooklistFilters
						section={section}
						handleSection={handleSection}
						handleSearch={handleSearch}
						popUpEdit={handleEditSection}
						options={options}
						value={value}
						handleValue={handleValue}
						defaultValue={defaultValue}
						listOptions={listOptions}
						isMobile={isMobile}
						isSuperUser={isSuperUser}
					/>
				)}

				{booklistV2.loadingContent ? (
					<div
						style={{
							margin: '20px auto',
							display: 'grid',
							gridTemplateColumns: '1fr 1fr',
							gap: '28px',
							maxWidth: '1200px',
						}}
					>
						<LoaderBooklistContent />
					</div>
				) : (
					<div style={isMobile ? {
						display: 'flex',
						padding: '0px 5px',
						flexDirection: 'column',
					} : {
						margin: '20px auto',
						display: 'grid',
						gridTemplateColumns: '1fr 1fr',
						gap: '28px',
						maxWidth: '1200px',
					}}>
						{isSearch ? (
							<BooklistSearchContent
								page={page}
								loadingContent={loadingContent}
								contents={contents}
								handlePage={handlePage}
								handleSection={handleSection}
								section={section}
							/>
						) : (
							<BooklistContent
								page={page}
								loadingContent={loadingContent}
								contents={contents}
								handlePage={handlePage}
								handleSection={handleSection}
								section={section}
								isMobile={isMobile}
							/>
						)}
					</div>
				)}
			</div>

			{popUpEditSection && (
				<SectionPopUpEdit
					closePopUp={() => setPopUpEditSection(false)}
					sectionId={sectionId}
					popUpDelete={handleRemoveSection}
				/>
			)}

			{popUpRemoveSection && (
				<PopUpDeleteSection
					closePopUp={() => setPopUpRemoveSection(false)}
					sectionId={sectionId}
					handleEditSection={handleEditSection}
				/>
			)}

			{exploration.openPopUpShare && (
				<PopUpShare closePopUp={() => setPopUpShare(null, false)} />
			)}
		</div>
	);
};

function mapStateToProps({ booklistV2, exploration }) {
	return {
		booklistV2,
		exploration,
	};
}

export default {
	component: nativeMenu(
		withRouter(
			connect(mapStateToProps, {
				getBooklistDetail,
				getContentsBooklists,
				setPopUpShare,
				getBooklistSession,
				getBooklistPermissions,
			})(BooklistV2Page)
		)
	),
};
