import {
	CLOSE_EDIT_CONTENT,
	EDIT_CONTENT, EDIT_NEW_CONTENT,
	EMPTY_PREVIEW_CONTENT,
	END_SUBMIT_EDITING_CONTENT,
	FETCH_EDITORIAL_PREVIEW_DATA,
	FETCH_PREVIEW_CONTENT,
	FETCH_PREVIEW_RECOMMENDATION,
	INPUT_CHANGE_CONTENT,
	SET_PREVIEW_DATA,
	START_FETCH_PREVIEW_CONTENT,
	START_SUBMIT_EDITING_CONTENT,
	SET_CONTENT_FROM_ACTIVITY,
	BOOKLIST_ADDED,
} from '../actions';
import {
	PUT_CONTENT_TAGS,
	UPDATE_CONTENT_PREVIEW
} from '../types/contentTypes';

const initialState = {
	data: {},
	included: {},
	recommendedData: [],
	editingTitle: '',
	editingDescription: '',
	editingIsPublic: undefined,
	editingCoverFile: null,
	loading: false,
	edit: false,
	new: false,
	booklistAdded: [],
	error: '',
	selectedContent: {},
	tags: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
	case START_SUBMIT_EDITING_CONTENT:
	case START_FETCH_PREVIEW_CONTENT:
		return {
			...state,
			loading: true
		};
	case EDIT_CONTENT:
		return {
			...state,
			edit: true
		};
	case EDIT_NEW_CONTENT:
		return {
			...state,
			new: true,
			edit: true
		};
	case CLOSE_EDIT_CONTENT:
		return {
			...state,
			edit: false,
			new: false
		};
	case END_SUBMIT_EDITING_CONTENT:
	case SET_PREVIEW_DATA:
		return {
			...state,
			data: action.payload.data,
			included: action.payload.included,
			loading: false,
			editingTitle: '',
			editingDescription: '',
			editingIsPublic: undefined,
			editingCoverFile: null,
		};
	case FETCH_EDITORIAL_PREVIEW_DATA:
		const relatedData = state.included;
		return  {
			...state,
			included: {
				...relatedData,
				editorial: action.payload.data.name
			}
		};
	case FETCH_PREVIEW_RECOMMENDATION:
		return {
			...state,
			recommendedData: action.payload,
		};
	case FETCH_PREVIEW_CONTENT:
		return {
			...state,
			data: action.payload,
			included: action.payload,
			loading: false
		};
	case INPUT_CHANGE_CONTENT:
		return {
			...state,
			[action.payload.type]: action.payload.value
		};
	case EMPTY_PREVIEW_CONTENT:
		return initialState;
	case SET_CONTENT_FROM_ACTIVITY:
		return {
			...state,
			selectedContent: action.payload
		};
	case BOOKLIST_ADDED:

		return {
			...state,
			booklistAdded: action.payload,
			loading: false
		};
	case PUT_CONTENT_TAGS:
		return {
			...state,
			tags: action.payload
		};
	case UPDATE_CONTENT_PREVIEW:
		return {
			...state,
			data: {
				attributes: {
					...action.payload
				}
			}
		};
	default:
		return state;
	}
};
