import axios from 'axios';
import Cognito from '../../helpers/cognito';
import {environment} from './index';


export const START_PUT_FILE = 'START_PUT_FILE';
export const PUT_FILE_IMAGE_PROGRESS = 'PUT_FILE_IMAGE_PROGRESS';
export const PUT_FILE_FILE_PROGRESS = 'PUT_FILE_FILE_PROGRESS';
export const END_PUT_FILE = 'END_PUT_FILE';
export const ERROR_PUT_FILE = 'ERROR_PUT_FILE';
export const CLEAR_UPLOAD_FILE = 'CLEAR_UPLOAD_FILE';

export const clearUploadFile = () => async(dispatch) => {
	dispatch({
		type: CLEAR_UPLOAD_FILE,
	});
};

export const START_PUT_LINK = 'START_PUT_LINK';
export const getMetadata = (link) => async(dispatch) => {
	dispatch({
		type: START_PUT_FILE,
	});
	if(link.startsWith('https')){
		try {
			const request = await axios({
				method: 'POST',
				url: `${env.msContentNew}/api/v2/contents/metadata`,
				data: {
					url: link,
				},
			});

			const metadata = request.data;

			if(metadata) {
				const positionSlug = 2;
				let imageUrl = '';

				if(metadata.image && metadata.image !== ''){
					if(metadata.image.startsWith('/')){
						imageUrl = `${metadata.url}${metadata.image.slice(1)}`;
					} else {
						imageUrl = metadata.image;
					}

				} else if(metadata.jsonld.publisher) {
					imageUrl = metadata.jsonld.publisher.logo.url;
				}

				const data = {
					author: metadata.author || '',
					imageURL: imageUrl,
					title: metadata.title !== '' ? metadata.title : link.split('/')[positionSlug],
					description: metadata.description || '',
					url: link,
					categories: '',
				};
				return data;
			}
		} catch (error) {
			console.error(error);
		}
	} else {
		return undefined;
	}
};

const saveLinkFunction = async (getState, params, dispatch) => {
	dispatch({
		type: START_PUT_FILE,
	});
	try {
		const session = await  Cognito.retrieveToken();
		if (params.cover) {
			const imageURL = await uploadFile(params.cover, session, PUT_FILE_IMAGE_PROGRESS, dispatch);
			if(imageURL === undefined) {
				imageURL = null;
			}
			const link = await uploadLink(getState, dispatch, params, session, imageURL);
			return link;
		} else if (params.imageURL) {
			if (params.imageURL.startsWith('http') || params.imageURL.startsWith('https')) {
				return uploadLink(getState, dispatch, params, session, params.imageURL);
			} else {
				return uploadLink(getState, dispatch, params, session);
			}
		} else {
			return uploadLink(getState, dispatch, params, session);
		}
	} catch (error) {
		console.error(error);
	}
};

export const saveLink = (params) => async(dispatch, getState) => {
	return saveLinkFunction(getState, params, dispatch);
};

function uploadLink(getState, dispatch, params, session, imageURL) {

	return axios({
		method: 'POST',
		url: `${env.msContentNew}/api/contents/auth`,
		data: {
			title: params.title,
			description: params.description,
			author: params.author,
			image: imageURL,
			type: 'LINKS',
			url: params.url,
			categories: params.categories,
			categoryId: params.categoryId,
			metadata: params.metadata,
		},
		headers: {
			'Authorization': session.idToken.jwtToken,
			'env': environment
		}
	})
		.then((response) => {
			const { profile } = getState();
			dispatch({
				type: END_PUT_FILE,
				payload: response
			});
			return response.data;
		});

}

export const putFile = (params) => async(dispatch, getState) => {
	dispatch({
		type: START_PUT_FILE,
	});
	try {
		const session = await Cognito.retrieveToken();
		if (params.cover) {
			const imageUrl = await uploadFile(params.cover, session, PUT_FILE_IMAGE_PROGRESS, dispatch);;


			const fileUrl = await uploadFile(params.file, session, PUT_FILE_FILE_PROGRESS, dispatch);

			return postDocumentHandler(getState, params, fileUrl, imageUrl, session, dispatch);
		} else {
			const fileUrl = await uploadFile(params.file, session, PUT_FILE_FILE_PROGRESS, dispatch);
			return postDocumentHandler(getState, params, fileUrl, null, session, dispatch);
		}
	} catch (error) {
		dispatch({
			type: ERROR_PUT_FILE,
			payload: error
		});
	}


};

export async function postDocumentHandler(getState, params, fileUrl, imageUrl, session, dispatch) {
	return postDocument(getState, params, fileUrl, imageUrl, session, dispatch);
}

async function postDocument(getState, params, fileURL, imageURL, session, dispatch) {
	
	try {
		const formData = new FormData();
		formData.append('file', params.file);
		formData.append('title', params.title);
		formData.append('description', params.description);
		formData.append('author', params.author);
		if (params.categoryId && params.categoryId !== 'undefined') {
			formData.append('categoryId', params.categoryId);
		}
		formData.append('mimetype', params.file.type);
		formData.append('type', 'DOCUMENTS');
		if(imageURL && imageURL !== 'undefined'){
			formData.append('image', imageURL);
		}
		const response = await axios({
			method: 'POST',
			url: `${env.msContentNew}/api/v2/contents/document`,
			data: formData,
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment,
				'Content-Type': 'multipart/form-data'
			}
		});
	
		dispatch({
			type: END_PUT_FILE,
			payload: response.data
		});
	
		return response.data;
	} catch (error) {
		console.error('error', error);
	}
	

}

function getFileRender(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = function (e) {
			if (e.target.error) {
				reject(e.target.error);
			}
			resolve(e.target.result);
		};
		reader.readAsArrayBuffer(file);
	});
}

export const uploadFile = async (file, session, constProgress, dispatch) => {
	try {
		const fileRender = await getFileRender(file);
		const urlData = await axios({
			method: 'GET',
			url: `${env.msContent}/contentUploadURL?type=${file.type}&name=${file.name}`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});

		const responses = await axios({
			url: urlData.data.uploadURL,
			method: 'PUT',
			data: new Blob([fileRender], {type: file.type}),
			headers: {
				'Content-Type': file.type
			},
			onUploadProgress: (progressEvent) => {
				if (constProgress) {
					dispatch({
						type: constProgress,
						payload: progressEvent.loaded / progressEvent.total
					});
				}
			}
		});
		return `${env.bucketUrl}/${urlData.data.fileName}`;
	} catch (error) {
		console.error(error);
	}
};

export const putFileBeforeCreate = (file, content, fileUrl, imageFile) => async(dispatch, getState) => {
	dispatch({
		type: START_PUT_FILE,
	});
	try {
		const session = await Cognito.retrieveToken();

		if(!content) {
			const fileUrl = await uploadFile(file, session, PUT_FILE_FILE_PROGRESS, dispatch);
			return fileUrl;
		} else {
			const imageUrl = await uploadFile(imageFile, session, PUT_FILE_FILE_PROGRESS, dispatch);
			return postDocumentHandler(getState, content, fileUrl, imageUrl, session, dispatch);
		}

	} catch (error) {
		console.error('putFileBeforeCreate', error);
		dispatch({
			type: ERROR_PUT_FILE,
			payload: error
		});
	}
};
