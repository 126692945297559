export const profileDefaultImage = 'https://cdn.booklick.net/public/img/profile.png';

export const sectionDefault = {
	section: 'Contenido',
	id: 'default',
};
export const iconlike = 'https://cdn.booklick.net/public/img/icons/icon-like-grey.svg';
export const iconBooklist = 'https://cdn.booklick.net/public/img/icons/booklist-white.svg';
export const iconContent = 'https://cdn.booklick.net/public/img/icons/text-white.svg';
export const coverOffilineBook = 'https://cdn.booklick.net/public/img/images/Azul+Libros.jpg';
export const iconFilterOn = 'https://cdn.booklick.net/public/img/icons/iconFilterOn.svg'
export const iconFilterOff = 'https://cdn.booklick.net/public/img/icons/iconFilterOff.svg'

export const icons = {
	book: 'https://cdn.booklick.net/public/img/icons/book-white.svg',
	text: 'https://cdn.booklick.net/public/img/icons/text-white.svg',
	article: 'https://cdn.booklick.net/public/img/icons/text-white.svg',
	journal: 'https://cdn.booklick.net/public/img/icons/text-white.svg',
	image: 'https://cdn.booklick.net/public/img/icons/icon-img-white.svg',
	video: 'https://cdn.booklick.net/public/img/icons/icon-video-white.svg',
	default: 'https://cdn.booklick.net/public/img/icons/text-white.svg',
};

export const permissions = {
	author: 'author',
	edit: 'edit',
	view: 'view'
};

export const contentType = {
	booklist: 'BOOKLISTS',
	book: 'book',
	article: 'article',
	journal: 'journal'
};

export const contentTypeFilter = [
	{ value: 'LINKS', translationKey: 'textSearchFilterLink' },
	{ value: 'ARTICLES', translationKey: 'textSearchFilterArticle' },
	{ value: 'EXTERNAL_BOOKS', translationKey: 'textSearchResultExternalBook' },
	{ value: 'BOOKS', translationKey: 'titleTypeContentBook' },
	{ value: 'BOOKLISTS', translationKey: 'textSearchFilterBooklist' },
	{ value: 'DOCUMENTS', translationKey: 'textSearchFilterDocument' },
	{ value: 'OFFLINE_BOOKS', translationKey: 'textSearchResultOfflineBook' },
]

export const rolType = {
	student: 'student',
	admin: 'admin',
	teacher: 'teacher',
	graduate: 'graduate',
	staff: 'staff',
	master: 'master',
    specialization: 'specialization',
    postgraduate: 'postgraduate',
};

export const profileTypes = [
	{ value: 'admin', translationKey: 'roleAdmin' },
	{ value: 'graduate', translationKey: 'titleGraduate' },
	{ value: 'teacher', translationKey: 'titleTeacher' },
	{ value: 'student', translationKey: 'titleStudent' },
	{ value: 'staff', translationKey: 'titleStaff' },
	{ value: 'master', translationKey: 'titleMaster' },
	{ value: 'specialization', translationKey: 'titleSpecialization' },
	{ value: 'postgraduate', translationKey: 'titlePostgraduate' },
];


export const offlineBookType = 6;
export const offlineBookLabel = 'Libro Físico';
export const libraryNamePosition = 4;
export const offLineBookDescription = 'Biblioteca: ';

export const iconBtnWhite = 'https://cdn.booklick.net/public/img/icons/icon-btn-x-white.svg';
export const titleDelete = '¿Estás seguro de eliminar la sección?';
export const infoDelete = 'Una vez que hayas eliminado la sección, no podrás deshacerlo y los contenido volverán a la sección inicial de la Booklist.';
export const enterKeyCode = 13;
export const iconSearchGrey = 'https://cdn.booklick.net/public/img/icons/icon-search-grey.svg';
export const iconEdit = 'https://cdn.booklick.net/public/img/icons/edit.svg';


export const arrowSection = {
	left: {
		active: 'https://cdn.booklick.net/public/img/icons/arrow-left-active.svg',
		inactive: 'https://cdn.booklick.net/public/img/icons/arrow-left-inactive.svg'
	},
	right: {
		active: 'https://cdn.booklick.net/public/img/icons/arrow-right-active.svg',
		inactive: 'https://cdn.booklick.net/public/img/icons/arrow-right-inactive.svg'
	}
};

export let scrollDistance = 150;
export let minSize = 705;
export const breakPointMobile = 550;
export const breakPointTablet = 1050;
export const breakPoint = {
	mobileMedium: 550,
	table: 1050,
	mobileWide: 740
};


//Booklist new Home Icons

export const iconAddCircle = 'https://cdn.booklick.net/public/img/icons/IconAddCircle.svg';
export const iconArrowSliderLeft = 'https://cdn.booklick.net/public/img/icons/IconArrowSliderLeft.svg';
export const iconArrowSliderRight = 'https://cdn.booklick.net/public/img/icons/IconArrowSliderRight.svg';
export const iconAudio = 'https://cdn.booklick.net/public/img/icons/IconAudio.svg';
export const iconBooklistWhite = 'https://cdn.booklick.net/public/img/icons/IconBooklistWhite.svg';
export const iconBookOpen = 'https://cdn.booklick.net/public/img/icons/IconBookOpen.svg';
export const iconFileText = 'https://cdn.booklick.net/public/img/icons/IconFileText.svg';
export const iconFilm = 'https://cdn.booklick.net/public/img/icons/IconFilm.svg';
export const iconImage = 'https://cdn.booklick.net/public/img/icons/IconImage.svg';
export const iconLikeNew = 'https://cdn.booklick.net/public/img/icons/IconLikeNew.svg';
export const iconMagazine = 'https://cdn.booklick.net/public/img/icons/IconMagazine.svg';
export const iconPencilWhite = 'https://cdn.booklick.net/public/img/icons/IconPencilWhite.svg';
export const iconShareNew = 'https://cdn.booklick.net/public/img/icons/IconShareNew.svg';
export const iconSlides = 'https://cdn.booklick.net/public/img/icons/IconSlides.svg';
export const university = 'https://cdn.booklick.net/public/img/icons/uniminuto.png';
export const messageHeader = 'https://cdn.booklick.net/public/img/icons/new-chat.svg';
export const iconSearchBlack = 'https://cdn.booklick.net/public/img/icons/new-lens.svg';

export const iconClassList = 'https://cdn.booklick.net/public/img/icons/IconClassList.svg';
export const iconCloseGray = 'https://cdn.booklick.net/public/img/icons/IconCloseGray.svg';
export const iconArrowDown = 'https://cdn.booklick.net/public/img/icons/IconArrowDown.svg';
export const iconLibrary = 'https://cdn.booklick.net/public/img/icons/IconLibrary.svg';
export const iconLock = 'https://cdn.booklick.net/public/img/icons/IconLock.svg';
export const iconUser = 'https://cdn.booklick.net/public/img/icons/IconUser.svg';

export const linkIcon = 'https://cdn.booklick.net/public/img/icons/link.svg';
export const mailIcon = 'https://cdn.booklick.net/public/img/icons/mail.svg';
export const whatsappIcon = 'https://cdn.booklick.net/public/img/icons/Whatsapp.svg';
export const facebookIcon = 'https://cdn.booklick.net/public/img/icons/Facebook.svg';
export const inconSendWhite = 'https://cdn.booklick.net/public/img/icons/send-alt.svg';
export const iconFollowingWhite = 'https://cdn.booklick.net/public/img/icons/icon-following-white.svg';
export const iconFollowingGrey = 'https://cdn.booklick.net/public/img/icons/icon-following-grey.svg';

export const iconEditWhite = 'https://cdn.booklick.net/public/img/icons/icon-edit-white.svg';


export const iconNoContentMyBooklists = 'https://cdn.booklick.net/public/img/icons/icon-no-content-my-booklist.svg';
export const iconNoContentBooklists = 'https://cdn.booklick.net/public/img/icons/icon-no-content-booklist.svg';
export const iconCameraBlack = 'https://cdn.booklick.net/public/img/icons/icon-camera-black.svg';
export const iconDeleteRed = 'https://cdn.booklick.net/public/img/icons/icon-delete-red.svg';
export const iconSaveWhite = 'https://cdn.booklick.net/public/img/icons/icon-save-white.svg';
export const iconSearchGreyDark = 'https://cdn.booklick.net/public/img/icons/icon-search-grey-dark.svg';
export const iconNoResultSearch = 'https://cdn.booklick.net/public/img/icons/icon-no-result-search.svg';
export const iconMoreFill = 'https://cdn.booklick.net/public/img/icons/icon-more-fill.svg';


export const studentId = '1dfd44ee-5400-481e-af5d-4ee570034f82'
export const teacherId = 'f48d72f8-312c-4e7e-9be1-79034f5ec9e1'
export const adminId = 'f971f0aa-59da-417c-85ea-bcec3b99f66e'
export const booklistExploration = 'ae5b6a04-fe9b-44bf-bcec-f1abdc72d432'

export const iconByType = {
	1: 'https://cdn.booklick.net/public/img/icons/IconBookOpen.svg',
	2: 'https://cdn.booklick.net/public/img/icons/IconFileText.svg',
	3: 'https://cdn.booklick.net/public/img/icons/IconBooklistWhite.svg',
	4: 'https://cdn.booklick.net/public/img/icons/IconMagazine.svg',
	5: 'https://cdn.booklick.net/public/img/icons/IconBookOpen.svg',
	6: 'https://cdn.booklick.net/public/img/icons/IconBookOpen.svg',
	7: 'https://cdn.booklick.net/public/img/icons/IconMagazine.svg',
};
export const labelByType = {
	1: 'Libro',
	2: 'Documento',
	3: 'Booklist',
	4: 'Artículo',
	5: 'Libro',
	6: 'Libro físico',
	7: 'Link',
};

export const sourceBooklick = 'Booklick'
export const allOption = {
	value: 'Todos',
	label: 'Todos',
};


export const imageCreateProfile = 'https://cdn.booklick.net/public/img/images/imageCreateProfile.png';
export const imageUpdateProfile = 'https://cdn.booklick.net/public/img/images/ImageUpdateProfile.jpg';
export const imageUpdateProfileResponsive = 'https://cdn.booklick.net/public/img/images/imageUpdateProfileResponsive.png';

export const eventShareType = {
	popupOpen: 'POPUP_OPEN',
	shareSearch: 'SHARE_SEARCH',
	shareProfile: 'SHARE_PROFILE',
	shareLink: 'SHARE_LINK',
	shareEmail: 'SHARE_EMAIL',
	shareWhatsapp: 'SHARE_WHATSAPP',
	shareFacebook: 'SHARE_FACEBOOK',
	popupClose: 'POPUP_CLOSE',
};

export const eventType = {
	initPopup: 'INIT_POPUP',
	selectInstitution: 'IES_SELECTION',
	login: 'LOG_IN',
	signUp: 'SING_UP',
	verifyEmail: 'VERIFY_EMAIL',
	signUpCompleted: 'SING_UP_COMPLETED',
	unlistedInstitution: 'NOT_LISTED',
};

export const eventSearchType = {
    termSearch:'TERM_SEARCH',
    resultSearch:'RESULT_SEARCH',
    contentRedirect:'CONTENT_REDIRECT_SEARCH',
    profileRedirect:'PROFILE_REDIRECT_SEARCH',
    scrollSearch:'SCROLL_SEARCH'
};

export const LIMIT_SEARCH = 10;
export const PROFILE_CONTENTS_LIMIT = 12;
export const DEFAULT_PAGE = 1;

export const eventCommentType = {
	addComment: 'ADD_COMMENT',
	scrollComment: 'SCROLL_COMMENT',
};

export const imageInstitutionResponsive = 'https://cdn.booklick.net/public/img/images/institutionResponsive.png';

export const STEP_SELECT_INSTITUTION = 0;
export const STEP_CREATE_PROFILE = 1;
export const STEP_UPDATE_PROFILE = 2;
export const STEP_FORGOT_PASSWORD = 3;

export const schoolsPerScroll = {
	default: 3,
	breakpoint1320: 6,
	breakpoint960: 5,
	breakpoint768: 4,
	breakpoint650: 3,
};

export const schoolsPerSlide = {
	default: 6,
	breakpoint1320: 6,
	breakpoint960: 5,
	breakpoint768: 4,
	breakpoint650: 3,
};

export const minimumSchoolsForScroll = 6;
export const enviromentTrial = 'enviromentTrial';

export const windowToShare={
	width:1000,
	height:600
};

export const iconCheckGreenBack = 'https://cdn.booklick.net/public/img/icons/IconCheckBackgroundGreen.svg';
export const iconNotResults ='https://cdn.booklick.net/public/img/icons/IconNotResults.svg'
export const iconWhatsappNew = 'https://cdn.booklick.net/public/img/icons/IconWhatsappNew.png'

export const PROGRAM_SUGGEST_MAX_LENGTH = 21;


export const NUMBER_SEMESTER_LENGTH = 2;

export const regexEmail = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/gim;

export const imageByType = {
	text: 'https://cdn.booklick.net/public/img/icons/iconText.png',
	'book-offline-catalogo': 'https://cdn.booklick.net/public/img/icons/iconBook.png',
	booklist:'https://cdn.booklick.net/public/img/images/top-week-cover.png',
	BOOKLISTS: 'https://cdn.booklick.net/public/img/images/top-week-cover.png',
	links: 'https://cdn.booklick.net/public/img/icons/iconLink.png',
	link: 'https://cdn.booklick.net/public/img/icons/iconLink.png',
	document: 'https://cdn.booklick.net/public/img/icons/iconText.png',
	article: 'https://cdn.booklick.net/public/img/icons/iconArticle.png',
	physicalBook:
		'https://cdn.booklick.net/public/img/icons/iconPhysicalBook.png',
	book: 'https://cdn.booklick.net/public/img/icons/iconBook.png',
	1: 'https://cdn.booklick.net/public/img/icons/iconBook.png',
	2: 'https://cdn.booklick.net/public/img/icons/iconText.png',
	3: 'https://cdn.booklick.net/public/img/images/top-week-cover.png',
	4: 'https://cdn.booklick.net/public/img/icons/iconArticle.png',
	5: 'https://cdn.booklick.net/public/img/icons/iconBook.png',
	6: 'https://cdn.booklick.net/public/img/icons/iconPhysicalBook.png',
	7: 'https://cdn.booklick.net/public/img/icons/iconLink.png',
};

export const termsAndConditions = 'https://cdn.booklick.net/public/terms.pdf';

export const contentTypeNew= {
	BOOKS: {
		'label': 'Libro',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/Libro.svg'
	},
	BOOKLISTS: {
		'label': 'Booklist',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/Booklist.svg'
	},
	ARTICLES: {
		'label': 'Articulo',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/Articulo.svg'
	},
	DOCUMENTS: {
		'label': 'Documento',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/documento.svg'
	},
	OFFLINE_BOOKS:{
		'label': 'Libro fisico',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/libro-fisico.svg'},
	physicalBook:{
		'label': 'Libro fisico',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/libro-fisico.svg'
	},
	LINKS:{
		'label': 'Enlace',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/enlace.svg'},
	'book-offline-catalogo': {
		'label': 'Libro fisico',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/libro-fisico.svg'
	},
	links:{
		'label': 'Enlace',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/enlace.svg'
	},
	EXTERNAL_BOOKS: {
		'label': 'Libro externo',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/libro-fisico.svg'
	},
	book: {
		'label': 'Libro',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/Libro.svg'
	},
	booklist: {
		'label': 'Booklist',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/Booklist.svg'
	},
	article: {
		'label': 'Articulo',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/Articulo.svg'
	},
	document: {
		'label': 'Documento',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/documento.svg'
	},
	offline_book:{
		'label': 'Libro fisico',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/libro-fisico.svg'
	},
	offline_books:{
		'label': 'Libro fisico',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/libro-fisico.svg'
	},
	link:{
		'label': 'Enlace',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/enlace.svg'
	},
	external_book: {
		'label': 'Libro',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/libro-fisico.svg'
	},
	text: {
		'label': 'Texto',
		'imgUrl': 'https://cdn.booklick.net/public/img/icons/iconText.png'
	}
};

export const imagesDefaultError = [
	'https://cdn.booklick.net/public/img/images/luke-chesser.jpg',
	'https://cdn.booklick.net/public/img/images/gradienta.jpg'
];

export const ISO_369_1 = [
	{
	  'name': 'aa',
	  'label': 'Afar'
	},
	{
	  'name': 'ab',
	  'label': 'Abkhazian'
	},
	{
	  'name': 'af',
	  'label': 'Afrikaans'
	},
	{
	  'name': 'ak',
	  'label': 'Akan'
	},
	{
	  'name': 'sq',
	  'label': 'Albanian'
	},
	{
	  'name': 'am',
	  'label': 'Amharic'
	},
	{
	  'name': 'ar',
	  'label': 'Arabic'
	},
	{
	  'name': 'an',
	  'label': 'Aragonese'
	},
	{
	  'name': 'hy',
	  'label': 'Armenian'
	},
	{
	  'name': 'as',
	  'label': 'Assamese'
	},
	{
	  'name': 'av',
	  'label': 'Avaric'
	},
	{
	  'name': 'ae',
	  'label': 'Avestan'
	},
	{
	  'name': 'ay',
	  'label': 'Aymara'
	},
	{
	  'name': 'az',
	  'label': 'Azerbaijani'
	},
	{
	  'name': 'ba',
	  'label': 'Bashkir'
	},
	{
	  'name': 'bm',
	  'label': 'Bambara'
	},
	{
	  'name': 'eu',
	  'label': 'Basque'
	},
	{
	  'name': 'be',
	  'label': 'Belarusian'
	},
	{
	  'name': 'bn',
	  'label': 'Bengali'
	},
	{
	  'name': 'bh',
	  'label': 'Bihari languages'
	},
	{
	  'name': 'bi',
	  'label': 'Bislama'
	},
	{
	  'name': 'bo',
	  'label': 'Tibetan'
	},
	{
	  'name': 'bs',
	  'label': 'Bosnian'
	},
	{
	  'name': 'br',
	  'label': 'Breton'
	},
	{
	  'name': 'bg',
	  'label': 'Bulgarian'
	},
	{
	  'name': 'my',
	  'label': 'Burmese'
	},
	{
	  'name': 'ca',
	  'label': 'Catalan; Valencian'
	},
	{
	  'name': 'cs',
	  'label': 'Czech'
	},
	{
	  'name': 'ch',
	  'label': 'Chamorro'
	},
	{
	  'name': 'ce',
	  'label': 'Chechen'
	},
	{
	  'name': 'zh',
	  'label': 'Chinese'
	},
	{
	  'name': 'cu',
	  'label': 'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic'
	},
	{
	  'name': 'cv',
	  'label': 'Chuvash'
	},
	{
	  'name': 'kw',
	  'label': 'Cornish'
	},
	{
	  'name': 'co',
	  'label': 'Corsican'
	},
	{
	  'name': 'cr',
	  'label': 'Cree'
	},
	{
	  'name': 'cy',
	  'label': 'Welsh'
	},
	{
	  'name': 'cs',
	  'label': 'Czech'
	},
	{
	  'name': 'da',
	  'label': 'Danish'
	},
	{
	  'name': 'de',
	  'label': 'German'
	},
	{
	  'name': 'dv',
	  'label': 'Divehi; Dhivehi; Maldivian'
	},
	{
	  'name': 'nl',
	  'label': 'Dutch; Flemish'
	},
	{
	  'name': 'dz',
	  'label': 'Dzongkha'
	},
	{
	  'name': 'el',
	  'label': 'Greek, Modern (1453-)'
	},
	{
	  'name': 'en',
	  'label': 'English'
	},
	{
	  'name': 'eo',
	  'label': 'Esperanto'
	},
	{
	  'name': 'et',
	  'label': 'Estonian'
	},
	{
	  'name': 'eu',
	  'label': 'Basque'
	},
	{
	  'name': 'ee',
	  'label': 'Ewe'
	},
	{
	  'name': 'fo',
	  'label': 'Faroese'
	},
	{
	  'name': 'fa',
	  'label': 'Persian'
	},
	{
	  'name': 'fj',
	  'label': 'Fijian'
	},
	{
	  'name': 'fi',
	  'label': 'Finnish'
	},
	{
	  'name': 'fr',
	  'label': 'French'
	},
	{
	  'name': 'fr',
	  'label': 'French'
	},
	{
	  'name': 'fy',
	  'label': 'Western Frisian'
	},
	{
	  'name': 'ff',
	  'label': 'Fulah'
	},
	{
	  'name': 'ka',
	  'label': 'Georgian'
	},
	{
	  'name': 'de',
	  'label': 'German'
	},
	{
	  'name': 'gd',
	  'label': 'Gaelic; Scottish Gaelic'
	},
	{
	  'name': 'ga',
	  'label': 'Irish'
	},
	{
	  'name': 'gl',
	  'label': 'Galician'
	},
	{
	  'name': 'gv',
	  'label': 'Manx'
	},
	{
	  'name': 'el',
	  'label': 'Greek, Modern (1453-)'
	},
	{
	  'name': 'gn',
	  'label': 'Guarani'
	},
	{
	  'name': 'gu',
	  'label': 'Gujarati'
	},
	{
	  'name': 'ht',
	  'label': 'Haitian; Haitian Creole'
	},
	{
	  'name': 'ha',
	  'label': 'Hausa'
	},
	{
	  'name': 'he',
	  'label': 'Hebrew'
	},
	{
	  'name': 'hz',
	  'label': 'Herero'
	},
	{
	  'name': 'hi',
	  'label': 'Hindi'
	},
	{
	  'name': 'ho',
	  'label': 'Hiri Motu'
	},
	{
	  'name': 'hr',
	  'label': 'Croatian'
	},
	{
	  'name': 'hu',
	  'label': 'Hungarian'
	},
	{
	  'name': 'hy',
	  'label': 'Armenian'
	},
	{
	  'name': 'ig',
	  'label': 'Igbo'
	},
	{
	  'name': 'is',
	  'label': 'Icelandic'
	},
	{
	  'name': 'io',
	  'label': 'Ido'
	},
	{
	  'name': 'ii',
	  'label': 'Sichuan Yi; Nuosu'
	},
	{
	  'name': 'iu',
	  'label': 'Inuktitut'
	},
	{
	  'name': 'ie',
	  'label': 'Interlingue; Occidental'
	},
	{
	  'name': 'ia',
	  'label': 'Interlingua (International Auxiliary Language Association)'
	},
	{
	  'name': 'id',
	  'label': 'Indonesian'
	},
	{
	  'name': 'ik',
	  'label': 'Inupiaq'
	},
	{
	  'name': 'is',
	  'label': 'Icelandic'
	},
	{
	  'name': 'it',
	  'label': 'Italian'
	},
	{
	  'name': 'jv',
	  'label': 'Javanese'
	},
	{
	  'name': 'ja',
	  'label': 'Japanese'
	},
	{
	  'name': 'kl',
	  'label': 'Kalaallisut; Greenlandic'
	},
	{
	  'name': 'kn',
	  'label': 'Kannada'
	},
	{
	  'name': 'ks',
	  'label': 'Kashmiri'
	},
	{
	  'name': 'ka',
	  'label': 'Georgian'
	},
	{
	  'name': 'kr',
	  'label': 'Kanuri'
	},
	{
	  'name': 'kk',
	  'label': 'Kazakh'
	},
	{
	  'name': 'km',
	  'label': 'Central Khmer'
	},
	{
	  'name': 'ki',
	  'label': 'Kikuyu; Gikuyu'
	},
	{
	  'name': 'rw',
	  'label': 'Kinyarwanda'
	},
	{
	  'name': 'ky',
	  'label': 'Kirghiz; Kyrgyz'
	},
	{
	  'name': 'kv',
	  'label': 'Komi'
	},
	{
	  'name': 'kg',
	  'label': 'Kongo'
	},
	{
	  'name': 'ko',
	  'label': 'Korean'
	},
	{
	  'name': 'kj',
	  'label': 'Kuanyama; Kwanyama'
	},
	{
	  'name': 'ku',
	  'label': 'Kurdish'
	},
	{
	  'name': 'lo',
	  'label': 'Lao'
	},
	{
	  'name': 'la',
	  'label': 'Latin'
	},
	{
	  'name': 'lv',
	  'label': 'Latvian'
	},
	{
	  'name': 'li',
	  'label': 'Limburgan; Limburger; Limburgish'
	},
	{
	  'name': 'ln',
	  'label': 'Lingala'
	},
	{
	  'name': 'lt',
	  'label': 'Lithuanian'
	},
	{
	  'name': 'lb',
	  'label': 'Luxembourgish; Letzeburgesch'
	},
	{
	  'name': 'lu',
	  'label': 'Luba-Katanga'
	},
	{
	  'name': 'lg',
	  'label': 'Ganda'
	},
	{
	  'name': 'mk',
	  'label': 'Macedonian'
	},
	{
	  'name': 'mh',
	  'label': 'Marshallese'
	},
	{
	  'name': 'ml',
	  'label': 'Malayalam'
	},
	{
	  'name': 'mi',
	  'label': 'Maori'
	},
	{
	  'name': 'mr',
	  'label': 'Marathi'
	},
	{
	  'name': 'ms',
	  'label': 'Malay'
	},
	{
	  'name': 'mk',
	  'label': 'Macedonian'
	},
	{
	  'name': 'mg',
	  'label': 'Malagasy'
	},
	{
	  'name': 'mt',
	  'label': 'Maltese'
	},
	{
	  'name': 'mn',
	  'label': 'Mongolian'
	},
	{
	  'name': 'mi',
	  'label': 'Maori'
	},
	{
	  'name': 'ms',
	  'label': 'Malay'
	},
	{
	  'name': 'my',
	  'label': 'Burmese'
	},
	{
	  'name': 'na',
	  'label': 'Nauru'
	},
	{
	  'name': 'nv',
	  'label': 'Navajo; Navaho'
	},
	{
	  'name': 'nr',
	  'label': 'Ndebele, South; South Ndebele'
	},
	{
	  'name': 'nd',
	  'label': 'Ndebele, North; North Ndebele'
	},
	{
	  'name': 'ng',
	  'label': 'Ndonga'
	},
	{
	  'name': 'ne',
	  'label': 'Nepali'
	},
	{
	  'name': 'nl',
	  'label': 'Dutch; Flemish'
	},
	{
	  'name': 'nn',
	  'label': 'Norwegian Nynorsk; Nynorsk, Norwegian'
	},
	{
	  'name': 'nb',
	  'label': 'Bokmål, Norwegian; Norwegian Bokmål'
	},
	{
	  'name': 'no',
	  'label': 'Norwegian'
	},
	{
	  'name': 'ny',
	  'label': 'Chichewa; Chewa; Nyanja'
	},
	{
	  'name': 'oc',
	  'label': 'Occitan (post 1500)'
	},
	{
	  'name': 'oj',
	  'label': 'Ojibwa'
	},
	{
	  'name': 'or',
	  'label': 'Oriya'
	},
	{
	  'name': 'om',
	  'label': 'Oromo'
	},
	{
	  'name': 'os',
	  'label': 'Ossetian; Ossetic'
	},
	{
	  'name': 'pa',
	  'label': 'Panjabi; Punjabi'
	},
	{
	  'name': 'fa',
	  'label': 'Persian'
	},
	{
	  'name': 'pi',
	  'label': 'Pali'
	},
	{
	  'name': 'pl',
	  'label': 'Polish'
	},
	{
	  'name': 'pt',
	  'label': 'Portuguese'
	},
	{
	  'name': 'ps',
	  'label': 'Pushto; Pashto'
	},
	{
	  'name': 'qu',
	  'label': 'Quechua'
	},
	{
	  'name': 'rm',
	  'label': 'Romansh'
	},
	{
	  'name': 'ro',
	  'label': 'Romanian; Moldavian; Moldovan'
	},
	{
	  'name': 'ro',
	  'label': 'Romanian; Moldavian; Moldovan'
	},
	{
	  'name': 'rn',
	  'label': 'Rundi'
	},
	{
	  'name': 'ru',
	  'label': 'Russian'
	},
	{
	  'name': 'sg',
	  'label': 'Sango'
	},
	{
	  'name': 'sa',
	  'label': 'Sanskrit'
	},
	{
	  'name': 'si',
	  'label': 'Sinhala; Sinhalese'
	},
	{
	  'name': 'sk',
	  'label': 'Slovak'
	},
	{
	  'name': 'sk',
	  'label': 'Slovak'
	},
	{
	  'name': 'sl',
	  'label': 'Slovenian'
	},
	{
	  'name': 'se',
	  'label': 'Northern Sami'
	},
	{
	  'name': 'sm',
	  'label': 'Samoan'
	},
	{
	  'name': 'sn',
	  'label': 'Shona'
	},
	{
	  'name': 'sd',
	  'label': 'Sindhi'
	},
	{
	  'name': 'so',
	  'label': 'Somali'
	},
	{
	  'name': 'st',
	  'label': 'Sotho, Southern'
	},
	{
	  'name': 'es',
	  'label': 'Spanish; Castilian'
	},
	{
	  'name': 'sq',
	  'label': 'Albanian'
	},
	{
	  'name': 'sc',
	  'label': 'Sardinian'
	},
	{
	  'name': 'sr',
	  'label': 'Serbian'
	},
	{
	  'name': 'ss',
	  'label': 'Swati'
	},
	{
	  'name': 'su',
	  'label': 'Sundanese'
	},
	{
	  'name': 'sw',
	  'label': 'Swahili'
	},
	{
	  'name': 'sv',
	  'label': 'Swedish'
	},
	{
	  'name': 'ty',
	  'label': 'Tahitian'
	},
	{
	  'name': 'ta',
	  'label': 'Tamil'
	},
	{
	  'name': 'tt',
	  'label': 'Tatar'
	},
	{
	  'name': 'te',
	  'label': 'Telugu'
	},
	{
	  'name': 'tg',
	  'label': 'Tajik'
	},
	{
	  'name': 'tl',
	  'label': 'Tagalog'
	},
	{
	  'name': 'th',
	  'label': 'Thai'
	},
	{
	  'name': 'bo',
	  'label': 'Tibetan'
	},
	{
	  'name': 'ti',
	  'label': 'Tigrinya'
	},
	{
	  'name': 'to',
	  'label': 'Tonga (Tonga Islands)'
	},
	{
	  'name': 'tn',
	  'label': 'Tswana'
	},
	{
	  'name': 'ts',
	  'label': 'Tsonga'
	},
	{
	  'name': 'tk',
	  'label': 'Turkmen'
	},
	{
	  'name': 'tr',
	  'label': 'Turkish'
	},
	{
	  'name': 'tw',
	  'label': 'Twi'
	},
	{
	  'name': 'ug',
	  'label': 'Uighur; Uyghur'
	},
	{
	  'name': 'uk',
	  'label': 'Ukrainian'
	},
	{
	  'name': 'ur',
	  'label': 'Urdu'
	},
	{
	  'name': 'uz',
	  'label': 'Uzbek'
	},
	{
	  'name': 've',
	  'label': 'Venda'
	},
	{
	  'name': 'vi',
	  'label': 'Vietnamese'
	},
	{
	  'name': 'vo',
	  'label': 'Volapük'
	},
	{
	  'name': 'cy',
	  'label': 'Welsh'
	},
	{
	  'name': 'wa',
	  'label': 'Walloon'
	},
	{
	  'name': 'wo',
	  'label': 'Wolof'
	},
	{
	  'name': 'xh',
	  'label': 'Xhosa'
	},
	{
	  'name': 'yi',
	  'label': 'Yiddish'
	},
	{
	  'name': 'yo',
	  'label': 'Yoruba'
	},
	{
	  'name': 'za',
	  'label': 'Zhuang; Chuang'
	},
	{
	  'name': 'zh',
	  'label': 'Chinese'
	},
	{
	  'name': 'zu',
	  'label': 'Zulu'
	},
	{
	  "name":"undetermined",
	  "label":"No language"
	}
	
];

export const COLORS = {
    "global": {
        "primary": "#2ebdee",
        "secondary": "#19addf",
        "complementary": "#EF446D",
        "dark": "#000",
		"resources": {
            "defaultCover": "https://cdn.booklick.net/public/img/images/top-week-cover.png",
			"moreFill": "https://cdn.booklick.net/public/img/icons/more-fill-green.svg",
			"iconLink":"https://cdn.booklick.net/public/img/icons/icon-link-blue.svg",
			"iconFileUpload":"https://cdn.booklick.net/public/img/icons/icon-file-upload-blue.svg",
			"iconAdd":"https://cdn.booklick.net/public/img/icons/icon-add-blue.svg",
			"iconClose":"https://cdn.booklick.net/public/img/icons/IconCloseNew.svg",
			"iconDelete":"https://cdn.booklick.net/public/img/icons/icon-delete-blue.svg",
			"iconLikeActive": "https://cdn.booklick.net/public/img/icons/like-active.svg",
			"iconMoveSection":"https://cdn.booklick.net/public/img/icons/icon-mov-section-blue.svg",
			"iconFollowing": "https://cdn.booklick.net/public/img/icons/icon-following-blue.svg",
			"iconContentCopy": "https://cdn.booklick.net/public/img/icons/icon-content-copy.svg",
			"iconArrowLeft": "https://cdn.booklick.net/public/img/icons/IconArrowLeftBlue.svg",
			"iconEdit": "https://cdn.booklick.net/public/img/icons/icon-edit-blue.svg",
			"iconArrowDropDown": "https://cdn.booklick.net/public/img/icons/icon-arrow-drop-down-blue.svg",
			"iconArrowDropUp": "https://cdn.booklick.net/public/img/icons/icon-arrow-drop-up-blue.svg",
			"iconSuccess":"https://cdn.booklick.net/public/img/icons/success-icon-blue.svg",
        }
    },
    "udla": {
        "primary": "#991b30",
        "secondary": "#991b30",
        "complementary": "#808285",
        "dark": "#231f20",
		"resources": {
            "defaultCover": "https://cdn.booklick.net/public/img/images/cover-udla.png",
			"moreFill": "https://cdn.booklick.net/public/img/icons/udla/more-fill-green.svg",
			"iconLink":"https://cdn.booklick.net/public/img/icons/udla/icon-link.svg",
			"iconFileUpload":"https://cdn.booklick.net/public/img/icons/udla/icon-file-upload.svg",
			"iconAdd":"https://cdn.booklick.net/public/img/icons/udla/icon-add.svg",
			"iconDelete":"https://cdn.booklick.net/public/img/icons/udla/icon-delete.svg",
			"iconClose":"https://cdn.booklick.net/public/img/icons/udla/icon-close.svg",
			"iconLikeActive": "https://cdn.booklick.net/public/img/icons/udla/like-active.svg",
			"iconMoveSection":"https://cdn.booklick.net/public/img/icons/udla/icon-move-section.svg",
			"iconFollowing": "https://cdn.booklick.net/public/img/icons/udla/icon-following.svg",
			"iconContentCopy": "https://cdn.booklick.net/public/img/icons/udla/icon-content-copy.svg", 
			"iconArrowLeft": "https://cdn.booklick.net/public/img/icons/udla/icon-arrow-left.svg",
			"iconEdit": "https://cdn.booklick.net/public/img/icons/udla/icon-edit.svg",
			"iconArrowDropDown": "https://cdn.booklick.net/public/img/icons/udla/icon-arrow-drop-down.svg",
			"iconArrowDropUp": "https://cdn.booklick.net/public/img/icons/udla/icon-arrow-drop-up.svg",
			"iconSuccess":"https://cdn.booklick.net/public/img/icons/udla/success-icon.svg",
        }
    }
};

export function getResources(type) {
    if (type && COLORS[type] && COLORS[type].resources) {
        return COLORS[type].resources;
    } else {
        return COLORS.global.resources;
    }
}

export function transformUsersToPeople(originalArray) {
	const filteredArray = originalArray.filter(Boolean).filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
    return filteredArray.map(originalObject => {		
        return {
            id: originalObject.id,
            name: originalObject.name || `${originalObject.firstName} ${originalObject.lastName}`,
            email: originalObject.email,
            rol: originalObject.identifier || originalObject.rol,
            env: originalObject.env || null,
            mayor: originalObject.mayor || null,
            image: originalObject.profileImgUrl || originalObject.image || null
        };
	});
}

export const SOPORTE = 'soporte@booklick.co';

export const PROFILE_TABS = [
	{ key: 'CREATED_CONTENT', logo: 'content_copy' },
	{ key: 'COLABORATIVE_BOOKLISTS', logo: 'partner_exchange' },
	{ key: 'FOLLOWED_BOOKLISTS', logo: 'done' },
	{ key: 'LIKED_CONTENT', logo: 'thumb_up' },
	{ key: 'SETTINGS', logo: 'settings' },
	{ key: 'LOGOUT', logo: 'logout' },
];

export const PROFILE_TABS_MOBILE = [
	{ key: 'CREATED_CONTENT', logo: 'content_copy' },
	{ key: 'FOLLOWED_BOOKLISTS', logo: 'done' },
	{ key: 'LIKED_CONTENT', logo: 'thumb_up' },
	{ key: 'COLABORATIVE_BOOKLISTS', logo: 'partner_exchange' },
];

export const iconMoreInfo = 'https://cdn.booklick.net/public/img/icons/more-info.svg';

export const booklistsType = ['BOOKLISTS', 'booklist'];
export const CONTENT_TYPES = {
	books: {
		id: 1,
		name: 'BOOKS',
		label: 'Libro',
		imgUrl: 'https://cdn.booklick.net/public/img/icons/Libro.svg',
		translationKey: 'textSearchFilterBook',
	},
	booklists: {
		id: 3,
		name: 'BOOKLISTS',
		label: 'Booklist',
		imgUrl: 'https://cdn.booklick.net/public/img/icons/Booklist.svg',
		translationKey: 'textSearchFilterBooklist',
	},
	articles: {
		id: 4,
		name: 'ARTICLES',
		label: 'Articulo',
		imgUrl: 'https://cdn.booklick.net/public/img/icons/Articulo.svg',
		translationKey: 'textSearchFilterArticle',
	},
	external_books: {
		id: 5,
		name: 'EXTERNAL_BOOKS',
		label: 'Libro',
		imgUrl: 'https://cdn.booklick.net/public/img/icons/Libro.svg',
		translationKey: 'textSearchResultExternalBook',
	},
	documents: {
		id: 2,
		name: 'DOCUMENTS',
		label: 'Documento',
		imgUrl: 'https://cdn.booklick.net/public/img/icons/documento.svg',
		translationKey: 'textSearchFilterDocument',
	},
	offline_books: {
		id: 6,
		name: 'OFFLINE_BOOKS',
		label: 'Libro fisico',
		imgUrl: 'https://cdn.booklick.net/public/img/icons/libro-fisico.svg',
		translationKey: 'textSearchResultOfflineBook',
	},
	links: {
		id: 7,
		name: 'LINKS',
		label: 'Enlace',
		imgUrl: 'https://cdn.booklick.net/public/img/icons/enlace.svg',
		translationKey: 'textSearchFilterLink',
	},
};
