import {environment, PROFILE_TYPE} from '../client/actions';

export const openProfile = (email, id, loadingProfile, history, visitStats, refresh) => {
	if(refresh === true) {
		return (window.location = `${window.location.origin}/perfil/${environment}/${window.btoa(unescape(encodeURIComponent(email))).replace(/(=)/g, '')}`)
	}
	history.push(`/perfil/${environment}/${window.btoa(unescape(encodeURIComponent(email))).replace(/(=)/g, '')}`);

};

export const openProfileNew = (email) => {
	return (window.location = `${
		window.location.origin
	}/perfil/${environment}/${window
		.btoa(unescape(encodeURIComponent(email)))
		.replace(/(=)/g, '')}`);
};