import React, { Component } from 'react';
import {
	visitStats,
	getUserBooklist,
	fetchMyBooklists,
	postContentBooklist,
	addContent,
	deleteBooklist,
	fetchBooklistPermissions,
	updateContentPreview,
	postRedirectContent,
	openPopUp,
	setPopUpShare,
	environment
} from '../../actions';
import Cognito from '../../../helpers/cognito';
import styles from './styles/contextMenu.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	FacebookShareButton,
	WhatsappShareButton,
	LinkedinShareButton,
	EmailShareButton
} from 'react-share';
import PopUpCreate from '../create-source/PopUpCreate';
import { withTranslation } from 'react-i18next';

import {
	sectionDefault,
	breakPoint,
	windowToShare,
	getResources
} from '../../../utils/constans';
import SubMenuBooklist from './SubMenuBooklist';

const iconAdd = 'https://cdn.booklick.net/public/img/icons/add-circle-line-gray.svg';
const booklistType = 'booklists';
const booklistTypeActivity = 'booklist';
const booklistNumberType = 3;
const offLineBookType = 'OFFLINE_BOOKS';
const emojiImage = 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/118/smiling-face-with-open-mouth-and-cold-sweat_1f605.png';
const iconDelete = 'https://cdn.booklick.net/public/img/icons/delete-red.svg';
const fileIconWhite = 'https://cdn.booklick.net/public/img/icons/icon-file-white.svg';
const fileIconBlack = 'https://cdn.booklick.net/public/img/icons/icon-file-black.svg';
const successDelete = 'https://cdn.booklick.net/public/img/icons/checkbox-circle-red.svg';
const delayUpdateProfile = 2000;
const showDeleting = 3000;
const confirmDeleteStep = 0;
const minLengthBooklists = 0;
const deletingContentStep = 1;
const deleteCompletedStep = 2;
const deleteError = 3;
const editPositonPopUp = 5;
const fullViewMessage = 'Vista Completa';
const editPermission = 'edit';
const iconMoreWhite = 'https://cdn.booklick.net/public/img/icons/more-fill-white.svg';
const iconScrew = 'https://cdn.booklick.net/public/img/icons/screw.svg'
const colorWhite = 'white';

class ContextMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			item: this.props.item,
			logged: false,
			copied: this.props.t('textContexMenuShareLink'),
			openMenu: false,
			openMenuBooklist: false,
			openSubMenuMyBooklist: false,
			openSubMenuBooklistPermissions: false,
			openMenuShare: false,
			openMenuSocialMedia: false,
			openMenuSection: false,
			openMenuDelete: false,
			booklists: [],
			hasNextPage: false,
			booklistPermissions: [],
			fullView: '',
			booklist: '',
			popupCreate: false,
			positionPopup: editPositonPopUp,
			data: null,
			popUpDelete: false,
			deleteStep: confirmDeleteStep,
			isBooklist: false,
			loading: false
		};

		this.openMenu = this.openMenu.bind(this);
		this.openMenuBooklist = this.openMenuBooklist.bind(this);
		this.openSubMenuMyBooklist = this.openSubMenuMyBooklist.bind(this);
		this.openSubMenuBooklistPermissions = this.openSubMenuBooklistPermissions.bind(this);
		this.openMenuShare = this.openMenuShare.bind(this);
		this.openMenuSocialMedia = this.openMenuSocialMedia.bind(this);
		this.closeAll = this.closeAll.bind(this);
		this.getUrlPreview = this.getUrlPreview.bind(this);
		this.handleOnClickCopyLink = this.handleOnClickCopyLink.bind(this);
		this.openCreatePopup = this.openCreatePopup.bind(this);
		this.closePopup = this.closePopup.bind(this);
		this.handleClosePopUp = this.handleClosePopUp.bind(this);
		this.handleDeletePopup = this.handleDeletePopup.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	isBooklist = () => {
		let isBooklist = false;
		let contentType = this.state.item.type
		if (contentType) {
			if (typeof contentType === 'string') {
				isBooklist = (contentType.toLowerCase() === booklistType ||
					contentType.toLowerCase() === booklistTypeActivity)
			} else {
				isBooklist = (contentType === booklistNumberType)
			}
		}
		this.setState({
			isBooklist
		})
	}

	fetchData = async () => {
		try {
			this.setState({ logged: true });

			this.setState({
				loading: true
			});

			await Promise.all([
				this.isBooklist ? await this.props.fetchMyBooklists({ booklistId: this.state.item.info.id }) : this.props.fetchMyBooklists(),
				await this.props.fetchBooklistPermissions()
			])

			this.setState({
				booklists: this.props.contentList.myBooklists.booklists || null,
				hasNextPage: this.props.contentList.myBooklists.hasNextPage || false
			});
			this.setState({
				booklistPermissions: this.props.contentList.booklistPermissions ? this.props.contentList.booklistPermissions.filter((booklist) => booklist.permissions === editPermission) : []
			});
			this.setState({
				loading: false
			});

		} catch (error) {
			console.error(error);

		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.contentList !== this.props.contentList) {
			this.setState({
				booklists: this.props.contentList.myBooklists ? this.props.contentList.myBooklists.booklists : null,
				hasNextPage: this.props.contentList.myBooklists ? this.props.contentList.myBooklists.hasNextPage : false
			});
			this.setState({
				booklistPermissions: this.props.contentList.booklistPermissions ?
					this.props.contentList.booklistPermissions.filter((booklist) => booklist.permissions === editPermission)
					:
					[]
			});
		}
		if (prevProps.item !== this.props.item) {
			this.setState({
				item: this.props.item,
			});
		}
	}

	async openMenu() {
		try {
			const session = await Cognito.retrieveToken();
			this.setState({ logged: true });
			this.isBooklist();
			this.fetchData();
			this.setState({ openMenu: !this.state.openMenu });
		} catch (error) {
			this.props.openPopUp(true);
		}
	}

	openMenuBooklist() {
		this.setState({ openMenuBooklist: !this.state.openMenuBooklist });
	}

	openMenuShare() {
		if (window.innerWidth > breakPoint.table) {
			this.openPopUpShare()
		} else {
			this.setState({ openMenuShare: !this.state.openMenuShare });
		}
	}

	openSubMenuMyBooklist() {
		this.setState({ openSubMenuMyBooklist: !this.state.openSubMenuMyBooklist })
	}

	openSubMenuBooklistPermissions() {
		this.setState({ openSubMenuBooklistPermissions: !this.state.openSubMenuBooklistPermissions })
	}

	async openMenuSocialMedia() {
		await this.getUrlPreview();
		this.setState({ openMenuSocialMedia: !this.state.openMenuSocialMedia });
	}

	openCreatePopup() {
		this.setState({ popupCreate: !this.state.popupCreate });
	};

	closePopup(name, value) {
		this.setState({
			[name]: value,
			popUpBooklist: false,
		});
	};

	handleClosePopUp() {
		this.setState({
			popupCreate: false,
			popUpBooklist: false
		});
	}

	closeAll() {
		this.setState({
			openMenu: false,
			openMenuBooklist: false,
			openSubMenuMyBooklist: false,
			openSubMenuBooklistPermissions: false,
			openMenuShare: false,
			openMenuSocialMedia: false,
			openMenuSection: false,
			copied: this.props.t('textContextMenuShareCopyLink'),
			fullView: fullViewMessage
		});
	}

	openPopUpShare = async () => {
		let url;
		if (!this.state.isBooklist) {
			if (this.props.isContent === 0) {
				try {
					let result = await addContent(this.state.item, this.state.item.attributes, this.state.item.type);
					url = `${window.location.origin}/preview/${result.data.data.slug}`;
				} catch (error) {
					url = `${window.location.origin}/preview/${this.state.item.info.slug}`;
				}
			} else {
				url = `${window.location.origin}/preview/${this.state.item.info.slug}`;
			}
		} else {
			url = `${window.location.origin}/booklist/${this.state.item.info.slug}`;
		}
		this.props.setPopUpShare({
			...this.state.item.info,
			url,
		}, true)
	}

	openMenuSection = () => {
		this.setState({
			openMenuSection: true
		})
	}

	handleAddToSection = async (section) => {
		await this.props.handleAddToSection(section)
		this.closeAll()
	}

	handleDeleteFromBooklist = () => {
		this.props.handleDeleteFromBooklist()
		this.closeAll()

	}
	handleGoToContent = async () => {
		this.setState({
			fullView: 'Redireccionando'
		});
		if (!this.state.isBooklist) {
			if (this.props.isContent === 0) {
				try {
					let result = await addContent(this.state.item, this.state.item.attributes, this.state.item.type);
					this.closeAll();
					this.props.visitStats(result.data.data.slug, this.state.item.type);
					window.location = `${window.location.origin}/preview/${result.data.data.slug}`;
				} catch (error) {
					this.closeAll();
					this.props.visitStats(this.state.item.info.slug, this.state.item.type);
					window.location = `${window.location.origin}/preview/${this.state.item.info.slug}`;
				}
			} else {
				this.closeAll();
				this.props.updateContentPreview({
					...this.state.item.info,
				})
				this.props.visitStats(this.state.item.info.slug, this.state.item.type);
				this.props.history.push(`/preview/${this.state.item.info.slug}`)
			}
		} else {
			this.closeAll();
			this.props.postRedirectContent(this.state.item.info.id);
			this.props.visitStats(this.state.item.info.slug, this.state.item.type);
			window.location = `${window.location.origin}/booklist/${this.state.item.info.slug}`;
		}

	}

	handleAddToBooklist = async (booklist) => {
		if (!this.state.isBooklist) {
			try {
				if (this.props.search) {
					this.props.handleAddToBooklist()
				}
				this.setState({
					booklist: `${booklist.id}`
				});
				let result = await addContent(this.state.item, this.state.item.attributes, this.state.item.type);
				try {
					await this.props.postContentBooklist(result.data.data.slug, booklist.id);
					window.location = `${window.location.origin}/booklist/${booklist.attributes.slug}`;
				} catch (error) {
					await this.props.postContentBooklist(this.state.item.info.slug, booklist.id);
					window.location = `${window.location.origin}/booklist/${booklist.attributes.slug}`;
				}
			} catch (error) {
				this.setState({
					booklist: `${booklist.id}`
				});
				await this.props.postContentBooklist(this.state.item.info.slug, booklist.id);
				window.location = `${window.location.origin}/booklist/${booklist.attributes.slug}`;
			}
		}
		else {
			this.setState({
				booklist: `${booklist.id}`
			});
			await this.props.postContentBooklist(this.state.item.info.slug, booklist.id);
			window.location = `${window.location.origin}/booklist/${booklist.attributes.slug}`;
		}
	}


	handleAddToNewBooklist = async () => {
		let content = {
			id: this.state.item.info.id,
			type: this.state.item.type,
			attributes: {
				author: this.state.item.info.author,
				title: this.state.item.info.title,
				slug: this.state.item.info.slug,
				image: this.state.item.info.image,
				description: this.state.item.info.description,
				type: this.state.item.type
			}
		}
		if (!this.state.isBooklist) {
			if (this.props.search) {
				this.props.handleAddToBooklist()
			}
			await addContent(this.state.item, this.state.item.attributes, this.state.item.type);
			this.setState({
				positionPopup: 3,
				popUpBooklist: true,
				contentNewBooklist: content,
			})
		} else {
			this.setState({
				positionPopup: 3,
				popUpBooklist: true,
				contentNewBooklist: content,
			})
		}
	}

	handleDeletePopup() {
		if (this.state.deleteStep !== deletingContentStep) {
			this.setState({
				popUpDelete: !this.state.popUpDelete,
				deleteStep: confirmDeleteStep
			})
		}
	}

	async handleDelete() {
		this.setState({
			deleteStep: deletingContentStep
		});
		setTimeout(async () => {
			try {
				this.setState({
					deleteStep: deleteCompletedStep
				})
				setTimeout(
					async () => {
						await this.props.deleteBooklist(this.state.item.info.slug)
						this.setState({
							popUpDelete: false,
						})
					}, delayUpdateProfile);

			} catch {
				this.setState({
					deleteStep: deleteError
				})
			}
		}, showDeleting);
	}

	noLoggedView(t) {
		if (this.props.color === 'white') {
			return (
				<div className={styles.gridAutoDrop}>
					<img className={styles.icon16} src={fileIconBlack} alt="file" />

					<h3 className={styles.h3drop}
						onClick={async () => {
							if (!this.state.isBooklist) {
								if (this.props.isContent === 0) {
									this.closeAll();
									window.open(
										this.state.item.attributes.reader ?
											`${this.state.item.attributes.reader}`
											:
											`${this.state.item.info.slug}`, '_blank');
									this.props.visitStats(this.state.item.id, this.state.item.type);
								} else {
									this.closeAll();
									if (this.props.booklistPreview === true) {
										this.props.visitStats(this.state.item.id, this.state.item.type);
										window.location = `${window.location.origin}/booklist/${this.state.item.id}`;
									} else {
										this.props.visitStats(this.state.item.id, this.state.item.type);
										window.location = `${window.location.origin}/preview/${this.state.item.id}`;
									}
								}
							}
							else {
								this.closeAll();
								this.props.visitStats(this.state.item.info.slug, this.state.item.type);
								window.location = `${window.location.origin}/booklist/${this.state.item.info.slug}`;
							}
						}}>{this.props.t('fullView')}</h3>
				</div>
			);

		} else {
			return (
				<div className={styles.gridAutoDropGreen}>
					<img className={styles.icon16} src={fileIconWhite} alt="file" />
					<h3 className={styles.h3dropGreen}
						onClick={async () => {
							if (!this.state.isBooklist) {
								if (this.props.isContent === 0) {
									this.closeAll();
									window.open(
										this.state.item.attributes.reader ?
											`${this.state.item.attributes.reader}`
											:
											`${this.state.item.info.slug}`, '_blank');
									this.props.visitStats(this.state.item.id, this.state.item.type);
								} else {
									this.closeAll();
									this.props.visitStats(this.state.item.id, this.state.item.type);
									window.location = `${window.location.origin}/preview/${this.state.item.id}`;
								}
							}
							else {
								this.closeAll();
								this.props.visitStats(this.state.item.info.slug, this.state.item.type);
								window.location = `${window.location.origin}/booklist/${this.state.item.info.slug}`;
							}
						}
						}
					>{this.props.t('fullView')}</h3>
				</div>
			);

		}
	}

	editPopup(t) {
		if (this.props.color === 'white') {
			return (
				<div className={styles.gridAutoDrop} onClick={this.openCreatePopup}>
					<img className={styles.icon16} src="https://cdn.booklick.net/public/img/icons/edit-line-white.svg" alt="file" />

					<h3 className={styles.h3drop}>{this.props.t('textContextMenuEdit')}</h3>
				</div>
			);

		} else {
			return (
				<div className={styles.gridAutoDropGreen} onClick={this.openCreatePopup} datatest="buttom-content-edit">
					<img className={styles.icon16} src="https://cdn.booklick.net/public/img/icons/edit-line-white.svg" alt="file" />
					<h3 className={styles.h3dropGreen}>{this.props.t('textContextMenuEdit')}</h3>
				</div>
			);
		}

	}

	deleteContent(t) {
		if (this.props.color === 'white') {
			return (
				<div className={styles.gridAutoDrop} onClick={() => this.props.deleteBooklist(this.state.item.info.slug)}>
					<img className={styles.icon16} src="https://cdn.booklick.net/public/img/icons/icon-delete-blue-light.svg" alt="file" />

					<h3 className={styles.h3drop}>{this.props.t('textContextMenuDelete')}</h3>
				</div>
			);

		} else {
			return (
				<div className={styles.gridAutoDropGreen} datatest="button-delete" onClick={() => {
					this.handleDeletePopup();
					this.closeAll();
				}}>
					<img className={styles.icon16} src="https://cdn.booklick.net/public/img/icons/icon-delete-white.svg" alt="file" />
					<h3 className={styles.h3dropGreen}>{this.props.t('textContextMenuDelete')}</h3>
				</div>
			);
		}

	}

	fullView(t) {
		if (this.props.color === 'white') {
			return (
				<div className={styles.gridAutoDrop}>
					<img className={styles.icon16} src="https://cdn.booklick.net/public/img/icons/icon-file-black.svg" alt="file" />
					{this.state.fullView === 'Redireccionando' ?
						<div className={styles.containerCenter}>
							<h3 className={`${styles.h3drop} ${styles.h3dropPadding}`} >{this.props.t('fullView')}</h3>
							<div className={`${styles.iconSpinnerBlack} ${styles.iconSpinnerFont}`}></div>
						</div>
						:
						<h3 className={styles.h3drop}
							onClick={this.handleGoToContent}>{this.props.t('fullView')}</h3>}
				</div>
			);
		}
		else {
			return (
				<div className={styles.gridAutoDropGreen}>
					<img className={styles.icon16} src="https://cdn.booklick.net/public/img/icons/icon-file-white.svg" alt="file" />
					{this.state.fullView === 'Redireccionando' ?
						<div className={styles.containerCenter}>
							<h3 className={`${styles.h3dropGreen} ${styles.h3dropPadding}`}>{this.props.t('fullView')}</h3>
							<div className={`${styles.iconSpinnerWhite} ${styles.iconSpinnerFont}`} />
						</div>
						:
						<h3 className={styles.h3dropGreen} onClick={this.handleGoToContent}
						>{this.props.t('fullView')}</h3>
					}
				</div>
			);
		}

	}

	addToBooklist(t) {
		if (this.props.color === 'white') {
			return (
				<div className={
					this.state.openMenuBooklist ?
						`${styles.gridAutoDrop} ${styles.activeSub}`
						:
						`${styles.gridAutoDrop}`
				}> <div className={styles.containerCenterGap}
					onClick={this.openMenuBooklist}>
						<img className={styles.icon20} src="https://cdn.booklick.net/public/img/icons/icon-booklist-new-black.svg" alt="booklist" />
						<h3 className={styles.h3drop}>{this.props.t('addToBooklist')}</h3>
					</div>

					{
						this.state.openMenuBooklist ?
							<div className={styles.closeSub} onClick={this.closeAll}></div>
							:
							undefined
					}

					{
						this.state.openMenuBooklist ?
							<div className={styles.posSubBooklist}>
								<div className={`${styles.boxSub} ${styles.scrollSuBBooklist}`}>
									{this.state.loading &&
										(<div className={styles.containerCenter}>
											<div className={styles.spinnerBlackCenter}></div>
										</div>)}

									{this.state.booklistPermissions.length === minLengthBooklists
										&& !this.state.loading
										&& (
											<SubMenuBooklist {...this} />
										)
									}

								</div>

								{this.state.booklistPermissions.length !== minLengthBooklists && !this.state.loading && this.subMenuBooklist(t)}

								{!this.state.loading && (
									<div className={styles.gridAddNewBooklist}>
										<div className={styles.containerCenter}>
											<h3 className={`${styles.h3sub} `} onClick={() => this.handleAddToNewBooklist()}>
												<img className={styles.icon15} src={iconAdd} /> {this.props.t('textContextMenuNewBooklist')}
											</h3>
										</div>
									</div>
								)
								}

							</div>
							:
							undefined
					}
				</div>
			);
		}
		else {
			return (
				<div className={this.state.openMenuBooklist ?
					`${styles.gridAutoDropGreen} ${styles.activeSubGreen}`
					:
					this.props.search ?
						`${styles.gridAutoDropGreen} ${styles.deleteLastBorder}`
						:
						`${styles.gridAutoDropGreen}`}>
					<div className={styles.containerCenterGap}
						onClick={this.openMenuBooklist}>
						<img className={styles.icon20} src="https://cdn.booklick.net/public/img/icons/icon-booklist-new-white.svg" alt="booklist" />
						<h3 className={styles.h3dropGreen} >{this.props.t('addToBooklist')}</h3>
					</div>

					{
						this.state.openMenuBooklist ?
							<div className={styles.closeSub} onClick={this.closeAll}></div>
							:
							undefined
					}

					{
						this.state.openMenuBooklist ?
							<div className={styles.posSubBooklist}>
								<div className={`${styles.boxSub} ${styles.scrollSuBBooklist}`}>
									{this.state.loading &&
										(<div className={styles.containerCenter}>
											<div className={styles.spinnerBlackCenter}></div>
										</div>)}
									{this.state.booklistPermissions.length === minLengthBooklists
										&& !this.state.loading && (
											<SubMenuBooklist {...this} />
										)
									}
								</div>

								{this.state.booklistPermissions.length !== minLengthBooklists && !this.state.loading && this.subMenuBooklist(t)}

								{!this.state.loading && (
									<div className={styles.gridAddNewBooklist}>
										<div className={styles.containerCenter}>
											<h3 className={`${styles.h3sub}`}
												onClick={() => this.handleAddToNewBooklist()}
											><img className={styles.icon15} src={iconAdd} />{this.props.t('textContextMenuNewBooklist')}</h3>
										</div>
									</div>
								)
								}

							</div> :
							undefined
					}
				</div>
			);
		}

	}


	addToSection(t) {
		if (this.props.color === 'green') {
			return (
				<div className={this.state.openMenuSection ?
					`${styles.gridAutoDropGreen} ${styles.activeSubGreen}`
					:
					`${styles.gridAutoDropGreen}`}>
					<div className={styles.containerCenterGap}
						onClick={this.openMenuSection}>
						<img className={styles.icon20} src="https://cdn.booklick.net/public/img/icons/add-circle-white.svg" alt="booklist" />
						<h3 className={styles.h3dropGreen}>{this.props.t('textContextMenuAddSection')}</h3>
					</div>

					{
						this.state.openMenuSection ?
							<div className={styles.closeSub} onClick={this.closeAll}></div>
							:
							undefined
					}

					{
						this.state.openMenuSection ?
							<div className={styles.posSubBooklist}>
								<div className={styles.boxSub}>
									{this.props.sections.map((section) => {
										if (section.id !== sectionDefault.id) {
											return (
												<div key={section.id} className={styles.gridSub}>
													<h3
														className={styles.h3sub}
														onClick={() => this.handleAddToSection(section)}
													>{section.section}</h3>
												</div>
											);
										}
									})}
								</div>
							</div>
							:
							undefined
					}
				</div>
			);
		}
	}

	deleteFromBooklist(t) {
		if (this.props.color === 'green') {
			return (
				<div className={this.state.openMenuDelete ?
					`${styles.gridAutoDrop} ${styles.activeSub} ${styles.deleteLastBorder} ${styles.radiusActive} ${styles.boderTop}`
					:
					`${styles.gridAutoDrop} ${styles.deleteLastBorder} ${styles.boderTop}`}>
					<div className={styles.containerCenterGap}
						onClick={this.handleDeleteFromBooklist}>
						<img className={styles.icon20} src="https://cdn.booklick.net/public/img/icons/icon-delete-white.svg" alt="booklist" />
						<h3 className={styles.h3dropGreen}>{this.props.t('textContextMenuDeleteFromBooklist')}</h3>
					</div>
				</div>
			);
		}
	}




	share(t) {
		if (this.props.color === 'white') {
			return (
				<>
					<div className={this.state.openMenuShare ?
						`${styles.gridAutoDrop} ${styles.activeSub} ${styles.deleteLastBorder} ${styles.radiusActive}`
						: `${styles.gridAutoDrop} ${styles.deleteLastBorder}`} onClick={this.openMenuShare}>
						<img className={styles.icon16} src="https://cdn.booklick.net/public/img/icons/icon-share-circle-black.svg" alt="share" />
						<h3 className={styles.h3drop}>{this.props.t('buttonShare')}</h3>
					</div>

					{
						this.state.openMenuShare ?
							<div className={styles.closeSub} onClick={this.closeAll}></div>
							:
							undefined
					}

					{
						this.state.openMenuShare ?
							<div className={
								this.props.fullView ?
									`${styles.posSub} ${this.state.logged ? undefined : styles.posSubLogged}`
									:
									`${styles.posSubNoView} ${this.state.logged ? undefined : styles.posSubLogged}`
							}>
								<div className={styles.boxSub}>
									<div className={`${styles.gridSub} ${styles.gridSubPointer}`}
										onClick={this.handleOnClickCopyLink}
									>
										<h3 className={styles.h3sub}>{this.state.copied}</h3>
									</div>
									{this.subMenuShare()}

								</div>
							</div> :
							undefined
					}
				</>
			);
		}
		else {
			return (
				<>
					<div className={this.state.openMenuShare ?
						`${styles.gridAutoDropGreen} ${styles.activeSubGreen} ${styles.deleteLastBorder} ${styles.radiusActive}`
						: `${styles.gridAutoDropGreen} ${styles.deleteLastBorder}`} onClick={this.openMenuShare}>
						<img className={styles.icon16} src="https://cdn.booklick.net/public/img/icons/icon-share-circle-white.svg" alt="share" />
						<h3 className={styles.h3dropGreen}>{this.props.t('buttonShare')}</h3>
					</div>

					{
						this.state.openMenuShare ?
							<div className={styles.closeSub} onClick={this.closeAll}></div>
							:
							undefined
					}

					{
						this.state.openMenuShare ?
							<div className={
								this.props.fullView ?
									`${styles.posSub} ${this.state.logged ? undefined : styles.posSubLogged}`
									:
									`${styles.posSubNoView} ${this.state.logged ? undefined : styles.posSubLogged}`
							}>
								<div className={styles.boxSub}>
									<div className={styles.gridSub}
										onClick={this.handleOnClickCopyLink}
									>
										<h3 className={styles.h3sub}>{this.state.copied}</h3>
									</div>
									{this.subMenuShare()}

								</div>
							</div> :
							undefined
					}
				</>
			);
		}
	}

	async handleOnClickCopyLink() {
		await this.getUrlPreview();
		navigator.clipboard.writeText(this.state.urlPreview);
		this.setState({ copied: this.props.t('textCopy') });
	}

	subMenuBooklist(t) {

		return (
			<>
				<div className={`${styles.gridSub} ${styles.deleteLastBorder} ${styles.gridSubPointer}`}>
					<h3 className={styles.h3sub} onClick={this.openSubMenuMyBooklist}>{this.props.t('textContextMySubMenuBooklist')}</h3>
				</div>

				{
					this.state.openSubMenuMyBooklist ?
						<div className={styles.closeSub} onClick={this.closeAll}></div>
						:
						undefined
				}

				{
					this.state.openSubMenuMyBooklist && (
						<div className={styles.posSubMenuBooklist}>
							<SubMenuBooklist {...this} />
						</div> 
					)
				}
				<div className={`${styles.gridSub} ${styles.deleteLastBorder} ${styles.gridSubPointer}`}>
					<h3 className={styles.h3sub} onClick={this.openSubMenuBooklistPermissions}>{this.props.t('textMyBooklistCollabote')}</h3>
				</div>
				{
					this.state.openSubMenuBooklistPermissions ?
						<div className={styles.closeSub} onClick={this.closeAll}></div>
						:
						undefined
				}

				{
					this.state.openSubMenuBooklistPermissions && (
						<div className={styles.posSubMenuBooklist}>
							<SubMenuBooklist {...this} isBooklistColaborative={true} />
						</div> 
					)
				}
			</>
		);
	}

	subMenuShare() {

		return (
			<>
				<div className={`${styles.gridSub} ${styles.deleteLastBorder} ${styles.gridSubPointer}`}>
					<h3 className={styles.h3sub} onClick={this.openMenuSocialMedia}>{this.props.t('textContextMenuShareSocial')}</h3>
				</div>

				{
					this.state.openMenuSocialMedia ?
						<div className={styles.closeSub} onClick={this.closeAll}></div>
						:
						undefined
				}

				{
					this.state.openMenuSocialMedia ?
						<div className={styles.posSubShare}>
							<div className={`${styles.boxSub} ${styles.boxSubMinWidth}`}>
								<div className={styles.gridSub} >
									{environment != 'eur' && (
										<FacebookShareButton
											url={this.state.urlPreview}
											quote={this.state.item.info.title}
											windowWidth={windowToShare.width}
											windowHeight={windowToShare.height}
											className={`${styles.h3sub} ${styles.h3subContainer}`}
										>
											<img className={styles.icon20} src="https://cdn.booklick.net/public/img/icons/facebook-circle-fill.svg" alt="facebook" />
											<h3 className={`${styles.h3sub} ${styles.h3SubMargin}`}>Facebook</h3>
										</FacebookShareButton>
									)}

								</div>
								<div className={styles.gridSub} >
									<WhatsappShareButton
										url={this.state.urlPreview}
										quote={this.state.item.info.title}
										windowWidth={windowToShare.width}
										windowHeight={windowToShare.height}
										separator=":: "
										className={`${styles.h3sub} ${styles.h3subContainer}`}
									>
										<img className={styles.icon20} src="https://cdn.booklick.net/public/img/icons/whatsapp-fill.svg" alt="whatsapp" />
										<h3 className={`${styles.h3sub} ${styles.h3SubMargin}`}>Whatsapp</h3>
									</WhatsappShareButton>
								</div>
								<div className={styles.gridSub} >
									<EmailShareButton
										url={this.state.urlPreview}
										subject={this.state.item.info.title}
										body="Un contenido que encontré en Booklick para tí"
										className={`${styles.h3sub} ${styles.h3subContainer}`}
									>
										<img className={styles.icon20} src="https://cdn.booklick.net/public/img/icons/mail-fill.svg" alt="email" />
										<h3 className={`${styles.h3sub} ${styles.h3SubMargin}`}>{this.props.t('textEmail')}</h3>
									</EmailShareButton>
								</div>
							</div>
						</div> :
						undefined
				}
			</>
		);
	}

	async getUrlPreview() {
		if (!this.state.isBooklist) {
			if (this.props.isContent === 0) {
				try {
					let result = await addContent(this.state.item, this.state.item.attributes, this.state.item.type);
					this.setState({
						urlPreview: `${window.location.origin}/preview/${result.data.data.slug}`,
					});
				} catch (error) {
					this.setState({
						urlPreview: `${window.location.origin}/preview/${this.state.item.info.slug}`,
					});
				}
			} else {
				this.setState({
					urlPreview: `${window.location.origin}/preview/${this.state.item.id}`,
				});
			}
		} else {
			this.setState({
				urlPreview: `${window.location.origin}/booklist/${this.state.item.info.slug}`,
			});
		}
	}

	handleIcon = () => {
		if (this.props.icon) {
			return this.props.icon
		} else {
			if (this.props.booklistResponsive) {
				return iconScrew
			} else {
				if (this.props.color === colorWhite) {
					return iconMoreWhite
				} else {
					return getResources(environment).moreFill
				}
			}
		}
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<div>
					{
						this.state.openMenu ?
							<div className={styles.closeDrop} onClick={this.closeAll}></div>
							:
							undefined
					}

					<div>
						<img
							onClick={this.openMenu}
							datatest="buttom-edit"
							className={this.props.booklistResponsive || this.props.exploration ?
								this.props.iconSize ?
									styles.icon23
									:
									styles.icon20
								:
								styles.icon28More}
							src={this.handleIcon()}
							alt="more" />
						{
							this.state.openMenu && !this.props.exploration ?
								<div className={this.props.color === colorWhite ? styles.posDrop : styles.posDropGreen}>
									<div className={this.props.color === colorWhite ? styles.boxDrop : styles.boxDropGreen}>
										{
											this.state.logged ?
												this.props.fullView ?
													this.fullView(t)
													:
													undefined
												:
												this.noLoggedView(t)
										}

										{
											this.state.logged ?
												this.props.edit ?
													this.editPopup(t)
													:
													undefined
												:
												undefined
										}

										{
											this.state.logged ?
												this.props.addToBooklist ?
													this.addToBooklist(t)
													:
													undefined
												:
												undefined
										}
										{
											this.state.logged ?
												this.props.sections ?
													this.addToSection(t)
													:
													undefined
												:
												undefined
										}

										{
											this.state.logged ?
												this.props.deleteContent ?
													this.deleteContent(t)
													:
													undefined
												:
												undefined
										}


										{
											this.props.share ?
												this.share(t)
												:
												undefined
										}
										{
											this.state.logged ?
												this.props.delete ?
													this.deleteFromBooklist(t)
													:
													undefined
												:
												undefined
										}
									</div>
								</div>

								:
								this.state.openMenu && this.props.exploration ?
									<div className={`${this.props.color === colorWhite ? styles.posDrop : styles.posDropGreen} ${styles.addMenu}`}>
										<div className={this.props.color === colorWhite ? styles.boxDrop : styles.boxDropGreen}>
											{this.state.logged ?
												this.props.fullView ?
													this.fullView()
													:
													undefined
												:
												this.noLoggedView()
											}
											{this.state.openMenu && this.props.share ?
												<>
													{
														this.state.openMenu ?
															<div className={styles.closeSub} onClick={this.closeAll}></div>
															:
															undefined
													}

													{
														this.state.openMenu ?
															<div className={
																`${styles.posSubNoView} ${this.state.logged ? undefined : styles.posSubLogged} ${styles.shareMenu}`
															}>
																<div className={styles.boxSub}>
																	<div className={`${styles.gridSub} ${styles.gridSubPointer}`}
																		onClick={this.handleOnClickCopyLink}
																	>
																		<h3 className={styles.h3sub}>{this.state.copied}</h3>
																	</div>
																	{this.subMenuShare()}

																</div>
															</div> :
															undefined
													}
												</>
												:
												undefined}

											{this.state.logged ?
												this.props.addToBooklist ?
													this.addToBooklist()
													:
													undefined
												:
												undefined
											}
										</div>
									</div>
									: null
						}
					</div>
					{this.state.popupCreate &&
						<PopUpCreate
							id='PopUpCreateContent'
							showPopUp={this.closePopup}
							handleClosePopUp={this.handleClosePopUp}
							name={'popupCreate'}
							positionPopup={this.state.positionPopup}
							data={this.state.data}
							contentId={this.props.isContent}
							{...this.props}
						/>}
					{this.state.popUpBooklist &&
						<PopUpCreate
							id='PopUpCreateContent'
							showPopUp={this.closePopup}
							handleClosePopUp={this.handleClosePopUp}
							name={'popupCreate'}
							positionPopup={this.state.positionPopup}
							data={this.state.data}
							contentId={this.state.contentNewBooklist.id ? this.state.contentNewBooklist.id : null}
							contextMenu={this.state.contentNewBooklist}
							{...this.props}
						/>}
				</div>
				{this.state.popUpDelete &&
					<div className={styles.relativeContainer}>
						<div className={styles.fixedBlur}></div>
						<div className={styles.fixedClose} onClick={this.handleDeletePopup}></div>
						<div className={styles.containerPopUpDelete}>
							{this.state.deleteStep === deletingContentStep ?
								<div>
									<div>
										<img className={styles.trashIcon} src={iconDelete} />
										<div className={`${styles.spin} ${styles.spinner}`}></div>
									</div>
									<h3 className={styles.popUpDeleted}>{!this.state.isBooklist ? this.props.t('deletingMessageContent') : this.props.t('deletingMessageBooklist')}</h3>
								</div>
								:
								undefined
							}
							{this.state.deleteStep === confirmDeleteStep ?
								<div className={styles.containerIconsDelete}>
									<img className={`${styles.trashIcon} ${styles.trashIconPos}`} src={iconDelete} />
									<h3 className={styles.popUpDeleteTitle}>{!this.state.isBooklist ? this.props.t('titleDeleteContent') : this.props.t('titleDeleteBooklist')}</h3>
									<p className={styles.popUpDeleteInfo}>{!this.state.isBooklist ? this.props.t('infoDeleteContent') : this.props.t('infoDeleteBooklist')}</p>
									<div className={styles.containerPopUpDeleteButton}>
										<div
											className={styles.btnDelete}
											onClick={this.handleDeletePopup}
										>
											{this.props.t('textContextMenuCancel')}
										</div>
										<div
											className={styles.btnDelete2}
											onClick={this.handleDelete}
										>
											{this.props.t('textContextMenuDelete')}
										</div>
									</div>
								</div>
								:
								undefined
							}
							{this.state.deleteStep === deleteCompletedStep ?
								<div>
									<img className={styles.confirmIcon} src={successDelete} />
									<h3 className={styles.popUpDeleted}>{!this.state.isBooklist ? this.props.t('deleteCompletedContent') : this.props.t('deleteCompletedBooklist')}</h3>
								</div>
								:
								undefined
							}
							{this.state.deleteStep === deleteError ?
								<div>
									<img className={styles.confirmIcon} src={emojiImage} />
									<h3 className={styles.popUpDeleteError}>{this.props.t('errorMessageTitle')}</h3>
									<p className={styles.popUpDeleteInfo}>{this.props.t('errorMessageDescription')}</p>
								</div>
								:
								undefined
							}
						</div>
					</div>
				}
			</>
		);
	}
}
function mapStateToProps({ profile, contentList }) {
	return { profile, contentList };
}

export default withRouter(connect(mapStateToProps, {
	visitStats,
	deleteBooklist,
	fetchMyBooklists,
	fetchBooklistPermissions,
	postContentBooklist,
	updateContentPreview,
	postRedirectContent,
	openPopUp,
	setPopUpShare
})(withTranslation()(ContextMenu)));