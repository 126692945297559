import { isSuperUser } from '../actions/booklistV2Actions';
import {
	LOADING,
	ERROR,
	BOOKLIST_INFO,
	BOOKLIST_CONTENT,
	LOADING_BOOKLIST_CONTENT,
	MORE_BOOKLIST_CONTENT,
	LOADING_PUT_BOOKLIST,
	PUT_BOOKLIST_INFO,
	POST_BOOKLIST_SECTION,
	PUT_BOOKLIST_SECTION,
	DELETE_CONTENT_BOOKLIST,
	LOADING_BOOKLIST_SECTIONS,
	GET_BOOKLIST_SECTIONS,
	BOOKLIST_PERMISSIONS,
	ADD_COLABORATOR,
	DELETE_COLABORATOR,
	DELETE_BOOKLIST_SECTION,
	EDIT_COLABORATOR,
} from '../types/booklistV2Types';


const initialState = {
	booklistsInfo: null,
	booklistsPermissions:[],
	booklistContent: null,
	booklistSections: null,
	loading: true,
	loadingContent:true,
	loadingFilter:true,
	loadingPut:false,
	error: '',
	isEditor:false,
	isViewer:false,
};

export default (state = initialState, action) => {
	switch (action.type) {
	case LOADING:
		return {
			...state,
			loading: action.payload
		};
	case LOADING_BOOKLIST_CONTENT:
		return {
			...state,
			loadingContent: action.payload
		};
	case LOADING_BOOKLIST_SECTIONS:
		return {
			...state,
			loadingFilter: action.payload
		};
	case LOADING_PUT_BOOKLIST:
		return {
			...state,
			loadingPut: action.payload
		};
	case BOOKLIST_INFO:
		return {
			...state,
			booklistsInfo: action.payload,
			permissions:null
		};
	case BOOKLIST_CONTENT:
		return {
			...state,
			booklistContent: action.payload,
		};
	case GET_BOOKLIST_SECTIONS:
		return {
			...state,
			booklistSections: action.payload,
		};
	case MORE_BOOKLIST_CONTENT:
		return {
			  ...state,
			  booklistContent:{
				...action.payload,
				contents: [...new Set([...state.booklistContent.contents, ...action.payload.contents])],
			  } 
		};
	case PUT_BOOKLIST_INFO:
		return {
			...state,
			booklistsInfo:{
				...state.booklistsInfo,
				...action.payload
			} 
		};
	case DELETE_CONTENT_BOOKLIST:
		return {
			...state,
			booklistContent:{
				contents: state.booklistContent.contents.filter((content)=>content.slug !== action.payload),
			},
			loadingPut:true
		};
	case PUT_BOOKLIST_SECTION:
		return {
			...state,
			booklistSections:state.booklistSections.map(section=>{
				if(section.id === action.payload.booklistSectionId){
					return ({
						...section,
						section: action.payload.section
					});
				}else{
					return ({...section});
				}
			})
		};
	case DELETE_BOOKLIST_SECTION:
		return {
			...state,
			booklistSections: state.booklistSections.filter(section=>section.id !== action.payload)
		};
	case POST_BOOKLIST_SECTION:
		return {
			...state,
			booklistSections:[...state.booklistSections , action.payload]
		};
	case ERROR:
		return {
			...state,
			error: action.payload,
		};
	case BOOKLIST_PERMISSIONS:
		let permissionEdit = false;
		let permissionView = false;

		const existPermission = action.payload.permissions.find(permission=>permission.email.email === action.payload.currentUser);
		if (existPermission) {
			switch (existPermission.permission.identifier) {
			case 'edit':
				permissionEdit = true;
				break;
			case 'view':
				permissionView = true;
				break;
			default:
				break;
			}
		}

		return {
			...state,
			booklistsPermissions:action.payload.permissions,
			isEditor:permissionEdit,
			isViewer:permissionView
		};
	case ADD_COLABORATOR:
		return {
			...state,
			booklistsPermissions:[
				{
					email: {
						email: action.payload.email,
						firstName: action.payload.firstName,
						lastName: action.payload.lastName,
						mayor: action.payload.mayor,
						profileImgUrl: action.payload.profileImgUrl,
					},
					permission: {
						id: '2c58f3fc-e3b3-4b1a-af98-958f7272b9f7',
						label: 'Editar',
						identifier: 'edit',
						createdAt: '2020-07-24T23:27:18.192Z',
						updatedAt: '2020-07-24T23:27:18.192Z'
					}
				},
				...state.booklistsPermissions
			]
		};
	case DELETE_COLABORATOR:
		return {
			...state,
			booklistsPermissions: state.booklistsPermissions.filter(permission=>permission.email.email !== action.payload) 
		};
	case EDIT_COLABORATOR:
		return {
			...state,
			booklistsPermissions: state.booklistsPermissions.map(permission=>{
				if(permission.email.email == action.payload.email) permission.permission.identifier = action.payload.permission;
				return permission;
			}) 
		};
	default:
		return state;
	}
};
