import React, { useState } from 'react';
import Button, {appearances}from './Button';
import styles from  './styles/buttonGroup.scss';
const ButtonGroup = ({ buttons,initialSelectedIndex,style}) => {
	const [selectedButton, setSelectedButton] = useState(initialSelectedIndex);

	const handleButtonClick = (buttonIndex) => {
		setSelectedButton(buttonIndex);
	};

	return (
		<div
			style={{...style.buttonGroup}} 
			className={styles.buttonGroup}
		>
			{buttons.map((button, index) => (
				<Button 
					key={index}
					appearance={selectedButton === index ? appearances.primary : appearances.secondary}
					action={()=>{
						handleButtonClick(index);
						button.action();
					}}
					style={{
						flex: 1,
						transition: 'background-color 0.3s, color 0.3s',
						border: '1px solid transparent'
					}}
					title={button.label}
				/>
			))}
		</div>
	);
};

export default ButtonGroup;
