import React, { useEffect, useState } from 'react';
import PopUp from './PopUp';
import styles from './styles/popUpContact.scss';
import { SOPORTE, iconWhatsappNew, mailIcon } from '../../../utils/constans';
import { useTranslation } from 'react-i18next';

const PopUpContact = ({ onClose, contactObject }) => {
	const { t } = useTranslation();
	const [contactEmail, setContactEmail] = useState(contactObject.email);

	useEffect(() => {
		setContactEmail(contactObject.email || SOPORTE);
	}, [contactObject]);

	const dynamicButtons = (
		<>
			{contactEmail && (
				<div className={styles.flex}>
					<a
						href={`mailto:${contactEmail}?subject=${encodeURIComponent(
							t('contactLibraryAssistanceText')
						)}`}
					>
						<img
							className={styles.mailIconImage}
							src={mailIcon}
							alt={'Email Icon'}
						/>
					</a>
					<p className={styles.buttonsText}>
						{t('contactLibraryEmail')}{' '}
						<span
							className={styles.buttonsTextEmail}
						>{`: ${contactEmail}`}</span>
					</p>
				</div>
			)}

			{contactObject.whatsapp && (
				<div className={styles.flex}>
					<a
						href={`https://api.whatsapp.com/send?phone=${
							contactObject.whatsapp
						}&text=${encodeURIComponent(
							t('contactLibraryAssistanceText')
						)}`}
						target="_blank"
					>
						<img
							className={styles.iconImage}
							src={iconWhatsappNew}
							alt={'WhatsApp Icon'}
						/>
					</a>
					<p className={styles.buttonsText}>
						{t('contactLibraryWhatsapp')}
					</p>
				</div>
			)}
		</>
	);

	return (
		<PopUp
			closePopUp={onClose}
			style={{
				popUp: { width: 'fit-content', maxWidth: '500px' },
				popUpContent: {
					display: 'flex',
					flexDirection: 'column',
					margin: '40px',
				},
				button: {
					justifyContent: 'center',
				},
			}}
			buttons={{
				style: {
					borderRadius: '8px',
					marginRight: '20px',
				},
			}}
		>
			<div className={styles.contactContainer}>
				<div className={styles.titleText}>
					{t('contactLibraryTitle')}
				</div>
				<div className={styles.desriptionText}>
					{t('contactLibraryDescription')}
				</div>
				<div className={styles.buttonsContainer}>{dynamicButtons}</div>
			</div>
		</PopUp>
	);
};

export default PopUpContact;
