import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from './styles/booklistInfo.scss';

import {
	iconNoContentBooklists,
	iconNoContentMyBooklists,
} from '../../../utils/constans';

import { useTranslation } from 'react-i18next';

const NoContentBooklist = ({ booklistInfo }) => {
	const { t } = useTranslation();
	return (
		<div
			style={{ textAlign: 'center', display: 'grid', justifyItems: 'center' }}
		>
			<div className={`${styles.boxImage}`}>
				<img
					className={styles.imgBox}
					src={
						booklistInfo.isMyBooklist
							? iconNoContentMyBooklists
							: iconNoContentBooklists
					}
				/>
			</div>

			<div style={{ marginTop: '25px' }}>
				<p style={{ fontSize: '16px', fontWeight: '500' }}>
					{booklistInfo.isMyBooklist
						? t('titleMyBooklistNoContent')
						: t('titleBooklistNoContent')}
				</p>
				<p style={{ fontSize: '16px' }}>
					{booklistInfo.isMyBooklist
						? t('infoMyBooklistNoContent')
						: t('infoBooklistNoContent')}
				</p>
			</div>
		</div>
	);
};

function mapStateToProps({ booklistV2 }) {
	return {
		booklistInfo: booklistV2.booklistsInfo,
	};
}

export default withRouter(connect(mapStateToProps, {})(NoContentBooklist));
