import React, {Component} from 'react';
import {
	fetchProfileByEmail,
	loadingProfile,
	visitStats,
	putNotification
} from '../../actions';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { openProfile } from '../../../helpers/profileRouting';

import styles from './styles/alertMessages.scss';
import { withTranslation } from 'react-i18next';

const imageProfile = 'https://cdn.booklick.net/public/img/profile.png';

class AlertMessagesItem extends Component{
	
	constructor(props) {
		super(props);

		this.state = {
		};

		this.onProfileClick = this.onProfileClick.bind(this);
		this.redirect = this.redirect.bind(this);
	}

	onProfileClick() {
		openProfile(
			this.props.notification.metadata.profile.email,
			this.props.notification.metadata.profile.id,
			this.props.loadingProfile,
			this.props.history,
			this.props.visitStats
		);
	}

	redirect() {
		let url;
		switch(this.props.notification.redirect.identifier){
		case 'redirect-booklist':
			if(this.props.notification.metadata.url) {
				window.open(this.props.notification.metadata.url, '_blank');
				break;
			}
			if(this.props.notification.type.identifier === 'following-booklist'){
				url = `${window.location.origin}/booklist/${this.props.notification.metadata.slug}`;
			} else {
				url = `${window.location.origin}/booklist/${this.props.notification.metadata.booklistSlug}`;
			}
			window.location = url;
			break;
		case 'redirect-content':
			if (this.props.notification.metadata.type && this.props.notification.metadata.type === 3) {
				url = `${window.location.origin}/booklist/${this.props.notification.metadata.slug}`;
			}else{
				url = `${window.location.origin}/content/${this.props.notification.metadata.slug}`;
			}
			window.location = url;
			break;
		case 'redirect-profile':
			openProfile(
				this.props.notification.metadata.profile.email,
				this.props.notification.metadata.profile.id,
				this.props.loadingProfile,
				this.props.history,
				this.props.visitStats
			);
			break;
		case 'redirect-chat':
			url = `${window.location.origin}/chat/${this.props.notification.metadata.idChat}`;
			window.location = url;
			break;
		default:
			break;
		}
	}

	timeAgo = (prevDate) => {
        const differenceInSeconds = Number(new Date()) - Number(new Date(prevDate));
        const minute = 60 * 1000;
        const hour = minute * 60;
		const day = hour * 24;
		const week = day * 7;
        const month = day * 30;
        const year = day * 365;
        switch (true) {
            case differenceInSeconds < minute:
				 return this.props.t('titleAlertMessagessItemMenosMinu');
            case differenceInSeconds < hour:
                return this.props.t('titleAlertMessagessItemMenosHour');
            case differenceInSeconds < day:
				return  this.props.t('titleMessagessItemHour',{time:Math.round(differenceInSeconds / hour)});
			case differenceInSeconds < week:
				return  this.props.t('titleMessagessItemDay', {time:Math.round(differenceInSeconds / day)});
            case differenceInSeconds < month:
				return  this.props.t('titleMessagessItemWeek',{time:Math.round(differenceInSeconds / week)});
            case differenceInSeconds < year:
                return this.props.t('titleAlertMessagessItemMoreMonth');
            default:
                return this.props.t('titleAlertMessagessItemMoreMonth');
        }
    };
	
	render(){
		const {t} = this.props
		if(this.props.notification.isRead){
			return(<div className={`${styles.gridMessage}`}>
				<div className={styles.circleUser} onClick={this.onProfileClick}>
					<img className={styles.imgCircle} src=
					{
						this.props.notification.metadata.profile.profileImgUrl ? 
							this.props.notification.metadata.profile.profileImgUrl 
							:
								this.props.notification.metadata.profile.imgUrl ?
									this.props.notification.metadata.profile.imgUrl
									:
							imageProfile 
					} alt="test"/>
				</div>
				<div className={styles.gridRow5} onClick={async () => {
						this.redirect();
						}}>
					<h3 className={styles.h3User}>{this.props.notification.metadata.profile.firstName} {this.props.notification.metadata.profile.lastName}</h3>
					<p className={`${styles.paragraph} ${styles.pLimit}`}>{this.props.notification.message}</p>
				</div>
				<p className={styles.paragraph2}>{this.timeAgo(this.props.notification.createdAt)}</p>
			</div>);
		}else{
			return (
				<div className={`${styles.gridMessage} ${styles.backgroundGrey}`}>
					<div className={styles.circleUser} onClick={this.onProfileClick}>
						<img className={styles.imgCircle} src=
						{
							this.props.notification.metadata.profile.profileImgUrl ? 
								this.props.notification.metadata.profile.profileImgUrl 
								:
									this.props.notification.metadata.profile.imgUrl ?
										this.props.notification.metadata.profile.imgUrl
										:
								imageProfile 
						} alt="test"/>
					</div>
					<div className={styles.gridRow5} 
					onClick={async () => {
						await putNotification(this.props.notification._id);
						this.redirect();
						}}>
						<h3 className={styles.h3User}>{this.props.notification.metadata.profile.firstName} {this.props.notification.metadata.profile.lastName}</h3>
						<p className={`${styles.paragraph} ${styles.pLimit}`}>{this.props.notification.message}</p>
					</div>
					<p className={styles.paragraph2}>{this.timeAgo(this.props.notification.createdAt)}</p>
				</div>
			);
		}
	}
}

function mapStateToProps() {
	return {};
}

export default withRouter(connect(mapStateToProps, {
	fetchProfileByEmail,
	loadingProfile,
	visitStats
})(withTranslation()(AlertMessagesItem)));

