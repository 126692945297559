import React, {useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import InputGroup from '../shared/InputGroup';
import DropDown from '../shared/DropDown';
import {getResources, iconSearchGreyDark} from '../../../utils/constans';
import useFormFileds from '../../hooks/useFormFields';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import { environment } from '../../actions';
const { Option } = components;

const BooklistFilters = ({
	options,
	value,
	handleValue,
	defaultValue,
	listOptions,
	booklistSections,
	section,
	isMyBooklist,
	handleSearch,
	handleSection, 
	popUpEdit,
	isEditor,
	isMobile,
	isSuperUser,
}) => {
	const { t } = useTranslation();
	const { fields, handleOnchage } = useFormFileds({
		searchContent:''
	  });

	useEffect(() => {
		defaultValue({
			value: booklistSections[0].id,
			label:booklistSections[0].section === 'Contenido' ? t('textSearchFilterContent') : booklistSections[0].section,
		});
		
	}, []);

	useEffect(() => {
		if (value.value){
			try {		
				const sectionSelect = listOptions.find((section)=>section.value === value.value);
				if(sectionSelect.label !==  value.label){
					defaultValue(sectionSelect);
					handleSection(sectionSelect.value);
				}
			} catch (error) {
				defaultValue({
					value: booklistSections[0].id,
					label:booklistSections[0].section,
				});
				handleSection(booklistSections[0].id);
			}
		}

	}, [listOptions]);
	

	useEffect(() => {
		const delaySearch = setTimeout(() => {
			handleSearch(fields.searchContent);
		  }, 600);
	  
		  return () => clearTimeout(delaySearch);
	}, [fields.searchContent]);

    

	useEffect(() => {
		 if (value.value && section !== value.value) {
			 handleSection(value.value);
		}
	}, [value]);

	const CustomOptionMyBooklist = props  => (
		<Option {...props}>
			<div style={{display:'flex', justifyContent:'space-between'}}>
				<div style={{fontSize:'16px', color:'#6d6c6c'}}>{props.data.label}</div>
				<img  style={{width:'20px' , cursor:'pointer'}} src={getResources(environment).iconEdit} onClick={()=>popUpEdit(props.data.value)}  alt="" />
			</div>
		</Option>
	);
	
	const CustomOption = props  => (
		<Option {...props}>
			<div style={{display:'flex', justifyContent:'center'}}>
				<div style={{fontSize:'16px', color:'#6d6c6c'}}>{props.data.label}</div>
			</div>
		</Option>
	);

	return isMobile ? (
		<div
			style={{
				margin: '15px auto',
				display: 'flex',
				justifyContent: 'space-between',
				padding: '0px 5px',
			}}
		>
			<InputGroup
				inputName={'searchContent'}
				style={{
					height: '48px',
					margin: '0px',
					fontSize: '14px',
				}}
				icon={iconSearchGreyDark}
				placeholder={`${t('titleSearchBooklist')}...`}
				onChange={handleOnchage}
				value={fields.searchContent}
			/>

			<DropDown
				listOptions={listOptions}
				minHeight={48}
				fontSizeTitle={'14px'}
				options={options}
				value={value}
				isSearchable={true}
				handleValue={handleValue}
				customOption={
					isMyBooklist || isEditor ? CustomOptionMyBooklist : CustomOption
				}
				boxShadow={'0 2px 4px 0 rgba(51, 51, 51, 0.1)'}
			/>

		</div>
	) : (
		<div
			style={{
				margin: '25px auto',
				display: 'grid',
				gridTemplateColumns: '1fr 1fr',
				gap: '28px',
				maxWidth: '1200px',
			}}
		>
			<InputGroup
				inputName={'searchContent'}
				style={{
					height: '48px',
					margin: '0px',
					fontSize: '16px',
				}}
				icon={iconSearchGreyDark}
				placeholder={`${t('titleSearchBooklist')}...`}
				onChange={handleOnchage}
				value={fields.searchContent}
			/>

			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-start',
					flexWrap: 'wrap',
					alignContent: 'flex-start',
				}}>
				<h3
					style={{
						margin: '11px 0 0 0',
						paddingRight: '20px',
						overflow: 'hidden',
						display: '-webkit-box',	
						fontSize: '2rem',
						fontWeight: '600',
					}}>
					{t('textSection')}:
				</h3>
				<DropDown
					listOptions={listOptions}
					maxWidth={'260px'}
					width={'260px'}
					minHeight={48}
					fontSizeTitle={'16px'}
					options={options}
					value={value}
					isSearchable={true}
					handleValue={handleValue}
					customOption={
						isMyBooklist || isEditor || isSuperUser ? CustomOptionMyBooklist : CustomOption
					}
					boxShadow={'0 2px 4px 0 rgba(51, 51, 51, 0.1)'}
					withSeparator={true}
				/>
			</div>
		</div>
	);
	
};

const mapStateToProps = ({ booklistV2  }) => {
	return {
		booklistSections: booklistV2.booklistSections,
		isMyBooklist: booklistV2.booklistsInfo.isMyBooklist,
		isEditor: booklistV2.isEditor
	};
};

export default  withRouter(connect(mapStateToProps,{})(BooklistFilters));
