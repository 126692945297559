import React from 'react';
import styles from './styles/profileBasicInfo.scss';
import { profileDefaultImage } from '../../../../utils/constans';
import { useTranslation } from 'react-i18next';
import Button, { appearances } from '../../shared/Button';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logOut, refreshHome } from '../../../actions';

const ProfileBasicInfo = ({
	userData,
	handleOpenPopUpFollows,
	isMobile,
	isSameUser,
	refreshHome,
	logOut,
	history,
}) => {
	const { t } = useTranslation();

	const handleClickFollowers = () => {
		handleOpenPopUpFollows({
			open: true,
			followers: true,
			followings: false,
			email: userData.email,
		});
	};

	const handleClickFollowings = () => {
		handleOpenPopUpFollows({
			open: true,
			followers: false,
			followings: true,
			email: userData.email,
		});
	};

	const handleLogout = () => {
		logOut().then(() => {
			if (env.proxyLogOut) {
				refreshHome();
				window.location.href = env.proxyLogOut;
			} else {
				refreshHome();
				history.push('/');
			}
		});
	};

	if (isMobile) {
		return (
			<div className={styles.mainContainerMobile}>
				<div className={isSameUser ? styles.basicInfoContainerMobile : styles.otherProfileContainerMobile}>
					<div className={styles.pictureCircleContainerMobile}>
						<img
							className={styles.profilePictureMobile}
							src={userData.profileImage || profileDefaultImage}
							alt={userData.name}
						/>
					</div>
					<div className={styles.statsAndButtonContainerMobile}>
						<div
							className={
								isSameUser
									? styles.statsColumnContainerMobile
									: styles.statsOtherProfileContainerMobile
							}>
							<div
								className={`${styles.statsColumnMobile} ${styles.cursorPointerMobile}`}
								onClick={handleClickFollowers}>
								<span className={styles.statsNumberMobile}>
									{userData.followersCount}
								</span>
								<span className={styles.statsTextMobile}>
									{t('textFollowsContent')}
								</span>
							</div>
							<div
								className={`${styles.statsColumnMobile} ${styles.cursorPointerMobile}`}
								onClick={handleClickFollowings}>
								<span className={styles.statsNumberMobile}>
									{userData.followingCount}
								</span>
								<span className={styles.statsTextMobile}>
									{t('buttonContentFollowed')}
								</span>
							</div>
							<div className={styles.statsColumnMobile}>
								<span className={styles.statsNumberMobile}>
									{userData.contentsCount || '-'}
								</span>
								<span className={styles.statsTextMobile}>
									{t('textContentsCount')}
								</span>
							</div>
						</div>
						{isSameUser ? (
							<div className={styles.buttonRowMobile} onClick={() => handleLogout()}>
								<Button
									height={'45px'}
									appearance={appearances.red}
									borderRadius='6px'
									width={'45px'}
									title={
										<div className={styles.buttonIcon}>
											<span className='material-symbols-outlined'>logout</span>
										</div>
									}
								/>
							</div>
						) : null}
					</div>
				</div>
				<div className={styles.profileContainerMobile}>
					<span className={styles.basicInfoNameMobile}>
						{userData.fullName}
					</span>
					<span className={styles.basicInfoEmailMobile}>{userData.email}</span>
				</div>
				<p className={styles.descriptionContainerMobile}>
					{userData.description}
				</p>
			</div>
		);
	}
	return (
		<div className={styles.mainContainer}>
			<div className={styles.basicInfoContainer}>
				<div className={styles.pictureCircleContainer}>
					<img
						className={styles.profilePicture}
						src={userData.profileImage || profileDefaultImage}
						alt={userData.name}
					/>
				</div>
				<div className={styles.basicInfoColumn}>
					<span className={styles.basicInfoName}>
						{userData.fullName}
					</span>
					<span className={styles.basicInfoEmail}>
						{userData.email}
					</span>
				</div>
			</div>
			<p className={styles.descriptionContainer}>
				{userData.description}
			</p>
			<div className={styles.statsContainer}>
				<div className={`${styles.statsColumn} ${styles.cursorPointer}`} onClick={handleClickFollowers}>
					<span className={styles.statsNumber}>
						{userData.followersCount}
					</span>
					<span className={styles.statsText}>
						{t('textFollowsContent')}
					</span>
				</div>
				<div className={`${styles.statsColumn} ${styles.cursorPointer}`} onClick={handleClickFollowings}>
					<span className={styles.statsNumber}>
						{userData.followingCount}
					</span>
					<span className={styles.statsText}>
						{t('buttonContentFollowed')}
					</span>
				</div>
				<div className={styles.statsColumn}>
					<span className={styles.statsNumber}>
						{userData.contentsCount || '-'}
					</span>
					<span className={styles.statsText}>
						{t('textContentsCount')}
					</span>
				</div>
			</div>
		</div>
	);
};

function mapStateToProps({}) {
	return {};
}

export default withRouter(
	connect(mapStateToProps, {
		refreshHome,
		logOut,
	})(ProfileBasicInfo)
);
