
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styles from './styles/peopleShareItem.scss';

import {
	profileDefaultImage,
	eventShareType
} from '../../../utils/constans';

import {
	postShareContent,
	environment
} from '../../actions';

import { postEventSharePopup } from '../../../helpers/tracking';
import useRoleProfile from '../../hooks/useRoleProfile';

const PeopleItem = ({
	profile,
	content,
	targetProfile
}) => {
	const [isShared, setIsShared] = useState(false);
	const { t, i18n } = useTranslation();
	const {roleKey} = useRoleProfile(targetProfile.role);
	const share = () => {
		postEventSharePopup(eventShareType.shareProfile, environment, profile.profile.data.email, targetProfile.email, content.id);
		if(!isShared) {
			if(isIdFromElastic(content.id)) {
				postShareContent(content.id, targetProfile.email, content);
			} else {
				postShareContent(content.id || content.content_id, targetProfile.email, null);
			}
			setIsShared(true);
		}
	};

	function isIdFromElastic(id) {
		var regex =
			/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
		if (regex.test(id)) {
			return true;
		}
		return false;
	}

	return (
		<>
			<div className={styles.gridPeople}>
				<div className={styles.relative}>
					<div className={styles.circleUser}>
						<img className={styles.imgPeople} src={targetProfile.profileImg ? targetProfile.profileImg : targetProfile.profileImgUrl ? targetProfile.profileImgUrl : profileDefaultImage} alt={'test'} />
					</div>
					{targetProfile.logo || (targetProfile.institution && targetProfile.institution.logo) ?
						<div className={styles.posLogo}>
							<div className={styles.circleLogo}>
								<img className={styles.logoUni} src={targetProfile.logo ? targetProfile.logo : targetProfile.institution.logo} alt="" />
							</div>
						</div>
						: null
					}
				</div>

				<div className={styles.gridRow5}>
					<div className={styles.containerInfo}>
						<h2 className={styles.fontTitle}>{t(roleKey)}</h2>
						<h2 className={styles.font}>{`${targetProfile.firstName} ${targetProfile.lastName}`}</h2>
						<h2 className={`${styles.font} ${styles.fontLight}`}>{targetProfile.mayor}</h2>
					</div>

					{isShared ?
						<div className={styles.button_113px_white} onClick={share}>
							{t('buttonShared')}
						</div>
						:
						<div className={styles.button_113px_blue} onClick={share}>
							{t('buttonShare')}
						</div>
					}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = ({ profile }) => {
	return { profile };
};

export default withRouter(connect(mapStateToProps, {})(PeopleItem));
