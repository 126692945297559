import React, { useEffect, useState } from 'react';
import ProfileSettings from './ProfileSettings';
import { PROFILE_TABS, PROFILE_TABS_MOBILE } from '../../../../utils/constans';
import styles from './styles/contentHandler.scss';
import CreatedContent from './CreatedContent';
import { logOut, refreshHome } from '../../../actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FollowedBooklists from './FollowedBooklists';
import CollaborativeBooklists from './CollaborativeBooklists';
import LikedContent from './LikedContent';

const ContentHandler = ({
	selectedTab,
	refreshHome,
	logOut,
	history,
	email,
	profile,
	isMobile,
}) => {
	const [content, setContent] = useState(null);
	const { t } = useTranslation();
	const handleLogout = () => {
		logOut().then(() => {
			if (env.proxyLogOut) {
				refreshHome();
				window.location.href = env.proxyLogOut;
			} else {
				refreshHome();
				history.push('/');
			}
		});
	};

	useEffect(() => {
		if (isMobile) {
			switch (selectedTab) {
			case PROFILE_TABS_MOBILE[0].key:
				setContent(<CreatedContent email={email} isMobile={isMobile} />);
				break;
			case PROFILE_TABS_MOBILE[1].key:
				setContent(<FollowedBooklists email={email} isMobile={isMobile} />);
				break;
			case PROFILE_TABS_MOBILE[2].key:
				setContent(<LikedContent email={email} isMobile={isMobile} />);
				break;
			case PROFILE_TABS_MOBILE[3].key:
				setContent(
					<CollaborativeBooklists email={email} isMobile={isMobile} />);
				break;
			default:
				setContent(<>{selectedTab}</>);
				break;
			}
		} else {
			switch (selectedTab) {
			case PROFILE_TABS[0].key:
				setContent(<CreatedContent email={email} isMobile={isMobile} />);
				break;
			case PROFILE_TABS[1].key:
				setContent(<CollaborativeBooklists email={email} />);
				break;
			case PROFILE_TABS[2].key:
				setContent(<FollowedBooklists email={email} />);
				break;
			case PROFILE_TABS[3].key:
				setContent(<LikedContent email={email} />);
				break;
			case PROFILE_TABS[4].key:
				setContent(<ProfileSettings profile={profile} />);
				break;
			case PROFILE_TABS[5].key:
				handleLogout();
				break;
			default:
				setContent(<>{selectedTab}</>);
				break;
			}
		}
	}, [selectedTab, email, isMobile]);

	if (isMobile) {
		return (
			<div className={styles.mobileMainContainer}>
				<>{content}</>
			</div>
		);
	}
	return (
		<div className={styles.mainContainer}>
			<p className={styles.titleContent}>{t(selectedTab)}</p>
			<div className={styles.separator}></div>
			<>{content}</>
		</div>
	);
};

function mapStateToProps({}) {
	return {};
}

export default withRouter(
	connect(mapStateToProps, {
		refreshHome,
		logOut,
	})(ContentHandler)
);
