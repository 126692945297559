import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
	getBooklistsCollaborative,
	getMoreBooklistsCollaborative,
} from '../../../actions';

import { ImpulseSpinner } from 'react-spinners-kit';
import LoaderBooklistContent from '../../booklists/LoaderBooklistContent';
import CardBooklist from '../CardBooklist';

const CollaborativeBooklists = ({
	loading,
	collaborativeBooklists,
	handleCardSelect,
	selectedBooklistId,
	getBooklistsCollaborative,
	getMoreBooklistsCollaborative,
}) => {
	const [page, setPage] = useState(1);

	useEffect(() => {
		if (!collaborativeBooklists.booklists) {
			getBooklistsCollaborative();
		}
	}, []);

	useEffect(() => {
		if (
			collaborativeBooklists.booklists &&
			!loading.moreBooklistCollaborative &&
			collaborativeBooklists.hasNextPage
		) {
			let observerScroll = document.getElementById('observerScroll');
			let intersectionObserver = new IntersectionObserver(
				(entries) => {
					if (entries[0].isIntersecting) {
						handleMoreBooklistsCollaborative();
					}
				},
				{
					rootMargin: '0px 0px 1px 0px',
				}
			);
			intersectionObserver.observe(observerScroll);
		}
	}, [collaborativeBooklists.booklists, loading.moreBooklistCollaborative]);

	const handleMoreBooklistsCollaborative = async () => {
		if (collaborativeBooklists.hasNextPage) {
			getMoreBooklistsCollaborative(page + 1);
			setPage(page + 1);
		}
	};

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					maxHeight: '40vh',
					overflow: 'auto',
					justifyItems: 'center',
				}}
			>
				{!collaborativeBooklists.booklists ||
				loading.booklistCollaborative ? (
					<LoaderBooklistContent height="147px" />
				) : (
					collaborativeBooklists.booklists.map((booklist) => (
						<CardBooklist
							key={booklist.booklistId}
							widthCard={'100%'}
							heightCard={'147px'}
							content={{
								...booklist.content,
								id: booklist.booklistId,
							}}
							isSelected={
								booklist.booklistId === selectedBooklistId
							}
							onSelect={handleCardSelect}
						/>
					))
				)}
			</div>

			{loading.moreBooklistCollaborative && (
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<ImpulseSpinner
						size={40}
						color="var(--primary-color)"
						frontColor="#10CFC9"
						backColor="#F0FBFF"
						loading={loading.moreMyBooklist}
					/>
				</div>
			)}

			{collaborativeBooklists &&
			collaborativeBooklists.booklists &&
			collaborativeBooklists.hasNextPage &&
			!loading.moreBooklistCollaborative ? (
				<div
					style={{ marginTop: '20px', padding: '5px' }}
					id="observerScroll"
				/>
			) : null}
		</>
	);
};

const mapStateToProps = ({ contextMenu }) => {
	return {
		loading: contextMenu.loading,
		collaborativeBooklists: contextMenu.booklistPermissions,
	};
};

export default withRouter(
	connect(mapStateToProps, {
		getBooklistsCollaborative,
		getMoreBooklistsCollaborative,
	})(CollaborativeBooklists)
);
