import React, { useEffect, useRef, useState } from 'react';
import styles from './styles/createLink.scss';
import DropDown from '../shared/DropDown';
import InputGroup from '../shared/InputGroup';
import Button, { appearances } from '../shared/Button';
import useFormFields from '../../hooks/useFormFields';
import { getResources } from '../../../utils/constans';
import { connect } from 'react-redux';
import { saveLink, environment, fetchProfile } from '../../actions';
import { withRouter } from 'react-router-dom';
import { openProfileNew } from '../../../helpers/profileRouting';
import { useTranslation } from 'react-i18next';

const imageCoverBooklick = getResources(environment).defaultCover;

const CreateLink = ({ meta, link, saveLink, fetchProfile, closePopUp }) => {
	const { t } = useTranslation();
	const [type, setType] = useState();
	const [cover, setCover] = useState();
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { fields, handleOnchage } = useFormFields({
		title: meta.title || '',
		description: meta.description || '',
		imageURL: meta.imageURL || imageCoverBooklick,
		cover: '',
		author: '',
		editorial: '',
		edition: '',
	});

	const fileTypes = [
		{ value: 0, label: t('titleTypeContentAudio') },
		{ value: 1, label: t('titleTypeContentIamge') },
		{ value: 2, label: t('titleTypeContentReading') },
		{ value: 3, label: t('titleTypeContentBook') },
		{ value: 4, label: t('titleTypeContentVideo') },
	];

	const selectType = (value) => {
		setType(value);
	};

	const fileInputRef = useRef(null);

	const handleFileUpload = () => {
		fileInputRef.current.click();
	};

	const handleFileSelected = (e) => {
		const selectedFile = e.target.files[0];
		if (selectedFile instanceof Blob) {
			handleOnchage(selectedFile, 'cover');
			const blob = new Blob([selectedFile], { type: selectedFile.type });
			const reader = new FileReader();
			reader.onload = function (event) {
				setCover(event.target.result);
			};
			reader.readAsDataURL(blob);
		}
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		if (isFormCompleted()) {
			let params;
			const profile = await fetchProfile();
			const email = profile.data.email;
			if (fields.cover) {
				params = {
					title: fields.title,
					description: fields.description,
					cover: fields.cover,
					url: link,
					metadata: {
						editorial: fields.editorial,
						edition: fields.edition,
						author: fields.author,
					},
				};
			} else {
				params = {
					title: fields.title,
					description: fields.description,
					imageURL: fields.imageURL,
					url: link,
					metadata: {
						editorial: fields.editorial,
						edition: fields.edition,
						author: fields.author,
					},
				};
			}
			await saveLink(params);
			openProfileNew(email);
		} else {
			setError(true);
			setTimeout(() => {
				setError(false);
			}, 5000);
		}
		setIsLoading(false);
	};

	const isFormCompleted = () => {
		return fields.title && fields.description && type;
	};

	return (
		<div className={styles.mainContainer}>
			<div onClick={closePopUp} className={styles.iconClose}>
				<img
					className={styles.icon20}
					src={getResources(environment).iconClose}
					alt='icon-close'
				/>
			</div>
			<h2 className={styles.title}>{t('textContentCreate')}</h2>
			<div className={styles.separator} />
			<div className={styles.content}>
				<div className={styles.imageContainer}>
					{fields.cover ? (
						<img alt='cover' src={cover} className={styles.image} />
					) : (
						<img alt='photo' src={fields.imageURL} className={styles.image} />
					)}
				</div>

				<div className={styles.textContainer}>
					<div className={styles.inputGroup}>
						<h3 className={styles.subtitle}>{t('textInputPhoto')}</h3>
						<input
							type='file'
							accept='image/*'
							style={{ display: 'none' }}
							ref={fileInputRef}
							onChange={handleFileSelected}
						/>
						<Button
							height={'45px'}
							appearance={appearances.secondary}
							borderRadius='6px'
							width={'100%'}
							title={
								<div className={styles.buttonIcon}>
									<div className={styles.Icon}>
										<span className='material-symbols-outlined'>
											photo_camera
										</span>
									</div>
									<h4 className={styles.textIcon}>
										{t('IpuntoChagePhotoBooklist')}
									</h4>
								</div>
							}
							marginLeft={'0px'}
							action={handleFileUpload}
						/>
					</div>
					<div className={styles.inputGroup}>
						<h3 className={styles.subtitle}>{t('textTitleContent')}</h3>
						<InputGroup
							inputName={'title'}
							style={{
								height: '45px',
								margin: '0px',
								fontSize: '14px',
							}}
							placeholder={t('textTitleContent')}
							onChange={handleOnchage}
							value={fields.title}
							type={'input'}
						/>
					</div>
				</div>
			</div>
			<div className={styles.descriptionContainer}>
				<h3 className={styles.subtitle}>{t('textDescriptionContent')}</h3>
				<InputGroup
					inputName={'description'}
					style={{
						height: '60px',
						margin: '0px',
						fontSize: '14px',
						width: '440px',
					}}
					placeholder={t('textDescriptionContent')}
					onChange={handleOnchage}
					value={fields.description}
					type={'textArea'}
				/>
			</div>
			<div className={styles.dataContainer}>
				<div className={styles.dataRow}>
					<div className={styles.dataCell}>
						<h3 className={styles.subtitle}>{t('textTypeContent')}</h3>
						<DropDown
							options={fileTypes}
							isSearchable={false}
							placeholder={t('placeholderFilterTopic')}
							handleValue={selectType}
							value={type}
							minHeight={'45px'}
							width={'210px'}
						/>
					</div>
					<div className={styles.dataCell}>
						<h3 className={styles.subtitle}>{t('inputAdvanceSearchAutor')}</h3>
						<InputGroup
							inputName={'author'}
							style={{
								height: '45px',
								margin: '0px',
								fontSize: '14px',
							}}
							placeholder={t('textAuthorExample')}
							onChange={handleOnchage}
							value={fields.author}
							type={'input'}
						/>
					</div>
				</div>
				{type && type.value === 3 && (
					<div className={styles.dataRow}>
						<div className={styles.dataCell}>
							<h3 className={styles.subtitle}>
								{t('inputAdvanceSearchPublisher')}
							</h3>
							<InputGroup
								inputName={'editorial'}
								style={{
									height: '45px',
									margin: '0px',
									fontSize: '14px',
								}}
								placeholder={t('inputAdvanceSearchPublisher')}
								onChange={handleOnchage}
								value={fields.editorial}
								type={'input'}
							/>
						</div>
						<div className={styles.dataCell}>
							<h3 className={styles.subtitle}>{t('titleEdition')}</h3>
							<InputGroup
								inputName={'edition'}
								style={{
									height: '45px',
									margin: '0px',
									fontSize: '14px',
								}}
								placeholder={t('titleEdition')}
								onChange={handleOnchage}
								value={fields.edition}
								type={'input'}
							/>
						</div>
					</div>
				)}
				<Button
					height={'45px'}
					appearance={appearances.primary}
					borderRadius='6px'
					width={'100%'}
					title={t('buttonEndForm')}
					action={handleSubmit}
					loader={isLoading}
					disabled={isLoading}
				/>
				{error && !isFormCompleted() && (
					<h4 className={styles.h4Error}>{t('errorUncompleteForm')}</h4>
				)}
			</div>
		</div>
	);
};

function mapStateToProps({}) {
	return {};
}

export default withRouter(
	connect(mapStateToProps, {
		saveLink,
		fetchProfile,
	})(CreateLink),
);
