import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


import PopUp from '../shared/PopUp';
import InputGroup from '../shared/InputGroup';

import { useTranslation } from 'react-i18next';
import useFormFileds from '../../hooks/useFormFields';

import { iconSearchGreyDark } from '../../../utils/constans';
import { getMyBooklist, getMoreMyBooklist, resetState, fetchSectionsOfBooklist, postAddContentInBooklist, putMoveContentToSection, postSearchMyBooklists, postSearchBooklistsCollaborative, postContent, clearProfileContent } from '../../actions';
import ButtonGroup from '../shared/ButtonGroup';
import MyBooklistContextMenu from './MyBooklistContextMenu';
import CollaborativeContextMenu from './CollaborativeContextMenu';
import SectionsBooklistContextMenu from './SectionsBooklistContextMenu';
import PopupSuccess from './PopupSuccess';
import SearchBooklistContextMenu from './SearchBooklistContextMenu';


const PopupContextMenu = ({
	closePopUp,
	content,
	resetState,
	createdContent,
	clearProfileContent
}) => {
	const [selectedBooklistId, setSelectedBooklistId] = useState({
		myBooklistId: null,
		booklistCollaborativeId: null
	});
	const [tabPage, setTabPage] = useState({
		myBooklist: true,
		collavorative: false
	});
	const [loading, setLoading] = useState({
		search:false,
		save:false
	});
	const [selectedSection, setSelectedSection] = useState(null);
	const [showSections, setShowSections] = useState(false);
	const [booklistSections, setBooklistSections] = useState(null);
	const [moveToSection, setMoveToSection] = useState(false);
	const [openPopupSuccess, setOpenPopupSuccess] = useState(false);
	const [isSearch,setIsSearch] = useState(false);
	const [resultBooklists,setResultBooklists] = useState(null);
	const { t } = useTranslation();
	const { fields, handleOnchage } = useFormFileds({
		searchContent: ''
	});

	useEffect(() => {
		return () => {
			resetState();
		};
	}, []);

	useEffect(() => {
		const delaySearch = setTimeout(() => {
			handleSearch(fields.searchContent);
		  }, 600);
	  
		  return () => clearTimeout(delaySearch);
	}, [fields.searchContent]);

	const handleCardSelect = (booklistId) => {
		if (tabPage.myBooklist) {
			setSelectedBooklistId({
				myBooklistId: booklistId,
				booklistCollaborativeId: null
			});
		} else if (tabPage.collavorative) {
			setSelectedBooklistId({
				myBooklistId: null,
				booklistCollaborativeId: booklistId
			});
		}

	};


	const handleTabPage = (value) => {
		setResultBooklists([]);
		setLoading({...loading,search:false});
		setIsSearch(false);
		setTabPage(value);
	};

	const handleClickAddContent = async () => {
		const booklistId = selectedBooklistId.myBooklistId || selectedBooklistId.booklistCollaborativeId;
		if (booklistId == content.id) {
			setLoading({...loading,save:false});
			console.error("same booklist");
			return;
		}
		if (booklistId) {
			try {
				setLoading({...loading, save:true});
				const sectionsOfBooklist = await fetchSectionsOfBooklist(booklistId);
				if (sectionsOfBooklist.length === 1) {
					if (content['resource_origin'] !== 'content-user') {
						const resultContent = await createContent(content);
						content = {...content,...resultContent};
					}
					await postAddContentInBooklist(content.slug, booklistId, sectionsOfBooklist[0].id);
					setLoading({...loading,save:false});
					setOpenPopupSuccess(true);
				} else {
					const sectionId = sectionsOfBooklist[0].id;
					setBooklistSections(sectionsOfBooklist);
					setSelectedSection(sectionId);
					setShowSections(true);
					setLoading({...loading,save:false});
				}
			} catch (error) {
				setLoading({...loading,save:false});
				console.error(error);
			}

		}
	};

	const handleClickAddContentWithSection = async () => {
		try {
			const booklistId = selectedBooklistId.myBooklistId || selectedBooklistId.booklistCollaborativeId;
			setLoading({...loading,save:true});
			
			if (content['resource_origin'] !== 'content-user') {
				const resultContent = await createContent(content);
				content = {...content,...resultContent};
			}
			
			await postAddContentInBooklist(content.slug, booklistId, selectedSection);
			setLoading({...loading,save:false});
			setOpenPopupSuccess(true);
		} catch (error) {
			if (error === 409) {		
				setLoading({...loading,save:false});
				setMoveToSection(true);
			}
			setLoading({...loading,save:false});
			console.error(error);
		}
	};

	const handleMoveToSection = async () => {
		const booklistId = selectedBooklistId.myBooklistId || selectedBooklistId.booklistCollaborativeId;
		try {
			setLoading({...loading,save:true});
			await putMoveContentToSection(booklistId, content.id , selectedSection);
			setLoading({...loading,save:false});
			setMoveToSection(false);
			setOpenPopupSuccess(true);
		} catch (error) {
			setLoading({...loading,save:false});
			console.error(error);
		}
	};

	const handleGoToBack = () => {
		setShowSections(false);
	};

	const handleSearch = async (term) => {
		if (term === '') {
			setResultBooklists([]);
			setLoading({...loading,search:false});
			setIsSearch(false);
		} else {
			try {
				setLoading({...loading,search:true});
				setIsSearch(true);
				let resultSearch;
				if (tabPage.myBooklist) {
					resultSearch = await postSearchMyBooklists(
						term.toLowerCase()
					);
				}else if(tabPage.collavorative){
					resultSearch = await postSearchBooklistsCollaborative(
						term.toLowerCase()
					);
				}
				
				if (resultSearch) { 
					setResultBooklists([...resultSearch]);
					setLoading({...loading,search:false});
				} else {
					setLoading({...loading,search:false});
				}
			} catch (error) {
				console.error(error);
			}
		}
	};

	const createContent = async (content) => {
		const createContent = await postContent(content,content.type);
		return createContent;
	};

	const buttons = [
		{ label: t('textContextMySubMenuBooklist'), action: () => handleTabPage({ collavorative: false, myBooklist: true }) },
		{ label: t('textMyBooklistCollabote'), action: () => handleTabPage({ myBooklist: false, collavorative: true }) },
	];

	const style = {
		buttonGroup: {
			width: '360px',
			height: '45px',
			marginBottom: '15px'
		}
	};

	if (moveToSection) {
		return (
			<PopUp
				closePopUp={closePopUp}
				style={{
					popUp:{
						maxHeight:'406px',
						maxWidth:'534px'
					},
					popUpContent:{
						margin:'40px'
					},
					button:{
						marginTop:'30px',
						justifyContent:'center',
					}
				}}
		
				buttons={{
					style:{
						width:'220px',
						height:'50px',
						borderRadius:'8px',
						marginRight:'15px',	
					},
					primary:{
						title:t('textPreferenceSave'),
						type:'primary',
						action:handleMoveToSection,
						loading:loading.save
					},
					secondary:{
						title:t('textContextMenuCancel'),
						type:'secondary',
						disabled:false,
						action:closePopUp
					}
				}}
			>
				<div style={{ marginTop: '16px' }}>
					<p style={{ fontSize: '18px', fontWeight: '500' }}>{t('textPopUpContextMenuAlreadyExists')}</p>
					<p style={{ fontSize: '18px' }}>{t('textPopUpContextMenuAlreadyExistsSection')}</p>
				</div>
			</PopUp>

		);
	}

	if (openPopupSuccess) {
		clearProfileContent();
		return(
			<PopupSuccess closePopUp={closePopUp} />
		);
	}



	return (
		<PopUp
			closePopUp={closePopUp}
			isButtonFooter={true}
			style={{
				popUp: {
					maxHeight: 644,
					maxWidth: '881px',
				},
				popUpContent: {
					margin: '40px',
				},
			}}

			buttons={{
				style: {
					width: '100%',
					height: '50px',
					borderRadius: '8px'
				},
				primary: {
					title: t('textKeywordAdd'),
					type: 'primary',
					disabled: ((tabPage.myBooklist && !selectedBooklistId.myBooklistId) ||
						(tabPage.collavorative && !selectedBooklistId.booklistCollaborativeId)) ? true : false,
					action: showSections ? handleClickAddContentWithSection : handleClickAddContent,
					loading: loading.save
				},
				goToback:{
					title: t('back'),
					action:handleGoToBack,
					hidden:!showSections
				}
			}}
		>
			<div style={{ width: '100%' }}>

				{showSections ? (
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<div style={{ marginTop: '16px' }}>
							<p style={{ fontSize: '22px', fontWeight: '500' }}>{t('textPopUpContextMenuSectionSelect')}</p>
						</div>
					</div>
				) : (
					<>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							<div style={{ marginTop: '16px' }}>
								<p style={{ fontSize: '22px', fontWeight: '500' }}>{t('textPopUpContextMenuBooklistSelect')}</p>
							</div>

							<ButtonGroup
								buttons={buttons}
								initialSelectedIndex={tabPage.collavorative ? 1 : 0}
								style={style}
							/>
						</div>

						<InputGroup
							inputName={'searchContent'}
							style={{
								height: '48px',
								margin: '0px',
								fontSize: '16px',
								width: '100%'
							}}
							icon={iconSearchGreyDark}
							placeholder={`${t('titleSearchBooklist')}...`}
							onChange={handleOnchage}
							value={fields.searchContent}
						/>
					</>
				)
				}

				{showSections ?
					(
						<SectionsBooklistContextMenu
							booklistSections={booklistSections}
							setSelectedSection={setSelectedSection}
							sectionId={selectedSection}
						/>
					) : isSearch ? ( 
						<SearchBooklistContextMenu 
							currentBooklistId={content.id}
							results={resultBooklists}
							loading={loading.search}
							handleCardSelect={handleCardSelect}
							selectedBooklistId={tabPage.myBooklist ?  selectedBooklistId.myBooklistId : selectedBooklistId.booklistCollaborativeId}
						/>
					) : tabPage.myBooklist && !isSearch ? (
						<MyBooklistContextMenu
							currentBooklistId={content.id}
							handleCardSelect={handleCardSelect}
							selectedBooklistId={selectedBooklistId.myBooklistId}
						/>
					) : tabPage.collavorative && !isSearch? (
						<CollaborativeContextMenu
							currentBooklistId={content.id}
							handleCardSelect={handleCardSelect}
							selectedBooklistId={selectedBooklistId.booklistCollaborativeId}
						/>
					) : null
				}


			</div>
		</PopUp>
	);
};

const mapStateToProps = ({ contentList, contextMenu }) => {
	return {
		createdContent: contentList.myContent,
		loading: contextMenu.loading,
		myBooklist: contextMenu.myBooklist
	};
};

export default withRouter(connect(mapStateToProps, { getMyBooklist, getMoreMyBooklist, resetState, clearProfileContent })(PopupContextMenu)); 