import { useEffect, useState } from 'react';

const useTypeContent = (typeContent) => {
	const [labelKey, setLabelKey] = useState();

	useEffect(() => {
		switch (typeContent) {
		case 'Libros':
			setLabelKey('textSearchFilterBook');
			break;
		case 'Artículos':
			setLabelKey('textSearchFilterArticle');
			break;
		case 'Articulo':
			setLabelKey('textSearchFilterArticle');
			break;
		case 'Revistas':
			setLabelKey('textSearchFilterPaper');
			break;
		case 'Perfiles':
			setLabelKey('textSearchFilterProfiles');
			break;
		case 'Booklists':
			setLabelKey('textSearchFilterBooklist');
			break;
		case 'Videos':
			setLabelKey('textSearchResultVideo');
			break;
		case 'Textos':
			setLabelKey('textSearchResultText');
			break;
		case 'Imágenes':
			setLabelKey('textSearchResultImagen');
			break;
		case 'Libro fisico':
			setLabelKey('textSearchResultOfflineBook');
			break;
		case 'Enlace':
			setLabelKey('textSearchFilterLink');
			break;
		case 'Libros':
			setLabelKey('textSearchFilterBook');
			break;
		case 'Libro externo':
			setLabelKey('textSearchResultExternalBook');
			break;
		case 'Documento':
			setLabelKey('textSearchFilterDocument');
			break;
		case 'Enlace':
			setLabelKey('textSearchFilterLink');
			break;
		default:
			setLabelKey(typeContent);
			break;
		}
	}, []);

	return {labelKey};

};

export default useTypeContent;