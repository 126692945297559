import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import NavBar from '../layout/NavBar';
import styles from './styles/errorGroup.scss';

const errorImage = 'http://cdn.booklick.net/public/img/icons/error-warning-fill-green.svg';
const errorTitle = '¡Lo sentimos!';
const errorMessage = 'Algo no salió como lo esperabamos. No encontramos la página';
const homeMessage = 'Volver al inicio';

class CatchError extends Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}
    
	componentDidCatch(error, errorInfo) {
		this.setState({
			error: error,
			errorInfo: errorInfo
		});
	}
    
	render() {
		if (this.state.errorInfo) {
			return (
				<>
					<NavBar /> 
					<div className={styles.paddingPage}>
						<div className={styles.containerPage}>
							<div className={styles.errorPos}>
								<div className={styles.errorContainer}>
									<div>
								    <img className={styles.errorImage} src={errorImage}/>
									</div>
									<h2 className={styles.errorTitle} >{errorTitle}</h2>
									<div className={styles.errorText}>{errorMessage}</div>
									<div className={styles.btnMediumFollow2}
										onClick={async () => window.location= `${window.location.origin}/` }>
										<h3 className={styles.h3Home}>
											{homeMessage}
										</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			);
		}
		return this.props.children;
	}  
}

function mapStateToProps() {
	return {};
}

export default withRouter(connect(mapStateToProps, {})(CatchError));