import React, {useState,useEffect} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getMyBooklist, getMoreMyBooklist } from '../../actions';

import { ImpulseSpinner } from 'react-spinners-kit';
import LoaderBooklistContent from '../booklists/LoaderBooklistContent';
import CardBooklist from './CardBooklist';

const MyBooklistContextMenu = ({
	loading,
	myBooklist,
	handleCardSelect,
	selectedBooklistId,
	getMyBooklist,
	getMoreMyBooklist,
	currentBooklistId,
}) => {

	const [page, setPage] = useState(1);

	useEffect(() => {
		if (!myBooklist.booklists) {
			getMyBooklist();
		}
	}, []);

	useEffect(() => {
		if (myBooklist.booklists && !loading.moreMyBooklist && myBooklist.hasNextPage) {
			let observerScroll = document.getElementById('observerScroll');
			let intersectionObserver = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting) {
					handleMoreMyBooklists();
				}
			}, {
				rootMargin: '0px 0px 1px 0px',
			});
			intersectionObserver.observe(observerScroll);
		}
	}, [myBooklist.booklists, loading.moreMyBooklist]);

	const handleMoreMyBooklists = async () => {
		if (myBooklist.hasNextPage) {
			getMoreMyBooklist(page + 1);
			setPage(page + 1);
		}
	};  

	return (
		<>
			<div
				style={{
					margin: '20px auto',
					display: 'grid',
					gridTemplateColumns: '1fr 1fr',
					gap: '28px',
					justifyItems: 'center',
					paddingBottom: '60px',
				}}
			>
				{!myBooklist.booklists || loading.myBooklist ? (
					<LoaderBooklistContent width="347px" height="147px" />
				) : (
					myBooklist.booklists
						.filter((booklist) => booklist.id !== currentBooklistId)
						.map((booklist) => (
							<CardBooklist
								key={booklist.id}
								widthCard={'347px'}
								heightCard={'147px'}
								content={booklist}
								isSelected={booklist.id === selectedBooklistId}
								onSelect={handleCardSelect}
							/>
						))
				)}
			</div>

			{loading.moreMyBooklist && (
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<ImpulseSpinner
						size={40}
						color="var(--primary-color)"
						frontColor="#10CFC9"
						backColor="#F0FBFF"
						loading={loading.moreMyBooklist}
					/>
				</div>
			)}

			{myBooklist.booklists &&
			myBooklist.hasNextPage &&
			!loading.moreMyBooklist ? (
				<div
					style={{ marginTop: '20px', padding: '5px' }}
					id="observerScroll"
				/>
			) : null}
		</>
	);
};

const mapStateToProps = ({ contextMenu }) => {
	return {
		loading: contextMenu.loading,
		myBooklist: contextMenu.myBooklist
	};
};

export default withRouter(connect(mapStateToProps, { getMyBooklist,getMoreMyBooklist })(MyBooklistContextMenu)); 
