import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import nativeMenu from '../components/hocs/nativeMenu';
import { connect } from 'react-redux';
import styles from './styles/searchV2Page.scss';
import PopUpShare from '../components/shared/PopUpShare';
import { useTranslation } from 'react-i18next';
import ProfilesResult from '../components/search/ProfilesResult';
import LoadSearch from '../components/shared/LoadSearch';
import SearchPlaceholder from '../components/search/SearchPlaceholder';
import LoaderBooklistContent from '../components/booklists/LoaderBooklistContent';

import { setPopUpShare, filterSearchNew, environment } from '../actions';

import { breakPoint, iconFilterOff, iconFilterOn } from '../../utils/constans';

import {
	scrollSearch,
	removeListenerScroll,
	endTrackHome,
} from '../../helpers/tracking';
import LoaderSearchContent from '../components/search/LoaderSearchContent';
import NoResultSearch from '../components/search/NoResultSearch';
import ContentResult from '../components/search/ContentResult';
import FiltersComponent from '../components/search/FiltersComponent';
import { ERROR_LOGGING_IN } from '../actions/profileActions';
import { isPublicSearchAllowed } from '../../utils/redirectRoutes';

const SearchV2Page = ({
	searchNew,
	exploration,
	setPopUpShare,
	profile,
	filterSearchNew,
	history,
}) => {
	const [results, setResults] = useState();
	const [hasNextPage, setHasNextPage] = useState();
	const [filters, setFilters] = useState({
		editorial: null,
		lang: null,
		type: null,
		student: null,
		admin: null,
		profesor: null,
	});

	const [loadingScroll, setLoadingScroll] = useState();
	const [page, setPage] = useState(1);
	const [profiles, setProfiles] = useState();
	const [isMobile, setIsMobile] = useState(
		window.innerWidth < breakPoint.mobileWide
	);
	const [isFilterVisible, setIsFilterVisible] = useState(true);

	const toggleVisibility = () => {
		setIsFilterVisible(!isFilterVisible);
	};
	const { t } = useTranslation();

	useEffect(() => {
		resetFilters();
		endTrackHome();
		handleResize();
		document.body.style.backgroundColor = '#fcfcfc';
		window.addEventListener('resize', handleResize);
		return () => {
			setResults();
			setHasNextPage();
			setProfiles();
			window.removeEventListener('resize', handleResize);
			removeListenerScroll();
		};
	}, []);

	useEffect(() => {
		if (!isMobile) {
			setIsFilterVisible(true);
		}
	}, [isMobile]);

	useEffect(() => {
		resetFilters();
	}, [searchNew.searchTerm]);

	useEffect(() => {
		if(profile.error === ERROR_LOGGING_IN && !isPublicSearchAllowed()){			
			window.localStorage.setItem('termQuery', searchNew.searchTerm);
			if (env.proxy) {
				window.location = env.proxy ? `${env.proxy}` : `${window.location.origin}`;
			} else {				
				history.push('/');
			}
		}
	}, [profile]);	

	useEffect(() => {
		setLoadingScroll(true);
		setHasNextPage(searchNew.hasNextPage);
		setResults(searchNew.results);
		if (profile.profile) scrollSearch(profile.profile.data.email);
	}, [searchNew.results]);

	useEffect(() => {
		if (searchNew.profiles) {
			setProfiles(searchNew.profiles);
		} else {
			setProfiles();
		}
	}, [searchNew.profiles]);

	useEffect(() => {
		if (results) {
			setLoadingScroll(false);
		}
	}, [results]);

	useEffect(() => {
		if (!loadingScroll && hasNextPage) {
			let observerScroll = document.getElementById('observerScroll');
			if (observerScroll) {
				let intersectionObserver = new IntersectionObserver(
					(entries) => {
						if (entries[0].isIntersecting) {
							setLoadingScroll(true);
							handleMoreResults();
						}
					},
					{
						rootMargin: '0px 0px 1px 0px',
					},
				);
				intersectionObserver.observe(observerScroll);
				return () => {
					intersectionObserver.disconnect();
				};
			}
		}
	}, [loadingScroll]);

	useEffect(() => {
		setLoadingScroll(true);
		setPage(1);
	}, [searchNew.filters]);

	useEffect(() => {
		setPage(1);
	}, [filters]);

	useEffect(() => {
		if (filters.editorial === 'Todos') {
			filterSearchNew({ ...filters, editorial: '' }, 1);
			setFilters({ ...filters, editorial: null });
		} else if (filters.editorial) {
			filterSearchNew(filters, 1);
		}
	}, [filters.editorial]);

	useEffect(() => {
		if (filters.lang === 'Todos') {
			filterSearchNew({ ...filters, lang: '' }, 1);
			setFilters({ ...filters, lang: null });
		} else if (filters.lang) {
			filterSearchNew(filters, 1);
		}
	}, [filters.lang]);

	useEffect(() => {
		if (filters.type === '') {
			filterSearchNew({ ...filters, type: '' }, 1);
		} else if (filters.type) {
			filterSearchNew(filters, 1);
		}
	}, [filters.type]);

	const handleResize = () => {
		setIsMobile(window.innerWidth < breakPoint.mobileWide);
	};

	const resetFilters = () => {
		setFilters({
			editorial: null,
			lang: null,
			type: null,
			student: null,
			admin: null,
			profesor: null,
		});
	};

	const handleMoreResults = () => {
		filterSearchNew(filters, page + 1);
		setPage(page + 1);
	};

	return (
		<div
			className={isMobile ? styles.mainMobileContainer : styles.mainContainer}
		>
			{searchNew.loading ? (
				<LoadSearch />
			) : !results && !searchNew.loading ? (
				<SearchPlaceholder />
			) : (
				<>
					<div className={isMobile ? '' : styles.gridContainer}>
						<div
							className={
								isMobile
									? styles.filtersMobileContainer
									: styles.filtersContainer
							}
						>
							{isMobile && (
								<button
									className={styles.filterButton}
									onClick={toggleVisibility}
								>
									<img
										src={isFilterVisible ? iconFilterOn : iconFilterOff}
										alt="Filter"
										width="20"
										height="20"
									/>
								</button>
							)}
							<div className={isFilterVisible ? '' : styles.invisibleContainer}>
								<FiltersComponent
									filtersFromSearch={searchNew.filters}
									profilesFromSearch={searchNew.profiles}
									filtersFromComponent={filters}
									setFilters={setFilters}
									setProfiles={setProfiles}
									isMobile={isMobile}
								/>
							</div>
						</div>
						{isMobile && (
							<div className={styles.profilesContainer}>
								<ProfilesResult
									profiles={profiles}
									searchTerm={searchNew.searchTerm}
									isMobile={isMobile}
								/>
							</div>
						)}
						<div className={styles.contentContainer}>
							{searchNew.loadingFilters ? (
								<LoaderSearchContent />
							) : results && results.length > 0 ? (
								<>
									<h2 className={styles.fontTitle}>
										{t('titleSearchContents', { query: searchNew.searchTerm, totalResults:searchNew.totalHits ,totalPages: searchNew.totalPages})}
									</h2>
									<div style={{ marginTop: '25px' }}>
										<ContentResult
											results={searchNew.results}
											isMobile={isMobile}
										/>

										{loadingScroll && <LoaderBooklistContent />}

										{hasNextPage && !loadingScroll ? (
											<div
												className={styles.observerScroll}
												id="observerScroll"
											/>
										) : null}
									</div>
								</>
							) : (
								<NoResultSearch />
							)}
						</div>
						{!isMobile && (
							<div className={styles.profilesContainer}>
								<ProfilesResult
									profiles={profiles}
									searchTerm={searchNew.searchTerm}
									isMobile={isMobile}
								/>
							</div>
						)}
					</div>
				</>
			)}

			{exploration.openPopUpShare && (
				<PopUpShare closePopUp={() => setPopUpShare(null, false)} />
			)}
		</div>
	);
};

function mapStateToProps({ searchNew, search, exploration, profile }) {
	return {
		searchNew,
		search,
		exploration,
		profile,
	};
}

export default {
	component: nativeMenu(
		withRouter(
			connect(mapStateToProps, {
				setPopUpShare,
				filterSearchNew,
			})(SearchV2Page)
		)
	),
};
