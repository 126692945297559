 import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import styles from './styles/popupCreate.scss'
import {connect} from 'react-redux';
import {
  fetchOthersContent,
  fetchMyContent,
  postBooklist,
  postContentToBooklist,
  submitEditingContent,
  submitBooklistCover
} from '../../actions';

import CreateBooklistCard from './CreateBooklistCard'
import { withTranslation } from 'react-i18next';

const iconCamera = "https://cdn.booklick.net/public/img/icons/icon-camera-fill.svg";
const iconSearch = "https://cdn.booklick.net/public/img/icons/icon-search-grey.svg";

const imageProfile = 'https://cdn.booklick.net/public/img/profile.png';
const imageDefault = "https://cdn.booklick.net/public/img/images/mini-01.png";

const breakPointResponsive = 550;

class CreateBooklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewImage: null,
      previewTitle: null,
      previewDescription: null,
      imageFile: null,
      content: [],
      selectedContent: [],
      loading: false,
      error: false,
      data: undefined,
      loadingData: false,
      alertMessage: false,
      search: '',
      mobileMenu: false,
    }
    this.handleSubmitBooklist = this.handleSubmitBooklist.bind(this)
    this.handleSelectContent = this.handleSelectContent.bind(this)
    this.handleRemoveContent = this.handleRemoveContent.bind(this)
    this.handleSearch = this.handleSearch.bind(this)

  }

  componentDidMount() {
    this.fetchData()


  }

  fetchData = async () => {
    this.setState({ loading: true, error: null})
    try {
      const data = await this.props.fetchMyContent(this.props.profile.profile.data.email)
      this.setState({
        loading: false,
        data: data,
        content: data.data,
        searchData: data.data
      })
      if(this.props.contextMenu) {
        let included = data.data.filter(content => content.id === this.props.contextMenu.id)
        if(included.length === 0) {
          let newData = {
            data: [...this.state.data.data, this.props.contextMenu],
            included: [...this.state.data.included, this.props.contextMenu],
          }
          this.setState({
            data: newData,
            content: [this.props.contextMenu, ...this.state.content],
            searchData: [this.props.contextMenu, ...this.state.searchData],
          })
        }
      }
    } catch (error) {
      this.setState({ loading: false, error: error})
    }
  }
  componentDidUpdate (prevProps) {
    if(this.props.mobileMenuPreviewStep !== prevProps.mobileMenuPreviewStep) {
      this.setState({
        mobileMenu: this.props.mobileMenuPreviewStep
      })
    }
  }

  handleImageUpload = (file) => {
    this.setState({
      imageFile: file[0]
    })
    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        previewImage: reader.result
      });
    }
    reader.readAsDataURL(file[0])
  }
  
  handleText = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSearch= event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
    if(this.state.search === '') {
      this.setState({
        content: this.state.searchData
      })
    } else {
      setTimeout(
        () => this.setState({
          content: this.state.searchData.filter(item => item.attributes.title.toLowerCase().search(new RegExp(this.state.search.toLowerCase())) !== -1)
        })
        ,500)

    }
  }

  handleSelectContent = (id) => {
    this.setState({
      selectedContent: [
        ...this.state.selectedContent,
        {
          data: this.state.data.data.find(item => item.id === id),
          included: this.state.data.included.find(item => item.id === id),
        }
      ]
    })
  }
  handleRemoveContent = (id) => {
    this.setState({
      selectedContent: this.state.selectedContent.filter(item => item.data.id !== id)
    })

  }

  handleSubmitBooklist = async () => {
    try {
      if(
        !this.state.previewTitle ||
        !this.state.previewDescription
        ) {
        this.handleShake()

      } else {
        this.setState({
          loadingData: true
        })

        if(this.state.imageFile) {
          const imageUrl = await this.props.submitBooklistCover(this.state.imageFile)
          this.setState({
            imageFile: imageUrl
          })
        }

        const response = await this.props.postBooklist({
          title: this.state.previewTitle,
          description: this.state.previewDescription,
          isPublic: true,
          image: this.state.imageFile ? this.state.imageFile : null
        })
        const booklist = response;

        if(this.state.selectedContent.length !== 0) {
          let contentSlugs = this.state.selectedContent.map(content => content.data.slug)
          postContentToBooklist(booklist.id, contentSlugs, this.props.profile)
          
        }
        this.setState({
          loadingData: false
        })
        this.props.handleCreateBooklistMobile(false)
        this.props.booklistId(booklist)
        this.props.positionPopup(4)
      }

    } catch (error) {
      console.error(error)
    }
  }
  handleShake = () =>{
    this.setState({
      alertMessage: true
    })
    setTimeout(
      () => this.setState({
        alertMessage:false
      })
      ,500)
  }
  handleMobileCreate = () => {
    if(
      !this.state.previewTitle ||
      !this.state.previewDescription
      ) {
      this.handleShake()

    } else {
      this.setState({ mobileMenu: !this.state.mobileMenu})
      this.props.handleCreateBooklistMobile(true)
    }
  }

  render() {
    const { t } = this.props;
    return (

      <div className={`${styles.gridAdd} ${styles.alignStart} ${styles.gridRemove}` }>

      <div className={styles.gridRow16}>
      {(this.props.mobileMenu && this.props.mobileMenu < breakPointResponsive ) && this.state.mobileMenu ?
        null
        :
        <div className={styles.gridInfoAdd}>
          <Dropzone onDrop={this.handleImageUpload}>
            {({ getRootProps, getInputProps }) => (
              <div  {...getRootProps()}>
                <input {...getInputProps()} />
                {
                  this.state.previewImage ?
                  <div className={styles.boxaddpic}>
                    <img
                      className={styles.fileImgCard}
                      src={this.state.previewImage}
                      alt="cover"
                    />
                    <div className={styles.fileGridRow10}>
                      <div className={styles.circleIcon}>
                        <img className={styles.icon26} src={iconCamera} alt="add pic" />
                      </div>
                      <h3 className={styles.h3infocenter2}>{t('textImageChange')}</h3>
                    </div>
                  </div>
                  :
                  <div className={styles.boxaddpic}>
                    <div className={styles.gridRow10}>
                      <div className={styles.circleIcon}>
                        <img className={styles.icon26} src={iconCamera} alt="add pic"/>
                      </div>
                      <h3 className={styles.h3infocenter2}>{t('textImageChange')}</h3>
                    </div>
                  </div>
                }
              </div>
            )}
          </Dropzone>

          <div className={styles.gridRow10}>
            <input
              name='previewTitle'
              datatest="booklist-title"
              type="text"
              className={this.state.alertMessage && !this.state.previewTitle ? `${styles.input} ${styles.alertVibration}` : styles.inputTitle}
              placeholder={t('textTitleContent')}
              value={this.state.previewTitle ? this.state.previewTitle : null}
              onChange={this.handleText}
            />
            <textarea
              name="previewDescription"
              datatest="booklist-description"
              rows="7"
              cols="1"
              className={this.state.alertMessage && !this.state.previewDescription ? `${styles.inputDescriptionBooklist} ${styles.alertVibration}` : styles.inputDescriptionBooklist}
              placeholder={t('textDescriptionContent')}
              onChange={this.handleText}
              value={this.state.previewDescription  ? this.state.previewDescription : null}
              >
              </textarea>

          </div>

        </div>

      }
        {(this.props.mobileMenu && this.props.mobileMenu < breakPointResponsive ) && !this.state.mobileMenu ?
          <div className={styles.btnContainerMobile}>
            <div
              className={`${styles.btnGreenBooklists} ${styles.btnCreateMobile2}`}
              onClick={this.state.loadingData === true ? null : this.handleSubmitBooklist}
              >
              {this.state.loadingData ? <div className={`${styles.fileSpinner} ${styles.fileIcon}`} aria-hidden="true"></div> : t('textNavBarCreated')}
            </div>
            <div
              className={styles.btnSelectContent}
              onClick={this.handleMobileCreate}
              >
              {t('titleSelectContent')}
            </div>
          </div>
          :
          <div className={styles.interCardScroll}>
            <div
              className={`${styles.btnGreenBooklists} ${styles.btnCreateMobile}`}
              onClick={this.state.loadingData === true ? null : this.handleSubmitBooklist}
              >
              {this.state.loadingData ? <div className={`${styles.fileSpinner} ${styles.fileIcon}`} aria-hidden="true"></div> : t('textNavBarCreated')}
            </div>
            <div className={styles.gridRow10}>
              <h3 className={styles.h3titlecardSearch}>{t('titleSelectContentFull')}</h3>
              <div className={styles.max200search}  >
                <div className={styles.relative}>
                  <input
                    autoComplete= "off"
                    id='search'
                    name='search'
                    type="search"
                    className={styles.inputSearch}
                    placeholder={t('titleSearchMyContent')}
                    onChange={this.handleSearch}/>
                  <div className={styles.posSearch}>
                    <img className={styles.icon16} src={iconSearch} alt="search"/>
                  </div>
                </div>
              </div>

            <div className={`${styles.grid3content} ${styles.paddingBottom}`}>
              {
                this.state.loading ? null
                : this.state.content && this.state.content.map(content => {
                  return (
                    <CreateBooklistCard
                      key={content.id}
                      handleSelectContent={content => this.handleSelectContent(content)}
                      handleRemoveContent={content => this.handleRemoveContent(content)}
                      item={content}
                      contentId={this.props.contextMenu ? this.props.contextMenu.id : this.props.contentId}
                      selectedContent={this.state.selectedContent.map(selected => selected.data.id)}
                    />
                  )

                })
              }
              {
                this.state.search !== '' && this.state.content && this.state.content.length === 0 ?
                <h2 className={styles.h2Message}>{t('textContentEmpty')}</h2>
                :
                null
              }
              {
                this.state.loading ? null
                : this.state.search === '' && this.state.content && this.state.content.length === 0 ?
                <p className= {styles.paragraphContentNull}>{t('textContentInvitationCreated')}
                </p>
                : null
              }

            </div>
            </div>
          </div>
        }
      </div>

      <div className={styles.gridRow10}>

        <div className={styles.space10}></div>

        <div className={styles.gridCard}>

          <div className={styles.posUser}>
            <div className={styles.circleUser}>
              <img
                className={styles.imgCircle}
                src={this.props.profile.profile.data.profileImgUrl ? this.props.profile.profile.data.profileImgUrl : imageProfile}
                alt="user"/>
            </div>
          </div>

          <div className={styles.distance}>
            <div className={styles.gridSpace}>
              <div>
                <h3 className={styles.h3titlecard}>{t('textBooklistTo')}</h3>
                <h3 className={styles.h3titlecard}>{`${this.props.profile.profile.data.firstName} ${this.props.profile.profile.data.lastName}`}</h3>

              </div>
              <div className={styles.miniBtn}>
                {t('buttonProfileFollow')}
              </div>
            </div>
          </div>

          <div className={styles.gridCardsContent}>
            <div className={`${styles.boxImagesCard} ${styles.z3}`}>
              <img
                className={styles.imgCard}
                src={
                  this.state.previewImage ?
                    `${this.state.previewImage}`
                  :
                    t('imageCreateBooklist')
                    }
                alt="portada"/>
            </div>

          </div>
          <h3 className={styles.h3titlecard2}>
            {this.state.previewTitle ? this.state.previewTitle : t('textTitleContent')}
          </h3>
          <p className={styles.paragraphCard}>{this.state.previewDescription === null || this.state.previewDescription === '' ? t('textDescriptionContent') : this.state.previewDescription}</p>
        </div>



        <div >
          <div className={styles.gridRow10}>
            <div
              className={`${styles.btnGreenBooklists} ${styles.none550}`}
              datatest="button-create"
              onClick={this.state.loadingData === true ? null : this.handleSubmitBooklist}
              >
              {this.state.loadingData ? <div className={`${styles.fileSpinner} ${styles.fileIcon}`} aria-hidden="true"></div> : t('textNavBarCreated')}
            </div>
          </div>

        </div>

      </div>

    </div>

    )
  }

}

function mapStateToProps({ profile }) {
	return {
    profile,

  };
}

export default connect(mapStateToProps, {
  fetchOthersContent,
  fetchMyContent,
  postBooklist,
  submitEditingContent,
  submitBooklistCover
} )(withTranslation()(CreateBooklist));
