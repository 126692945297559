import React, { useEffect, useState } from 'react';
import DropDown from '../shared/DropDown';
import { useTranslation } from 'react-i18next';
import styles from '../../pages/styles/searchV2Page.scss';
import useOptionDropDown from '../../hooks/useOptionDropDown';
import {
	contentTypeFilter,
	ISO_369_1,
	profileTypes,
} from '../../../utils/constans';

const FiltersComponent = ({
	filtersFromSearch,
	profilesFromSearch,
	filtersFromComponent,
	setFilters,
	setProfiles,
	isMobile,
}) => {
	const { t } = useTranslation();

	const [listEditorials, setListEditorial] = useState([]);
	const [listLanguages, setListLanguages] = useState([]);
	const [editorials, editorial, handleEditorial, resetEditorial] =
    useOptionDropDown(listEditorials, true);
	const [languages, language, handleLaguage, resetLanguage] = useOptionDropDown(
		listLanguages,
		true
	);
	const [profileTypesList, setProfileTypesList] = useState([]);
	const [documentFilter, setDocumentFilter] = useState([]);
	const [
		roleOptions,
		selectedRole,
		handleRole,
		resetSelectedRole,
		defaultRole,
	] = useOptionDropDown(profileTypesList, false);

	const [
		typeOptions,
		selectedType,
		handleType,
		resetSelectedType,
		defaultType,
	] = useOptionDropDown(documentFilter, false);

	useEffect(() => {
		filtersFromSearch &&
      filtersFromSearch.editorial &&
      setListEditorial(
      	filtersFromSearch.editorial.map((editorial) => {
      		return {
      			value: editorial.name,
      			label:
              editorial.name === 'no editorial'
              	? t('textNoEditorial')
              	: editorial.name,
      		};
      	})
      );

		filtersFromSearch &&
      filtersFromSearch.language &&
      setListLanguages(
      	filtersFromSearch.language.map((lan) => {
      		const index = ISO_369_1.findIndex((item) => item.name === lan.name);
      		return {
      			value: lan.name,
      			label: t('dropDownLanguage')[index].label,
      		};
      	})
      );
	}, [filtersFromSearch]);

	useEffect(() => {
		if (editorial.length > 0 || editorial) {
			setFilters({ ...filtersFromComponent, editorial: editorial.value });
		}
	}, [editorial]);

	useEffect(() => {
		if (language.length > 0 || editorial) {
			setFilters({ ...filtersFromComponent, lang: language.value });
		}
	}, [language]);

	useEffect(() => {
		if (selectedRole?.value) {
			setProfiles(
				profilesFromSearch.filter(
					(profile) => profile.rol === selectedRole.value,
				),
			);
		} else {
			setProfiles(profilesFromSearch);
		}
	}, [selectedRole]);

	useEffect(() => {
		const translatedProfiles = profileTypes.map((type) => ({
			value: type.value,
			label: t(type.translationKey),
		}));
		setProfileTypesList(translatedProfiles);
	}, [t]);

	useEffect(() => {
		if (contentTypeFilter && filtersFromSearch?.type) {
			const filters = contentTypeFilter
				.filter((contentType) =>
					filtersFromSearch.type.some(
						(filter) => filter.name === contentType.value,
					),
				)
				.map((contentType) => ({
					value: contentType.value,
					label: t(contentType.translationKey),
				}));

			setDocumentFilter(filters);
		}
	}, [filtersFromSearch, t]);

	useEffect(() => {
		if (selectedType?.value) {
			setFilters({
				...filtersFromComponent,
				type: selectedType.value,
			});
		}
	}, [selectedType]);	

	return (
		<>
			<div
				style={
					isMobile
						? null
						: {
								height: '100vh',
								position: 'absolute',
								width: '20vw',
								maxWidth: '280px',
						  }
				}
				className={styles.boxPeople}
			>
				<h2 className={styles.fontTitle}>{t('titleSearchFilters')}</h2>
				<div className={isMobile ? styles.filtersContainerMobile : null}>
					<div style={{ marginTop: '30px' }}>
						<DropDown
							title={t('searchFilterSource')}
							listOptions={listEditorials}
							maxWidth={'218px'}
							width={isMobile ? '180px' :'218px'}
							minHeight={35}
							fontSizeTitle={'15px'}
							fontSizeDropDown={14}
							options={editorials}
							value={editorial}
							handleValue={handleEditorial}
							boxShadow={'0 2px 6px 0 rgba(0, 0, 0, 0.1)'}
							borderRadius={4}
						/>
					</div>
					<div style={{ marginTop: '30px' }}>
						<DropDown
							title={t('searchFilterLang')}
							listOptions={listLanguages}
							maxWidth={'218px'}
							width={isMobile ? '180px' :'218px'}
							minHeight={35}
							fontSizeTitle={'15px'}
							fontSizeDropDown={14}
							options={languages}
							value={language}
							handleValue={handleLaguage}
							boxShadow={'0 2px 6px 0 rgba(0, 0, 0, 0.1)'}
							borderRadius={4}
						/>
					</div>
				</div>
				<div className={styles.containerFilter}>
					<h3 className={styles.titleFilter}>
						{t('searchFilterProfileType')}
					</h3>
					<div
						className={
							isMobile
								? styles.containerRadioButtonsMobile
								: styles.containerRadioButtons
						}>
						<DropDown
							options={roleOptions}
							isSearchable={false}
							placeholder={t('textChooseRole')}
							handleValue={handleRole}
							value={selectedRole}
							minHeight={35}
							fontSizeDropDown={14}
							fontSizeTitle={'15px'}
							maxWidth={'218px'}
							width={isMobile ? '180px' :'218px'}
							boxShadow={'0 2px 6px 0 rgba(0, 0, 0, 0.1)'}
							borderRadius={4}
						/>
					</div>
				</div>
				<div className={styles.containerFilter}>
					<h3 className={styles.titleFilter}>
						{t('searchFilterDocType')}
					</h3>
					<div className={
						isMobile ? 
							styles.containerRadioButtonsMobile : 
							styles.containerRadioButtons
					}>
						{filtersFromSearch && filtersFromSearch.type && (
							<DropDown
								options={typeOptions}
								isSearchable={false}
								placeholder={t('textChooseType')}
								handleValue={handleType}
								value={selectedType}
								minHeight={35}
								fontSizeDropDown={14}
								fontSizeTitle={'15px'}
								maxWidth={'218px'}
								width={isMobile ? '180px' : '218px'}
								boxShadow={'0 2px 6px 0 rgba(0, 0, 0, 0.1)'}
								borderRadius={4}
							/> 
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default FiltersComponent;
