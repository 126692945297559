import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { openProfile } from "../../../helpers/profileRouting";
import styles from "./styles/resultsPeople.scss";
import { profileDefaultImage } from "../../../utils/constans";
import {
  putFollowing,
  loadingProfile,
  visitStats,
  deleteFollowing,
} from "../../actions";
import useRoleProfile from "../../hooks/useRoleProfile";
import { getProfileByEmail } from "../../actions/profileActions";

const ResultsPeople = ({
  history,
  profileResult,
  loadingProfile,
  visitStats,
  profile,
}) => {
  const [isFollow, setIsFollow] = useState();
  const { t } = useTranslation();
  const { roleKey } = useRoleProfile(profileResult.rol);

  useEffect(() => {
    if (
      profile &&
      profile.profile &&
      profile.profile.data &&
      profile.profile.data.email !== profileResult.email
    ) {
      getProfileByEmail(profileResult.email).then((data) =>
        setIsFollow(data.isFollow)
      );
    }
  }, []);

  const unfollow = () => {
    deleteFollowing(profileResult.email);
    setIsFollow(false);
  };

  const follow = () => {
    putFollowing(profileResult.email);
    setIsFollow(true);
  };

  const handleOpenProfile = async () => {
    openProfile(
      profileResult.email,
      profileResult.id,
      loadingProfile,
      history,
      visitStats,
      true
    );
  };

  return (
    <div className={styles.gridPeople}>
      <div className={styles.relative}>
        <div className={styles.circleUser} onClick={handleOpenProfile}>
          <img
            className={styles.imgPeople}
            src={
              profileResult.image ? profileResult.image : profileDefaultImage
            }
            alt={profileResult.name || profileResult.firstName}
          />
        </div>
        {isFollow != undefined && (
          <div className={styles.posLogo}>
            <div className={styles.circleLogo}>
              {isFollow ? (
                <span
                  onClick={() => unfollow()}
                  className={`material-symbols-outlined ${styles.iconRemove}`}
                >
                  do_not_disturb_on
                </span>
              ) : (
                <span
                  onClick={() => follow()}
                  className={`material-symbols-outlined ${styles.iconAdd}`}
                >
                  add_circle
                </span>
              )}
            </div>
          </div>
        )}
      </div>

      <div className={styles.gridRow5}>
        <div>
          <h2 className={styles.fontTitle}>{t(roleKey)}</h2>
          <h2
            className={styles.font}
            style={{ marginTop: "5px" }}
            onClick={handleOpenProfile}
          >
            {profileResult.name || profileResult.firstName}
          </h2>
          <h2 className={`${styles.font} ${styles.fontLight}`}>
            {profileResult.mayor}
          </h2>
        </div>

        <div className={styles.button_94px_blue} onClick={handleOpenProfile}>
          {t("buttonViewPeople")}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ profile }) => {
  return {
    profile,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    loadingProfile,
    visitStats,
  })(ResultsPeople)
);
