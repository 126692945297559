import React, {Component} from 'react';
import Cognito from '../../../helpers/cognito';
import { visitStats, getSimilarMayor, getTeachers, getSameUniversity, getOtherUniversity,
	fetchProfile, fetchFollowers, fetchFollowing} from '../../actions';
import styles from './styles/discoverPeople.scss';
import DiscoverPeopleItem from './DiscoverPeopleItem';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';
import Loading from '../../components/shared/Loading';

const basicShowMore = 10;
const following = 'following';
const followers = 'followers';
const breakPointMobile = 550;
const breakPointTablet = 1050;
const millisecondForRender = 1000;

class DiscoverPeopleRow extends Component{

	constructor(props) {
		super(props);
		this.state = {
			profiles: [],
			loadMore: false,
			page: 1,
			showMore: 5,
			mayor: '',
			university: '',
			width: 0,
			height: 0,
		};
  
		this.handleLoadMore = this.handleLoadMore.bind(this);
	}

	async componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);

		this.fetchData();
		if(this.state.width < breakPointTablet) {
			setTimeout(() => this.scrollFetch() ,millisecondForRender)
			
		}
		try {
			const session = await Cognito.retrieveToken();
			this.setState({ Logged: true});
		} catch (error) {
			console.error(error);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.profile.profile === null){
			this.props.fetchProfile();
		}else if (this.props.profile.profile !== prevProps.profile.profile) {
			this.setState({
				mayor: this.props.profile.profile.data.mayor,
				university: this.props.profile.profile.data.belongsTo,
			});
		}

		if(this.props.followers !== prevProps.followers){
			this.fetchData();
		}

		if(this.props.following !== prevProps.following){
			this.fetchData();
		}
		if(this.state.width !== prevState.width) {
			if(this.state.width < breakPointTablet) {
				
				setTimeout(() => this.scrollFetch() ,millisecondForRender)
			}
		}
	}
	scrollFetch = () => {
		if(this.state.width < breakPointTablet) {
			const observerScroll = document.getElementById('observerScroll');
			const intersectionObserver = new IntersectionObserver(entries => {
				if(entries[0].isIntersecting) {
					this.handleLoadMore()
				}
			}, {
				rootMargin: '0px 100% 0px  0px',
			})
			intersectionObserver.observe(observerScroll);
		}
	}

	async fetchData(){
		if (this.props.profile.profile !== null){
			this.setState({
				mayor: this.props.profile.profile.data.mayor,
				university: this.props.profile.profile.data.belongsTo
			});
		}
		
		switch (this.props.type) {
		case 'similarMayor':
			this.setState({
				profiles: await getSimilarMayor(this.state.page)
			});
			break;
		case 'teachers':
			this.setState({
				profiles: await getTeachers(this.state.page)
			});
			break;
		case 'sameUniversity':
			this.setState({
				profiles: await getSameUniversity(this.state.page)
			});
			break;
		case 'otherUniversity':
			this.setState({
				profiles: await getOtherUniversity(this.state.page)
			});
			break;
		case 'following':
			this.setState({
				profiles: this.props.following ? this.props.following : []
			});
			break;
		case 'followers':
			this.setState({
				profiles: this.props.followers ? this.props.followers : []
			});
			break;
		default:
			break;
		}
	}
	
	handleTitle(){
		let title;
		switch (this.props.type) {
		case 'similarMayor':
			title = this.props.t('titleDiscoverPeopleStudent',{mayor:this.state.mayor});
			break;
		case 'teachers':
			title = this.props.t('titleDiscoverPeopleProfessor',{mayor:this.state.mayor});
			break;
		case 'sameUniversity':
			title = this.props.t('titleDiscoverPeopleSameUniversity',{mayor:this.state.university});
			break;
		case 'otherUniversity':
			title = this.props.t('titleDiscoverPeopleOtherUniversity');
			break;
		case 'following':
			title = this.props.t('buttonProfileFollows');
			break;
		case 'followers':
			title = this.props.t('textFollowsContent'); 
			break;
		default:
			title = this.props.t('titleDiscoverPeopleOtherMistake');
			break;
		}
		return title;
	}
 
	async handleLoadMore(){

		switch (this.props.type) {
		
		case 'similarMayor':
			this.setState({
				profiles: this.state.profiles.concat( await getSimilarMayor(this.state.page + 1) )
			});
			break;

		case 'teachers':
			this.setState({
				profiles: this.state.profiles.concat( await getTeachers(this.state.page + 1) )
			});
			break;

		case 'sameUniversity':
			this.setState({
				profiles: this.state.profiles.concat( await getSameUniversity(this.state.page + 1) )
			});
			break;

		case 'otherUniversity':
			this.setState({
				profiles: this.state.profiles.concat( await getOtherUniversity(this.state.page + 1) )
			});
			break;

		}
  
		this.setState({
			page: this.state.page + 1,
			showMore: this.state.showMore + 10
		});
	}
	updateWindowDimensions = () => {
		this.setState({
			width: window.innerWidth,
			height: window.innerHeight
		});
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	render() {
		const {t} = this.props
		if (!this.props.profile.profile) return <div className={styles.loadingContainer}><Loading loading/></div>;
		return(
			<>
				{this.state.profiles.length !== 0 ?
					<>
						<div className={!this.state.loadMore ? styles.gridRow20 : styles.gridRow20LoadMore}>
				 
					
							<div className={styles.gridSpaceTitle}>
								<h2 className={styles.h2Title}>{this.handleTitle()}</h2>
								{this.state.profiles.length >= this.state.showMore ?
									!this.state.loadMore ?
										<div className={styles.btnAll} onClick={() =>{
											this.setState({
												loadMore: true,
												showMore: 10,
											});
										}}>
											{t('titleViewMore')}
										</div>
										:
										<div className={styles.btnAll} onClick={() =>{
											this.setState({
												loadMore: false,
												showMore: 5,
											});
										}}>
											{t('titleViewLess')}
										</div>
									:
									!this.state.loadMore ?
										undefined
										:
										<div className={styles.btnAll} onClick={() =>{
											this.setState({
												loadMore: false,
												showMore: 5,
											});
										}}>
											{t('titleViewLess')}
										</div>
								}
							</div>

							<div className={styles.scrollH}>
								<div className={styles.gridContentUsers}>
									{this.state.profiles.slice(0,this.state.showMore).map((profile, i) =>
										<DiscoverPeopleItem key={i} mobile={this.state.width} {...profile}/>
									)}
									{this.state.loadMore ?
										this.state.profiles.length >= this.state.showMore ?
											<div className={styles.btnLoadMore} onClick={() => this.handleLoadMore()}>
												{t('titleDiscoverPeopleLoadMore')}
											</div>
											:
											this.state.profiles.length >= (basicShowMore * this.state.page)  && (
												<div className={styles.loadMoreMessage}>
													{t('titleDiscoverPeopleEmpty')}
												</div>
											)
										:
										undefined
									}
								
								</div>
								<div id='observerScroll'></div>
							</div>
							
						</div>
						<div className={`${styles.gridContentUsers} ${styles.gridContentUsersLine}`}>
						</div>
					</>
					:
					this.props.type === following || this.props.type === followers ?
						<>
							<h2 className={styles.h2Title}>Sin {this.handleTitle()}</h2>
							<div className={`${styles.gridContentUsers} ${styles.gridContentUsersLine}`}>
							</div>
						</>
						:
						undefined
				}
			</>
		);
	}
}

function mapStateToProps({profile, search, institution}) {
	return { profile, search, institution };
}

export default withRouter(connect(mapStateToProps, {
	fetchProfile,
	visitStats,
	fetchFollowers,
	fetchFollowing
})(withTranslation()(DiscoverPeopleRow)));
