import React, {Component} from 'react';
import {
	fetchOtherProfile,
	getTags,
	getLikeTotal,
	visitStats,
	loadingProfile,
	getLike,
	putLike,
	deleteLike,
	putFollowing,
	deleteFollowing,
	getVisits,
	fetchProfile,
	postRedirectContent,
	openPopUp,
	environment
} from '../../actions';
import ContextMenu from '../context-menu/ContextMenu';
import {openProfile} from '../../../helpers/profileRouting';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styles from './styles/contentNewCard.scss';
import { withTranslation } from 'react-i18next';
import { getResources } from '../../../utils/constans';
import Cognito from '../../../helpers/cognito';

const iconlike = 'https://cdn.booklick.net/public/img/icons/icon-like-grey.svg' ;
const iconPageWhite = 'https://cdn.booklick.net/public/img/icons/text-white.svg';
const iconBooklistWhite = 'https://cdn.booklick.net/public/img/icons/booklist-white.svg';
const iconBookWhite = 'https://cdn.booklick.net/public/img/icons/book-white.svg';
const iconFollowblue = 'https://cdn.booklick.net/public/img/icons/icon-follow-blue.svg';
const iconfollowWhite = 'https://cdn.booklick.net/public/img/icons/icon-follow-white.svg';

const imageCover = getResources(environment).defaultCover;


const imageProfile = 'https://cdn.booklick.net/public/img/profile.png';
const coverOffilineBook = 'https://cdn.booklick.net/public/img/images/Azul+Libros.jpg';

const maxLettersTitle = 60;
const maxLettersDescription = 140;
const maxNumberTags = 5;
const millimetersForRender = 800;
const offLineBookType = 'OFFLINE_BOOKS';
const label = 'Libro Físico';
const libraryNamePosition = 4;
const descriptionText = 'Biblioteca: ';


class ContentNewCard extends Component{
	constructor(props) {
		super(props);
		this.state = {
			content: this.props.content,
			message: 'Seguido',
			tags: null,
			visits: null,
			totalLikes: 0,
			like: false,
			followed: false,
			loginUser: this.props.profile.profile,
			author: null,
			reader: this.props.content.url,
			icon: iconPageWhite,
			numberTitleLetters: null,
			numberDescriptionLetters: null,
			numberTags: null,
			offLineBook: this.props.content.data.type === offLineBookType,
		};
		this.handleGoToContent = this.handleGoToContent.bind(this);
		this.handlePutLike = this.handlePutLike.bind(this);
		this.handleRemoveLike = this.handleRemoveLike.bind(this);
		this.handleUnfollow = this.handleUnfollow.bind(this);
		this.handleFollow = this.handleFollow.bind(this);
		this.handleContentType = this.handleContentType.bind(this);
	}

	componentDidMount() {
	   this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.profile.profile === null){
			this.props.fetchProfile();
		}else if (this.props.profile.profile !== prevProps.profile.profile) {
			this.setState({
				loginUser: this.props.profile.profile
			});

		}
		if(this.props.content.tags !== prevProps.content.tags) {
			setTimeout(() => {
				this.setState({
					tags: this.props.content.tags
				});
			}, millimetersForRender);
		}
	}

	async fetchData() {

		const author = await fetchOtherProfile(this.props.content.data.author);
		if(author.data.data){
			this.setState({
				author: author.data.data
			});
		}
		const tags = await getTags(this.state.content.data.id);
		const visits = await getVisits(this.state.content.data.slug);
		this.setState({
			tags: this.props.content.tags ? this.props.content.tags : tags,
			visits,
			numberDescriptionLetters: this.props.content.data.description.length,
			numberTitleLetters: this.props.content.data.title.length,
			numberTags: tags.length,

		});
		const totalLikes = await getLikeTotal(this.state.content.data.id);
		const like = await getLike(this.state.content.data.id);
		this.setState({
			totalLikes,
			like
		});
		const following = this.props.profile.profile && this.props.profile.profile.data.following ? this.props.profile.profile.data.following.filter(
			(profile) => profile.id === this.state.author.id).length > 0 : false;
		this.setState({
			followed: following
		});

		this.handleContentType();
	}

	handleContentType() {
		let icon;
		switch (this.state.content.data.type){
		case 'BOOKS':
		case 'DOCUMENTS':
		case 'ARTICLES':
			icon = iconPageWhite;
			break;
		case 'BOOKLISTS':
		case 3:
			icon =  iconBooklistWhite;
			break;
		case 'EXTERNAL_BOOKS':
		case 'OFFLINE_BOOKS':
			icon = iconBookWhite;
			break;
		case 'LINKS':
		case 7:
			icon = iconPageWhite;
			break;
		}
		this.setState({
			icon
		});
	}

	async handleGoToContent() {
		const session = await Cognito.retrieveToken();
		let url;
		switch (this.state.content.data.type){
		case 'BOOKS':
		case 'DOCUMENTS':
		case 'ARTICLES':
		case 2:
			url =  this.state.content.data.url ?  `${this.state.content.data.url}?access_token=${session.accessToken.jwtToken}` :  `${this.state.content.data.reader}?access_token=${session.accessToken.jwtToken}`;
			break;
		case 'EXTERNAL_BOOKS':
		case 'OFFLINE_BOOKS':
			url =  this.state.content.data.hostURL || this.state.content.data.url;
			break;
		case 'LINKS':
		case 7:
			url = this.state.content.data.url;
			break;
		}
		this.props.postRedirectContent(this.state.content.data.id);
		this.props.visitStats(this.state.content.data.slug, this.state.content.type);
		window.open(url, '_blank');
	}

	async handlePutLike() {
		if(this.state.loginUser !== null) {
			try {
				this.setState({
					like: true,
					totalLikes: this.state.totalLikes + 1
				});
				const addLike = this.props.putLike(this.state.content.data.id);
			} catch (error) {
				console.error(error);
			}
		} else {
			this.props.openPopUp(true);
		}
	}

	async handleRemoveLike() {
		if(this.state.loginUser !== null) {
			try {
				this.setState({
					like: false,
					totalLikes: this.state.totalLikes - 1
				});
				const removeLike = deleteLike(this.state.content.data.id);
			} catch (error) {
				console.error(error);
			}
		} else {
			this.props.openPopUp(true);
		}
	}

	async handleFollow() {
		try {
			this.setState({
			  followed: true
			});
			  await this.props.putFollowing(this.props.content.data.attributes.author);
		  } catch (error) {
			  console.log('Error', error);
		  }
	}

	async handleUnfollow() {
		try {
			this.setState({
			  followed: false
			});
			await deleteFollowing(this.props.content.data.attributes.author);
		} catch (error) {
			console.log('Error', error);
		}
	}

	onProfileClick(email, id) {
		openProfile(
			email,
			id,
			this.props.loadingProfile,
			this.props.history,
			this.props.visitStats
		);
	}
	handleLibraryOffLineBook(text) {
		if(text.trim() && text.split('\n')[libraryNamePosition]) {
			return text.split('\n')[libraryNamePosition].replace(descriptionText, '').replace(' ,', '').trim();
		} else {
			return '';
		}
	}

	render(){
		const {
			title,
			description,
			image,
			author,
			slug,
			type
		} = this.props.content.data;
		const {t} = this.props;

		return (
			<div className={styles.card}>
				<div className={`${styles.padding20} ${styles.relative} ${styles.nonePadding768}`}>
					<div className={styles.posMore}>
						<ContextMenu
							color={'green'}
							fullView={false}
							edit={ this.state.loginUser === null ? false : this.state.loginUser.data.email == author }
							addToBooklist={true}
							share={true}
							item={{
								id: slug,
								type: type,
								info: {
									author: this.state.author ? `${this.state.author.firstName} ${this.state.author.lastName}` : author,
									description: description,
									image: image ? image : imageCover,
									id: this.state.content.data.id,
									slug: slug,
									title: title,
									link: `${window.location.href}/preview/${slug}`
								},
								attributes: {
									reader: this.state.reader
								}
							}}
							isContent={this.state.content.data.id}
						/>
					</div>

					<div className={this.state.offLineBook ? `${styles.gridRow10} ${styles.gapNone}` : styles.gridRow10}>
						<h2 className={styles.h2titleScroll}>{title}</h2>

						{this.state.offLineBook ?
							<div className={styles.marginTop20}>
								{description.split('\n').map(text => {
									return (
										<p className={`${styles.paragraphCard} ${styles.offLineBook}`}>{text.replace(/,/g, '')}{'\n'}</p>
									);
								})}
							</div>
							:
							<p className={styles.paragraphCard}>{description.length > 0 ? description : t('textWithoutDescriptionContent')}</p>
						}
						<div className={this.state.numberTags > maxNumberTags ? `${styles.flexHash}` : `${styles.flexHash} ${styles.scrollNone}` }>
							{this.state.tags && this.state.tags.length !== 0 && this.state.tags.map((item, i) => {
								return (
									<div key={i} className={styles.btnHash}>
										{`${item.tag}`}
									</div>
								);
							})}
						</div>

						<div className={styles.spaceInfo}>

							<div className={styles.gridAutoCol10}>
								{this.state.offLineBook ?
									null
									:
									<div className={styles.circleUser}>
										<img className={styles.imgCard} src={this.state.author ? this.state.author.profileImgUrl ?  this.state.author.profileImgUrl : imageProfile : imageProfile} alt="user"
											onClick={() => this.state.author ? this.onProfileClick(this.state.author.email, this.state.author.id) : null}/>
									</div>
								}
								<div className={styles.gridRow5}>
									<h3 className={styles.h3sub}>{this.state.author ? t('textContentNewCardPublisherBy') : t('textContentNewCardAutorText')}</h3>
									<h2 className={styles.h2name}>{this.state.author ? `${this.state.author.firstName} ${this.state.author.lastName}` : author}</h2>
								</div>
							</div>
						</div>

						<div className={styles.spaceLikes}>
							<div className={styles.gridBtnSocial}>
								<div className={styles.gridAuto5}>
									<img className={styles.icon25} src={this.state.like === true ? getResources(environment).iconLikeActive : iconlike} alt="like"
										onClick={this.state.like === true ?
											this.handleRemoveLike
											:
											this.handlePutLike
										}
									/>
									<h4 className={styles.h4likes}>{this.state.totalLikes}</h4>
									<h4 className={styles.h4likes}>{t('textLikeIt')}</h4>
								</div>

								<div className={styles.gridAuto5}>
									<div className={styles.lineGreyVertical}></div>
									<h4 className={styles.h4likes}>{this.state.visits ? `${this.state.visits} ${t('viewContent')}` : null}</h4>
								</div>
							</div>

						</div>
						{
							this.state.loginUser && this.state.loginUser.data.email != author && this.state.author &&(
								this.state.followed ?
									<div className={styles.btnFollowActive} onClick={this.handleUnfollow}
										onMouseEnter={() => {
											this.setState({
												message: t('buttonProfileLeave')
											});
										}}
										onMouseLeave={() =>{
											this.setState({
												message: t('buttonProfileFollowed')
											});
										}}>
										{this.state.message}
									</div>
									:
									<div className={styles.btnFollowInactive} onClick={this.handleFollow}>
										{t('buttonProfileFollow')}
										<div className={styles.iconBlue}>
											<img className={styles.icon14} src={iconFollowblue} alt="follow"/>
										</div>
										<div className={styles.iconWhite}>
											<img className={styles.icon14} src={iconfollowWhite} alt="follow"/>
										</div>
									</div>
							)
						}
						{!this.state.offLineBook &&
							<div className={styles.btnSee} onClick={this.handleGoToContent}>
								{t('buttonView')}
							</div>
						}
					</div>
				</div>

				<div className={styles.boxImg}>
					<img
						className={this.state.offLineBook ? `${styles.imgCard} ${styles.imgOffilineBook}` : styles.imgCard}
						src={
							image ?
								image
								:
								this.state.offLineBook ?
									coverOffilineBook
									:
									imageCover
						}
						onClick={this.state.offLineBook ? null : this.handleGoToContent}
						alt="user"/>
					<div className={this.state.offLineBook ? styles.labelOfflineBook : styles.posIconPic}>
						{this.state.offLineBook ?
							<h4 className={styles.labelIcon}>{label}</h4>
							:
							<img className={styles.icon35} src={this.state.icon} alt="like"/>
						}
					</div>
					{this.state.offLineBook &&
						<div className={styles.libraryOffilineBook}>
							<h2>{this.handleLibraryOffLineBook(description)}</h2>
						</div>
					}
				</div>

			</div>



		);
	}
}


export default withRouter(connect(null, {
	getTags,
	fetchOtherProfile,
	loadingProfile,
	visitStats,
	fetchProfile,
	putFollowing,
	putLike,
	postRedirectContent,
	openPopUp
})(withTranslation()(ContentNewCard)));
