import axios from 'axios';
import Cognito from '../../helpers/cognito';
import {environment} from './index';
import { GET_BOOKLIST_COLLABORATIVE, GET_MY_BOOKLIST, LOADING_BOOKLIST_COLLABORATIVE, LOADING_MORE_BOOKLIST_COLLABORATIVE, LOADING_MORE_MY_BOOKLIST, LOADING_MY_BOOKLIST, MORE_BOOKLIST_COLLABORATIVE, MORE_MY_BOOKLIST, OPEN_POPUP, RESET_CONTEXT_MENU } from '../types/contextMenuTypes';

export const openContextMenu = (closePopUp) => (dispatch) => {
	dispatch({
		type: OPEN_POPUP,
		payload: closePopUp
	});
};

export const getMyBooklist = () => async(dispatch)=>{
	try {
		dispatch({
			type: LOADING_MY_BOOKLIST,
			payload: true
		});
		
		const myBooklist = await fetchMyBooklists();
		
		dispatch({
			type: GET_MY_BOOKLIST,
			payload: myBooklist
		});

		dispatch({
			type: LOADING_MY_BOOKLIST,
			payload: false
		});

	} catch (error) {
		console.error(error);
	}
};

export const getMoreMyBooklist = (page) => async(dispatch)=>{
	try {
		dispatch({
			type: LOADING_MORE_MY_BOOKLIST,
			payload: true
		});
		
		const myBooklist = await fetchMyBooklists(page);
		
		dispatch({
			type: MORE_MY_BOOKLIST,
			payload: myBooklist
		});

		dispatch({
			type: LOADING_MORE_MY_BOOKLIST,
			payload: false
		});

	} catch (error) {
		console.error(error);
	}
};

export const getBooklistsCollaborative = () => async(dispatch)=>{
	try {
		dispatch({
			type:LOADING_BOOKLIST_COLLABORATIVE,
			payload:true
		});

		const booklistsCollaborative = await fetchBooklistsPermissions();

		dispatch({
			type:GET_BOOKLIST_COLLABORATIVE,
			payload:booklistsCollaborative
		});

		dispatch({
			type:LOADING_BOOKLIST_COLLABORATIVE,
			payload:false
		});
	} catch (error) {
		console.error(error);
	}
};

export const getMoreBooklistsCollaborative = (page) => async (dispatch) => {
	try {
		dispatch({
			type:LOADING_MORE_BOOKLIST_COLLABORATIVE,
			payload:true
		});

		const moreBooklistsCollaborative = await fetchBooklistsPermissions(page);

		dispatch({
			type:MORE_BOOKLIST_COLLABORATIVE,
			payload:moreBooklistsCollaborative
		});

		dispatch({
			type:LOADING_MORE_BOOKLIST_COLLABORATIVE,
			payload:false
		});
	} catch (error) {
		console.error(error);
	}
}; 

export const resetState =()=>(dispatch)=>{
	dispatch({
		type:RESET_CONTEXT_MENU
	});
};

export const fetchMyBooklists = async (page=1 , limit=6, booklistId) => {
	try {
		const filterBooklist = booklistId ? `&booklistId=${booklistId}` : '';
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'GET',
			url: `${env.msContentNew}/api/v2/booklists/my-booklists?page=${page}&limit=${limit}${filterBooklist}`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});
		return  request.data;
	} catch (error) {
		console.error(error);
	}
};

export const fetchBooklistsPermissions = async (page=1 , limit=6) => {
	const session = await Cognito.retrieveToken();
	try {
		const request = await axios({
			method: 'GET',
			url: `${env.msContentNew}/api/v2/booklist-permissions?page=${page}&limit=${limit}`,
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});
		return  request.data;
	} catch (error) {
		
	}
};

export const postAddContentInBooklist = async (contentSlug,booklistId, sectionId) => {
	const session = await Cognito.retrieveToken();
	try {
		const request = await axios({
			method:'POST',
			url: `${env.msContentNew}/api/v2/booklist-contents`,
			data:{
				booklistId,
				sectionId,
				contentSlug
			},
			headers: {
				'Authorization': session.idToken.jwtToken,
				'env': environment
			}
		});
		return request.data;
	} catch (error) {
		console.error(error);
		throw error.response.status;
	}
};

export const postSearchMyBooklists = async (query) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'POST',
			url: `${env.msContentNew}/api/v2/booklists/search`,
			data: {
				query,
			},
			headers: {
				Authorization: session.idToken.jwtToken,
				env: environment,
			},
		});

		let data = request.data;

		return data;
	} catch (error) {
		console.error(error);
		throw Error(error);
	}
};

export const postSearchBooklistsCollaborative = async (query) => {
	try {
		const session = await Cognito.retrieveToken();
		const request = await axios({
			method: 'POST',
			url: `${env.msContentNew}/api/v2/booklist-permissions/search`,
			data: {
				query,
			},
			headers: {
				Authorization: session.idToken.jwtToken,
				env: environment,
			},
		});

		let data = request.data;

		return data;
	} catch (error) {
		console.error(error);
		throw Error(error);
	}
};

