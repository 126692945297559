import React, { useEffect, useState } from 'react';
import styles from './styles/createdContent.scss';
import useFormFileds from '../../../hooks/useFormFields';
import DropDown from '../../shared/DropDown';
import { useTranslation } from 'react-i18next';
import useOptionDropDown from '../../../hooks/useOptionDropDown';
import InputGroup from '../../shared/InputGroup';
import {
	CONTENT_TYPES,
	allOption,
	iconSearchGreyDark,
} from '../../../../utils/constans';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { clearProfileContent, getAllContentsOfProfile } from '../../../actions';
import CardsWithPaginator from './CardsWithPaginator';
import LoaderBooklistContent from '../../booklists/LoaderBooklistContent';
import { getAllContentsOfProfileWithFilters } from '../../../actions/contentActions';

const CreatedContent = ({
	profile,
	myContent,
	getAllContentsOfProfile,
	email,
	clearProfileContent,
	isMobile,
}) => {
	const { t } = useTranslation();
	const [listTypes, setListTypes] = useState([]);
	const [types, type, handleTypes, resetTypes] = useOptionDropDown(
		listTypes,
		true
	);
	const [hasNextPage, setHasNextPage] = useState(false);
	const [page, setPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState('');
	const [listOrders, setListOrders] = useState([]);
	const [isFilteredSearch, setIsFilteredSearch] = useState(false);
	const [orders, order, handleOrders, resetOrders, defaultOrder] =
		useOptionDropDown(listOrders, false);
	const { fields, handleOnchage } = useFormFileds({
		searchTerm: '',
	});
	const [content, setContent] = useState();
	const [filters, setFilters] = useState({
		type: undefined,
		order: 'AZ-ASC',
	});

	useEffect(() => {
		setListTypes([
			{
				value: CONTENT_TYPES.documents.name,
				label: t(CONTENT_TYPES.documents.translationKey),
			},
			{
				value: CONTENT_TYPES.booklists.name,
				label: t(CONTENT_TYPES.booklists.translationKey),
			},
			{
				value: CONTENT_TYPES.links.name,
				label: t(CONTENT_TYPES.links.translationKey),
			},
		]);
		setListOrders([
			{
				value: 'AZ-ASC',
				label: t('profileFiltersAsc'),
			},
			{
				value: 'AZ-DESC',
				label: t('profileFiltersDesc'),
			},
			{
				value: 'DATE-ASC',
				label: t('profileFiltersDateAsc'),
			},
			{
				value: 'DATE-DESC',
				label: t('profileFiltersDateDesc'),
			},
		]);
		defaultOrder({
			value: 'AZ-ASC',
			label: 'A-Z',
		});
	}, []);

	useEffect(() => {
		clearProfileContent().then(() => {
		});
	}, [email]);

	useEffect(() => {
		if (order.value && order.value !== filters.order) {
			setFilters({ ...filters, order: order.value });
		}
	}, [order]);

	useEffect(() => {
		if (type.value && type.value !== filters.type) {
			setFilters({ ...filters, type: type.value });
		}
	}, [type]);

	useEffect(() => {
		if (myContent) {
			setHasNextPage(myContent.hasNextPage);
			setContent(myContent);
		}else {
			setContent(null);
			getAllContentsOfProfile(email);
		}
	}, [myContent]);

	useEffect(() => {
		const delaySearch = setTimeout(() => {
			setSearchTerm(fields.searchTerm);
		}, 600);

		return () => clearTimeout(delaySearch);
	}, [fields.searchTerm]);

	useEffect(() => {
		if (
			filters.order === 'AZ-ASC' &&
			(!filters.type || filters.type === allOption.value) &&
			searchTerm == ''
		) {
			if (myContent) {
				setHasNextPage(myContent.hasNextPage);
				setPage(myContent.page || 1);
				setContent(myContent);
				setIsFilteredSearch(false);
			}
		} else {
			setIsFilteredSearch(true);
			handleFilteredSearch(1);
		}
	}, [filters.order, filters.type, searchTerm]);

	const handleMoreResults = () => {
		isFilteredSearch
			? handleFilteredSearch(page + 1)
			: getAllContentsOfProfile(email, page + 1);
		setPage(page + 1);
	};

	const handleFilteredSearch = (page) => {
		const filterType =
			filters.type == allOption.value || !filters.type
				? ''
				: filters.type;
		setPage(page);
		getAllContentsOfProfileWithFilters(
			email,
			page,
			filterType,
			filters.order,
			searchTerm || ''
		).then((response) => {
			setHasNextPage(response.hasNextPage);
			if (page > 1) {
				const mergedContents = [
					...content.contents,
					...response.contents,
				];

				const updatedContent = {
					...response,
					contents: mergedContents,
				};

				setContent(updatedContent);
			} else {
				setContent(response);
			}
		});
	};

	return (
		<>
			<div className={isMobile ? styles.rowMobile : styles.row}>
				{!isMobile ? (
					<InputGroup
						inputName={'searchTerm'}
						style={{
							height: '40px',
							margin: '0px',
							fontSize: '16px',
							marginRight: '25px',
						}}
						icon={iconSearchGreyDark}
						placeholder={`${t('buttonSearch')}...`}
						onChange={handleOnchage}
						value={fields.searchTerm}
					/>
				) : null}
				<DropDown
					listOptions={listTypes}
					maxWidth={'100%'}
					width={'147px'}
					minHeight={40}
					fontSizeTitle={'15px'}
					fontSizeDropDown={14}
					options={types}
					isSearchable={false}
					placeholder={t('textTypeContentFilter')}
					value={type.value ? type : null}
					handleValue={handleTypes}
					boxShadow={'0 2px 6px 0 rgba(0, 0, 0, 0.1)'}
					borderRadius={4}
				/>
				<div style={{ marginRight: '15px' }}></div>
				<DropDown
					listOptions={listOrders}
					maxWidth={'100%'}
					isSearchable={false}
					width={'147px'}
					minHeight={40}
					fontSizeTitle={'15px'}
					fontSizeDropDown={14}
					options={orders}
					value={order}
					handleValue={handleOrders}
					boxShadow={'0 2px 6px 0 rgba(0, 0, 0, 0.1)'}
					borderRadius={4}
				/>
			</div>
			<div style={{ height: '100%', width: '100%', overflow: 'auto' }}>
				{myContent ? (
					<CardsWithPaginator
						contents={content}
						handleNextPage={handleMoreResults}
						hasNextPage={hasNextPage}
						isBooklistArray={false}
						page={page}
					/>
				) : (
					<div
						className={
							isMobile ? styles.loadingContainerMobile : styles.contentContainer
						}>
						<LoaderBooklistContent width='100%' />
						<LoaderBooklistContent width='100%' />
					</div>
				)}
			</div>
		</>
	);
};

function mapStateToProps({ profile, contentList }) {
	return {
		myContent: contentList.myContent,
		profile: profile.otherProfile,
	};
}

export default withRouter(
	connect(mapStateToProps, {
		getAllContentsOfProfile,
		clearProfileContent,
	})(CreatedContent),
);
