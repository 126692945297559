import React from 'react';
import { useTranslation } from 'react-i18next';
import useRoleProfile from '../../hooks/useRoleProfile';
import { openProfile } from '../../../helpers/profileRouting';
import {
	loadingProfile,
	visitStats
} from '../../actions';
import AvatarProfile from './AvatarProfile';
import styles from './styles/cardProfile.scss';
const CardProfile = ({ history, targetProfile }) => {

	const { roleKey } = useRoleProfile(targetProfile.role);
	const { t } = useTranslation();

	const handleOnClickProfile = () => {

		openProfile(
			targetProfile.email,
			targetProfile.id,
			loadingProfile,
			history,
			visitStats,
			true
		);

	};
	return (
		<div className={styles.gridPeople}>
			<AvatarProfile
				styles={{
					width: '66px',
					height: '66px',
					borderRadius: '50%',
					margin: 'auto'
				}}
				profileImgUrl={targetProfile.profileImgUrl}
				onClick={handleOnClickProfile} />

			<div className={styles.gridRow5}>
				<div className={styles.containerInfo}>
					<h2 className={styles.fontTitle}>{t(roleKey)}</h2>
					<h2 className={styles.font}>{`${targetProfile.firstName} ${targetProfile.lastName}`}</h2>
					<h2 className={`${styles.font} ${styles.fontLight}`}>{targetProfile.mayor}</h2>
				</div>
				<div className={styles.button_113px_blue} onClick={handleOnClickProfile}>
					{t('buttonViewPeople')}
				</div>
			</div>
		</div>
	);
};

export default CardProfile;