import React, { useState, useEffect } from 'react';
import { ImpulseSpinner, ClapSpinner } from 'react-spinners-kit';
import {
	addContent,
	getMyBooklists,
	postContent
} from '../../actions';
import styles from './styles/contextMenu.scss';
import SubMenuAddBooklist from './SubMenuAddBooklist';

const limit = 5;

const SubMenuBooklist = (props) => {
	const [booklistCollabortive , setBooklistCollaborative] = useState([]);
	const [isBooklistCollaborative, setIsBooklistCollaborative] = useState(false);
	const [booklists, setBooklists] = useState(props.state.booklists);
	const [hasNextBooklists, setHasNextBooklists] = useState(props.state.hasNextPage);
	const [loadingScroll, setLoadingScroll] = useState(false);
	const [loadingAddBooklist, setLoadingAddBooklist] = useState(false);
	const [page, setPage] = useState(1);

	useEffect(() => {
		if (booklists && booklists.length >= limit && !loadingScroll && hasNextBooklists) {
			let observerScroll = document.getElementById('observerScroll');
			let intersectionObserver = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting) {
					setLoadingScroll(true);
					handleMoreBooklists();
				}
			}, {
				rootMargin: '0px 0px 1px 0px',
			});
			intersectionObserver.observe(observerScroll);
		}
	}, [booklists, loadingScroll]);

	useEffect(() => {
		if (props.isBooklistColaborative) {
			setBooklistCollaborative(props.state.booklistPermissions);
			setIsBooklistCollaborative(true);
		}
	}, [props.isBooklistColaborative]);
	


	const handleMoreBooklists = async () => {
		if (hasNextBooklists) {
			const nextBooklists = props.isBooklist ?  await getMyBooklists(page + 1 , 5 , props.state.item.id) : await getMyBooklists(page + 1);
			setPage(page + 1);
			setHasNextBooklists(nextBooklists.hasNextPage);
			setBooklists(booklists.concat(nextBooklists.booklists));
			setLoadingScroll(false);
		}
	};

	const handleAddBooklists = async (booklist) => {
		let result;
		 if (!props.state.isBooklist) {
		 	try {
		 		if (props.props.search) {
		 			props.props.handleAddToBooklist();
		 		}
				if(!props.state.item.attributes.id){
					const createContent = await postContent(props.state.item.attributes, props.state.item.type);
					result = createContent;
				}else{
					const addContent =  await addContent(props.state.item, props.state.item.attributes, props.state.item.type);
					result = addContent.data.data;
				}	
		 		try {
		 			await props.props.postContentBooklist(result.slug, booklist.id);
		 			window.location = `${window.location.origin}/booklist/${booklist.slug}`;
		 		} catch (error) {
		 			await props.props.postContentBooklist(props.state.item.info.slug, booklist.id);
		 			window.location = `${window.location.origin}/booklist/${booklist.slug}`;
		 		}
		 	} catch (error) {
		 		await props.props.postContentBooklist(props.state.item.info.slug, booklist.id);
		 		window.location = `${window.location.origin}/booklist/${booklist.slug}`;
		 	}
		 }
		 else {
		 	await props.props.postContentBooklist(props.state.item.info.slug, booklist.id);
		 	setLoadingAddBooklist(false);
		 	window.location = `${window.location.origin}/booklist/${booklist.slug}`;
		 }
	};


	return (
		<div className={`${styles.boxSub} ${styles.boxSubMinWidth}`}>
			{!isBooklistCollaborative && booklists.map((booklist) => {
				return (
					<SubMenuAddBooklist 
						key={booklist.id} 
						booklist={booklist} 
						handleAddBooklists={()=>handleAddBooklists(booklist)}   
						loading={loadingAddBooklist}
					/>
				);
			})}

			{isBooklistCollaborative && booklistCollabortive.map((booklist) => {
				return (
					<SubMenuAddBooklist 
						key={booklist.id} 
						booklist={booklist} 
						handleAddBooklists={()=>handleAddBooklists(booklist)}   
						loading={loadingAddBooklist}
					/>
				);
			})				
			}

			{loadingScroll &&
				<div className={styles.loadingSpinner}>
					<ImpulseSpinner
						size={40}
						color="var(--primary-color)"
						frontColor="var(--primary-color)"
						backColor='#F0FBFF'
						loading={loadingScroll}
					/>
				</div>
			}

			{!isBooklistCollaborative && booklists && booklists.length >= limit && hasNextBooklists && !loadingScroll ?
				<div className={styles.observerScroll} id='observerScroll' /> : null
			}
		</div>
	);
};

export default SubMenuBooklist;