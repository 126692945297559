import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import PopUp  from '../shared/PopUp';
import { useTranslation } from 'react-i18next';
import styles from './styles/popupMoveContentToSection.scss';

import { putContentToBooklist } from '../../actions';
const PopUpMoveContentToSection = ({
	booklistSections,
	closePopUp,
	sectionId, 
	booklistId, 
	contentId,
	loadingPut,
	putContentToBooklist,
	handleSection
}) => {
	
	const { t } = useTranslation();
	const [checkboxSections, setCheckboxSections] = useState(Object.fromEntries(booklistSections.map(section=>([section.id,section.id === sectionId]))));
	const [selectedSection, setSelectedSection] = useState(sectionId);

	const handleMoveContentToSection = async () => {
		await putContentToBooklist(selectedSection , contentId , booklistId);
		handleSection(selectedSection);
		closePopUp();
	};

	const handleInputChange = (e) => {
		const target = e.target;
		const name = target.name;
		let sections = {};
		for (const key in checkboxSections) {
			if(key === name){
				setSelectedSection(key);
				sections[key] = target.checked;
			}else{
				sections[key] = false;
			}
		}

		setCheckboxSections(sections);
		
	};

	return (
		<PopUp
			closePopUp={closePopUp}
			style={{
				popUp:{
					maxHeight:'406px',
					maxWidth:'487px'
				},
				popUpContent:{
					margin:'40px'
				},
				button:{
					marginTop:'30px',
					justifyContent:'center',
				}
			}}
    
			buttons={{
				style:{
					width:'100%',
					height:'50px',
					borderRadius:'8px',
				},
				primary:{
					title:t('textPreferenceSave'),
					type:'primary',
					action:handleMoveContentToSection,
					loading:loadingPut
				}
			}}
		>
			<div style={{width:'100%'}}>
				<div style={{marginTop:'16px'}}>
					<p style={{fontSize:'22px', fontWeight:'500'}}>{t('titleMoveContentToSection')}</p>
				</div>
				<div style={{marginTop:'5px'}}>
					{booklistSections.map(section=>(
						<div key={section.id} className={styles.containerRadioButton}>
							<div className={styles.radio}>
								<input 
									type='checkbox'
									onChange={(e)=>handleInputChange(e)}
									name={section.id} 
									checked={checkboxSections[section.id]}
								/>
							</div>
							<label className={`${styles.fontp} ${styles.cursor}`}>{section.section  === 'Contenido' ? t('textSearchFilterContent') : section.section}</label>
						</div>
					))}
				</div>
			</div>
		</PopUp>
	);
};

const mapStateToProps = ({ booklistV2  }) => {
	return {
		booklistSections: booklistV2.booklistSections,
		loadingPut: booklistV2.loadingPut
	};
};

export default  withRouter(connect(mapStateToProps,{putContentToBooklist})(PopUpMoveContentToSection));
