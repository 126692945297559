import React, { useEffect, useState } from 'react';
import styles from './styles/collaborativeBooklists.scss';
import useFormFileds from '../../../hooks/useFormFields';
import { useTranslation } from 'react-i18next';
import InputGroup from '../../shared/InputGroup';
import { iconSearchGreyDark } from '../../../../utils/constans';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getBooklistPermissionsOfProfile } from '../../../actions';
import LoaderBooklistContent from '../../booklists/LoaderBooklistContent';
import CardsWithPaginator from './CardsWithPaginator';
import { getCollaborativeBooklistByEmail } from '../../../actions/contentActions';

const CollaborativeBooklists = ({
	profile,
	collaborativeBooklists,
	getCollaborativeBooklistByEmail,
	email,
	isMobile,
}) => {
	const { t } = useTranslation();
	const [hasNextPage, setHasNextPage] = useState(false);
	const [page, setPage] = useState(1);
	const { fields, handleOnchage } = useFormFileds({
		searchTerm: '',
	});

	useEffect(() => {
		if (!collaborativeBooklists) {
			getCollaborativeBooklistByEmail(1);
		} else {
			setPage(collaborativeBooklists.page);
			setHasNextPage(collaborativeBooklists.hasNextPage);
		}
	}, []);

	useEffect(() => {
		if (collaborativeBooklists) {
			setHasNextPage(collaborativeBooklists.hasNextPage);
		}
	}, [collaborativeBooklists]);

	const handleMoreResults = () => {
		setHasNextPage(false);
		getCollaborativeBooklistByEmail(page + 1);
		setPage(page + 1);
	};

	return (
		<>
			<div style={{ height: '100%', width: '100%', overflow: 'auto' }}>
				{collaborativeBooklists ? (
					<CardsWithPaginator
						contents={collaborativeBooklists}
						handleNextPage={handleMoreResults}
						hasNextPage={hasNextPage}
						isBooklistArray={true}
						page={page}
					/>
				) : (
					<div className={isMobile ? styles.contentContainerMobile : styles.contentContainer}>
						<LoaderBooklistContent width="100%" />
						<LoaderBooklistContent width="100%" />
					</div>
				)}
			</div>
		</>
	);
};

function mapStateToProps({ profile, contentList }) {
	return {
		collaborativeBooklists: contentList.collaborativeBooklists,
		profile: profile.otherProfile,
	};
}

export default withRouter(
	connect(mapStateToProps, {
		getCollaborativeBooklistByEmail,
	})(CollaborativeBooklists)
);
