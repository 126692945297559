import React,{useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	iconCameraBlack,
	iconSaveWhite,
	getResources,
	breakPoint} from '../../../utils/constans';
import { useTranslation } from 'react-i18next';

import useFormFileds from '../../hooks/useFormFields';

import { 
	clearProfileContent,
	environment,
	submitEditingContentFile
} from '../../actions';
import Button, { appearances } from '../shared/Button';
import InputGroup, { types } from '../shared/InputGroup';
import Dropzone from 'react-dropzone';
import PopUp from '../shared/PopUp';

import styles from './styles/booklistsPopUpEdit.scss';

const PopUpEditContent = ({
	content,
	submitEditingContentFile,
	closePopUp,
	deleteContentToBooklist,
	sectionId,
	handleSection,
	clearProfileContent,
}) => {
	const { t } = useTranslation();
	const [previewImage, setPreviewImage] = useState(content.image ? content.image : getResources(environment).defaultCover);
	const [editImage, setEditImage] = useState(null);
	const [isLoading, setIsLoading] = useState();
	const [isMobile, setIsMobile] = useState(
		window.innerWidth < breakPoint.mobileWide,
	);

	const { fields, handleOnchage } = useFormFileds({
		titleContent: content.title,
		descriptionContent: content.description,
		...(content.author && { authorContent: content.author }),
		...(content.edition && { editionContent: content.edition }),
		...(content.editorial && { editorialContent: content.editorial }),
	});

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < breakPoint.mobileWide);
		};
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
			setIsLoading(false);
		};
	}, []);

	const handleChangeCover = (file) => {
		if (file[0].type.startsWith('image')) {
			let reader = new FileReader();
			reader.onloadend = () => {
				setPreviewImage(reader.result);
			};
			reader.readAsDataURL(file[0]);
			setEditImage(file[0]);
		}
	};

	const handleEditContent = async () => {
		setIsLoading(true);
		const data = {
			title: fields.titleContent,
			description: fields.descriptionContent,
			image: previewImage,
			slug: content.slug,
			cover: editImage,
			...(fields.authorContent &&
				fields.editionContent &&
				fields.editorialContent ?
					{
						metadata: {
							...(fields.authorContent && { author: fields.authorContent }),
							...(fields.editionContent && { edition: fields.editionContent }),
							...(fields.editorialContent && { editorial: fields.editorialContent }),
						},
					}
				: {}),
		};

		await submitEditingContentFile(data);
		sectionId && handleSection(sectionId);
		clearProfileContent();
		setIsLoading(false);
		closePopUp();
	};

	const handleDeleteContentToBooklist = () => {
		deleteContentToBooklist(content,'delete');
	};


	return (
		<PopUp
			closePopUp={closePopUp}
			style={{
				popUp: {
					maxHeight: 644,
					maxWidth: '1068px'
				},
				popUpContent: {
					display: 'grid',
					margin: isMobile ? '40px 64px 20px 28px' : '40px'
				},
				button: {
					marginTop: !isMobile && '30px',
					justifyContent: 'center',
					marginLeft: isMobile ? '39px' : '150px',
					flexDirection: isMobile &&  'column',	
				}
			}}

			buttons={{
				style: {
					width: isMobile ? '262px' : '258px',
					height: '50px',
					borderRadius: '8px',
					marginRight: '25px',
					marginTop: isMobile && '15px',
					secondary: {
						marginTop: isMobile && '15px',
					}
				},
				primary: {
					materialIcon: 'save',
					title: t('titleEditEntryHintSubmit'),
					type: 'primary',
					disabled: isLoading,
					action: handleEditContent,
				},
				secondary:{
					title:t('textContextMenuCancel'),
					type:'secondary',
					action:closePopUp
				}
			}}
		>
			<div className={`${styles.boxImage}`}>
				<img className={styles.imgBox} src={previewImage} />
			</div>

			<div style={{ width: '100%', marginLeft: '40px' }}>
				<p className={isMobile ? styles.titleMobile : styles.title}>{t('titlePopUpEditContent')}</p>
				<div style={{ marginTop: '12px', display: 'flex' }}>
					<div style={{ display: 'grid', gap: '12px', alignItems: 'center', }}>
						<h3 style={{
							fontSize: '16px',
							fontFamily: 'Roboto',
							color: '#333333',
							fontWeight: '500',
							margin: '0'
						}}>
							{t('textInputPhoto')}
						</h3>

						<Dropzone onDrop={handleChangeCover}>
							{({ getRootProps, getInputProps }) => (
								<div  {...getRootProps()}>
									<input {...getInputProps()} accept="image/*,.png,.jpg,.gif" />
									<Button
										appearance={appearances.input}
										borderRadius={'8px'}
										width={'257px'}
										height={'52px'}
										icon={iconCameraBlack}
										title={t('IpuntoChagePhotoBooklist')}
									/>
								</div>
							)}
						</Dropzone>
					</div>
				</div>

				<div style={{ marginTop: '30px', display: 'grid', gap: 30 }}>
					<InputGroup
						inputName={'titleContent'}
						title={t('textTitleContent')}
						onChange={handleOnchage}
						value={fields.titleContent}
						fontSize={'16px'}
						fontWeight={'500'}
						type={types.input}
						style={{
							width: '100%',
							height: '45px'
						}}
					/>

					{content.editorial && content.edition && (
						<>
							<InputGroup
								inputName={'authorContent'}
								title={t('inputAdvanceSearchAutor')}
								onChange={handleOnchage}
								value={fields.authorContent}
								fontSize={'16px'}
								fontWeight={'500'}
								type={types.input}
								style={{
									width: '100%',
									height: '45px',
								}}
							/>

							<InputGroup
								inputName={'editionContent'}
								title={t('inputAdvanceSearchPublisher')}
								onChange={handleOnchage}
								value={fields.editionContent}
								fontSize={'16px'}
								fontWeight={'500'}
								type={types.input}
								style={{
									width: '100%',
									height: '45px',
								}}
							/>

							<InputGroup
								inputName={'editorialContent'}
								title={t('titleEdition')}
								onChange={handleOnchage}
								value={fields.editorialContent}
								fontSize={'16px'}
								fontWeight={'500'}
								type={types.input}
								style={{
									width: '100%',
									height: '45px',
								}}
							/>
						</>
					)}

					<InputGroup
						inputName={'descriptionContent'}
						title={t('textDescriptionContent')}
						onChange={handleOnchage}
						value={fields.descriptionContent}
						fontSize={'16px'}
						fontWeight={'500'}
						type={types.textArea}
						style={{
							width: '100%',
							height: '100px'
						}}
					/>
				</div>


			</div>
		</PopUp>

	);
};

const mapStateToProps = ({  }) => {
	return {
	};
};

export default withRouter(connect(mapStateToProps, { 
	submitEditingContentFile, 
	clearProfileContent,
})(PopUpEditContent));
