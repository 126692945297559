import React from 'react';
import CardBooklist from '../CardBooklist';
import LoaderBooklistContent from '../../booklists/LoaderBooklistContent';
import NoResultSearch from '../../search/NoResultSearch';

const SearchBooklists = ({
	results,
	handleCardSelect,
	selectedBooklistId,
	loading,
}) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				maxHeight: '40vh',
				overflow: 'auto',
				justifyItems: 'center',
			}}
		>
			{!results || loading ? (
				<LoaderBooklistContent height="147px" />
			) : results.length < 1 ? (
				<NoResultSearch hideSubtitle={true} maxWidth={'160px'}/>
			) : (
				results.map((booklist) => (
					<CardBooklist
						key={booklist.id}
						widthCard={'100%'}
						heightCard={'147px'}
						content={booklist}
						isSelected={booklist.id === selectedBooklistId}
						onSelect={handleCardSelect}
					/>
				))
			)}
		</div>
	);
};

export default SearchBooklists;
