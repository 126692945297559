import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ImpulseSpinner } from 'react-spinners-kit';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

import PeopleShareItem from '../home/PeopleShareItem';
import styles from './styles/sharePopup.scss';

import {
	iconSearchGrey,
	linkIcon,
	mailIcon,
	iconWhatsappNew,
	facebookIcon,
	windowToShare,
	iconArrowSliderLeft,
	iconArrowSliderRight,
	iconCheckGreenBack,
	iconNotResults,
	eventShareType,
	getResources,
	
} from '../../../utils/constans';

import { postEventSharePopup } from '../../../helpers/tracking';
import { 
	FacebookShareButton, 
	WhatsappShareButton,
	EmailShareButton 
} from 'react-share';

import { getProfilesToShare, searchProfiles, setPopUpShare, environment } from '../../actions';

const numberOfProfiles = 3;
const millisecondForRender = 500;
const millisecondToClose = 2000;
const firstProfile = 1;
const numberOfProfilesToSearch = 12;

let searchTimeout;

const SharePopUp = ({
	closePopUp,
	searchProfiles, 
	searchNew,
	content,
	profile
}) => {
	const [profiles, setProfiles] = useState();
	const [defaultProfiles, setDefaulProfile] = useState();
	const [loading, setLoading] = useState(false);
	const [url, setUrl] = useState('');
	const [copyLink, setCopyLink] = useState(false);
	const { t, i18n } = useTranslation();
	const sliderRef = useRef();

	const settings = {
		dots: false,
		focusOnSelect: false,
		infinite: true,
		autoplay: false,
		autoplaySpeed: 4000,
		pauseOnHover: true,
		speed: 500,
		fade: false,
		slidesToShow: numberOfProfiles,
		slidesToScroll: numberOfProfiles,
		draggable: false,
		ref: sliderRef,
		arrows: false,
		className: styles.sliderTrack
	};

	useEffect(()=> {
		handleUrl();
		fetchData();
	},[]);

	useEffect(() => {
		if(defaultProfiles && !profiles) {
			setProfiles(defaultProfiles);
		}
	}, [profiles, defaultProfiles]);

	const handleUrl = () => {
		if((content.url.includes('/preview') || content.url.includes('/booklist')) ) {
			setUrl(content.url);
		} else {
			if(content.search) {
				setUrl(content.url);
			} else {
				setUrl(`${window.location.origin}/preview/${content.slug}`);
			}
		}
	};

	const fetchData = async () => {
		setLoading(true);
		const profilesToShare = await getProfilesToShare();
		setProfiles(profilesToShare);
		setDefaulProfile(profilesToShare);
		setLoading(false);
	};

	const handleSearch = async (query) => {
		postEventSharePopup(eventShareType.shareSearch, environment, profile.profile.data.email, null, content.id);
		if (query) {
			setLoading(true);
			let profiles = await searchProfiles(query, numberOfProfilesToSearch);
			setProfiles(profiles);
			setLoading(false);
		}
	};

	const handleInputChange = (query) => {
		if (searchTimeout !== undefined) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => handleSearch(query), millisecondForRender);
	};

	const handleCopyLink = () => {
		postEventSharePopup(eventShareType.shareLink, environment, profile.profile.data.email, null, content.id);
		navigator.clipboard.writeText(url);
		setCopyLink(true);
		setTimeout(() => setCopyLink(false), millisecondToClose);
	};


	return (
		<div className={styles.contentPop}>
			<div onClick={closePopUp} className={styles.fixedClosePop}></div>
			<div className={styles.boxPopUp}>
				<div onClick={closePopUp} className={styles.iconClose}>
					<img className={styles.icon20} src={getResources(environment).iconClose} alt="" />
				</div>
				<p className={styles.title}>{t('titlePopUpShare')}</p>
				<div className={`${styles.relative} ${styles.w315}`}>
					<div className={styles.posSearch}>
						<img className={styles.icon14} src={iconSearchGrey} alt="more"/>
					</div>
					<input
						autoComplete= "off"
						type="search"
						name='search'
						className={styles.inputSearch}
						placeholder={t('searchProfiles')}
						onChange={event => handleInputChange(event.target.value)}
					/>
				</div>
				<div className={`${styles.relative} ${styles.w100} ${styles.gridProfile}`}>
					{profiles && !loading ?
						profiles.length ?
							<>
								{profiles.length > numberOfProfiles ? (
									<>
										<div className={styles.positionArrowLeft}>
											<div className={styles.ArrowsButtonSlider} onClick={() => sliderRef.current.slickPrev()}>
												<img src={iconArrowSliderLeft} alt="" />
											</div>
										</div>
										<div className={styles.positionArrowRight}>
											<div className={styles.ArrowsButtonSlider} onClick={() => sliderRef.current.slickNext()}>
												<img src={iconArrowSliderRight} alt="" />
											</div>
										</div>
										<Slider
											{...settings}
										>
											{profiles.map((profile, index) => {
												return (
													<div key={`${profile.email}${index}`}>
														<PeopleShareItem key={profile.email} targetProfile={profile} content={content} />
													</div>
												);
											})}
										</Slider>
									</>
								)
									:
									<div className={`${styles.relative} ${styles.w100} ${styles.gridProfile2}`}>
										{profiles.map((profile, index) => {
											return (
												<div className={profiles.length === firstProfile ? styles.centerItem : null} key={`${profile.email}${index}`}>
													<PeopleShareItem key={profile.email} targetProfile={profile} content={content} />
												</div>
											);
										})}
									</div>
								}
							</>
							:
							<div className={styles.loadingSpinner}>
								<p className={`${styles.title} ${styles.titleNotFount} `}>{t('ProfilesNotFound')}</p>
								<img src={iconNotResults} />
							</div>
						:
						<div className={styles.loadingSpinner}>
							<ImpulseSpinner
								size={80}
								color="var(--primary-color)"
								frontColor="var(--primary-color)"
								backColor='#F0FBFF'
								loading={loading}
							/>
						</div>
					}
				</div>
			    <p className={`${styles.title} ${styles.marginTop}`}>{t('textShare')}</p>
				<div className={`${styles.relative} ${styles.w100} ${styles.gridShare}`}>
					<div className={styles.flex}>
						<div onClick={handleCopyLink} className={`${styles.circleIcon} ${styles.circleBoxShadow}`} >
							<img className={styles.imgLinkIcon} src={linkIcon} alt={'test'}/>
						</div>
						<p className={styles.font}>{t('textCopyLink')}</p>
					</div>
					{/* 
					<div className={styles.flex}>
						<EmailShareButton
							onClick={() => postEventSharePopup(eventShareType.shareEmail, environment, profile.profile.data.email, null, content.id)}
							url={url}
							subject={content.title}
							body={t('shareMessage')}
							className={`${styles.circleIcon} ${styles.circleBoxShadow}`}
						>
							<img className={styles.imgMailIcon} src={mailIcon} alt={'test'} />
						</EmailShareButton>
						<p className={styles.font}>Email</p>
					</div>
					 */}
					<div className={styles.flex}>
						<WhatsappShareButton
							onClick={() => postEventSharePopup(eventShareType.shareWhatsapp, environment, profile.profile.data.email, null, content.id)}
							url={url}
							quote={content.title}
							separator=":: "
							windowWidth={windowToShare.width}
							windowHeight={windowToShare.height}
						>
							<img className={styles.iconImage} src={iconWhatsappNew} alt={'test'} />
						</WhatsappShareButton>
						<p className={styles.font}>Whatsapp</p>
					</div>

					{environment !== 'eur' && (
						<div className={styles.flex}>
							<FacebookShareButton
								onClick={() => postEventSharePopup(eventShareType.shareFacebook, environment, profile.profile.data.email, null, content.id)}
								url={url}
								quote={content.title}
								windowWidth={windowToShare.width}
								windowHeight={windowToShare.height}
							>
								<img className={styles.iconImage} src={facebookIcon} alt={'test'} />
							</FacebookShareButton>
							<p className={styles.font}>Facebook</p>
						</div>
					)}
					
					
				</div>
				{copyLink &&
					<div className={styles.containerCopyMessage}>
						<img className={styles.iconImage} src={iconCheckGreenBack} />
						<p className={styles.font}>{t('textCopyMessage')}</p>
					</div>
				}
			</div>

		</div>
	);
};

const mapStateToProps = ({ searchNew, exploration, profile }) => {
	return {
		profile,
		searchNew,
		content: exploration.contentToShare
	};
};

export default withRouter(connect(mapStateToProps, {
	searchProfiles
})(SharePopUp));