import {getCache, setCache} from '../../helpers/cacheStore';
import axios from 'axios';
import { openPopUp } from './contextMenuActions';

// ENDPOINTS
export const msStats = 'https://ies.ms-stats.booklick.net';
export const msVeronica = 'https://ms-veronica.booklick.net/prod';
export const backgroundBKColorsarch =
  'https://ies.ms-content.booklick.net/prod-search';

// TYPES
export const CONTENT_TYPE = 'content';
export const BOOKLIST_TYPE_ACTIVITY = 'booklist';
//CONTENT_TYPES
export const BOOK_TYPE = 'BOOKS';
export const EXTERNAL_BOOK_TYPE = 'EXTERNAL_BOOKS';
export const OFFLINE_BOOK_TYPE = 'OFFLINE_BOOKS';
export const DOCUMENTS_TYPE = 'DOCUMENTS';
export const BOOKLIST_TYPE = 'BOOKLISTS';
export const ARTICLE_TYPE = 'ARTICLES';
export const LINK_TYPE = 'links';
export const LINK_TYPES = 'LINKS';
export const PROFILE_TYPE = 'profile';
// CONTENT_SOURCES
export const ELIBRO_SOURCE = 'ELIBRO_SOURCE';
export const EDS_SOURCE = 'EDS_SOURCE';
export const PRIMO_SOURCE = 'PRIMO_SOURCE';
export const PRIMO_OFFLINE_SOURCE = 'PRIMO_OFFLINE_SOURCE';
export const ELSEVIER_SOURCE = 'ELSEVIER_SOURCE';
export const CROSSREF_SOURCE = 'CROSSREF_SOURCE';
// CONTENT_SEARCH_TYPES
export const EBOOK_EDS_TYPE = 'Ebook';
export const BOOKLIST_SEARCH_TYPE = 'booklist';
export const LINK_SEARCH_TYPE = 'link';
export const BOOK_EL_SEARCH_TYPE = 'book-el';
export const BOOK_OFFLINE = 'book-offline';
export const BOOK_ELSEVIER = 'elsevier-book';
export const JOURNAL_ELSEVIER = 'elsevier-journal';
export const BOOK_CROSSREF = 'crossref-book';
export const JOURNAL = 'journal';
//ACTIVITY_TYPES
export const CREATE = 'activityCreate';
export const EDIT = 'activityEdit';
export const FOLLOW = 'activityFollow';
export const RATE = 'activityRate';
export const SHARE = 'activityShare';
// ADVANCE SEARCH CATEGORIES
export const ALL = 'all';
export const TITLE = 'title';
export const AUTHOR = 'author';
// OPERATORS
export const OR = 'OR';
export const AND = 'AND';
// RATES
export const ONE_RATE = 'ONE_RATE';
export const TWO_RATE = 'TWO_RATE';
export const THREE_RATE = 'THREE_RATE';
export const FOUR_RATE = 'FOUR_RATE';
export const FIVE_RATE = 'FIVE_RATE';
export const RATES = [ONE_RATE, TWO_RATE, THREE_RATE, FOUR_RATE, FIVE_RATE];

export const REACT_NATIVE = 'React Native';

export const UPDATE_SHOWED = 'UPDATE_SHOWED';
export const NOTIFICATION_POPUP = 'NOTIFICATION_POPUP';

export const getEnvironment = () => {
	let environment = env.env;
	if(environment === 'beta' || environment === 'lang') environment = 'global';
	if(environment === 'ngrok' || environment === 'test' || environment === '127' || environment === 'localhost:3000') environment = 'global';
	return environment;
};

export const environment = getEnvironment();

export const ID_ACTIVITY_HOME = 'loc-home';
//ROLE_TYPES
export const ADMIN = 'admin';

export const FETCH_BOOKS_DEFAULT = 'fetch_books_default';
export const fetchBooksDefault = () => async (dispatch) => {
	await fetchContent(FETCH_BOOKS_DEFAULT, 'content/type/BOOKS/sort/default', dispatch);
};

export const FETCH_DOCUMENTS_DEFAULT = 'fetch_documents_default';
export const fetchDocumentsDefault = () => async (dispatch) => {
	await fetchContent(FETCH_DOCUMENTS_DEFAULT, 'content/type/DOCUMENTS/sort/default', dispatch);
};

export const FETCH_BOOKLISTS_DEFAULT = 'fetch_booklists_default';
export const fetchBooklistsDefault = () => async (dispatch) => {
	await fetchContent(FETCH_BOOKLISTS_DEFAULT, 'content/type/BOOKLISTS/sort/default', dispatch);
};

async function fetchContent(key, url, dispatch) {
	const cachedDefault = getCache(url);
	if (cachedDefault) {
		dispatch({
			type: key,
			payload: cachedDefault
		});
	} else {
		axios({
			method: 'GET',
			url: `${env.msContent}/${url}`,
			headers: {
				'env': environment
			}
		}).then(res => {
			setCache(url, res.data);
			dispatch({
				type: key,
				payload: res.data
			});
		}).catch(error => {
			console.log(error);
		});
	}
}

function getActionConstantWithType(contentType) {
	switch (contentType) {
	case BOOK_TYPE:
		return FETCH_BOOKS_DEFAULT;
	case DOCUMENTS_TYPE:
		return FETCH_DOCUMENTS_DEFAULT;
	case BOOKLIST_TYPE:
		return FETCH_BOOKLISTS_DEFAULT;
	}
}

export const START_BOOK_DEFAULT = 'start_book_default';
export const START_DOCUMENTS_DEFAULT = 'start_documents_default';
export const START_BOOKSLITS_DEFAULT = 'start_booklist_default';

function getStartActionConstantWithType(contentType) {
	switch (contentType) {
	case BOOK_TYPE:
		return START_BOOK_DEFAULT;
	case DOCUMENTS_TYPE:
		return START_DOCUMENTS_DEFAULT;
	case BOOKLIST_TYPE:
		return START_BOOKSLITS_DEFAULT;
	}
}

export const menuFetchBooks = (sort, contentType) => async (dispatch) => {
	dispatch({
		type: getStartActionConstantWithType(contentType),
	});
	await fetchContent(getActionConstantWithType(contentType), `content/${contentType}/${sort}`, dispatch);
};

export const FETCH_EDITORIAL_PREVIEW_DATA = 'FETCH_EDITORIAL_PREVIEW_DATA';

// EXTERNAL FILES
// TODO ALL FUNCTIONS AND CONSTANTS SHOULD BE ON THEIR OWN FILE

export {
	getSimilarMayor,
	getTeachers,
	getSameUniversity,
	getOtherUniversity,
	getCommonProfiles
} from './recomendationsActions';

export {
	postBooklist,
	addToSelectedBooklist,
	addDocumentToBooklist,
	deleteBooklist,
	postSearchBooklistContents,
} from './booklistsActions';

export {
	START_SUBMIT_EMAIL_USER,
	END_SUBMIT_EMAIL_USER,
	SET_EZPROXY_LOCATION,
	submitEmailUser,
	getCognitoSessionOrRegister,
	setEzProxyLocation,
	cognitoLogin,
	setLDAPInfo,
	login
} from './userActions';

export {
	START_FETCH_PREVIEW_CONTENT,
	FETCH_PREVIEW_CONTENT,
	LOADING_FETCH_PREVIEW_CONTENT,
	EMPTY_PREVIEW_CONTENT,
	INPUT_CHANGE_CONTENT,
	START_SUBMIT_EDITING_CONTENT,
	END_SUBMIT_EDITING_CONTENT,
	EDIT_CONTENT,
	CLOSE_EDIT_CONTENT,
	EDIT_NEW_CONTENT,
	BOOKLIST_ADDED,
	submitEditingContent,
	inputChangeContent,
	fetchPreviewContent,
	getLike,
	putLike,
	deleteLike,
	putFollowBooklist,
	deleteFollowBooklist,
	submitBooklistCover,
	putTags,
	putPrivacy,
	submitEditingContentFile,
	getLikeTotal,
	addContent,
	getContentExists,
	getFollowingCount,
	postContentBooklist,
	getContent,
	getTags,
	getContentCategory,
	getFollowedBooklist,
	updateContentPreview,
	getAllContentsOfProfile,
	getBooklistFollowingsOfProfile,
	getBooklistPermissionsOfProfile,
	deleteContentFromProfilePage,
	clearProfileContent,
	postShareContent,
	postContent
} from './contentActions';

export {
	SEARCH_INPUT,
	CLEAN_SEARCH_RESULTS,
	AUTOCOMPLETE,
	CLEAN_INPUT_SEARCH,
	cleanInputSearch,
	onSearchInputChange,
} from './searchActions';



export {
	fetchAllCategories,
	fetchLinksTypes,
} from './categoryActions';

export {
	getMetadata,
	putFile,
	clearUploadFile,
	saveLink,
	uploadFile,
	putFileBeforeCreate,
	postDocumentHandler
} from './uploadFileActions';

export {
  loadNewHome,
  getPopularContentsPaginate,
  getPopularContentsWithFilters,
} from "./homeActions";

export {
	LOADING_PROFILE,
	FETCH_PROFILE,
	FETCH_PROFILE_PERFORMANCE,
	LOADING,
	END_PUT_PROFILE,
	OTHER_PROFILE,
	FOLLOW_PROFILE,
	UNFOLLOW_PROFILE,
	FETCH_FOLLOWING,
	FETCH_FOLLOWERS,
	PUT_PROFILE,
	LOG_OUT,
	logOut,
	loadingProfile,
	fetchProfile,
	fetchProfilePerformance,
	fetchOtherProfileByEmail,
	fetchProfileByEmail,
	fetchOtherProfile,
	getProfileCategories,
	EditProfileNew,
	fetchFollowers,
	fetchFollowing,
	putProfile,
	getProfilesToShare,
	getFollowersProfile,
	getFollowingsProfile
} from './profileActions';

export {
	FETCH_MY_CONTENT,
	FETCH_MY_BOOKLIST,
	START_CONTENT_LIST_FETCH,
	FETCH_OTHERS_BOOKLISTS,
	FETCH_OTHERS_CONTENT,
	START_MY_CONTENT_LIST_FETCH,
	fetchMyContent,
	fetchOthersContent,
	fetchOthersContentFunction,
	getUserBooklist,
	fetchMyBooklists,
	fetchBooklistPermissions,
	getMyBooklists,
} from './contentListActions';

export {
	putNotification,
	fetchNotifications,
	fetchNotificitionMessages,
	fetchCountMessages,
	fetchCountNotifications,
	putPlatformNotifications
} from './notificationActions';

export {
	LOADING_INSTITUTION,
	FETCH_INSTITUTION,
	fetchEnvironment,
	getInstitutions
} from './institutionActions';



export {
	loginStat,
	visitStats,
	getVisits,
	postRedirectSearch,
	postRedirectContent
} from './statsActions';




export {
	SEARCH_PROFILES_BOOKLIST,
	END_SEARCH_PROFILES_BOOKLIST,
	getProfiles,
	putFollowing,
	deleteFollowing,
	postHide,
	searchProfiles,
	onboardingCount,
	getLinkTypes,
	getProfile,
	fetchRoles,
	searchProgram,
	getSearchProfile
} from './ProfilesHomeActions';

export {
	postPermissions,
	postContentToBooklist,
} from './booklistActionsNew';

export {
	advancedSearchNew,
	searchNavBar,
	filterSearchNew
} from './searchNewActions';

export {
	loadHome,
	openPopUp,
	refreshHome,
	setPopUpShare,
} from './explorationActions';


export {
	getBooklistDetail,
	getContentsBooklists,
	getMoreContentsBooklist,
	putBooklist,
	putBooklistSection,
	postBooklistSection,
	deleteContentBooklist,
	getBooklistSession,
	getBooklistPermissions,
	addColaborator,
	deleteColaborator,
	editColaborator,
	postBooklistPermissions,
	putContentToBooklist,
	removeSection,
	deleteSection,
	fetchSectionsOfBooklist,
	putMoveContentToSection,
} from './booklistV2Actions';

export {
	openContextMenu,
	getMyBooklist,
	getMoreMyBooklist,
	getBooklistsCollaborative,
	getMoreBooklistsCollaborative,
	resetState,
	postAddContentInBooklist,
	postSearchBooklistsCollaborative,
	postSearchMyBooklists
} from './contextMenuActions';
