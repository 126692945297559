import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button, { appearances } from './Button';

import styles from './styles/popUp.scss';
import { environment } from '../../actions';
import { breakPoint, getResources } from '../../../utils/constans';

const PopUp = ({
	children,
	closePopUp,
	style,
	buttons,
	isButtonFooter
}) => {

	const [isMobile, setIsMobile] = useState(
		window.innerWidth < breakPoint.mobileWide,
	);

	useEffect(() => {
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleResize = () => {
		setIsMobile(window.innerWidth < breakPoint.mobileWide);
	};

	return (
		<div className={styles.contentPop} style={style.zIndex ? {zIndex:style.zIndex} : {zIndex:'10'}}>
			<div onClick={closePopUp} className={styles.fixedClosePop}></div>
			<div
				className={style.popUp.maxHeight === 644 ? styles.boxPopUp_hg_644 : styles.boxPopUp}
				style={style.popUp}
			>
				<div style={
					{			
						display: style.popUp.display,
						margin: style.popUpContent.margin,
					}
				}>
					<div onClick={closePopUp} className={styles.iconClose}>
						<img className={styles.icon20} src={getResources(environment).iconClose} alt="" />
					</div>
					{buttons.goToback && !buttons.goToback.hidden && (
						<div onClick={buttons.goToback.action} className={styles.back}>
							<img className={styles.icon20} src={getResources(environment).iconArrowLeft} alt="" />
							<p>{buttons.goToback.title}</p>
						</div>
					)}

					<div style={
						isMobile ? 
							{ 
								display: 'flex',
								justifyContent: 'center',
							} : 
							{ 
								display: 'flex',
							}
					}>
						{children}
					</div>
					{!isButtonFooter &&
						<div style={{ ...style.button, display: 'flex' }}>
							{buttons.primary && (
								<Button
									title={buttons.primary.title}
									width={buttons.style.width}
									height={buttons.style.height}
									borderRadius={buttons.style.borderRadius}
									appearance={buttons.primary.type}
									marginRight={buttons.style.marginRight}
									icon={buttons.primary.icon}
									materialIcon={buttons.primary.materialIcon}
									action={buttons.primary.action}
									loader={buttons.primary.loading}
									disabled={buttons.primary.disabled}
									style={buttons.style}
								/>
							)}

							{buttons.secondary && (
								<Button
									title={buttons.secondary.title}
									width={buttons.style.width}
									height={buttons.style.height}
									borderRadius={buttons.style.borderRadius}
									appearance={buttons.secondary.type}
									icon={buttons.secondary.icon}
									materialIcon={buttons.secondary.materialIcon}
									action={buttons.secondary.action}
									loader={buttons.secondary.loading}
									disabled={buttons.secondary.disabled}
									style={buttons.style.secondary}
								/>
							)}
						</div>
					}
				</div>
			</div>
			{isButtonFooter &&
				<div
					className={styles.popupFooter}
					style={{
						maxWidth: style.popUp.maxWidth,
					}}
				>
					{buttons.primary && (
						<Button
							title={buttons.primary.title}
							width={buttons.style.width}
							height={buttons.style.height}
							borderRadius={buttons.style.borderRadius}
							appearance={buttons.primary.type}
							marginRight={buttons.style.marginRight}
							icon={buttons.primary.icon}
							materialIcon={buttons.primary.materialIcon}
							action={buttons.primary.action}
							loader={buttons.primary.loading}
							disabled={buttons.primary.disabled}
						/>
					)}

					{buttons.secondary && (
						<Button
							title={buttons.secondary.title}
							width={buttons.style.width}
							height={buttons.style.height}
							borderRadius={buttons.style.borderRadius}
							appearance={buttons.secondary.type}
							icon={buttons.secondary.icon}
							materialIcon={buttons.secondary.materialIcon}
							action={buttons.secondary.action}
							loader={buttons.secondary.loading}
							disabled={buttons.secondary.disabled}
						/>
					)}
				</div>
			}
		</div>
	);
};

PopUp.prototype = {
	closePopUp: PropTypes.func,
	style: PropTypes.shape({
		zIndex:PropTypes.number,
		popUp: {
			maxHeight: PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.string,
			]),
			maxWidth: PropTypes.string,
			zIndex:PropTypes.number,
		},
		popUpContent: {
			display: PropTypes.string,
			margin: PropTypes.string
		},
		button: {
			marginTop: PropTypes.string,
			marginLeft: PropTypes.string,
			justifyContent: PropTypes.string,
		}
	}),
	buttons: PropTypes.shape({
		style: PropTypes.shape({
			width: PropTypes.string,
			height: PropTypes.string,
			borderRadius: PropTypes.string,
			marginRight: PropTypes.string,
		}),
		primary: PropTypes.shape({
			icon: PropTypes.string,
			title: PropTypes.string,
			type: PropTypes.oneOf([appearances.primary, appearances.secondary, appearances.red]),
			disabled: PropTypes.bool,
			action: PropTypes.func,
			loading: PropTypes.bool,
			materialIcon: PropTypes.string,
		}),
		secondary: PropTypes.shape({
			icon: PropTypes.string,
			title: PropTypes.string,
			disabled: PropTypes.bool,
			type: PropTypes.oneOf([appearances.primary, appearances.secondary, appearances.red]),
			action: PropTypes.func,
			loading: PropTypes.bool,
			materialIcon: PropTypes.string,
		}),
		goToback:PropTypes.shape({
			title: PropTypes.string,
			action: PropTypes.func,
		})
	})

};

export default PopUp;