import React from 'react';
import styles from './styles/sideBarTab.scss';

const SideBarTab = ({
	customLogo,
	materialLogo,
	title,
	onClick,
	isTabActive,
	isMobile,
}) => {
	const iconStyle = {
		color: isTabActive ? 'var(--primary-color)' : '#4a4a4a',
	};

	if(isMobile) {
		return (
			<>
				<div className={styles.sideBarTabMobile} onClick={onClick}>
					<span 
						className={isTabActive ? styles.tabTitleActive : styles.tabTitle}>
						{title}
					</span>
				</div>
			</>
		);	
	}
	return (
		<>
			<div className={styles.separator}></div>
			<div className={styles.sideBarTab} onClick={onClick}>
				{customLogo ? (
					<img
						src={customLogo}
						alt={title}
						style={iconStyle}
						className={styles.tabLogo}
					/>
				) : (
					<div className={styles.tabLogo}>
						<span
							className="material-symbols-outlined"
							style={iconStyle}
						>
							{materialLogo}
						</span>
					</div>
				)}
				<span
					className={
						isTabActive ? styles.tabTitleActive : styles.tabTitle
					}
				>
					{title}
				</span>
			</div>
		</>
	);
};

export default SideBarTab;
